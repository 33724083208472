import * as React from "react"
import { useListShareSuggestedUsersQuery } from "@digits-graphql/frontend/graphql-bearer"
import { Contact } from "@digits-graphql/frontend/graphql-public"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgUserPlus01 } from "@digits-shared/components/SVGIcons/line/UserPlus01.svg"
import { Input, InputElementProps } from "@digits-shared/components/UI/Elements/Form/Input"
import { Keys } from "@digits-shared/components/UI/Elements/Keys"
import { EMAIL_ADDRESS_PATTERN_REGEX } from "@digits-shared/helpers/emailAddressPattern"
import objectHelper from "@digits-shared/helpers/objectHelper"
import stringHelper from "@digits-shared/helpers/stringHelper"
import { useFormTracking } from "@digits-shared/hooks/useFormTracking"
import useForwardedRef from "@digits-shared/hooks/useForwardedRef"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { AutoComplete } from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/Content/InviteClients/AutoComplete"
import { InviteClientAction } from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/State/actions"
import { InviteClientState } from "src/frontend/components/OS/Applications/ClientPortal/InviteFlow/State/types"
import { CONTAINER_STYLES } from "src/frontend/components/OS/Shared/ClientPortal/ClientPortalShared"
import { InviteClientsModalState } from "src/frontend/components/Shared/Portals/State/types"
import FrontendSession from "src/frontend/session"

enum ClientContactField {
  givenName = "givenName",
  familyName = "familyName",
  emailAddress = "emailAddress",
  avatarUrl = "avatarUrl",
}

/*
  STYLES
*/

const StyledForm = styled.form<{ disabled: boolean }>`
  ${CONTAINER_STYLES};
  padding: 10px 18px 0;
  margin-bottom: 12px;
`

const Row = styled.div`
  display: flex;
  gap: 16px;
`

const AddUserIcon = styled(SvgUserPlus01)`
  ${svgPathStyles("rgba(39, 76, 105)", 1.5)};
  height: 20px;
  width: 20px;
  padding-left: 2px;
`

const AddUserIconWrapper = styled.div`
  border-radius: 50%;
  background: #71f5bb;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: flex;
  height: 25px;
  width: 25px;
  align-items: center;
  justify-content: center;
`

const AddMoreClients = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: none;
  font-size: 12px;
  color: ${colors.secondary};
  transition: opacity 100ms;
  align-self: flex-start;
  text-transform: uppercase;
  font-weight: ${fonts.weight.heavy};
  margin-left: 8px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.6;
      filter: grayscale(80%);
    `}
`

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  gap: 12px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const AddClientForm: React.FC<{
  state: InviteClientState
  dispatch: React.Dispatch<InviteClientAction>
  addClient: (client: Contact) => void
}> = ({ state, dispatch, addClient }) => {
  const { userPluralNoun } = state
  const { user } = useSession<FrontendSession>()
  const { currentLegalEntityId: legalEntityId } = useSession<FrontendSession>()
  const {
    onFieldBlurred,
    onFieldChanged,
    isFieldInvalid,
    resetFields,
    fieldHasValidValue,
    getFieldValue,
    updateFieldState,
  } = useFormTracking<ClientContactField>({ optionalFields: [ClientContactField.avatarUrl] })

  // store the list of suggested users
  useListShareSuggestedUsersQuery({
    variables: {
      legalEntityId,
    },
    onCompleted: (data) => {
      const autocompleteUsers = data.listShareSuggestedUsers.map((u) => ({
        avatarUrl: u.avatarUrl,
        emailAddress: u.primaryEmailAddress,
        familyName: u.familyName,
        givenName: u.givenName,
      }))

      dispatch({
        type: "setAutocompleteUsers",
        autocompleteUsers,
      })
    },
  })

  const formHasValue = objectHelper
    .keysOf(ClientContactField)
    .some((f) => !!getFieldValue(ClientContactField[f]))

  const emailIsSelf = React.useMemo(
    () => getFieldValue(ClientContactField.emailAddress) === user.emailAddress,
    [getFieldValue, user.emailAddress]
  )

  React.useEffect(() => {
    if (emailIsSelf) {
      dispatch({
        type: "setErrorMessage",
        message: (
          <>
            The email address entered is already associated with an existing account. Please invite
            a user with a new email address.
          </>
        ),
      })
    } else {
      dispatch({
        type: "setErrorMessage",
        message: undefined,
      })
    }
  }, [dispatch, emailIsSelf])

  const invalid = objectHelper
    .keysOf(ClientContactField)
    .some((f) => !fieldHasValidValue(ClientContactField[f]) || emailIsSelf)

  const resetForm = React.useCallback(
    (focusEmail?: boolean) => {
      resetFields()
      formRef.current?.reset()
      if (focusEmail) {
        emailInputRef.current?.focus()
      }
    },
    [resetFields]
  )

  const contactFromFields = React.useCallback(() => {
    if (invalid) {
      return undefined
    }

    const contact: Contact = {
      avatarUrl: getFieldValue(ClientContactField.avatarUrl) ?? "",
      emailAddress: getFieldValue(ClientContactField.emailAddress) ?? "",
      givenName: getFieldValue(ClientContactField.givenName),
      familyName: getFieldValue(ClientContactField.familyName),
    }

    return contact
  }, [getFieldValue, invalid])

  const onSubmit = React.useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()

      const contact = contactFromFields()
      if (!contact) return

      addClient(contact)
      resetForm(true)
    },
    [contactFromFields, addClient, resetForm]
  )

  const onAutoCompleted = React.useCallback(
    (contact: Contact) => {
      if (formRef?.current) {
        ;[
          [ClientContactField.emailAddress, contact.emailAddress],
          [ClientContactField.givenName, contact.givenName ?? ""],
          [ClientContactField.familyName, contact.familyName ?? ""],
          [ClientContactField.avatarUrl, contact.avatarUrl ?? ""],
        ].forEach(([field, value]) => {
          const element = formRef.current?.querySelector<HTMLInputElement>(`[name=${field}]`)

          if (element) {
            element.value = value || ""
          }
        })
      }

      setTimeout(() => {
        updateFieldState(ClientContactField.emailAddress, contact.emailAddress, true)
        updateFieldState(ClientContactField.givenName, contact.givenName ?? "", !!contact.givenName)
        updateFieldState(
          ClientContactField.familyName,
          contact.familyName ?? "",
          !!contact.familyName
        )
        updateFieldState(ClientContactField.avatarUrl, contact.avatarUrl ?? "", true)
        dispatch({ type: "hideAutocomplete" })
      }, 1)
    },
    [dispatch, updateFieldState]
  )

  const disableAutocomplete = React.useCallback(() => {
    dispatch({ type: "hideAutocomplete" })
  }, [dispatch])

  const enableAutocomplete = React.useCallback(() => {
    dispatch({ type: "showAutocomplete" })
  }, [dispatch])

  // Resets the state of the form if the modal transitions away from this state where it is shown.
  React.useEffect(() => {
    if (state.modalState !== InviteClientsModalState.InviteClients) {
      formRef.current?.reset()
      resetFields()
    }
  }, [resetFields, state.modalState])

  React.useEffect(() => {
    if (state.sendingInvitations) {
      resetForm(false)
    }
  }, [resetForm, state.sendingInvitations])

  React.useEffect(() => {
    const client = invalid ? undefined : contactFromFields()
    dispatch({ type: "setValidClientInForm", client })
    dispatch({ type: "setInviteFormValid", valid: !formHasValue || !invalid })
  }, [contactFromFields, dispatch, formHasValue, invalid])

  const formRef = React.useRef<HTMLFormElement>(null)
  const emailInputRef = React.useRef<HTMLInputElement>(null)

  return (
    <>
      <StyledForm ref={formRef} disabled={state.sendingInvitations}>
        <Row>
          <EmailAutoCompleteInput
            ref={emailInputRef}
            state={state}
            dispatch={dispatch}
            onAutoCompleted={onAutoCompleted}
            placeholder="Email"
            name={ClientContactField.emailAddress}
            autoFocus
            autoComplete="off"
            pattern={EMAIL_ADDRESS_PATTERN_REGEX}
            type="text"
            onFocus={enableAutocomplete}
            onChange={onFieldChanged}
            onBlur={onFieldBlurred}
            isInvalid={isFieldInvalid(ClientContactField.emailAddress)}
            disabled={state.sendingInvitations}
            required
          />
        </Row>
        <Row>
          <Input
            placeholder="First Name"
            name={ClientContactField.givenName}
            autoComplete="off"
            type="text"
            onFocus={disableAutocomplete}
            onChange={onFieldChanged}
            onBlur={onFieldBlurred}
            isInvalid={isFieldInvalid(ClientContactField.givenName)}
            disabled={state.sendingInvitations}
            required
          />
          <Input
            placeholder="Last Name"
            name={ClientContactField.familyName}
            autoComplete="off"
            type="text"
            onFocus={disableAutocomplete}
            onChange={onFieldChanged}
            onBlur={onFieldBlurred}
            isInvalid={isFieldInvalid(ClientContactField.familyName)}
            disabled={state.sendingInvitations}
            required
          />
        </Row>
        <Input
          hidden
          name={ClientContactField.avatarUrl}
          autoComplete="off"
          type="text"
          onChange={onFieldChanged}
          onBlur={onFieldBlurred}
          isInvalid={isFieldInvalid(ClientContactField.avatarUrl)}
          disabled={state.sendingInvitations}
        />
      </StyledForm>
      <BottomRow>
        <Buttons>
          <AddMoreClients onClick={onSubmit} disabled={invalid}>
            <AddUserIconWrapper>
              <AddUserIcon />
            </AddUserIconWrapper>
            Add More {stringHelper.titleCase(userPluralNoun)}
          </AddMoreClients>
        </Buttons>
      </BottomRow>
    </>
  )
}

interface EmailInputProps extends InputElementProps {
  state: InviteClientState
  dispatch: React.Dispatch<InviteClientAction>
  onAutoCompleted: (c: Contact) => void
}

export const EmailAutoCompleteInput = React.forwardRef<HTMLInputElement, EmailInputProps>(
  (
    {
      state,
      dispatch,
      onAutoCompleted,
      placeholder,
      name,
      autoComplete,
      autoFocus,
      pattern,
      type,
      onChange,
      onFocus,
      onBlur,
      isInvalid,
      required,
      tabIndex,
      disabled,
    },
    ref
  ) => {
    const forwardedRef = useForwardedRef(ref)
    const inputRef = ref ? forwardedRef : React.createRef<HTMLInputElement>()

    const inputOnKeyUp = React.useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        const { key } = event
        if (key === Keys.Escape) {
          dispatch({ type: "hideAutocomplete" })
          event.preventDefault()
        }
      },
      [dispatch]
    )

    return (
      <>
        <Input
          forwardRef={inputRef}
          placeholder={placeholder}
          name={name}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          pattern={pattern}
          type={type}
          onChange={onChange}
          onKeyUp={inputOnKeyUp}
          onFocus={onFocus}
          onBlur={onBlur}
          isInvalid={isInvalid}
          required={required}
          tabIndex={tabIndex}
          disabled={disabled}
        />
        <AutoComplete state={state} inputRef={inputRef} onAutoCompleted={onAutoCompleted} />
      </>
    )
  }
)
