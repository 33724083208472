import * as React from "react"
import { useInvertValues } from "@digits-shared/components/Contexts/InvertValuesContext"
import { Group } from "@visx/group"
import { Bar, BarRounded } from "@visx/shape"
import { ScaleBand, ScaleLinear } from "d3-scale"
import { StackableBarChartLabelForAllBarPeriods } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/Label/StackableBarChartLabelForAllBarsPeriods"
import {
  ChartPeriodsMap,
  STACKABLE_BAR_CHART_X_INNER_PADDING,
  stackableBarChartBarHeight,
  stackableBarChartXData,
  stackableBarChartYData,
  StackableBarData,
  StackableBarDimensions,
  useIsCurrentPeriod,
  useStackableBarChartClickGenerator,
  useStackableBarChartShowTooltipGenerator,
} from "src/frontend/components/OS/Shared/Charts/StackableBarChart/Shared"
import {
  StackableBarChartLabelType,
  useStackableBarChartContext,
} from "src/frontend/components/OS/Shared/Charts/StackableBarChart/StackableBarChartContext"

const BORDER_RADIUS = 3

/*
  COMPONENTS
*/

export const StackableBar: React.FC<{
  graphWidth: number
  graphHeight: number
  dataSet: StackableBarData[]
  xScale: ScaleBand<number>
  yScale: ScaleLinear<number, number>
  periods: ChartPeriodsMap
  yMax: number
  yOffset: number
  barData: StackableBarData
  index: number
}> = ({ graphHeight, dataSet, xScale, yScale, periods, yMax, yOffset, barData, index }) => {
  const invertValues = useInvertValues()
  const {
    glowFilterId,
    isHoveringOnBar,
    tooltipData,
    hideTooltip,
    onBarClick,
    onMouseOver,
    onMouseOut,
    otherDimensionName,
    label,
  } = useStackableBarChartContext()

  const isCurrent = useIsCurrentPeriod(barData.period.startedAt)

  const periodBarData = React.useMemo(
    () => periods[barData.period.startedAt] || [],
    [periods, barData.period.startedAt]
  )

  const onClickGenerator = useStackableBarChartClickGenerator()
  const showTooltipGenerator = useStackableBarChartShowTooltipGenerator()
  const onBarOver = React.useCallback(
    (event: React.MouseEvent) => {
      const periodBarIndex = Object.values(periods).indexOf(periodBarData)
      showTooltipGenerator(barData)(event)
      onMouseOver?.(barData, periodBarIndex)
    },
    [barData, onMouseOver, periodBarData, periods, showTooltipGenerator]
  )

  const onBarOut = React.useCallback(
    (event: React.MouseEvent) => {
      hideTooltip()
      onMouseOut?.(barData)
    },
    [barData, hideTooltip, onMouseOut]
  )

  const dimensions = React.useMemo(
    () =>
      calculateBarDimensions(
        invertValues,
        dataSet,
        barData,
        index,
        xScale,
        yScale,
        periodBarData,
        yMax,
        yOffset
      ),
    [invertValues, dataSet, barData, index, xScale, yScale, periodBarData, yMax, yOffset]
  )

  const nextPeriodStartedAt = React.useMemo(() => {
    const periodKeys = Object.keys(periods)
    const currentIndex = periodKeys.findIndex(
      (startedAt) => parseInt(startedAt, 10) === barData.period.startedAt
    )
    if (currentIndex === -1) return

    const nextPeriod = periodKeys[currentIndex + 1]

    return nextPeriod ? parseInt(nextPeriod, 10) : undefined
  }, [periods, barData.period.startedAt])

  const isNegative = stackableBarChartYData(invertValues, barData) < 0
  const firstStackSelector = isNegative ? Math.max : Math.min
  const firstStackIndex = React.useMemo(
    () => firstStackSelector(...periodBarData.map((b) => b.stackIndex)),
    [firstStackSelector, periodBarData]
  )
  const lastStackSelector = isNegative ? Math.min : Math.max
  const lastStackIndex = React.useMemo(
    () => lastStackSelector(...periodBarData.map((b) => b.stackIndex)),
    [lastStackSelector, periodBarData]
  )

  const isFirstStack = barData.stackIndex === firstStackIndex
  const isLastStack = React.useMemo(() => {
    if (barData.stackIndex === lastStackIndex) {
      return true
    }

    // if all of the other stacks after this one have totals of $0,
    // then this is effectively the last stack
    const nextStacks = periodBarData.filter((b) => b.stackIndex > barData.stackIndex)
    return !nextStacks?.find((b) => stackableBarChartYData(invertValues, b) !== 0)
  }, [barData.stackIndex, lastStackIndex, periodBarData, invertValues])

  const isHovered = isHoveringOnBar(barData)

  const nextX = xScale(nextPeriodStartedAt ?? 0) ?? 0

  const renderable = !!dimensions.height && !!dimensions.width
  const showGlow = renderable && ((isCurrent && (isHovered || !tooltipData)) || isHovered)
  const fill = useFillUrl(barData)

  return (
    <Group key={index}>
      {label === StackableBarChartLabelType.AllBarPeriods && (
        <StackableBarChartLabelForAllBarPeriods
          isLastStack={isLastStack}
          graphHeight={graphHeight}
          periodBarData={periodBarData}
          barDimensions={dimensions}
        />
      )}
      <BarRounded
        id={`${index}-${barData.legend}-${barData.stackIndex}`}
        css={onBarClick && barData.dataId !== otherDimensionName ? "cursor: pointer;" : ""}
        filter={showGlow ? `url(#${glowFilterId})` : ""}
        fill={renderable ? fill : "transparent"}
        radius={BORDER_RADIUS}
        top={isLastStack}
        bottom={isFirstStack}
        x={dimensions.x}
        y={dimensions.y}
        width={dimensions.width}
        height={dimensions.height}
        onMouseMove={onBarOver}
        onMouseLeave={onBarOut}
        onClick={onClickGenerator(barData)}
      />
      <Bar
        css={onBarClick && barData.dataId !== otherDimensionName ? "cursor: pointer;" : ""}
        x={dimensions.x - STACKABLE_BAR_CHART_X_INNER_PADDING}
        y={dimensions.y}
        fill="transparent"
        width={!nextX ? dimensions.width : nextX - dimensions.x < 0 ? 0 : nextX - dimensions.x}
        height={dimensions.height}
        onMouseMove={onBarOver}
        onMouseLeave={onBarOut}
        onClick={onClickGenerator(barData)}
      />
    </Group>
  )
}

function calculateBarDimensions(
  invertValues: boolean,
  dataSet: StackableBarData[],
  barData: StackableBarData,
  index: number,
  xScale: ScaleBand<number>,
  yScale: ScaleLinear<number, number>,
  periodData: StackableBarData[],
  yMax: number,
  yOffset: number
): StackableBarDimensions {
  let accumulatedHeight = 0
  for (let pos = 0; pos <= periodData.length && pos < barData.stackIndex; pos += 1) {
    const lowerBarData = periodData.filter((d) => d.stackIndex === pos)
    accumulatedHeight = lowerBarData.reduce(
      (accHeight, lbd) =>
        accHeight +
        stackableBarChartBarHeight(stackableBarChartYData(invertValues, lbd), yScale, yMax),
      accumulatedHeight
    )
  }

  const yD = stackableBarChartYData(invertValues, barData)
  const height = stackableBarChartBarHeight(yD, yScale, yMax)

  const xValue = stackableBarChartXData(barData)
  const x = xScale(xValue) || 0
  const width = xScale.bandwidth()

  let y = yD < 0 ? yOffset : yOffset - height
  y = yD < 0 ? y + accumulatedHeight : y - accumulatedHeight

  return {
    height,
    y,
    x,
    width,
  }
}

function useFillUrl(barData: StackableBarData) {
  const invertValues = useInvertValues()
  const { tooltipData, isHoveringOnBar } = useStackableBarChartContext()

  const isCurrent = useIsCurrentPeriod(barData.period.startedAt)
  const isHovered = isHoveringOnBar(barData)

  return React.useMemo(() => {
    const fillIds = barData.barColorIds

    let fillId: string | undefined
    switch (true) {
      case isCurrent && isHovered:
        fillId = fillIds.activePeriodHoverFillId
        break
      case isCurrent && !!tooltipData:
        fillId = fillIds.inactivePeriodFillId
        break
      case isCurrent:
        fillId = fillIds.activePeriodFillId
        break
      case isHovered:
        fillId = fillIds.inactivePeriodHoverFillId
        break
      default:
        fillId = fillIds.inactivePeriodFillId
    }

    const amount = stackableBarChartYData(invertValues, barData)
    return amount < 0 ? `url(#neg-${fillId})` : `url(#${fillId})`
  }, [barData, isCurrent, isHovered, tooltipData, invertValues])
}
