import * as React from "react"
import { TimeseriesLineChartStyle } from "src/frontend/components/OS/Shared/Charts/styles"
import { TileChartContainer } from "src/frontend/components/Shared/Layout/Components/Charts/shared"
import { ParentSizedTimeseriesLineChart } from "src/frontend/components/Shared/Layout/Components/Charts/TimeseriesLineChart"
import {
  TimeseriesValue,
  TimeseriesValues,
} from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { TileCard } from "src/frontend/components/Shared/Layout/Components/Tiles/TileCard"
import { TimeseriesCardHeading } from "src/frontend/components/Shared/Layout/Components/Tiles/TimeseriesCardHeading"

/*
 STYLES
*/

/*
 COMPONENTS
*/

interface TileProps {
  timeseries: TimeseriesValues
  skipAnimations?: boolean
  height: number
  selectedIndex?: number
  onMouseOver: (value: TimeseriesValue, index: number) => void
  onMouseOut: (value?: TimeseriesValue) => void
  onClick?: (value: TimeseriesValue, index: number) => void
  chartStyle?: TimeseriesLineChartStyle
}

export const TimeseriesLineChartTile: React.FC<TileProps> = ({
  timeseries,
  height,
  skipAnimations,
  selectedIndex,
  onMouseOver,
  onMouseOut,
  onClick,
  chartStyle,
}) => (
  <TileCard height={height} skipAnimations={skipAnimations}>
    <TimeseriesCardHeading timeseries={timeseries} selectedIndex={selectedIndex} />
    <TileChartContainer height={78} width="auto">
      <ParentSizedTimeseriesLineChart
        timeseries={timeseries}
        skipAnimations={skipAnimations}
        noTooltip
        hideAxis
        hideGrid
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onClick={onClick}
        chartStyle={chartStyle}
      />
    </TileChartContainer>
  </TileCard>
)
