import * as React from "react"
import { Period } from "@digits-graphql/frontend/graphql-bearer"
import { useInvertValues } from "@digits-shared/components/Contexts/InvertValuesContext"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import dateTimeHelper, { DateFormat } from "@digits-shared/helpers/dateTimeHelper"
import numberHelper, { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { STACKABLE_BAR_CHART_SINGLE_LABEL_HEIGHT } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/Shared"
import { useStackableBarChartContext } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/StackableBarChartContext"

/*
 STYLES
*/

const AmountLabel = styled.div`
  font-size: 18px;
  color: ${colors.neonGreen};
`

const PeriodLabel = styled.div`
  font-size: 11px;
  font-family: ${fonts.family.avenir};
  color: ${colors.secondary};
  text-transform: uppercase;
`

const LabelSpacing = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${STACKABLE_BAR_CHART_SINGLE_LABEL_HEIGHT}px;
`

/*
 COMPONENTS
*/

export const StackableBarChartLabelForSingleBar: React.FC<{
  useChildrenAsDefault?: boolean
  children?: React.ReactNode
}> = ({ useChildrenAsDefault, children }) => {
  const { tooltipData, currentPeriodBarData, loading } = useStackableBarChartContext()
  const invertValues = useInvertValues()

  const [totalCurrentPeriodValue, currentPeriod] = React.useMemo(() => {
    let period: Period | undefined
    const totalValue = Object.values(currentPeriodBarData || {}).reduce((agg, barData) => {
      if (!period) period = barData[0]?.period
      agg.amount += barData.reduce((total, d) => total + d.total.value.amount, 0)
      return agg
    }, numberHelper.buildMonetaryAmount())

    return [totalValue, period]
  }, [currentPeriodBarData])

  if (loading) {
    return (
      <LabelSpacing>
        <LoadingBlock width="80px" height="20px" />
        <LoadingBlock width="95px" height="16px" margin="10px" />
      </LabelSpacing>
    )
  }

  if (!tooltipData && useChildrenAsDefault) return <>{children}</>

  const value = tooltipData?.total.value || totalCurrentPeriodValue
  const renderOptions = { style: CurrencyStyle.Summary, invertValues }
  const amount = value ? numberHelper.currency(value, renderOptions) : ""

  const period = tooltipData?.period || currentPeriod
  const periodLabel = period && dateTimeHelper.displayNameFromPeriod(period, DateFormat.Short)

  return (
    <LabelSpacing>
      <AmountLabel>{tooltipData ? amount : ""}&nbsp;</AmountLabel>
      {tooltipData?.legend && <PeriodLabel>{periodLabel && `in ${periodLabel}`}&nbsp;</PeriodLabel>}
    </LabelSpacing>
  )
}
