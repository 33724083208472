import * as React from "react"
import { PropsWithChildren } from "react"
import { LayoutComponent, LayoutComponentType } from "@digits-graphql/frontend/graphql-bearer"
import { svgIconStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgDownload01 } from "@digits-shared/components/SVGIcons/line/Download01.svg"
import { SvgTrash01 } from "@digits-shared/components/SVGIcons/line/Trash01.svg"
import { SvgDotsVerticalSolid } from "@digits-shared/components/SVGIcons/solid/DotsVerticalSolid.svg"
import { Menu, MenuContent, MenuItem, MenuTrigger } from "@digits-shared/DesignSystem/Menu"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import { useExportToExcel } from "src/frontend/components/Shared/Layout/Components/Statements/Excel/useExportToExcel"
import { useCollapseAllSections } from "src/frontend/components/Shared/Layout/Components/Statements/useCollapseAllSections"
import { useComponentTitle } from "src/frontend/components/Shared/Layout/Components/useComponentTitle"
import { useConfigOriginOverride } from "src/frontend/components/Shared/Layout/hooks/useConfigOriginOverride"
import { COMPONENT_RADIUS } from "src/frontend/components/Shared/Layout/Shared"
import { matchComponent, MatchedComponent } from "src/frontend/components/Shared/Layout/types"
import { usePortalStatementLiveData } from "src/frontend/components/Shared/Portals/hooks/usePortalStatementLiveData"
import {
  usePortalDispatch,
  usePortalStore,
} from "src/frontend/components/Shared/Portals/State/portalStore"
import { useIsEditLayoutActive } from "src/frontend/components/Shared/Portals/State/useIsEditLayoutActive"
import { useStopClickPropagation } from "src/shared/hooks/useStopClickPropagation"

/*
  STYLES
*/

export const ComponentControls = styled.div`
  position: relative;
  border-radius: ${COMPONENT_RADIUS}px;
`

const ComponentActionsIcon = styled(SvgDotsVerticalSolid)`
  width: 20px;
  height: 20px;
  transition: fill 250ms ease;
  ${svgIconStyles(colors.translucentBlack20)};
  cursor: pointer;
`

const ComponentActionsIconsContainer = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 18px;
  right: 10px;
  width: 15px;
  height: 30px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background: ${colors.translucentSecondary10};

    ${ComponentActionsIcon} {
      ${svgIconStyles(colors.translucentBlack40)};
    }
  }
`

/*
  INTERFACES
*/

interface ComponentControlsProps {
  component: LayoutComponent
  isDragging: boolean | undefined
}

/*
  COMPONENTS
*/

export const ComponentControlsWrapper: React.FC<
  PropsWithChildren<
    ComponentControlsProps & {
      className?: string
    }
  >
> = ({ className, component, isDragging, children }) => {
  const isEditLayoutActive = useIsEditLayoutActive()

  return (
    <ComponentControls className={className} data-context-id={component.componentId}>
      {children}
      {isEditLayoutActive && !isDragging && (
        <ComponentEditActions component={component} isDragging={isDragging} />
      )}
      {!isEditLayoutActive && !isDragging && (
        <ComponentViewActions component={component} isDragging={undefined} />
      )}
    </ComponentControls>
  )
}

const ComponentViewActions: React.FC<ComponentControlsProps> = ({ component }) => {
  const isStatement = matchComponent(component, "statement", LayoutComponentType.Statement)
  if (!isStatement) return null

  return (
    <ComponentActionsMenu>
      <CollapsedView component={component} />
      <ExportExcel component={component} />
    </ComponentActionsMenu>
  )
}

const ComponentEditActions: React.FC<Required<ComponentControlsProps>> = ({
  component,
  isDragging,
}) => {
  const builderDispatch = usePortalDispatch()
  const deleteComponentAnimation = usePortalStore((state) => state.deleteComponentAnimation)
  const isStatement = matchComponent(component, "statement", LayoutComponentType.Statement)

  const onDeleteClick = React.useCallback(() => {
    builderDispatch({
      type: "animateDeleteComponent",
      componentId: component.componentId,
    })
  }, [builderDispatch, component])

  if (deleteComponentAnimation || isDragging) return null

  return (
    <ComponentActionsMenu>
      {isStatement && (
        <>
          <CollapsedView component={component} />
          <ExportExcel component={component} />
        </>
      )}
      <MenuItem onSelect={onDeleteClick} label="Delete" Icon={SvgTrash01} />
    </ComponentActionsMenu>
  )
}

const ComponentActionsMenu: React.FC<React.PropsWithChildren> = ({ children }) => {
  const stopPropagation = useStopClickPropagation()

  return (
    <Menu>
      <MenuTrigger asChild>
        <ComponentActionsIconsContainer
          onMouseDown={stopPropagation}
          onMouseUp={stopPropagation}
          onDrag={stopPropagation}
        >
          <ComponentActionsIcon />
        </ComponentActionsIconsContainer>
      </MenuTrigger>

      <MenuContent width={130} small>
        <div
          css="display: contents"
          onMouseDown={stopPropagation}
          onMouseUp={stopPropagation}
          onDrag={stopPropagation}
        >
          {children}
        </div>
      </MenuContent>
    </Menu>
  )
}

const ExportExcel: React.FC<{ component: MatchedComponent<"statement"> }> = ({ component }) => {
  const statementConfig = useConfigOriginOverride(component.config.statement, 1)
  const { title } = useComponentTitle(component)
  const config = React.useMemo(
    () => ({ ...component.config, statement: statementConfig }),
    [component.config, statementConfig]
  )
  const layoutId = usePortalStore((state) => state.layout.layoutId)
  const layoutVersionId = usePortalStore((state) => state.layout.layoutVersionId)
  const viewId = useViewVersion()

  const exportExcel = useExportToExcel({
    title,
    config,
    layoutId,
    layoutVersionId,
    viewId,
    dataId: undefined,
    options: undefined,
  })
  return <MenuItem label="Export to Excel..." Icon={SvgDownload01} onSelect={exportExcel} />
}

const CollapsedView: React.FC<{ component: MatchedComponent<"statement"> }> = ({ component }) => {
  const config = useConfigOriginOverride(component.config.statement, 1)
  const { statement } = usePortalStatementLiveData(config, LayoutComponentType.Statement)
  const { label, onClick, icon } = useCollapseAllSections(statement)
  return <MenuItem label={label} onSelect={onClick} Icon={icon} />
}
