import * as React from "react"
import urlHelper from "@digits-shared/helpers/urlHelper"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import routes from "src/frontend/routes"
import FrontendSession from "src/frontend/session"

const Message = styled.div`
  background-color: ${colors.orange};
  color: ${colors.white};
  margin: 0 20px 10px 20px;
  padding: 10px;
  border-radius: 2px;
  text-align: center;

  i {
    white-space: nowrap;
  }
`

export const DoppelgangerRedirect: React.FC = () => {
  const userId = useDoppelgangerRedirectUserId()
  const url = useDoppelgangerRedirectURL()
  if (!url) {
    return null
  }

  if (userId) {
    return (
      <Message>
        Please Doppelgang as <b>User ({userId})</b> before being redirected to:
        <br />
        <br />
        <i>{urlHelper.fullDashboardURLString(url)}</i>
      </Message>
    )
  }

  return (
    <Message>
      Please Doppelgang as the correct user before being redirected to:
      <br />
      <br />
      <i>{urlHelper.fullDashboardURLString(url)}</i>
    </Message>
  )
}

export function useDoppelgangerRedirectUserId() {
  const { location, search } = useRouter()
  if (location.name !== routes.internalDGRedirect.name) {
    return null
  }
  return search.get("user")
}

export function useDoppelgangerRedirectURL() {
  const { location, search } = useRouter()
  const encodedUrl = search.get("url")
  const urlStr = encodedUrl ? atob(encodedUrl) : ""
  if (location.name !== routes.internalDGRedirect.name || !urlHelper.isValidHttpUrl(urlStr)) {
    return null
  }
  const url = new URL(urlStr)
  return url.pathname + url.search
}

export function useDoppelgangerRedirect() {
  const { user, isDoppelganger } = useSession<FrontendSession>()
  const url = useDoppelgangerRedirectURL()
  const userId = useDoppelgangerRedirectUserId()

  if (!url) {
    return null
  }

  if ((userId && user.id !== userId) || !isDoppelganger) {
    return null
  }

  return url
}
