import * as React from "react"
import { WebNotification } from "@digits-graphql/frontend/graphql-bearer"
import useTimeout from "@digits-shared/hooks/useTimeout"
import { m } from "framer-motion"
import styled from "styled-components"
import {
  isWebNotification,
  WebNotificationItem,
} from "src/frontend/components/Shared/Alerts/Notifications/WebNotifications"
import { SystemWebNotification } from "src/frontend/types/SystemWebNotification"

const AlertWebNotificationItem = styled(WebNotificationItem)`
  opacity: 1;
  margin: 0;
`

const AlertItemContainer = styled.div`
  width: fit-content;
  pointer-events: auto;
  margin: 0 auto;
`

interface AlertItemProps {
  alert: WebNotification | SystemWebNotification
  removeAlert: (id: string) => void
  className?: string
}

export const AlertItem: React.FC<AlertItemProps> = ({ alert, removeAlert, className }) => {
  const onClose = React.useCallback(() => removeAlert(alert.id), [removeAlert, alert.id])

  useTimeout(
    () => {
      if (isWebNotification(alert) || alert.autoDismiss) {
        onClose()
      }
    },
    (!isWebNotification(alert) && alert?.autoDismissInMs) || 5000
  )

  return (
    <m.div
      layout
      initial={{ opacity: 0, y: 40 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
    >
      <AlertItemContainer className={className}>
        <AlertWebNotificationItem onClick={onClose} notification={alert} onCloseClick={onClose} />
      </AlertItemContainer>
    </m.div>
  )
}
