import { useDefinedConstant } from "@digits-shared/hooks/useConstant"
import { VaultFile } from "src/frontend/components/OS/Applications/Vault/types"
import { documentDownloadUrl } from "src/frontend/components/OS/Shared/DocumentViewer/helpers"

export function useFilePreview(file: VaultFile) {
  return useDefinedConstant(() => {
    const { thumbnail } = file
    if (!thumbnail) {
      return undefined
    }

    return documentDownloadUrl(thumbnail.collectionId, thumbnail.fileId, thumbnail.token)
  })
}
