import * as React from "react"
import {
  ProviderType,
  useReadDataSourcePreferencesQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import { JWTPermissionFlag } from "@digits-shared/session/jwt/jwtPermissions"
import { useHasPermission } from "src/frontend/components/Shared/Permissions/Requires"
import FrontendSession from "src/frontend/session"
import {
  FrontendPermissionModule,
  FrontendPermissionSource,
} from "src/frontend/session/permissionModule"

export function useDataSourcePreference(providerType: ProviderType) {
  const { currentLegalEntityId: legalEntityId } = useSession<FrontendSession>()

  const canRead = useHasPermission({
    source: FrontendPermissionSource.LegalEntity,
    module: FrontendPermissionModule.DataSources,
    flag: JWTPermissionFlag.Read,
  })

  const { data } = useReadDataSourcePreferencesQuery({
    variables: {
      legalEntityId,
    },
    skip: !canRead || !legalEntityId,
  })

  return React.useMemo(
    () => data?.readDataSourcePreferences.find((pref) => pref.providerType === providerType),
    [data?.readDataSourcePreferences, providerType]
  )
}
