/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: number; output: number; }
  Long: { input: number; output: number; }
  Void: { input: any; output: any; }
};

export type AccessConstraint = {
  partyRole?: InputMaybe<PartyRole>;
};

export type Address = {
  /** city is used for the city/town/village of the address */
  city?: Maybe<Scalars['String']['output']>;
  /** ISO country code */
  countryCode?: Maybe<Scalars['String']['output']>;
  /** Latitude */
  lat?: Maybe<Scalars['Float']['output']>;
  /** Longitude */
  long?: Maybe<Scalars['Float']['output']>;
  /** postal identifier for the address */
  postalCode?: Maybe<Scalars['String']['output']>;
  /** region is the state, region, or any other subdivision of the country */
  region?: Maybe<Scalars['String']['output']>;
  /** street contains the housing number and street name */
  street?: Maybe<Scalars['String']['output']>;
  /** street_additional is used for any other additional street identifying information */
  streetAdditional?: Maybe<Scalars['String']['output']>;
};

export enum AffiliateType {
  Accounting = 'Accounting',
  Investment = 'Investment'
}

export enum ArrivalContext {
  ReportsBuilder = 'ReportsBuilder',
  TransactionReview = 'TransactionReview'
}

export type BankAccount = {
  accountIdMask: Scalars['String']['output'];
  iconObjectName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  institutionName: Scalars['String']['output'];
  isAutoPayout: Scalars['Boolean']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nickname: Scalars['String']['output'];
};

export type BillPaymentPreview = {
  amount: MonetaryValue;
  id: Scalars['String']['output'];
  image: BillPaymentPreviewSegment;
  payerCompanyName: Scalars['String']['output'];
  payerEmailAddress: Scalars['String']['output'];
  vendor: PartyPreview;
};

export type BillPaymentPreviewSegment = {
  collectionId: Scalars['String']['output'];
  fileId: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type CategoryPreview = {
  displayKey: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: CategoryType;
};

export enum CategoryType {
  Assets = 'Assets',
  CostOfGoodsSold = 'CostOfGoodsSold',
  Equity = 'Equity',
  Expenses = 'Expenses',
  Income = 'Income',
  Liabilities = 'Liabilities',
  OtherExpenses = 'OtherExpenses',
  OtherIncome = 'OtherIncome',
  UnknownType = 'UnknownType'
}

export type ConfirmationToken = {
  token: Scalars['String']['output'];
};

export type Contact = {
  avatarUrl?: Maybe<Scalars['String']['output']>;
  emailAddress: Scalars['String']['output'];
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
};

export type ContactInput = {
  emailAddress: Scalars['String']['input'];
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
};

export type CreditCard = {
  id: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
  nickname: Scalars['String']['output'];
};

export type Customer = InvoiceCustomerInterface & {
  address: CustomerAddress;
  email: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  providerType: ProviderType;
};

export type CustomerAddress = {
  address: Address;
  id: Scalars['String']['output'];
};

export type CustomerSummary = InvoiceCustomerSummaryInterface & {
  email: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Date = {
  day: Scalars['Int']['output'];
  month: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type DateInput = {
  day?: InputMaybe<Scalars['Int']['input']>;
  month?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export enum DisplayEntry {
  Credit = 'Credit',
  Debit = 'Debit',
  TransferCredit = 'TransferCredit',
  TransferDebit = 'TransferDebit',
  Unknown = 'Unknown'
}

export type DraftCustomer = InvoiceCustomerInterface & {
  address: CustomerAddress;
  email: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  providerType: ProviderType;
};

export type DraftCustomerSummary = InvoiceCustomerSummaryInterface & {
  email: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type DraftInvoicingProfile = InvoicingProfileInterface & {
  backgroundColor?: Maybe<Scalars['String']['output']>;
  companyAddress: InvoicingAddress;
  companyEmail: Scalars['String']['output'];
  companyIcon?: Maybe<Scalars['String']['output']>;
  companyName: Scalars['String']['output'];
  coverURL?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  payoutInstrumentID: Scalars['String']['output'];
};

export type EntityLegalEntity = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type EntityOrganization = {
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type EntityUser = {
  avatarUrl?: Maybe<Scalars['String']['output']>;
  emailAddress: Scalars['String']['output'];
  familyName: Scalars['String']['output'];
  givenName: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export enum Flow {
  Inbound = 'Inbound',
  Outbound = 'Outbound',
  UnknownFlow = 'UnknownFlow'
}

export type InputMonetaryValue = {
  amount: Scalars['Long']['input'];
  currencyMultiplier: Scalars['Long']['input'];
  iso4217CurrencyCode: Scalars['String']['input'];
};

/**
 * ObjectIdentifier is a generic identifier with an enumerated kind.
 * It can be used to identify different Digits' core objects.
 */
export type InputObjectIdentifier = {
  id: Scalars['String']['input'];
  kind: ObjectKind;
  legalEntityId: Scalars['String']['input'];
};

export enum Interval {
  Biweek = 'Biweek',
  Day = 'Day',
  Hour = 'Hour',
  IntervalNone = 'IntervalNone',
  Minute = 'Minute',
  Month = 'Month',
  Quarter = 'Quarter',
  Week = 'Week',
  Year = 'Year'
}

export type Invitation = {
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  invitationType: InvitationType;
  notifiedAt?: Maybe<Scalars['DateTime']['output']>;
  organization?: Maybe<Organization>;
  organizationTemplateId?: Maybe<Scalars['String']['output']>;
  recipient: Contact;
  role?: Maybe<Role>;
  sender?: Maybe<Contact>;
  service?: Maybe<OAuthService>;
  signupId?: Maybe<Scalars['String']['output']>;
  status: InvitationStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type InvitationProposal = {
  createdAt: Scalars['DateTime']['output'];
  invitationId: Scalars['String']['output'];
  legalEntity?: Maybe<EntityLegalEntity>;
  organization?: Maybe<EntityOrganization>;
  organizationTemplateId?: Maybe<Scalars['String']['output']>;
  proposalId: Scalars['String']['output'];
  proposalType: ProposalType;
  recipient?: Maybe<Contact>;
  sender?: Maybe<EntityUser>;
  status: InvitationProposalStatus;
};

export enum InvitationProposalStatus {
  Accepted = 'Accepted',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Rejected = 'Rejected',
  Sent = 'Sent',
  Unknown = 'Unknown'
}

export enum InvitationStatus {
  Accepted = 'Accepted',
  Active = 'Active',
  Cancelled = 'Cancelled',
  Unknown = 'Unknown'
}

export type InvitationToken = {
  token: Scalars['String']['output'];
};

export enum InvitationType {
  Accountant = 'Accountant',
  Operator = 'Operator',
  Passwordless = 'Passwordless',
  UnknownType = 'UnknownType'
}

export type Invoice = {
  customer?: Maybe<Customer>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  invoiceDate?: Maybe<Scalars['DateTime']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  invoicingProfile?: Maybe<InvoicingProfile>;
  legalEntityId: Scalars['String']['output'];
  lineItems: Array<InvoiceLineItem>;
  memo?: Maybe<Scalars['String']['output']>;
  status: InvoiceStatus;
  subtotal?: Maybe<MonetaryValue>;
  taxPercent?: Maybe<Scalars['Float']['output']>;
  totalAmount?: Maybe<MonetaryValue>;
  totalTax?: Maybe<MonetaryValue>;
};

export type InvoiceCustomerInterface = {
  address: CustomerAddress;
  email: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  providerType: ProviderType;
};

export type InvoiceCustomerSummaryInterface = {
  email: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type InvoiceLineItem = {
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Long']['output'];
  productId?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Long']['output'];
  taxable: Scalars['Boolean']['output'];
  unitPrice: MonetaryValue;
};

export type InvoicePaymentDetails = {
  paymentAmount: MonetaryValue;
  paymentConfirmationId: Scalars['String']['output'];
  paymentDate: Scalars['DateTime']['output'];
  paymentInstrument: PaymentInstrument;
};

export enum InvoiceStatus {
  Cancelled = 'Cancelled',
  Draft = 'Draft',
  Failed = 'Failed',
  OnHold = 'OnHold',
  Paid = 'Paid',
  PaymentCanceled = 'PaymentCanceled',
  PaymentProcessing = 'PaymentProcessing',
  PaymentRejected = 'PaymentRejected',
  PaymentRequiresAttention = 'PaymentRequiresAttention',
  PaymentReturned = 'PaymentReturned',
  Pending = 'Pending',
  Refunded = 'Refunded',
  Sent = 'Sent',
  Settled = 'Settled',
  Submitted = 'Submitted',
  Unknown = 'Unknown'
}

export type InvoicingAddress = {
  address: Address;
  id: Scalars['String']['output'];
};

export type InvoicingProfile = InvoicingProfileInterface & {
  backgroundColor?: Maybe<Scalars['String']['output']>;
  companyAddress: InvoicingAddress;
  companyEmail: Scalars['String']['output'];
  companyIcon?: Maybe<Scalars['String']['output']>;
  companyName: Scalars['String']['output'];
  coverURL?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  payoutInstrumentID: Scalars['String']['output'];
};

export type InvoicingProfileInterface = {
  backgroundColor?: Maybe<Scalars['String']['output']>;
  companyAddress: InvoicingAddress;
  companyEmail: Scalars['String']['output'];
  companyIcon?: Maybe<Scalars['String']['output']>;
  companyName: Scalars['String']['output'];
  coverURL?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  payoutInstrumentID: Scalars['String']['output'];
};

export enum LegalEntityStatus {
  Active = 'Active',
  Approved = 'Approved',
  New = 'New',
  Pending = 'Pending',
  PendingHold = 'PendingHold',
  Unknown = 'Unknown'
}

export enum MagicLinkRequestScheme {
  Digits = 'Digits',
  Https = 'Https'
}

export enum MetricType {
  COGS = 'COGS',
  Expenses = 'Expenses',
  GrossProfit = 'GrossProfit',
  Income = 'Income',
  NetIncome = 'NetIncome',
  NetOperatingIncome = 'NetOperatingIncome',
  ProfitPercentage = 'ProfitPercentage'
}

/** MonetaryValue */
export type MonetaryValue = {
  amount: Scalars['Long']['output'];
  currencyMultiplier: Scalars['Long']['output'];
  iso4217CurrencyCode: Scalars['String']['output'];
};

export type MoneyFlow = {
  businessFlow: Flow;
  isNormal: Scalars['Boolean']['output'];
  value: MonetaryValue;
};

export type MoneyFlowInput = {
  businessFlow: Flow;
  isNormal: Scalars['Boolean']['input'];
  value: InputMonetaryValue;
};

export type Mutation = {
  addSignup: PublicSignup;
  createPasswordReset?: Maybe<Scalars['Void']['output']>;
  createPaymentPlaidLinkToken: PlaidLinkToken;
  createPlaidGuestBankAccountInstrument: PaymentInstrument;
  logInEmail: Session;
  logInMagicLink: Session;
  logInOAuth: Session;
  /** Support */
  logInSupport: Session;
  logOut?: Maybe<Scalars['Void']['output']>;
  logOutSupport: Session;
  payInvoice: PayInvoiceSummary;
  provisionVerificationToken: ConfirmationToken;
  registerInviteEmail: Session;
  registerInviteOAuth: Session;
  registerInvitePasswordless: Session;
  registerUserEmail: Session;
  registerUserOAuth: Session;
  resetPassword: Session;
  sendAcceptedInvitationMagicLink?: Maybe<Scalars['Void']['output']>;
  sendEmailVerification?: Maybe<Scalars['Void']['output']>;
  sendMagicLinkEmail?: Maybe<Scalars['Void']['output']>;
  tokenRefresh: Session;
  updateSignup: PublicSignup;
  updateSignupFromOAuth: PublicSignup;
};


export type MutationAddSignupArgs = {
  attribution?: InputMaybe<SignupAttribution>;
  email: Scalars['String']['input'];
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  persona?: InputMaybe<Persona>;
  planIntent?: InputMaybe<PlanIntent>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreatePasswordResetArgs = {
  billId?: InputMaybe<Scalars['String']['input']>;
  contactToken?: InputMaybe<Scalars['String']['input']>;
  emailAddress: Scalars['String']['input'];
  intentId?: InputMaybe<Scalars['String']['input']>;
  object?: InputMaybe<InputObjectIdentifier>;
};


export type MutationCreatePaymentPlaidLinkTokenArgs = {
  plaidClientUserId: Scalars['String']['input'];
};


export type MutationCreatePlaidGuestBankAccountInstrumentArgs = {
  accountId: Scalars['String']['input'];
  institutionName: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
  publicToken: Scalars['String']['input'];
};


export type MutationLogInEmailArgs = {
  activeLegalEntityId?: InputMaybe<Scalars['String']['input']>;
  emailAddress: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationLogInMagicLinkArgs = {
  activeLegalEntityId?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};


export type MutationLogInOAuthArgs = {
  activeLegalEntityId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  service: OAuthService;
};


export type MutationLogInSupportArgs = {
  id: Scalars['String']['input'];
};


export type MutationLogOutSupportArgs = {
  id: Scalars['String']['input'];
};


export type MutationPayInvoiceArgs = {
  invoiceId: Scalars['String']['input'];
  paymentInstrumentId: Scalars['String']['input'];
};


export type MutationProvisionVerificationTokenArgs = {
  emailAddress: Scalars['String']['input'];
  familyName: Scalars['String']['input'];
  givenName: Scalars['String']['input'];
  intentId: Scalars['String']['input'];
  object: InputObjectIdentifier;
};


export type MutationRegisterInviteEmailArgs = {
  emailAddress: Scalars['String']['input'];
  invitationToken: Scalars['String']['input'];
  password: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
};


export type MutationRegisterInviteOAuthArgs = {
  code: Scalars['String']['input'];
  invitationToken: Scalars['String']['input'];
  service: OAuthService;
  timezone: Scalars['String']['input'];
};


export type MutationRegisterInvitePasswordlessArgs = {
  invitationToken: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
};


export type MutationRegisterUserEmailArgs = {
  confirmToken: Scalars['String']['input'];
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
  tosVersionAccepted?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRegisterUserOAuthArgs = {
  code: Scalars['String']['input'];
  service: OAuthService;
  timezone: Scalars['String']['input'];
  tosVersionAccepted?: InputMaybe<Scalars['String']['input']>;
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String']['input'];
  resetToken: Scalars['String']['input'];
};


export type MutationSendAcceptedInvitationMagicLinkArgs = {
  invitationToken: Scalars['String']['input'];
  redirectPath?: InputMaybe<Scalars['String']['input']>;
  scheme?: InputMaybe<MagicLinkRequestScheme>;
  state?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSendEmailVerificationArgs = {
  billId?: InputMaybe<Scalars['String']['input']>;
  constraint?: InputMaybe<AccessConstraint>;
  contactToken?: InputMaybe<Scalars['String']['input']>;
  destination: VerificationRequestDestination;
  emailAddress: Scalars['String']['input'];
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  intentId?: InputMaybe<Scalars['String']['input']>;
  object?: InputMaybe<InputObjectIdentifier>;
};


export type MutationSendMagicLinkEmailArgs = {
  emailAddress: Scalars['String']['input'];
  redirectPath?: InputMaybe<Scalars['String']['input']>;
  scheme?: InputMaybe<MagicLinkRequestScheme>;
  state?: InputMaybe<Scalars['String']['input']>;
};


export type MutationTokenRefreshArgs = {
  activeLegalEntityId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateSignupArgs = {
  companyCurrency?: InputMaybe<Scalars['String']['input']>;
  companyLedger?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  companyURL?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  industry?: InputMaybe<Scalars['String']['input']>;
  positionTitle?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateSignupFromOAuthArgs = {
  code: Scalars['String']['input'];
  id: Scalars['String']['input'];
  service: OAuthService;
};

export enum OAuthService {
  Google = 'Google',
  LinkedIn = 'LinkedIn',
  QuickBooks = 'QuickBooks',
  Unknown = 'Unknown'
}

export enum ObjectKind {
  Bill = 'Bill',
  Category = 'Category',
  Comment = 'Comment',
  DataSource = 'DataSource',
  Department = 'Department',
  Invoice = 'Invoice',
  LedgerTransaction = 'LedgerTransaction',
  LegalEntity = 'LegalEntity',
  Location = 'Location',
  Party = 'Party',
  Portal = 'Portal',
  Report = 'Report',
  ReportPackage = 'ReportPackage',
  Thread = 'Thread',
  Transaction = 'Transaction',
  User = 'User',
  WorkItem = 'WorkItem'
}

export type Organization = {
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type OrganizationTemplate = {
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type Pagination = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type PartyPreview = {
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum PartyRole {
  EntityAssetRole = 'EntityAssetRole',
  EntityCustomerRole = 'EntityCustomerRole',
  EntityLenderRole = 'EntityLenderRole',
  EntityLiabilitiesRole = 'EntityLiabilitiesRole',
  EntityOwedVendorRole = 'EntityOwedVendorRole',
  EntityPrepaidVendorRole = 'EntityPrepaidVendorRole',
  EntityShareholderRole = 'EntityShareholderRole',
  EntitySupplierRole = 'EntitySupplierRole',
  EntityUnpaidCustomerRole = 'EntityUnpaidCustomerRole',
  EntityUnspecifiedRole = 'EntityUnspecifiedRole',
  EntityVendorRole = 'EntityVendorRole',
  FacilitatorRole = 'FacilitatorRole',
  InstitutionCreditRole = 'InstitutionCreditRole',
  InstitutionDebitRole = 'InstitutionDebitRole',
  OwnerRole = 'OwnerRole',
  PaymentProcessorRole = 'PaymentProcessorRole',
  UnknownRole = 'UnknownRole'
}

export type PasswordReset = {
  contact: Contact;
  isValid: Scalars['Boolean']['output'];
};

export type PayInvoiceSummary = {
  invoicePaymentDetails: InvoicePaymentDetails;
  status: InvoiceStatus;
};

export type PaymentInstrument = BankAccount | CreditCard;

export enum PaymentStatus {
  Blocked = 'Blocked',
  Canceled = 'Canceled',
  Created = 'Created',
  Deposited = 'Deposited',
  Mailed = 'Mailed',
  OnHold = 'OnHold',
  Paid = 'Paid',
  PendingIncreaseReview = 'PendingIncreaseReview',
  PendingMailing = 'PendingMailing',
  Refunded = 'Refunded',
  Rejected = 'Rejected',
  RequiresAttention = 'RequiresAttention',
  Returned = 'Returned',
  Settled = 'Settled',
  Stopped = 'Stopped',
  Submitted = 'Submitted',
  Unknown = 'Unknown'
}

export type Period = {
  endedAt: Scalars['DateTime']['output'];
  interval: Interval;
  name: Scalars['String']['output'];
  startedAt: Scalars['DateTime']['output'];
};

export type PeriodInput = {
  endedAt: Scalars['DateTime']['input'];
  interval: Interval;
  name: Scalars['String']['input'];
  startedAt: Scalars['DateTime']['input'];
};

export enum Persona {
  AccountingLarge = 'AccountingLarge',
  AccountingMedium = 'AccountingMedium',
  AccountingSmall = 'AccountingSmall',
  Business = 'Business',
  BusinessAndSubsidiaries = 'BusinessAndSubsidiaries',
  InvestmentMedium = 'InvestmentMedium',
  InvestmentSmall = 'InvestmentSmall',
  MailingList = 'MailingList',
  UnknownPersona = 'UnknownPersona'
}

export type PlaidLinkToken = {
  token: Scalars['String']['output'];
};

export enum PlanIntent {
  FounderFinancePlan = 'FounderFinancePlan',
  FreePlan = 'FreePlan',
  GrowthPlan = 'GrowthPlan',
  PremiumPlan = 'PremiumPlan',
  UnknownPlan = 'UnknownPlan'
}

export enum ProposalType {
  AffiliateInviteManagedOperator = 'AffiliateInviteManagedOperator',
  AffiliationAssistClient = 'AffiliationAssistClient',
  AffiliationCreateClient = 'AffiliationCreateClient',
  AffiliationJoinClient = 'AffiliationJoinClient',
  CreateOrganization = 'CreateOrganization',
  EmploymentAssistOperator = 'EmploymentAssistOperator',
  EmploymentJoinOrg = 'EmploymentJoinOrg',
  OperatorAssistAffiliate = 'OperatorAssistAffiliate',
  UnknownProposalType = 'UnknownProposalType'
}

export enum ProviderType {
  CSVImport = 'CSVImport',
  Connect = 'Connect',
  DigitsInvoicing = 'DigitsInvoicing',
  DigitsPay = 'DigitsPay',
  DigitsPaymentsLedger = 'DigitsPaymentsLedger',
  Manual = 'Manual',
  Plaid = 'Plaid',
  QuickBooks = 'QuickBooks',
  UnknownProvider = 'UnknownProvider',
  Xero = 'Xero'
}

export type PublicInvoice = {
  customer?: Maybe<Customer>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  invoiceDate?: Maybe<Scalars['DateTime']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  invoicingProfile?: Maybe<InvoicingProfile>;
  legalEntityId: Scalars['String']['output'];
  lineItems: Array<InvoiceLineItem>;
  memo?: Maybe<Scalars['String']['output']>;
  status: InvoiceStatus;
  subtotal?: Maybe<MonetaryValue>;
  taxPercent?: Maybe<Scalars['Float']['output']>;
  totalAmount?: Maybe<MonetaryValue>;
  totalTax?: Maybe<MonetaryValue>;
};

export type PublicSignup = {
  companyCurrency?: Maybe<Scalars['String']['output']>;
  companyLedger?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  companyURL?: Maybe<Scalars['String']['output']>;
  contact: Contact;
  id: Scalars['String']['output'];
  industry?: Maybe<Scalars['String']['output']>;
  persona: Persona;
  planIntent: PlanIntent;
  positionTitle?: Maybe<Scalars['String']['output']>;
  status: SignupStatus;
  verificationMethod: VerificationMethod;
};

export type Query = {
  /**
   * Pay-based Bills aren't object-identifiable right now.
   * this is bill payment
   */
  previewBillPayment: BillPaymentPreview;
  previewShare: SharePreview;
  readInvitation: Invitation;
  readInvitationRecipient: UserContact;
  readPasswordReset: PasswordReset;
  readSignup: PublicSignup;
  viewInvoice: ViewInvoiceSummary;
};


export type QueryPreviewBillPaymentArgs = {
  contactToken: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type QueryPreviewShareArgs = {
  id: Scalars['String']['input'];
  intentId?: InputMaybe<Scalars['String']['input']>;
  kind: ObjectKind;
};


export type QueryReadInvitationArgs = {
  token: Scalars['String']['input'];
};


export type QueryReadInvitationRecipientArgs = {
  token: Scalars['String']['input'];
};


export type QueryReadPasswordResetArgs = {
  resetToken: Scalars['String']['input'];
};


export type QueryReadSignupArgs = {
  id: Scalars['String']['input'];
};


export type QueryViewInvoiceArgs = {
  contactToken: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export enum ReportKind {
  BalanceSheet = 'BalanceSheet',
  CashFlow = 'CashFlow',
  ExpenseSummary = 'ExpenseSummary',
  ProfitAndLoss = 'ProfitAndLoss',
  Unknown = 'Unknown'
}

export type ReportPackagePreview = {
  author: EntityUser;
  coverUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  interval: Interval;
  periodEndedAt: Scalars['DateTime']['output'];
  periodStartedAt: Scalars['DateTime']['output'];
  preparedBy?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type Role = {
  id: Scalars['String']['output'];
};

export type RoleIdentifier = {
  id: Scalars['String']['input'];
};

export type Session = {
  bearer: Scalars['String']['output'];
  employments?: Maybe<Array<SessionEmployment>>;
};

export type SessionAffiliation = {
  id: Scalars['String']['output'];
  kind?: Maybe<AffiliateType>;
  organization: SessionOrganization;
};

export type SessionEmployment = {
  affiliations: Array<SessionAffiliation>;
  id: Scalars['String']['output'];
  organization: SessionOrganization;
};

export type SessionLegalEntity = {
  aspects: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  status: LegalEntityStatus;
  viewKeys: Array<SessionViewKey>;
};

export type SessionOrganization = {
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  legalEntities: Array<SessionLegalEntity>;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type SessionViewKey = {
  legalEntityId: Scalars['String']['output'];
  mutationVersion?: Maybe<Scalars['String']['output']>;
  viewType?: Maybe<ViewType>;
  viewVersion?: Maybe<Scalars['String']['output']>;
};

export type ShareObject = CategoryPreview | PartyPreview | ReportPackagePreview | TransactionPreview;

export type SharePreview = {
  legalEntity: EntityLegalEntity;
  object: ShareObject;
  /** Invited user email. */
  userEmail?: Maybe<Scalars['String']['output']>;
};

export type SignupAttribution = {
  fromCommunity?: InputMaybe<Scalars['Boolean']['input']>;
  utmCampaign?: InputMaybe<Scalars['String']['input']>;
  utmContent?: InputMaybe<Scalars['String']['input']>;
  utmId?: InputMaybe<Scalars['String']['input']>;
  utmMedium?: InputMaybe<Scalars['String']['input']>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
  utmTerm?: InputMaybe<Scalars['String']['input']>;
};

export enum SignupStatus {
  Applied = 'Applied',
  Approved = 'Approved',
  Ignored = 'Ignored',
  InviteAccepted = 'InviteAccepted',
  Invited = 'Invited',
  PrimerSent = 'PrimerSent',
  Qualified = 'Qualified',
  Unknown = 'Unknown',
  Unqualified = 'Unqualified',
  Waitlisted = 'Waitlisted'
}

export enum TaxStatus {
  NonTaxable = 'NonTaxable',
  Taxable = 'Taxable',
  Undetermined = 'Undetermined',
  UnspecifiedTaxStatus = 'UnspecifiedTaxStatus'
}

export type TransactionPreview = {
  creditCategory?: Maybe<CategoryPreview>;
  debitCategory?: Maybe<CategoryPreview>;
  description?: Maybe<Scalars['String']['output']>;
  displayEntry: DisplayEntry;
  name?: Maybe<Scalars['String']['output']>;
  party?: Maybe<PartyPreview>;
  timestamp: Scalars['DateTime']['output'];
};

export type UserContact = {
  avatarUrl?: Maybe<Scalars['String']['output']>;
  emailAddress: Scalars['String']['output'];
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export enum VerificationMethod {
  AlreadyVerified = 'AlreadyVerified',
  Email = 'Email',
  Google = 'Google',
  UnknownVerificationMethod = 'UnknownVerificationMethod'
}

export enum VerificationRequestDestination {
  Bill = 'Bill',
  FounderFinance = 'FounderFinance',
  InSidedSSO = 'InSidedSSO',
  Share = 'Share',
  Unspecified = 'Unspecified'
}

export type ViewInvoiceSummary = {
  invoice: PublicInvoice;
  invoicePaymentDetails?: Maybe<InvoicePaymentDetails>;
};

export type ViewKey = {
  legalEntityId: Scalars['String']['input'];
  mutationVersion?: InputMaybe<Scalars['String']['input']>;
  viewType?: InputMaybe<ViewType>;
  viewVersion?: InputMaybe<Scalars['String']['input']>;
};

export enum ViewType {
  AIBookkeeper = 'AIBookkeeper',
  Ledger = 'Ledger',
  Unknown = 'Unknown'
}

export type SendEmailVerificationMutationVariables = Exact<{
  emailAddress: Scalars['String']['input'];
  givenName?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  destination: VerificationRequestDestination;
  object?: InputMaybe<InputObjectIdentifier>;
  intentId?: InputMaybe<Scalars['String']['input']>;
  constraint?: InputMaybe<AccessConstraint>;
  billId?: InputMaybe<Scalars['String']['input']>;
  contactToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type SendEmailVerificationMutation = Pick<Mutation, 'sendEmailVerification'>;

export type SendMagicLinkEmailMutationVariables = Exact<{
  emailAddress: Scalars['String']['input'];
  redirectPath?: InputMaybe<Scalars['String']['input']>;
  scheme?: InputMaybe<MagicLinkRequestScheme>;
  state?: InputMaybe<Scalars['String']['input']>;
}>;


export type SendMagicLinkEmailMutation = Pick<Mutation, 'sendMagicLinkEmail'>;

export type RegisterUserOAuthMutationVariables = Exact<{
  service: OAuthService;
  code: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
  tosVersionAccepted?: InputMaybe<Scalars['String']['input']>;
}>;


export type RegisterUserOAuthMutation = { registerUserOAuth: Pick<Session, 'bearer'> };

export type LogInOAuthMutationVariables = Exact<{
  service: OAuthService;
  code: Scalars['String']['input'];
  activeLegalEntityId?: InputMaybe<Scalars['String']['input']>;
}>;


export type LogInOAuthMutation = { logInOAuth: (
    Pick<Session, 'bearer'>
    & { employments?: Maybe<Array<(
      Pick<SessionEmployment, 'id'>
      & { organization: (
        Pick<SessionOrganization, 'id' | 'name' | 'slug' | 'iconUrl' | 'websiteUrl'>
        & { legalEntities: Array<(
          Pick<SessionLegalEntity, 'id' | 'name' | 'slug' | 'status' | 'aspects'>
          & { viewKeys: Array<Pick<SessionViewKey, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>> }
        )> }
      ), affiliations: Array<(
        Pick<SessionAffiliation, 'id' | 'kind'>
        & { organization: (
          Pick<SessionOrganization, 'id' | 'name' | 'slug' | 'iconUrl' | 'websiteUrl'>
          & { legalEntities: Array<(
            Pick<SessionLegalEntity, 'id' | 'name' | 'slug' | 'status' | 'aspects'>
            & { viewKeys: Array<Pick<SessionViewKey, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>> }
          )> }
        ) }
      )> }
    )>> }
  ) };

export type LogInMagicLinkMutationVariables = Exact<{
  token: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  activeLegalEntityId?: InputMaybe<Scalars['String']['input']>;
}>;


export type LogInMagicLinkMutation = { logInMagicLink: (
    Pick<Session, 'bearer'>
    & { employments?: Maybe<Array<(
      Pick<SessionEmployment, 'id'>
      & { organization: (
        Pick<SessionOrganization, 'id' | 'name' | 'slug' | 'iconUrl' | 'websiteUrl'>
        & { legalEntities: Array<(
          Pick<SessionLegalEntity, 'id' | 'name' | 'slug' | 'status' | 'aspects'>
          & { viewKeys: Array<Pick<SessionViewKey, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>> }
        )> }
      ), affiliations: Array<(
        Pick<SessionAffiliation, 'id' | 'kind'>
        & { organization: (
          Pick<SessionOrganization, 'id' | 'name' | 'slug' | 'iconUrl' | 'websiteUrl'>
          & { legalEntities: Array<(
            Pick<SessionLegalEntity, 'id' | 'name' | 'slug' | 'status' | 'aspects'>
            & { viewKeys: Array<Pick<SessionViewKey, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>> }
          )> }
        ) }
      )> }
    )>> }
  ) };

export type AddSignupMutationVariables = Exact<{
  email: Scalars['String']['input'];
  givenName?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  persona: Persona;
  planIntent: PlanIntent;
  attribution?: InputMaybe<SignupAttribution>;
}>;


export type AddSignupMutation = { signup: (
    Pick<PublicSignup, 'id' | 'positionTitle' | 'companyName' | 'companyURL' | 'companyLedger' | 'companyCurrency' | 'industry' | 'status' | 'persona' | 'verificationMethod' | 'planIntent'>
    & { contact: Pick<Contact, 'avatarUrl' | 'familyName' | 'givenName' | 'emailAddress'> }
  ) };

export type RegisterInviteOAuthMutationVariables = Exact<{
  invitationToken: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
  code: Scalars['String']['input'];
  service: OAuthService;
}>;


export type RegisterInviteOAuthMutation = { registerInviteOAuth: Pick<Session, 'bearer'> };

export type CreatePaymentPlaidLinkTokenMutationVariables = Exact<{
  plaidClientUserId: Scalars['String']['input'];
}>;


export type CreatePaymentPlaidLinkTokenMutation = { createPaymentPlaidLinkToken: Pick<PlaidLinkToken, 'token'> };

export type ProvisionVerificationTokenMutationVariables = Exact<{
  emailAddress: Scalars['String']['input'];
  givenName: Scalars['String']['input'];
  familyName: Scalars['String']['input'];
  object: InputObjectIdentifier;
  intentId: Scalars['String']['input'];
}>;


export type ProvisionVerificationTokenMutation = { provisionVerificationToken: Pick<ConfirmationToken, 'token'> };

export type PayInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['String']['input'];
  paymentInstrumentId: Scalars['String']['input'];
}>;


export type PayInvoiceMutation = { payInvoice: (
    Pick<PayInvoiceSummary, 'status'>
    & { invoicePaymentDetails: (
      Pick<InvoicePaymentDetails, 'paymentConfirmationId' | 'paymentDate'>
      & { paymentAmount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, paymentInstrument: Pick<BankAccount, 'id' | 'institutionName' | 'name' | 'accountIdMask' | 'isDefault' | 'nickname' | 'isAutoPayout' | 'iconObjectName'> | {} }
    ) }
  ) };

export type ResetPasswordMutationVariables = Exact<{
  resetToken: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { resetPassword: (
    Pick<Session, 'bearer'>
    & { employments?: Maybe<Array<(
      Pick<SessionEmployment, 'id'>
      & { organization: (
        Pick<SessionOrganization, 'id' | 'name' | 'slug' | 'iconUrl' | 'websiteUrl'>
        & { legalEntities: Array<(
          Pick<SessionLegalEntity, 'id' | 'name' | 'slug' | 'status' | 'aspects'>
          & { viewKeys: Array<Pick<SessionViewKey, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>> }
        )> }
      ), affiliations: Array<(
        Pick<SessionAffiliation, 'id' | 'kind'>
        & { organization: (
          Pick<SessionOrganization, 'id' | 'name' | 'slug' | 'iconUrl' | 'websiteUrl'>
          & { legalEntities: Array<(
            Pick<SessionLegalEntity, 'id' | 'name' | 'slug' | 'status' | 'aspects'>
            & { viewKeys: Array<Pick<SessionViewKey, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>> }
          )> }
        ) }
      )> }
    )>> }
  ) };

export type SendAcceptedInvitationMagicLinkMutationVariables = Exact<{
  invitationToken: Scalars['String']['input'];
  redirectPath?: InputMaybe<Scalars['String']['input']>;
  scheme?: InputMaybe<MagicLinkRequestScheme>;
  state?: InputMaybe<Scalars['String']['input']>;
}>;


export type SendAcceptedInvitationMagicLinkMutation = Pick<Mutation, 'sendAcceptedInvitationMagicLink'>;

export type TokenRefreshMutationVariables = Exact<{
  activeLegalEntityId?: InputMaybe<Scalars['String']['input']>;
}>;


export type TokenRefreshMutation = { tokenRefresh: (
    Pick<Session, 'bearer'>
    & { employments?: Maybe<Array<(
      Pick<SessionEmployment, 'id'>
      & { organization: (
        Pick<SessionOrganization, 'id' | 'name' | 'slug' | 'iconUrl' | 'websiteUrl'>
        & { legalEntities: Array<(
          Pick<SessionLegalEntity, 'id' | 'name' | 'slug' | 'status' | 'aspects'>
          & { viewKeys: Array<Pick<SessionViewKey, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>> }
        )> }
      ), affiliations: Array<(
        Pick<SessionAffiliation, 'id' | 'kind'>
        & { organization: (
          Pick<SessionOrganization, 'id' | 'name' | 'slug' | 'iconUrl' | 'websiteUrl'>
          & { legalEntities: Array<(
            Pick<SessionLegalEntity, 'id' | 'name' | 'slug' | 'status' | 'aspects'>
            & { viewKeys: Array<Pick<SessionViewKey, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>> }
          )> }
        ) }
      )> }
    )>> }
  ) };

export type RegisterInvitePasswordlessMutationVariables = Exact<{
  invitationToken: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
}>;


export type RegisterInvitePasswordlessMutation = { registerInvitePasswordless: Pick<Session, 'bearer'> };

export type CreatePlaidGuestBankAccountInstrumentMutationVariables = Exact<{
  invoiceId: Scalars['String']['input'];
  publicToken: Scalars['String']['input'];
  accountId: Scalars['String']['input'];
  institutionName: Scalars['String']['input'];
}>;


export type CreatePlaidGuestBankAccountInstrumentMutation = { createPlaidGuestBankAccountInstrument: (
    { __typename: 'BankAccount' }
    & Pick<BankAccount, 'id' | 'institutionName' | 'name' | 'accountIdMask' | 'isDefault' | 'nickname' | 'isAutoPayout' | 'iconObjectName'>
  ) | { __typename: 'CreditCard' } };

export type UpdateSignupMutationVariables = Exact<{
  id: Scalars['String']['input'];
  givenName?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  positionTitle?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  companyURL?: InputMaybe<Scalars['String']['input']>;
  companyLedger?: InputMaybe<Scalars['String']['input']>;
  companyCurrency?: InputMaybe<Scalars['String']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateSignupMutation = { updateSignup: (
    Pick<PublicSignup, 'id' | 'positionTitle' | 'companyName' | 'companyURL' | 'companyLedger' | 'companyCurrency' | 'industry' | 'status' | 'persona' | 'verificationMethod' | 'planIntent'>
    & { contact: Pick<Contact, 'avatarUrl' | 'familyName' | 'givenName' | 'emailAddress'> }
  ) };

export type RegisterInviteEmailMutationVariables = Exact<{
  invitationToken: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
  emailAddress: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type RegisterInviteEmailMutation = { registerInviteEmail: Pick<Session, 'bearer'> };

export type LogInSupportMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type LogInSupportMutation = { logInSupport: Pick<Session, 'bearer'> };

export type LogOutSupportMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type LogOutSupportMutation = { logOutSupport: Pick<Session, 'bearer'> };

export type CreatePasswordResetMutationVariables = Exact<{
  emailAddress: Scalars['String']['input'];
  object?: InputMaybe<InputObjectIdentifier>;
  intentId?: InputMaybe<Scalars['String']['input']>;
  billId?: InputMaybe<Scalars['String']['input']>;
  contactToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreatePasswordResetMutation = Pick<Mutation, 'createPasswordReset'>;

export type LogInEmailMutationVariables = Exact<{
  emailAddress: Scalars['String']['input'];
  password: Scalars['String']['input'];
  activeLegalEntityId?: InputMaybe<Scalars['String']['input']>;
}>;


export type LogInEmailMutation = { logInEmail: (
    Pick<Session, 'bearer'>
    & { employments?: Maybe<Array<(
      Pick<SessionEmployment, 'id'>
      & { organization: (
        Pick<SessionOrganization, 'id' | 'name' | 'slug' | 'iconUrl' | 'websiteUrl'>
        & { legalEntities: Array<(
          Pick<SessionLegalEntity, 'id' | 'name' | 'slug' | 'status' | 'aspects'>
          & { viewKeys: Array<Pick<SessionViewKey, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>> }
        )> }
      ), affiliations: Array<(
        Pick<SessionAffiliation, 'id' | 'kind'>
        & { organization: (
          Pick<SessionOrganization, 'id' | 'name' | 'slug' | 'iconUrl' | 'websiteUrl'>
          & { legalEntities: Array<(
            Pick<SessionLegalEntity, 'id' | 'name' | 'slug' | 'status' | 'aspects'>
            & { viewKeys: Array<Pick<SessionViewKey, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>> }
          )> }
        ) }
      )> }
    )>> }
  ) };

export type RegisterUserEmailMutationVariables = Exact<{
  confirmToken: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  tosVersionAccepted?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
}>;


export type RegisterUserEmailMutation = { registerUserEmail: Pick<Session, 'bearer'> };

export type BankAccountFieldsFragment = Pick<BankAccount, 'id' | 'institutionName' | 'name' | 'accountIdMask' | 'isDefault' | 'nickname' | 'isAutoPayout' | 'iconObjectName'>;

export type SessionFieldsFragment = (
  Pick<Session, 'bearer'>
  & { employments?: Maybe<Array<(
    Pick<SessionEmployment, 'id'>
    & { organization: (
      Pick<SessionOrganization, 'id' | 'name' | 'slug' | 'iconUrl' | 'websiteUrl'>
      & { legalEntities: Array<(
        Pick<SessionLegalEntity, 'id' | 'name' | 'slug' | 'status' | 'aspects'>
        & { viewKeys: Array<Pick<SessionViewKey, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>> }
      )> }
    ), affiliations: Array<(
      Pick<SessionAffiliation, 'id' | 'kind'>
      & { organization: (
        Pick<SessionOrganization, 'id' | 'name' | 'slug' | 'iconUrl' | 'websiteUrl'>
        & { legalEntities: Array<(
          Pick<SessionLegalEntity, 'id' | 'name' | 'slug' | 'status' | 'aspects'>
          & { viewKeys: Array<Pick<SessionViewKey, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>> }
        )> }
      ) }
    )> }
  )>> }
);

export type EmploymentFieldsFragment = (
  Pick<SessionEmployment, 'id'>
  & { organization: (
    Pick<SessionOrganization, 'id' | 'name' | 'slug' | 'iconUrl' | 'websiteUrl'>
    & { legalEntities: Array<(
      Pick<SessionLegalEntity, 'id' | 'name' | 'slug' | 'status' | 'aspects'>
      & { viewKeys: Array<Pick<SessionViewKey, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>> }
    )> }
  ), affiliations: Array<(
    Pick<SessionAffiliation, 'id' | 'kind'>
    & { organization: (
      Pick<SessionOrganization, 'id' | 'name' | 'slug' | 'iconUrl' | 'websiteUrl'>
      & { legalEntities: Array<(
        Pick<SessionLegalEntity, 'id' | 'name' | 'slug' | 'status' | 'aspects'>
        & { viewKeys: Array<Pick<SessionViewKey, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>> }
      )> }
    ) }
  )> }
);

export type SessionOrganizationFieldsFragment = (
  Pick<SessionOrganization, 'id' | 'name' | 'slug' | 'iconUrl' | 'websiteUrl'>
  & { legalEntities: Array<(
    Pick<SessionLegalEntity, 'id' | 'name' | 'slug' | 'status' | 'aspects'>
    & { viewKeys: Array<Pick<SessionViewKey, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>> }
  )> }
);

export type SessionLegalEntityFieldsFragment = (
  Pick<SessionLegalEntity, 'id' | 'name' | 'slug' | 'status' | 'aspects'>
  & { viewKeys: Array<Pick<SessionViewKey, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>> }
);

export type SessionAffiliationFieldsFragment = (
  Pick<SessionAffiliation, 'id' | 'kind'>
  & { organization: (
    Pick<SessionOrganization, 'id' | 'name' | 'slug' | 'iconUrl' | 'websiteUrl'>
    & { legalEntities: Array<(
      Pick<SessionLegalEntity, 'id' | 'name' | 'slug' | 'status' | 'aspects'>
      & { viewKeys: Array<Pick<SessionViewKey, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>> }
    )> }
  ) }
);

export type SessionViewKeyFieldsFragment = Pick<SessionViewKey, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>;

export type TransactionPreviewFieldsFragment = (
  Pick<TransactionPreview, 'timestamp' | 'description' | 'displayEntry'>
  & { transactionName: TransactionPreview['name'] }
  & { party?: Maybe<Pick<PartyPreview, 'id' | 'name' | 'iconUrl'>>, creditCategory?: Maybe<Pick<CategoryPreview, 'name' | 'displayKey' | 'type'>>, debitCategory?: Maybe<Pick<CategoryPreview, 'name' | 'displayKey' | 'type'>> }
);

export type InvoiceProfileFieldsFragment = (
  Pick<InvoicingProfile, 'id' | 'companyEmail' | 'companyName' | 'companyIcon' | 'backgroundColor' | 'coverURL' | 'payoutInstrumentID'>
  & { companyAddress: (
    Pick<InvoicingAddress, 'id'>
    & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
  ) }
);

export type BillPaymentPreviewSegmentFieldsFragment = Pick<BillPaymentPreviewSegment, 'collectionId' | 'fileId' | 'token'>;

export type InvoiceFieldsFragment = (
  Pick<PublicInvoice, 'id' | 'legalEntityId' | 'status' | 'invoiceNumber' | 'invoiceDate' | 'dueDate' | 'taxPercent' | 'memo'>
  & { invoicingProfile?: Maybe<(
    Pick<InvoicingProfile, 'id' | 'companyEmail' | 'companyName' | 'companyIcon' | 'backgroundColor' | 'coverURL' | 'payoutInstrumentID'>
    & { companyAddress: (
      Pick<InvoicingAddress, 'id'>
      & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
    ) }
  )>, customer?: Maybe<(
    Pick<Customer, 'id' | 'name' | 'email' | 'icon' | 'providerType'>
    & { address: (
      Pick<CustomerAddress, 'id'>
      & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
    ) }
  )>, lineItems: Array<(
    Pick<InvoiceLineItem, 'description' | 'id' | 'name' | 'quantity' | 'taxable' | 'productId' | 'position'>
    & { unitPrice: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  )>, totalTax?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, subtotal?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, totalAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
);

export type ViewInvoiceSummaryFieldsFragment = { invoice: (
    Pick<PublicInvoice, 'id' | 'legalEntityId' | 'status' | 'invoiceNumber' | 'invoiceDate' | 'dueDate' | 'taxPercent' | 'memo'>
    & { invoicingProfile?: Maybe<(
      Pick<InvoicingProfile, 'id' | 'companyEmail' | 'companyName' | 'companyIcon' | 'backgroundColor' | 'coverURL' | 'payoutInstrumentID'>
      & { companyAddress: (
        Pick<InvoicingAddress, 'id'>
        & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
      ) }
    )>, customer?: Maybe<(
      Pick<Customer, 'id' | 'name' | 'email' | 'icon' | 'providerType'>
      & { address: (
        Pick<CustomerAddress, 'id'>
        & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
      ) }
    )>, lineItems: Array<(
      Pick<InvoiceLineItem, 'description' | 'id' | 'name' | 'quantity' | 'taxable' | 'productId' | 'position'>
      & { unitPrice: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    )>, totalTax?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, subtotal?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, totalAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
  ), invoicePaymentDetails?: Maybe<(
    Pick<InvoicePaymentDetails, 'paymentConfirmationId' | 'paymentDate'>
    & { paymentAmount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, paymentInstrument: Pick<BankAccount, 'id' | 'institutionName' | 'name' | 'accountIdMask' | 'isDefault' | 'nickname' | 'isAutoPayout' | 'iconObjectName'> | {} }
  )> };

export type InvoiceAddressFieldsFragment = (
  Pick<InvoicingAddress, 'id'>
  & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
);

export type MonetaryValueFieldsFragment = Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>;

export type CustomerFieldsFragment = (
  Pick<Customer, 'id' | 'name' | 'email' | 'icon' | 'providerType'>
  & { address: (
    Pick<CustomerAddress, 'id'>
    & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
  ) }
);

export type PayInvoiceSummaryFieldsFragment = (
  Pick<PayInvoiceSummary, 'status'>
  & { invoicePaymentDetails: (
    Pick<InvoicePaymentDetails, 'paymentConfirmationId' | 'paymentDate'>
    & { paymentAmount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, paymentInstrument: Pick<BankAccount, 'id' | 'institutionName' | 'name' | 'accountIdMask' | 'isDefault' | 'nickname' | 'isAutoPayout' | 'iconObjectName'> | {} }
  ) }
);

export type CategoryPreviewFieldsFragment = Pick<CategoryPreview, 'name' | 'displayKey' | 'type'>;

export type EntityUserFieldsFragment = Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>;

export type AddressFieldsFragment = Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>;

export type CustomerAddressFieldsFragment = (
  Pick<CustomerAddress, 'id'>
  & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
);

export type DateFieldsFragment = Pick<Date, 'year' | 'month' | 'day'>;

export type InvoiceLineItemFieldsFragment = (
  Pick<InvoiceLineItem, 'description' | 'id' | 'name' | 'quantity' | 'taxable' | 'productId' | 'position'>
  & { unitPrice: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
);

export type PublicSignupFieldsFragment = (
  Pick<PublicSignup, 'id' | 'positionTitle' | 'companyName' | 'companyURL' | 'companyLedger' | 'companyCurrency' | 'industry' | 'status' | 'persona' | 'verificationMethod' | 'planIntent'>
  & { contact: Pick<Contact, 'avatarUrl' | 'familyName' | 'givenName' | 'emailAddress'> }
);

export type InvoicePaymentDetailsFieldsFragment = (
  Pick<InvoicePaymentDetails, 'paymentConfirmationId' | 'paymentDate'>
  & { paymentAmount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, paymentInstrument: Pick<BankAccount, 'id' | 'institutionName' | 'name' | 'accountIdMask' | 'isDefault' | 'nickname' | 'isAutoPayout' | 'iconObjectName'> | {} }
);

export type PartyPreviewFieldsFragment = Pick<PartyPreview, 'id' | 'name' | 'iconUrl'>;

export type PreviewBillPaymentQueryVariables = Exact<{
  id: Scalars['String']['input'];
  contactToken: Scalars['String']['input'];
}>;


export type PreviewBillPaymentQuery = { previewBillPayment: (
    Pick<BillPaymentPreview, 'id' | 'payerCompanyName' | 'payerEmailAddress'>
    & { amount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, vendor: Pick<PartyPreview, 'id' | 'name' | 'iconUrl'>, image: Pick<BillPaymentPreviewSegment, 'collectionId' | 'fileId' | 'token'> }
  ) };

export type ViewInvoiceQueryVariables = Exact<{
  id: Scalars['String']['input'];
  contactToken: Scalars['String']['input'];
}>;


export type ViewInvoiceQuery = { viewInvoice: { invoice: (
      Pick<PublicInvoice, 'id' | 'legalEntityId' | 'status' | 'invoiceNumber' | 'invoiceDate' | 'dueDate' | 'taxPercent' | 'memo'>
      & { invoicingProfile?: Maybe<(
        Pick<InvoicingProfile, 'id' | 'companyEmail' | 'companyName' | 'companyIcon' | 'backgroundColor' | 'coverURL' | 'payoutInstrumentID'>
        & { companyAddress: (
          Pick<InvoicingAddress, 'id'>
          & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
        ) }
      )>, customer?: Maybe<(
        Pick<Customer, 'id' | 'name' | 'email' | 'icon' | 'providerType'>
        & { address: (
          Pick<CustomerAddress, 'id'>
          & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'> }
        ) }
      )>, lineItems: Array<(
        Pick<InvoiceLineItem, 'description' | 'id' | 'name' | 'quantity' | 'taxable' | 'productId' | 'position'>
        & { unitPrice: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      )>, totalTax?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, subtotal?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, totalAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
    ), invoicePaymentDetails?: Maybe<(
      Pick<InvoicePaymentDetails, 'paymentConfirmationId' | 'paymentDate'>
      & { paymentAmount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, paymentInstrument: Pick<BankAccount, 'id' | 'institutionName' | 'name' | 'accountIdMask' | 'isDefault' | 'nickname' | 'isAutoPayout' | 'iconObjectName'> | {} }
    )> } };

export type ReadPasswordResetQueryVariables = Exact<{
  resetToken: Scalars['String']['input'];
}>;


export type ReadPasswordResetQuery = { readPasswordReset: (
    Pick<PasswordReset, 'isValid'>
    & { contact: Pick<Contact, 'familyName' | 'givenName' | 'emailAddress' | 'avatarUrl'> }
  ) };

export type ReadInvitationQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type ReadInvitationQuery = { readInvitation: (
    Pick<Invitation, 'service' | 'invitationType' | 'organizationTemplateId'>
    & { recipient: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress'>, sender?: Maybe<Pick<Contact, 'givenName' | 'avatarUrl' | 'emailAddress'>>, organization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name'>> }
  ), readInvitationRecipient: Pick<UserContact, 'userId' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'> };

export type ReadSignupQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ReadSignupQuery = { readSignup: (
    Pick<PublicSignup, 'id' | 'positionTitle' | 'companyName' | 'companyURL' | 'companyLedger' | 'companyCurrency' | 'industry' | 'status' | 'persona' | 'verificationMethod' | 'planIntent'>
    & { contact: Pick<Contact, 'avatarUrl' | 'familyName' | 'givenName' | 'emailAddress'> }
  ) };

export type PreviewShareQueryVariables = Exact<{
  kind: ObjectKind;
  id: Scalars['String']['input'];
  intentId?: InputMaybe<Scalars['String']['input']>;
}>;


export type PreviewShareQuery = { previewShare: (
    Pick<SharePreview, 'userEmail'>
    & { legalEntity: Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>, object: (
      { __typename: 'CategoryPreview' }
      & Pick<CategoryPreview, 'name' | 'displayKey' | 'type'>
    ) | (
      { __typename: 'PartyPreview' }
      & Pick<PartyPreview, 'id' | 'name' | 'iconUrl'>
    ) | (
      { __typename: 'ReportPackagePreview' }
      & Pick<ReportPackagePreview, 'createdAt' | 'periodStartedAt' | 'periodEndedAt' | 'interval' | 'title' | 'coverUrl' | 'preparedBy'>
      & { author: Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'> }
    ) | (
      { __typename: 'TransactionPreview' }
      & Pick<TransactionPreview, 'timestamp' | 'description' | 'displayEntry'>
      & { transactionName: TransactionPreview['name'] }
      & { party?: Maybe<Pick<PartyPreview, 'id' | 'name' | 'iconUrl'>>, creditCategory?: Maybe<Pick<CategoryPreview, 'name' | 'displayKey' | 'type'>>, debitCategory?: Maybe<Pick<CategoryPreview, 'name' | 'displayKey' | 'type'>> }
    ) }
  ) };

export type ReadInvitationRecipientQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type ReadInvitationRecipientQuery = { readInvitationRecipient: Pick<UserContact, 'userId' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'> };

export type LogOutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogOutMutation = Pick<Mutation, 'logOut'>;

export const SessionViewKeyFieldsFragmentDoc = gql`
    fragment SessionViewKeyFields on SessionViewKey {
  legalEntityId
  viewVersion
  mutationVersion
  viewType
}
    `;
export const SessionLegalEntityFieldsFragmentDoc = gql`
    fragment SessionLegalEntityFields on SessionLegalEntity {
  id
  name
  slug
  status
  viewKeys {
    ...SessionViewKeyFields
  }
  aspects
}
    ${SessionViewKeyFieldsFragmentDoc}`;
export const SessionOrganizationFieldsFragmentDoc = gql`
    fragment SessionOrganizationFields on SessionOrganization {
  id
  name
  slug
  iconUrl
  websiteUrl
  legalEntities {
    ...SessionLegalEntityFields
  }
}
    ${SessionLegalEntityFieldsFragmentDoc}`;
export const SessionAffiliationFieldsFragmentDoc = gql`
    fragment SessionAffiliationFields on SessionAffiliation {
  id
  kind
  organization {
    ...SessionOrganizationFields
  }
}
    ${SessionOrganizationFieldsFragmentDoc}`;
export const EmploymentFieldsFragmentDoc = gql`
    fragment EmploymentFields on SessionEmployment {
  id
  organization {
    ...SessionOrganizationFields
  }
  affiliations {
    ...SessionAffiliationFields
  }
}
    ${SessionOrganizationFieldsFragmentDoc}
${SessionAffiliationFieldsFragmentDoc}`;
export const SessionFieldsFragmentDoc = gql`
    fragment SessionFields on Session {
  bearer
  employments {
    ...EmploymentFields
  }
}
    ${EmploymentFieldsFragmentDoc}`;
export const PartyPreviewFieldsFragmentDoc = gql`
    fragment PartyPreviewFields on PartyPreview {
  id
  name
  iconUrl
}
    `;
export const CategoryPreviewFieldsFragmentDoc = gql`
    fragment CategoryPreviewFields on CategoryPreview {
  name
  displayKey
  type
}
    `;
export const TransactionPreviewFieldsFragmentDoc = gql`
    fragment TransactionPreviewFields on TransactionPreview {
  timestamp
  transactionName: name
  description
  party {
    ...PartyPreviewFields
  }
  displayEntry
  creditCategory {
    ...CategoryPreviewFields
  }
  debitCategory {
    ...CategoryPreviewFields
  }
}
    ${PartyPreviewFieldsFragmentDoc}
${CategoryPreviewFieldsFragmentDoc}`;
export const BillPaymentPreviewSegmentFieldsFragmentDoc = gql`
    fragment BillPaymentPreviewSegmentFields on BillPaymentPreviewSegment {
  collectionId
  fileId
  token
}
    `;
export const AddressFieldsFragmentDoc = gql`
    fragment AddressFields on Address {
  street
  streetAdditional
  city
  region
  postalCode
  countryCode
  lat
  long
}
    `;
export const InvoiceAddressFieldsFragmentDoc = gql`
    fragment InvoiceAddressFields on InvoicingAddress {
  id
  address {
    ...AddressFields
  }
}
    ${AddressFieldsFragmentDoc}`;
export const InvoiceProfileFieldsFragmentDoc = gql`
    fragment InvoiceProfileFields on InvoicingProfile {
  id
  companyEmail
  companyName
  companyIcon
  companyAddress {
    ...InvoiceAddressFields
  }
  backgroundColor
  coverURL
  payoutInstrumentID
}
    ${InvoiceAddressFieldsFragmentDoc}`;
export const CustomerAddressFieldsFragmentDoc = gql`
    fragment CustomerAddressFields on CustomerAddress {
  id
  address {
    ...AddressFields
  }
}
    ${AddressFieldsFragmentDoc}`;
export const CustomerFieldsFragmentDoc = gql`
    fragment CustomerFields on Customer {
  id
  name
  email
  icon
  address {
    ...CustomerAddressFields
  }
  providerType
}
    ${CustomerAddressFieldsFragmentDoc}`;
export const MonetaryValueFieldsFragmentDoc = gql`
    fragment MonetaryValueFields on MonetaryValue {
  iso4217CurrencyCode
  currencyMultiplier
  amount
}
    `;
export const InvoiceLineItemFieldsFragmentDoc = gql`
    fragment InvoiceLineItemFields on InvoiceLineItem {
  description
  id
  name
  unitPrice {
    ...MonetaryValueFields
  }
  quantity
  taxable
  productId
  position
}
    ${MonetaryValueFieldsFragmentDoc}`;
export const InvoiceFieldsFragmentDoc = gql`
    fragment InvoiceFields on PublicInvoice {
  id
  legalEntityId
  status
  invoicingProfile {
    ...InvoiceProfileFields
  }
  customer {
    ...CustomerFields
  }
  invoiceNumber
  invoiceDate
  dueDate
  lineItems {
    ...InvoiceLineItemFields
  }
  taxPercent
  totalTax {
    ...MonetaryValueFields
  }
  subtotal {
    ...MonetaryValueFields
  }
  totalAmount {
    ...MonetaryValueFields
  }
  memo
}
    ${InvoiceProfileFieldsFragmentDoc}
${CustomerFieldsFragmentDoc}
${InvoiceLineItemFieldsFragmentDoc}
${MonetaryValueFieldsFragmentDoc}`;
export const BankAccountFieldsFragmentDoc = gql`
    fragment BankAccountFields on BankAccount {
  id
  institutionName
  name
  accountIdMask
  isDefault
  nickname
  isAutoPayout
  iconObjectName
}
    `;
export const InvoicePaymentDetailsFieldsFragmentDoc = gql`
    fragment InvoicePaymentDetailsFields on InvoicePaymentDetails {
  paymentConfirmationId
  paymentDate
  paymentAmount {
    ...MonetaryValueFields
  }
  paymentInstrument {
    ...BankAccountFields
  }
}
    ${MonetaryValueFieldsFragmentDoc}
${BankAccountFieldsFragmentDoc}`;
export const ViewInvoiceSummaryFieldsFragmentDoc = gql`
    fragment ViewInvoiceSummaryFields on ViewInvoiceSummary {
  invoice {
    ...InvoiceFields
  }
  invoicePaymentDetails {
    ...InvoicePaymentDetailsFields
  }
}
    ${InvoiceFieldsFragmentDoc}
${InvoicePaymentDetailsFieldsFragmentDoc}`;
export const PayInvoiceSummaryFieldsFragmentDoc = gql`
    fragment PayInvoiceSummaryFields on PayInvoiceSummary {
  invoicePaymentDetails {
    ...InvoicePaymentDetailsFields
  }
  status
}
    ${InvoicePaymentDetailsFieldsFragmentDoc}`;
export const EntityUserFieldsFragmentDoc = gql`
    fragment EntityUserFields on EntityUser {
  id
  givenName
  familyName
  emailAddress
  avatarUrl
}
    `;
export const DateFieldsFragmentDoc = gql`
    fragment DateFields on Date {
  year
  month
  day
}
    `;
export const PublicSignupFieldsFragmentDoc = gql`
    fragment PublicSignupFields on PublicSignup {
  id
  contact {
    avatarUrl
    familyName
    givenName
    emailAddress
  }
  positionTitle
  companyName
  companyURL
  companyLedger
  companyCurrency
  industry
  status
  persona
  verificationMethod
  planIntent
}
    `;
export const SendEmailVerificationDocument = gql`
    mutation SendEmailVerification($emailAddress: String!, $givenName: String, $familyName: String, $destination: VerificationRequestDestination!, $object: InputObjectIdentifier, $intentId: String, $constraint: AccessConstraint, $billId: String, $contactToken: String) {
  sendEmailVerification(
    givenName: $givenName
    familyName: $familyName
    emailAddress: $emailAddress
    destination: $destination
    object: $object
    intentId: $intentId
    constraint: $constraint
    billId: $billId
    contactToken: $contactToken
  )
}
    `;
export type SendEmailVerificationMutationFn = Apollo.MutationFunction<SendEmailVerificationMutation, SendEmailVerificationMutationVariables>;

/**
 * __useSendEmailVerificationMutation__
 *
 * To run a mutation, you first call `useSendEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailVerificationMutation, { data, loading, error }] = useSendEmailVerificationMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *      givenName: // value for 'givenName'
 *      familyName: // value for 'familyName'
 *      destination: // value for 'destination'
 *      object: // value for 'object'
 *      intentId: // value for 'intentId'
 *      constraint: // value for 'constraint'
 *      billId: // value for 'billId'
 *      contactToken: // value for 'contactToken'
 *   },
 * });
 */
export function useSendEmailVerificationMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailVerificationMutation, SendEmailVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmailVerificationMutation, SendEmailVerificationMutationVariables>(SendEmailVerificationDocument, options);
      }
export type SendEmailVerificationMutationHookResult = ReturnType<typeof useSendEmailVerificationMutation>;
export type SendEmailVerificationMutationResult = Apollo.MutationResult<SendEmailVerificationMutation>;
export type SendEmailVerificationMutationOptions = Apollo.BaseMutationOptions<SendEmailVerificationMutation, SendEmailVerificationMutationVariables>;
export const SendMagicLinkEmailDocument = gql`
    mutation SendMagicLinkEmail($emailAddress: String!, $redirectPath: String, $scheme: MagicLinkRequestScheme, $state: String) {
  sendMagicLinkEmail(
    emailAddress: $emailAddress
    redirectPath: $redirectPath
    scheme: $scheme
    state: $state
  )
}
    `;
export type SendMagicLinkEmailMutationFn = Apollo.MutationFunction<SendMagicLinkEmailMutation, SendMagicLinkEmailMutationVariables>;

/**
 * __useSendMagicLinkEmailMutation__
 *
 * To run a mutation, you first call `useSendMagicLinkEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMagicLinkEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMagicLinkEmailMutation, { data, loading, error }] = useSendMagicLinkEmailMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *      redirectPath: // value for 'redirectPath'
 *      scheme: // value for 'scheme'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useSendMagicLinkEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendMagicLinkEmailMutation, SendMagicLinkEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMagicLinkEmailMutation, SendMagicLinkEmailMutationVariables>(SendMagicLinkEmailDocument, options);
      }
export type SendMagicLinkEmailMutationHookResult = ReturnType<typeof useSendMagicLinkEmailMutation>;
export type SendMagicLinkEmailMutationResult = Apollo.MutationResult<SendMagicLinkEmailMutation>;
export type SendMagicLinkEmailMutationOptions = Apollo.BaseMutationOptions<SendMagicLinkEmailMutation, SendMagicLinkEmailMutationVariables>;
export const RegisterUserOAuthDocument = gql`
    mutation RegisterUserOAuth($service: OAuthService!, $code: String!, $timezone: String!, $tosVersionAccepted: String) {
  registerUserOAuth(
    service: $service
    code: $code
    timezone: $timezone
    tosVersionAccepted: $tosVersionAccepted
  ) {
    bearer
  }
}
    `;
export type RegisterUserOAuthMutationFn = Apollo.MutationFunction<RegisterUserOAuthMutation, RegisterUserOAuthMutationVariables>;

/**
 * __useRegisterUserOAuthMutation__
 *
 * To run a mutation, you first call `useRegisterUserOAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserOAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserOAuthMutation, { data, loading, error }] = useRegisterUserOAuthMutation({
 *   variables: {
 *      service: // value for 'service'
 *      code: // value for 'code'
 *      timezone: // value for 'timezone'
 *      tosVersionAccepted: // value for 'tosVersionAccepted'
 *   },
 * });
 */
export function useRegisterUserOAuthMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserOAuthMutation, RegisterUserOAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterUserOAuthMutation, RegisterUserOAuthMutationVariables>(RegisterUserOAuthDocument, options);
      }
export type RegisterUserOAuthMutationHookResult = ReturnType<typeof useRegisterUserOAuthMutation>;
export type RegisterUserOAuthMutationResult = Apollo.MutationResult<RegisterUserOAuthMutation>;
export type RegisterUserOAuthMutationOptions = Apollo.BaseMutationOptions<RegisterUserOAuthMutation, RegisterUserOAuthMutationVariables>;
export const LogInOAuthDocument = gql`
    mutation LogInOAuth($service: OAuthService!, $code: String!, $activeLegalEntityId: String) {
  logInOAuth(
    service: $service
    code: $code
    activeLegalEntityId: $activeLegalEntityId
  ) {
    ...SessionFields
  }
}
    ${SessionFieldsFragmentDoc}`;
export type LogInOAuthMutationFn = Apollo.MutationFunction<LogInOAuthMutation, LogInOAuthMutationVariables>;

/**
 * __useLogInOAuthMutation__
 *
 * To run a mutation, you first call `useLogInOAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogInOAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logInOAuthMutation, { data, loading, error }] = useLogInOAuthMutation({
 *   variables: {
 *      service: // value for 'service'
 *      code: // value for 'code'
 *      activeLegalEntityId: // value for 'activeLegalEntityId'
 *   },
 * });
 */
export function useLogInOAuthMutation(baseOptions?: Apollo.MutationHookOptions<LogInOAuthMutation, LogInOAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogInOAuthMutation, LogInOAuthMutationVariables>(LogInOAuthDocument, options);
      }
export type LogInOAuthMutationHookResult = ReturnType<typeof useLogInOAuthMutation>;
export type LogInOAuthMutationResult = Apollo.MutationResult<LogInOAuthMutation>;
export type LogInOAuthMutationOptions = Apollo.BaseMutationOptions<LogInOAuthMutation, LogInOAuthMutationVariables>;
export const LogInMagicLinkDocument = gql`
    mutation LogInMagicLink($token: String!, $state: String, $activeLegalEntityId: String) {
  logInMagicLink(
    token: $token
    state: $state
    activeLegalEntityId: $activeLegalEntityId
  ) {
    ...SessionFields
  }
}
    ${SessionFieldsFragmentDoc}`;
export type LogInMagicLinkMutationFn = Apollo.MutationFunction<LogInMagicLinkMutation, LogInMagicLinkMutationVariables>;

/**
 * __useLogInMagicLinkMutation__
 *
 * To run a mutation, you first call `useLogInMagicLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogInMagicLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logInMagicLinkMutation, { data, loading, error }] = useLogInMagicLinkMutation({
 *   variables: {
 *      token: // value for 'token'
 *      state: // value for 'state'
 *      activeLegalEntityId: // value for 'activeLegalEntityId'
 *   },
 * });
 */
export function useLogInMagicLinkMutation(baseOptions?: Apollo.MutationHookOptions<LogInMagicLinkMutation, LogInMagicLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogInMagicLinkMutation, LogInMagicLinkMutationVariables>(LogInMagicLinkDocument, options);
      }
export type LogInMagicLinkMutationHookResult = ReturnType<typeof useLogInMagicLinkMutation>;
export type LogInMagicLinkMutationResult = Apollo.MutationResult<LogInMagicLinkMutation>;
export type LogInMagicLinkMutationOptions = Apollo.BaseMutationOptions<LogInMagicLinkMutation, LogInMagicLinkMutationVariables>;
export const AddSignupDocument = gql`
    mutation AddSignup($email: String!, $givenName: String, $familyName: String, $timezone: String, $persona: Persona!, $planIntent: PlanIntent!, $attribution: SignupAttribution) {
  signup: addSignup(
    email: $email
    givenName: $givenName
    familyName: $familyName
    timezone: $timezone
    persona: $persona
    planIntent: $planIntent
    attribution: $attribution
  ) {
    ...PublicSignupFields
  }
}
    ${PublicSignupFieldsFragmentDoc}`;
export type AddSignupMutationFn = Apollo.MutationFunction<AddSignupMutation, AddSignupMutationVariables>;

/**
 * __useAddSignupMutation__
 *
 * To run a mutation, you first call `useAddSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSignupMutation, { data, loading, error }] = useAddSignupMutation({
 *   variables: {
 *      email: // value for 'email'
 *      givenName: // value for 'givenName'
 *      familyName: // value for 'familyName'
 *      timezone: // value for 'timezone'
 *      persona: // value for 'persona'
 *      planIntent: // value for 'planIntent'
 *      attribution: // value for 'attribution'
 *   },
 * });
 */
export function useAddSignupMutation(baseOptions?: Apollo.MutationHookOptions<AddSignupMutation, AddSignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSignupMutation, AddSignupMutationVariables>(AddSignupDocument, options);
      }
export type AddSignupMutationHookResult = ReturnType<typeof useAddSignupMutation>;
export type AddSignupMutationResult = Apollo.MutationResult<AddSignupMutation>;
export type AddSignupMutationOptions = Apollo.BaseMutationOptions<AddSignupMutation, AddSignupMutationVariables>;
export const RegisterInviteOAuthDocument = gql`
    mutation RegisterInviteOAuth($invitationToken: String!, $timezone: String!, $code: String!, $service: OAuthService!) {
  registerInviteOAuth(
    invitationToken: $invitationToken
    timezone: $timezone
    code: $code
    service: $service
  ) {
    bearer
  }
}
    `;
export type RegisterInviteOAuthMutationFn = Apollo.MutationFunction<RegisterInviteOAuthMutation, RegisterInviteOAuthMutationVariables>;

/**
 * __useRegisterInviteOAuthMutation__
 *
 * To run a mutation, you first call `useRegisterInviteOAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterInviteOAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerInviteOAuthMutation, { data, loading, error }] = useRegisterInviteOAuthMutation({
 *   variables: {
 *      invitationToken: // value for 'invitationToken'
 *      timezone: // value for 'timezone'
 *      code: // value for 'code'
 *      service: // value for 'service'
 *   },
 * });
 */
export function useRegisterInviteOAuthMutation(baseOptions?: Apollo.MutationHookOptions<RegisterInviteOAuthMutation, RegisterInviteOAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterInviteOAuthMutation, RegisterInviteOAuthMutationVariables>(RegisterInviteOAuthDocument, options);
      }
export type RegisterInviteOAuthMutationHookResult = ReturnType<typeof useRegisterInviteOAuthMutation>;
export type RegisterInviteOAuthMutationResult = Apollo.MutationResult<RegisterInviteOAuthMutation>;
export type RegisterInviteOAuthMutationOptions = Apollo.BaseMutationOptions<RegisterInviteOAuthMutation, RegisterInviteOAuthMutationVariables>;
export const CreatePaymentPlaidLinkTokenDocument = gql`
    mutation CreatePaymentPlaidLinkToken($plaidClientUserId: String!) {
  createPaymentPlaidLinkToken(plaidClientUserId: $plaidClientUserId) {
    token
  }
}
    `;
export type CreatePaymentPlaidLinkTokenMutationFn = Apollo.MutationFunction<CreatePaymentPlaidLinkTokenMutation, CreatePaymentPlaidLinkTokenMutationVariables>;

/**
 * __useCreatePaymentPlaidLinkTokenMutation__
 *
 * To run a mutation, you first call `useCreatePaymentPlaidLinkTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentPlaidLinkTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentPlaidLinkTokenMutation, { data, loading, error }] = useCreatePaymentPlaidLinkTokenMutation({
 *   variables: {
 *      plaidClientUserId: // value for 'plaidClientUserId'
 *   },
 * });
 */
export function useCreatePaymentPlaidLinkTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentPlaidLinkTokenMutation, CreatePaymentPlaidLinkTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentPlaidLinkTokenMutation, CreatePaymentPlaidLinkTokenMutationVariables>(CreatePaymentPlaidLinkTokenDocument, options);
      }
export type CreatePaymentPlaidLinkTokenMutationHookResult = ReturnType<typeof useCreatePaymentPlaidLinkTokenMutation>;
export type CreatePaymentPlaidLinkTokenMutationResult = Apollo.MutationResult<CreatePaymentPlaidLinkTokenMutation>;
export type CreatePaymentPlaidLinkTokenMutationOptions = Apollo.BaseMutationOptions<CreatePaymentPlaidLinkTokenMutation, CreatePaymentPlaidLinkTokenMutationVariables>;
export const ProvisionVerificationTokenDocument = gql`
    mutation ProvisionVerificationToken($emailAddress: String!, $givenName: String!, $familyName: String!, $object: InputObjectIdentifier!, $intentId: String!) {
  provisionVerificationToken(
    givenName: $givenName
    familyName: $familyName
    emailAddress: $emailAddress
    object: $object
    intentId: $intentId
  ) {
    token
  }
}
    `;
export type ProvisionVerificationTokenMutationFn = Apollo.MutationFunction<ProvisionVerificationTokenMutation, ProvisionVerificationTokenMutationVariables>;

/**
 * __useProvisionVerificationTokenMutation__
 *
 * To run a mutation, you first call `useProvisionVerificationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProvisionVerificationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [provisionVerificationTokenMutation, { data, loading, error }] = useProvisionVerificationTokenMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *      givenName: // value for 'givenName'
 *      familyName: // value for 'familyName'
 *      object: // value for 'object'
 *      intentId: // value for 'intentId'
 *   },
 * });
 */
export function useProvisionVerificationTokenMutation(baseOptions?: Apollo.MutationHookOptions<ProvisionVerificationTokenMutation, ProvisionVerificationTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProvisionVerificationTokenMutation, ProvisionVerificationTokenMutationVariables>(ProvisionVerificationTokenDocument, options);
      }
export type ProvisionVerificationTokenMutationHookResult = ReturnType<typeof useProvisionVerificationTokenMutation>;
export type ProvisionVerificationTokenMutationResult = Apollo.MutationResult<ProvisionVerificationTokenMutation>;
export type ProvisionVerificationTokenMutationOptions = Apollo.BaseMutationOptions<ProvisionVerificationTokenMutation, ProvisionVerificationTokenMutationVariables>;
export const PayInvoiceDocument = gql`
    mutation PayInvoice($invoiceId: String!, $paymentInstrumentId: String!) {
  payInvoice(invoiceId: $invoiceId, paymentInstrumentId: $paymentInstrumentId) {
    ...PayInvoiceSummaryFields
  }
}
    ${PayInvoiceSummaryFieldsFragmentDoc}`;
export type PayInvoiceMutationFn = Apollo.MutationFunction<PayInvoiceMutation, PayInvoiceMutationVariables>;

/**
 * __usePayInvoiceMutation__
 *
 * To run a mutation, you first call `usePayInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payInvoiceMutation, { data, loading, error }] = usePayInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      paymentInstrumentId: // value for 'paymentInstrumentId'
 *   },
 * });
 */
export function usePayInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<PayInvoiceMutation, PayInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PayInvoiceMutation, PayInvoiceMutationVariables>(PayInvoiceDocument, options);
      }
export type PayInvoiceMutationHookResult = ReturnType<typeof usePayInvoiceMutation>;
export type PayInvoiceMutationResult = Apollo.MutationResult<PayInvoiceMutation>;
export type PayInvoiceMutationOptions = Apollo.BaseMutationOptions<PayInvoiceMutation, PayInvoiceMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($resetToken: String!, $newPassword: String!) {
  resetPassword(resetToken: $resetToken, newPassword: $newPassword) {
    ...SessionFields
  }
}
    ${SessionFieldsFragmentDoc}`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      resetToken: // value for 'resetToken'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SendAcceptedInvitationMagicLinkDocument = gql`
    mutation SendAcceptedInvitationMagicLink($invitationToken: String!, $redirectPath: String, $scheme: MagicLinkRequestScheme, $state: String) {
  sendAcceptedInvitationMagicLink(
    invitationToken: $invitationToken
    redirectPath: $redirectPath
    scheme: $scheme
    state: $state
  )
}
    `;
export type SendAcceptedInvitationMagicLinkMutationFn = Apollo.MutationFunction<SendAcceptedInvitationMagicLinkMutation, SendAcceptedInvitationMagicLinkMutationVariables>;

/**
 * __useSendAcceptedInvitationMagicLinkMutation__
 *
 * To run a mutation, you first call `useSendAcceptedInvitationMagicLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAcceptedInvitationMagicLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAcceptedInvitationMagicLinkMutation, { data, loading, error }] = useSendAcceptedInvitationMagicLinkMutation({
 *   variables: {
 *      invitationToken: // value for 'invitationToken'
 *      redirectPath: // value for 'redirectPath'
 *      scheme: // value for 'scheme'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useSendAcceptedInvitationMagicLinkMutation(baseOptions?: Apollo.MutationHookOptions<SendAcceptedInvitationMagicLinkMutation, SendAcceptedInvitationMagicLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendAcceptedInvitationMagicLinkMutation, SendAcceptedInvitationMagicLinkMutationVariables>(SendAcceptedInvitationMagicLinkDocument, options);
      }
export type SendAcceptedInvitationMagicLinkMutationHookResult = ReturnType<typeof useSendAcceptedInvitationMagicLinkMutation>;
export type SendAcceptedInvitationMagicLinkMutationResult = Apollo.MutationResult<SendAcceptedInvitationMagicLinkMutation>;
export type SendAcceptedInvitationMagicLinkMutationOptions = Apollo.BaseMutationOptions<SendAcceptedInvitationMagicLinkMutation, SendAcceptedInvitationMagicLinkMutationVariables>;
export const TokenRefreshDocument = gql`
    mutation TokenRefresh($activeLegalEntityId: String) {
  tokenRefresh(activeLegalEntityId: $activeLegalEntityId) {
    ...SessionFields
  }
}
    ${SessionFieldsFragmentDoc}`;
export type TokenRefreshMutationFn = Apollo.MutationFunction<TokenRefreshMutation, TokenRefreshMutationVariables>;

/**
 * __useTokenRefreshMutation__
 *
 * To run a mutation, you first call `useTokenRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenRefreshMutation, { data, loading, error }] = useTokenRefreshMutation({
 *   variables: {
 *      activeLegalEntityId: // value for 'activeLegalEntityId'
 *   },
 * });
 */
export function useTokenRefreshMutation(baseOptions?: Apollo.MutationHookOptions<TokenRefreshMutation, TokenRefreshMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TokenRefreshMutation, TokenRefreshMutationVariables>(TokenRefreshDocument, options);
      }
export type TokenRefreshMutationHookResult = ReturnType<typeof useTokenRefreshMutation>;
export type TokenRefreshMutationResult = Apollo.MutationResult<TokenRefreshMutation>;
export type TokenRefreshMutationOptions = Apollo.BaseMutationOptions<TokenRefreshMutation, TokenRefreshMutationVariables>;
export const RegisterInvitePasswordlessDocument = gql`
    mutation RegisterInvitePasswordless($invitationToken: String!, $timezone: String!) {
  registerInvitePasswordless(
    invitationToken: $invitationToken
    timezone: $timezone
  ) {
    bearer
  }
}
    `;
export type RegisterInvitePasswordlessMutationFn = Apollo.MutationFunction<RegisterInvitePasswordlessMutation, RegisterInvitePasswordlessMutationVariables>;

/**
 * __useRegisterInvitePasswordlessMutation__
 *
 * To run a mutation, you first call `useRegisterInvitePasswordlessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterInvitePasswordlessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerInvitePasswordlessMutation, { data, loading, error }] = useRegisterInvitePasswordlessMutation({
 *   variables: {
 *      invitationToken: // value for 'invitationToken'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useRegisterInvitePasswordlessMutation(baseOptions?: Apollo.MutationHookOptions<RegisterInvitePasswordlessMutation, RegisterInvitePasswordlessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterInvitePasswordlessMutation, RegisterInvitePasswordlessMutationVariables>(RegisterInvitePasswordlessDocument, options);
      }
export type RegisterInvitePasswordlessMutationHookResult = ReturnType<typeof useRegisterInvitePasswordlessMutation>;
export type RegisterInvitePasswordlessMutationResult = Apollo.MutationResult<RegisterInvitePasswordlessMutation>;
export type RegisterInvitePasswordlessMutationOptions = Apollo.BaseMutationOptions<RegisterInvitePasswordlessMutation, RegisterInvitePasswordlessMutationVariables>;
export const CreatePlaidGuestBankAccountInstrumentDocument = gql`
    mutation CreatePlaidGuestBankAccountInstrument($invoiceId: String!, $publicToken: String!, $accountId: String!, $institutionName: String!) {
  createPlaidGuestBankAccountInstrument(
    invoiceId: $invoiceId
    publicToken: $publicToken
    accountId: $accountId
    institutionName: $institutionName
  ) {
    __typename
    ...BankAccountFields
  }
}
    ${BankAccountFieldsFragmentDoc}`;
export type CreatePlaidGuestBankAccountInstrumentMutationFn = Apollo.MutationFunction<CreatePlaidGuestBankAccountInstrumentMutation, CreatePlaidGuestBankAccountInstrumentMutationVariables>;

/**
 * __useCreatePlaidGuestBankAccountInstrumentMutation__
 *
 * To run a mutation, you first call `useCreatePlaidGuestBankAccountInstrumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlaidGuestBankAccountInstrumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlaidGuestBankAccountInstrumentMutation, { data, loading, error }] = useCreatePlaidGuestBankAccountInstrumentMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      publicToken: // value for 'publicToken'
 *      accountId: // value for 'accountId'
 *      institutionName: // value for 'institutionName'
 *   },
 * });
 */
export function useCreatePlaidGuestBankAccountInstrumentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlaidGuestBankAccountInstrumentMutation, CreatePlaidGuestBankAccountInstrumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlaidGuestBankAccountInstrumentMutation, CreatePlaidGuestBankAccountInstrumentMutationVariables>(CreatePlaidGuestBankAccountInstrumentDocument, options);
      }
export type CreatePlaidGuestBankAccountInstrumentMutationHookResult = ReturnType<typeof useCreatePlaidGuestBankAccountInstrumentMutation>;
export type CreatePlaidGuestBankAccountInstrumentMutationResult = Apollo.MutationResult<CreatePlaidGuestBankAccountInstrumentMutation>;
export type CreatePlaidGuestBankAccountInstrumentMutationOptions = Apollo.BaseMutationOptions<CreatePlaidGuestBankAccountInstrumentMutation, CreatePlaidGuestBankAccountInstrumentMutationVariables>;
export const UpdateSignupDocument = gql`
    mutation UpdateSignup($id: String!, $givenName: String, $familyName: String, $positionTitle: String, $companyName: String, $companyURL: String, $companyLedger: String, $companyCurrency: String, $industry: String) {
  updateSignup(
    id: $id
    givenName: $givenName
    familyName: $familyName
    positionTitle: $positionTitle
    companyName: $companyName
    companyURL: $companyURL
    companyLedger: $companyLedger
    companyCurrency: $companyCurrency
    industry: $industry
  ) {
    ...PublicSignupFields
  }
}
    ${PublicSignupFieldsFragmentDoc}`;
export type UpdateSignupMutationFn = Apollo.MutationFunction<UpdateSignupMutation, UpdateSignupMutationVariables>;

/**
 * __useUpdateSignupMutation__
 *
 * To run a mutation, you first call `useUpdateSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSignupMutation, { data, loading, error }] = useUpdateSignupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      givenName: // value for 'givenName'
 *      familyName: // value for 'familyName'
 *      positionTitle: // value for 'positionTitle'
 *      companyName: // value for 'companyName'
 *      companyURL: // value for 'companyURL'
 *      companyLedger: // value for 'companyLedger'
 *      companyCurrency: // value for 'companyCurrency'
 *      industry: // value for 'industry'
 *   },
 * });
 */
export function useUpdateSignupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSignupMutation, UpdateSignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSignupMutation, UpdateSignupMutationVariables>(UpdateSignupDocument, options);
      }
export type UpdateSignupMutationHookResult = ReturnType<typeof useUpdateSignupMutation>;
export type UpdateSignupMutationResult = Apollo.MutationResult<UpdateSignupMutation>;
export type UpdateSignupMutationOptions = Apollo.BaseMutationOptions<UpdateSignupMutation, UpdateSignupMutationVariables>;
export const RegisterInviteEmailDocument = gql`
    mutation RegisterInviteEmail($invitationToken: String!, $timezone: String!, $emailAddress: String!, $password: String!) {
  registerInviteEmail(
    invitationToken: $invitationToken
    timezone: $timezone
    emailAddress: $emailAddress
    password: $password
  ) {
    bearer
  }
}
    `;
export type RegisterInviteEmailMutationFn = Apollo.MutationFunction<RegisterInviteEmailMutation, RegisterInviteEmailMutationVariables>;

/**
 * __useRegisterInviteEmailMutation__
 *
 * To run a mutation, you first call `useRegisterInviteEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterInviteEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerInviteEmailMutation, { data, loading, error }] = useRegisterInviteEmailMutation({
 *   variables: {
 *      invitationToken: // value for 'invitationToken'
 *      timezone: // value for 'timezone'
 *      emailAddress: // value for 'emailAddress'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useRegisterInviteEmailMutation(baseOptions?: Apollo.MutationHookOptions<RegisterInviteEmailMutation, RegisterInviteEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterInviteEmailMutation, RegisterInviteEmailMutationVariables>(RegisterInviteEmailDocument, options);
      }
export type RegisterInviteEmailMutationHookResult = ReturnType<typeof useRegisterInviteEmailMutation>;
export type RegisterInviteEmailMutationResult = Apollo.MutationResult<RegisterInviteEmailMutation>;
export type RegisterInviteEmailMutationOptions = Apollo.BaseMutationOptions<RegisterInviteEmailMutation, RegisterInviteEmailMutationVariables>;
export const LogInSupportDocument = gql`
    mutation LogInSupport($id: String!) {
  logInSupport(id: $id) {
    bearer
  }
}
    `;
export type LogInSupportMutationFn = Apollo.MutationFunction<LogInSupportMutation, LogInSupportMutationVariables>;

/**
 * __useLogInSupportMutation__
 *
 * To run a mutation, you first call `useLogInSupportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogInSupportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logInSupportMutation, { data, loading, error }] = useLogInSupportMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLogInSupportMutation(baseOptions?: Apollo.MutationHookOptions<LogInSupportMutation, LogInSupportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogInSupportMutation, LogInSupportMutationVariables>(LogInSupportDocument, options);
      }
export type LogInSupportMutationHookResult = ReturnType<typeof useLogInSupportMutation>;
export type LogInSupportMutationResult = Apollo.MutationResult<LogInSupportMutation>;
export type LogInSupportMutationOptions = Apollo.BaseMutationOptions<LogInSupportMutation, LogInSupportMutationVariables>;
export const LogOutSupportDocument = gql`
    mutation LogOutSupport($id: String!) {
  logOutSupport(id: $id) {
    bearer
  }
}
    `;
export type LogOutSupportMutationFn = Apollo.MutationFunction<LogOutSupportMutation, LogOutSupportMutationVariables>;

/**
 * __useLogOutSupportMutation__
 *
 * To run a mutation, you first call `useLogOutSupportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogOutSupportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logOutSupportMutation, { data, loading, error }] = useLogOutSupportMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLogOutSupportMutation(baseOptions?: Apollo.MutationHookOptions<LogOutSupportMutation, LogOutSupportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogOutSupportMutation, LogOutSupportMutationVariables>(LogOutSupportDocument, options);
      }
export type LogOutSupportMutationHookResult = ReturnType<typeof useLogOutSupportMutation>;
export type LogOutSupportMutationResult = Apollo.MutationResult<LogOutSupportMutation>;
export type LogOutSupportMutationOptions = Apollo.BaseMutationOptions<LogOutSupportMutation, LogOutSupportMutationVariables>;
export const CreatePasswordResetDocument = gql`
    mutation CreatePasswordReset($emailAddress: String!, $object: InputObjectIdentifier, $intentId: String, $billId: String, $contactToken: String) {
  createPasswordReset(
    emailAddress: $emailAddress
    object: $object
    intentId: $intentId
    billId: $billId
    contactToken: $contactToken
  )
}
    `;
export type CreatePasswordResetMutationFn = Apollo.MutationFunction<CreatePasswordResetMutation, CreatePasswordResetMutationVariables>;

/**
 * __useCreatePasswordResetMutation__
 *
 * To run a mutation, you first call `useCreatePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPasswordResetMutation, { data, loading, error }] = useCreatePasswordResetMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *      object: // value for 'object'
 *      intentId: // value for 'intentId'
 *      billId: // value for 'billId'
 *      contactToken: // value for 'contactToken'
 *   },
 * });
 */
export function useCreatePasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<CreatePasswordResetMutation, CreatePasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePasswordResetMutation, CreatePasswordResetMutationVariables>(CreatePasswordResetDocument, options);
      }
export type CreatePasswordResetMutationHookResult = ReturnType<typeof useCreatePasswordResetMutation>;
export type CreatePasswordResetMutationResult = Apollo.MutationResult<CreatePasswordResetMutation>;
export type CreatePasswordResetMutationOptions = Apollo.BaseMutationOptions<CreatePasswordResetMutation, CreatePasswordResetMutationVariables>;
export const LogInEmailDocument = gql`
    mutation LogInEmail($emailAddress: String!, $password: String!, $activeLegalEntityId: String) {
  logInEmail(
    emailAddress: $emailAddress
    password: $password
    activeLegalEntityId: $activeLegalEntityId
  ) {
    ...SessionFields
  }
}
    ${SessionFieldsFragmentDoc}`;
export type LogInEmailMutationFn = Apollo.MutationFunction<LogInEmailMutation, LogInEmailMutationVariables>;

/**
 * __useLogInEmailMutation__
 *
 * To run a mutation, you first call `useLogInEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogInEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logInEmailMutation, { data, loading, error }] = useLogInEmailMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *      password: // value for 'password'
 *      activeLegalEntityId: // value for 'activeLegalEntityId'
 *   },
 * });
 */
export function useLogInEmailMutation(baseOptions?: Apollo.MutationHookOptions<LogInEmailMutation, LogInEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogInEmailMutation, LogInEmailMutationVariables>(LogInEmailDocument, options);
      }
export type LogInEmailMutationHookResult = ReturnType<typeof useLogInEmailMutation>;
export type LogInEmailMutationResult = Apollo.MutationResult<LogInEmailMutation>;
export type LogInEmailMutationOptions = Apollo.BaseMutationOptions<LogInEmailMutation, LogInEmailMutationVariables>;
export const RegisterUserEmailDocument = gql`
    mutation RegisterUserEmail($confirmToken: String!, $timezone: String!, $password: String, $tosVersionAccepted: String, $givenName: String, $familyName: String) {
  registerUserEmail(
    confirmToken: $confirmToken
    timezone: $timezone
    password: $password
    tosVersionAccepted: $tosVersionAccepted
    givenName: $givenName
    familyName: $familyName
  ) {
    bearer
  }
}
    `;
export type RegisterUserEmailMutationFn = Apollo.MutationFunction<RegisterUserEmailMutation, RegisterUserEmailMutationVariables>;

/**
 * __useRegisterUserEmailMutation__
 *
 * To run a mutation, you first call `useRegisterUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserEmailMutation, { data, loading, error }] = useRegisterUserEmailMutation({
 *   variables: {
 *      confirmToken: // value for 'confirmToken'
 *      timezone: // value for 'timezone'
 *      password: // value for 'password'
 *      tosVersionAccepted: // value for 'tosVersionAccepted'
 *      givenName: // value for 'givenName'
 *      familyName: // value for 'familyName'
 *   },
 * });
 */
export function useRegisterUserEmailMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserEmailMutation, RegisterUserEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterUserEmailMutation, RegisterUserEmailMutationVariables>(RegisterUserEmailDocument, options);
      }
export type RegisterUserEmailMutationHookResult = ReturnType<typeof useRegisterUserEmailMutation>;
export type RegisterUserEmailMutationResult = Apollo.MutationResult<RegisterUserEmailMutation>;
export type RegisterUserEmailMutationOptions = Apollo.BaseMutationOptions<RegisterUserEmailMutation, RegisterUserEmailMutationVariables>;
export const PreviewBillPaymentDocument = gql`
    query PreviewBillPayment($id: String!, $contactToken: String!) {
  previewBillPayment(id: $id, contactToken: $contactToken) {
    id
    amount {
      ...MonetaryValueFields
    }
    payerCompanyName
    payerEmailAddress
    vendor {
      ...PartyPreviewFields
    }
    image {
      ...BillPaymentPreviewSegmentFields
    }
  }
}
    ${MonetaryValueFieldsFragmentDoc}
${PartyPreviewFieldsFragmentDoc}
${BillPaymentPreviewSegmentFieldsFragmentDoc}`;

/**
 * __usePreviewBillPaymentQuery__
 *
 * To run a query within a React component, call `usePreviewBillPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewBillPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewBillPaymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      contactToken: // value for 'contactToken'
 *   },
 * });
 */
export function usePreviewBillPaymentQuery(baseOptions: Apollo.QueryHookOptions<PreviewBillPaymentQuery, PreviewBillPaymentQueryVariables> & ({ variables: PreviewBillPaymentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreviewBillPaymentQuery, PreviewBillPaymentQueryVariables>(PreviewBillPaymentDocument, options);
      }
export function usePreviewBillPaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewBillPaymentQuery, PreviewBillPaymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreviewBillPaymentQuery, PreviewBillPaymentQueryVariables>(PreviewBillPaymentDocument, options);
        }
export function usePreviewBillPaymentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PreviewBillPaymentQuery, PreviewBillPaymentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PreviewBillPaymentQuery, PreviewBillPaymentQueryVariables>(PreviewBillPaymentDocument, options);
        }
export type PreviewBillPaymentQueryHookResult = ReturnType<typeof usePreviewBillPaymentQuery>;
export type PreviewBillPaymentLazyQueryHookResult = ReturnType<typeof usePreviewBillPaymentLazyQuery>;
export type PreviewBillPaymentSuspenseQueryHookResult = ReturnType<typeof usePreviewBillPaymentSuspenseQuery>;
export type PreviewBillPaymentQueryResult = Apollo.QueryResult<PreviewBillPaymentQuery, PreviewBillPaymentQueryVariables>;
export const ViewInvoiceDocument = gql`
    query ViewInvoice($id: String!, $contactToken: String!) {
  viewInvoice(id: $id, contactToken: $contactToken) {
    ...ViewInvoiceSummaryFields
  }
}
    ${ViewInvoiceSummaryFieldsFragmentDoc}`;

/**
 * __useViewInvoiceQuery__
 *
 * To run a query within a React component, call `useViewInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewInvoiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *      contactToken: // value for 'contactToken'
 *   },
 * });
 */
export function useViewInvoiceQuery(baseOptions: Apollo.QueryHookOptions<ViewInvoiceQuery, ViewInvoiceQueryVariables> & ({ variables: ViewInvoiceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewInvoiceQuery, ViewInvoiceQueryVariables>(ViewInvoiceDocument, options);
      }
export function useViewInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewInvoiceQuery, ViewInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewInvoiceQuery, ViewInvoiceQueryVariables>(ViewInvoiceDocument, options);
        }
export function useViewInvoiceSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ViewInvoiceQuery, ViewInvoiceQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ViewInvoiceQuery, ViewInvoiceQueryVariables>(ViewInvoiceDocument, options);
        }
export type ViewInvoiceQueryHookResult = ReturnType<typeof useViewInvoiceQuery>;
export type ViewInvoiceLazyQueryHookResult = ReturnType<typeof useViewInvoiceLazyQuery>;
export type ViewInvoiceSuspenseQueryHookResult = ReturnType<typeof useViewInvoiceSuspenseQuery>;
export type ViewInvoiceQueryResult = Apollo.QueryResult<ViewInvoiceQuery, ViewInvoiceQueryVariables>;
export const ReadPasswordResetDocument = gql`
    query ReadPasswordReset($resetToken: String!) {
  readPasswordReset(resetToken: $resetToken) {
    isValid
    contact {
      familyName
      givenName
      emailAddress
      avatarUrl
    }
  }
}
    `;

/**
 * __useReadPasswordResetQuery__
 *
 * To run a query within a React component, call `useReadPasswordResetQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadPasswordResetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadPasswordResetQuery({
 *   variables: {
 *      resetToken: // value for 'resetToken'
 *   },
 * });
 */
export function useReadPasswordResetQuery(baseOptions: Apollo.QueryHookOptions<ReadPasswordResetQuery, ReadPasswordResetQueryVariables> & ({ variables: ReadPasswordResetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadPasswordResetQuery, ReadPasswordResetQueryVariables>(ReadPasswordResetDocument, options);
      }
export function useReadPasswordResetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadPasswordResetQuery, ReadPasswordResetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadPasswordResetQuery, ReadPasswordResetQueryVariables>(ReadPasswordResetDocument, options);
        }
export function useReadPasswordResetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadPasswordResetQuery, ReadPasswordResetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadPasswordResetQuery, ReadPasswordResetQueryVariables>(ReadPasswordResetDocument, options);
        }
export type ReadPasswordResetQueryHookResult = ReturnType<typeof useReadPasswordResetQuery>;
export type ReadPasswordResetLazyQueryHookResult = ReturnType<typeof useReadPasswordResetLazyQuery>;
export type ReadPasswordResetSuspenseQueryHookResult = ReturnType<typeof useReadPasswordResetSuspenseQuery>;
export type ReadPasswordResetQueryResult = Apollo.QueryResult<ReadPasswordResetQuery, ReadPasswordResetQueryVariables>;
export const ReadInvitationDocument = gql`
    query ReadInvitation($token: String!) {
  readInvitation(token: $token) {
    recipient {
      givenName
      familyName
      emailAddress
    }
    sender {
      givenName
      avatarUrl
      emailAddress
    }
    organization {
      id
      slug
      name
    }
    service
    invitationType
    organizationTemplateId
  }
  readInvitationRecipient(token: $token) {
    userId
    givenName
    familyName
    emailAddress
    avatarUrl
  }
}
    `;

/**
 * __useReadInvitationQuery__
 *
 * To run a query within a React component, call `useReadInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadInvitationQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useReadInvitationQuery(baseOptions: Apollo.QueryHookOptions<ReadInvitationQuery, ReadInvitationQueryVariables> & ({ variables: ReadInvitationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadInvitationQuery, ReadInvitationQueryVariables>(ReadInvitationDocument, options);
      }
export function useReadInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadInvitationQuery, ReadInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadInvitationQuery, ReadInvitationQueryVariables>(ReadInvitationDocument, options);
        }
export function useReadInvitationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadInvitationQuery, ReadInvitationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadInvitationQuery, ReadInvitationQueryVariables>(ReadInvitationDocument, options);
        }
export type ReadInvitationQueryHookResult = ReturnType<typeof useReadInvitationQuery>;
export type ReadInvitationLazyQueryHookResult = ReturnType<typeof useReadInvitationLazyQuery>;
export type ReadInvitationSuspenseQueryHookResult = ReturnType<typeof useReadInvitationSuspenseQuery>;
export type ReadInvitationQueryResult = Apollo.QueryResult<ReadInvitationQuery, ReadInvitationQueryVariables>;
export const ReadSignupDocument = gql`
    query ReadSignup($id: String!) {
  readSignup(id: $id) {
    ...PublicSignupFields
  }
}
    ${PublicSignupFieldsFragmentDoc}`;

/**
 * __useReadSignupQuery__
 *
 * To run a query within a React component, call `useReadSignupQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadSignupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadSignupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadSignupQuery(baseOptions: Apollo.QueryHookOptions<ReadSignupQuery, ReadSignupQueryVariables> & ({ variables: ReadSignupQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadSignupQuery, ReadSignupQueryVariables>(ReadSignupDocument, options);
      }
export function useReadSignupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadSignupQuery, ReadSignupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadSignupQuery, ReadSignupQueryVariables>(ReadSignupDocument, options);
        }
export function useReadSignupSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadSignupQuery, ReadSignupQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadSignupQuery, ReadSignupQueryVariables>(ReadSignupDocument, options);
        }
export type ReadSignupQueryHookResult = ReturnType<typeof useReadSignupQuery>;
export type ReadSignupLazyQueryHookResult = ReturnType<typeof useReadSignupLazyQuery>;
export type ReadSignupSuspenseQueryHookResult = ReturnType<typeof useReadSignupSuspenseQuery>;
export type ReadSignupQueryResult = Apollo.QueryResult<ReadSignupQuery, ReadSignupQueryVariables>;
export const PreviewShareDocument = gql`
    query PreviewShare($kind: ObjectKind!, $id: String!, $intentId: String) {
  previewShare(kind: $kind, id: $id, intentId: $intentId) {
    legalEntity {
      id
      name
      slug
    }
    userEmail
    object {
      __typename
      ... on ReportPackagePreview {
        author {
          ...EntityUserFields
        }
        createdAt
        periodStartedAt
        periodEndedAt
        interval
        title
        coverUrl
        preparedBy
      }
      ... on TransactionPreview {
        ...TransactionPreviewFields
      }
      ... on PartyPreview {
        ...PartyPreviewFields
      }
      ... on CategoryPreview {
        ...CategoryPreviewFields
      }
    }
  }
}
    ${EntityUserFieldsFragmentDoc}
${TransactionPreviewFieldsFragmentDoc}
${PartyPreviewFieldsFragmentDoc}
${CategoryPreviewFieldsFragmentDoc}`;

/**
 * __usePreviewShareQuery__
 *
 * To run a query within a React component, call `usePreviewShareQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviewShareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviewShareQuery({
 *   variables: {
 *      kind: // value for 'kind'
 *      id: // value for 'id'
 *      intentId: // value for 'intentId'
 *   },
 * });
 */
export function usePreviewShareQuery(baseOptions: Apollo.QueryHookOptions<PreviewShareQuery, PreviewShareQueryVariables> & ({ variables: PreviewShareQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreviewShareQuery, PreviewShareQueryVariables>(PreviewShareDocument, options);
      }
export function usePreviewShareLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviewShareQuery, PreviewShareQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreviewShareQuery, PreviewShareQueryVariables>(PreviewShareDocument, options);
        }
export function usePreviewShareSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PreviewShareQuery, PreviewShareQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PreviewShareQuery, PreviewShareQueryVariables>(PreviewShareDocument, options);
        }
export type PreviewShareQueryHookResult = ReturnType<typeof usePreviewShareQuery>;
export type PreviewShareLazyQueryHookResult = ReturnType<typeof usePreviewShareLazyQuery>;
export type PreviewShareSuspenseQueryHookResult = ReturnType<typeof usePreviewShareSuspenseQuery>;
export type PreviewShareQueryResult = Apollo.QueryResult<PreviewShareQuery, PreviewShareQueryVariables>;
export const ReadInvitationRecipientDocument = gql`
    query ReadInvitationRecipient($token: String!) {
  readInvitationRecipient(token: $token) {
    userId
    givenName
    familyName
    emailAddress
    avatarUrl
  }
}
    `;

/**
 * __useReadInvitationRecipientQuery__
 *
 * To run a query within a React component, call `useReadInvitationRecipientQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadInvitationRecipientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadInvitationRecipientQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useReadInvitationRecipientQuery(baseOptions: Apollo.QueryHookOptions<ReadInvitationRecipientQuery, ReadInvitationRecipientQueryVariables> & ({ variables: ReadInvitationRecipientQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadInvitationRecipientQuery, ReadInvitationRecipientQueryVariables>(ReadInvitationRecipientDocument, options);
      }
export function useReadInvitationRecipientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadInvitationRecipientQuery, ReadInvitationRecipientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadInvitationRecipientQuery, ReadInvitationRecipientQueryVariables>(ReadInvitationRecipientDocument, options);
        }
export function useReadInvitationRecipientSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadInvitationRecipientQuery, ReadInvitationRecipientQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadInvitationRecipientQuery, ReadInvitationRecipientQueryVariables>(ReadInvitationRecipientDocument, options);
        }
export type ReadInvitationRecipientQueryHookResult = ReturnType<typeof useReadInvitationRecipientQuery>;
export type ReadInvitationRecipientLazyQueryHookResult = ReturnType<typeof useReadInvitationRecipientLazyQuery>;
export type ReadInvitationRecipientSuspenseQueryHookResult = ReturnType<typeof useReadInvitationRecipientSuspenseQuery>;
export type ReadInvitationRecipientQueryResult = Apollo.QueryResult<ReadInvitationRecipientQuery, ReadInvitationRecipientQueryVariables>;
export const LogOutDocument = gql`
    mutation LogOut {
  logOut
}
    `;
export type LogOutMutationFn = Apollo.MutationFunction<LogOutMutation, LogOutMutationVariables>;

/**
 * __useLogOutMutation__
 *
 * To run a mutation, you first call `useLogOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logOutMutation, { data, loading, error }] = useLogOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogOutMutation(baseOptions?: Apollo.MutationHookOptions<LogOutMutation, LogOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogOutMutation, LogOutMutationVariables>(LogOutDocument, options);
      }
export type LogOutMutationHookResult = ReturnType<typeof useLogOutMutation>;
export type LogOutMutationResult = Apollo.MutationResult<LogOutMutation>;
export type LogOutMutationOptions = Apollo.BaseMutationOptions<LogOutMutation, LogOutMutationVariables>;