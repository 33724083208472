import * as React from "react"
import { useRouteMatch } from "react-router-dom"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { NavSidebarProvider } from "src/frontend/components/Shared/Contexts/NavSidebarContext"
import { useNavSidebarContext } from "src/frontend/components/Shared/Contexts/useNavSidebarContext"
import { LogoFooter } from "src/frontend/components/Shared/NavSidebar/Footer/LogoFooter"
import { ModalFooter } from "src/frontend/components/Shared/NavSidebar/Footer/ModalFooter"
import { SidebarBrandedHeader } from "src/frontend/components/Shared/NavSidebar/SidebarBrandedHeader"
import {
  SharedUserSidebarContents,
  SidebarContents,
} from "src/frontend/components/Shared/NavSidebar/SidebarContents"
import { SidebarContainer } from "src/frontend/components/Shared/NavSidebar/SidebarExpando"
import {
  SharedUserFooter,
  SidebarFooter,
} from "src/frontend/components/Shared/NavSidebar/SidebarFooter"
import { SidebarHeader } from "src/frontend/components/Shared/NavSidebar/SidebarHeader"
import routes from "src/frontend/routes"
import FrontendSession from "src/frontend/session"
import { Experience } from "src/frontend/session/personas"

/*
  STYLES
*/

const Sidebar = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  color: ${colors.secondary};
  font-weight: ${fonts.weight.heavy};
  font-size: 12px;
  line-height: 16px;
`

/*
  COMPONENT
*/

export const NavSidebar: React.FC = () => {
  const { currentPrimaryExperience, currentLegalEntity } = useSession<FrontendSession>()
  const isSharedUser = useRouteMatch(routes.sharedWithMe.parameterizedPath)
  const isAssistant = useRouteMatch(routes.assistant.parameterizedPath)

  if (isSharedUser) {
    return (
      <NavSidebarProvider>
        <SharedUserSidebar />
      </NavSidebarProvider>
    )
  }

  if (isAssistant) {
    return null
  }

  if (!currentPrimaryExperience && !currentLegalEntity?.accountingViewVersions?.size) {
    return (
      <NavSidebarProvider>
        <SharedUserSidebar />
      </NavSidebarProvider>
    )
  }

  switch (currentPrimaryExperience) {
    case Experience.ClientPortal:
      return (
        <NavSidebarProvider>
          <ClientSidebar />
        </NavSidebarProvider>
      )

    // Accountant orgs can have clients and also LEs (their own business), show the billing plan info for both.
    default:
      return (
        <NavSidebarProvider>
          <UserSidebar />
        </NavSidebarProvider>
      )
  }
}

const UserSidebar: React.FC = () => {
  const { isHeaderMenuOpen, isNavPinned } = useNavSidebarContext()

  return (
    <SidebarContainer isHeaderMenuOpen={isHeaderMenuOpen} isNavPinned={isNavPinned}>
      <Sidebar>
        <SidebarHeader />
        <SidebarContents />
        <SidebarFooter />
      </Sidebar>
      <ModalFooter />
    </SidebarContainer>
  )
}

const ClientSidebar: React.FC = () => {
  const { isNavPinned, isHeaderMenuOpen } = useNavSidebarContext()

  return (
    <SidebarContainer isHeaderMenuOpen={isHeaderMenuOpen} isNavPinned={isNavPinned}>
      <Sidebar>
        <SidebarBrandedHeader />
        <SidebarContents />
        <SidebarFooter />
      </Sidebar>
      <LogoFooter />
    </SidebarContainer>
  )
}

const SharedUserSidebar: React.FC = () => (
  <SidebarContainer isHeaderMenuOpen={false} isNavPinned={false}>
    <Sidebar>
      <SharedUserSidebarContents />
      <SharedUserFooter />
    </Sidebar>
    <LogoFooter />
  </SidebarContainer>
)
