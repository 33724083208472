import * as React from "react"
import { GRAPHQL_TRACE_KEY } from "@digits-shared/components/Contexts/GraphQLTracerContext"
import useSession from "@digits-shared/hooks/useSession"
import FrontendSession from "src/frontend/session"
import { userPreferenceKeys } from "src/shared/config/userPreferenceKeys"

export function useInternalUserSettings() {
  const session = useSession<FrontendSession>()

  const toggleUserSetting = React.useCallback(
    (setting: string, enabled?: boolean) => {
      if (enabled === undefined) {
        return session.deleteUserPreference(setting)
      }
      session.setUserPreference(setting, enabled)
    },
    [session]
  )

  const graphqlTracing = session.getBooleanUserPreference(GRAPHQL_TRACE_KEY)

  /**
   * Check if the grid overlay should be displayed
   */
  const gridOverlayEnabled = session.getBooleanUserPreference(userPreferenceKeys.gridDevTools)

  /**
   * Check if the animation config dev tools preference is enabled.
   */
  const animationConfigDevToolsEnabled = session.getBooleanUserPreference(
    userPreferenceKeys.animationConfigDevTools
  )

  /**
   * Disable Graphql batching
   */
  const graphqlNoBatching = session.getBooleanUserPreference(userPreferenceKeys.graphqlNoBatching)

  /*
   * Check if the new Action Items UI should be displayed
   */
  const actionItemAssignments = session.getBooleanUserPreference(
    userPreferenceKeys.actionItemAssignments
  )

  return React.useMemo(
    () => ({
      graphqlTracing,
      gridOverlayEnabled,
      animationConfigDevToolsEnabled,
      toggleUserSetting,
      graphqlNoBatching,
      actionItemAssignments,
    }),
    [
      graphqlTracing,
      gridOverlayEnabled,
      animationConfigDevToolsEnabled,
      toggleUserSetting,
      graphqlNoBatching,
      actionItemAssignments,
    ]
  )
}
