import * as React from "react"
import {
  DepartmentChartConfig,
  EntityDepartment,
  LayoutComponentType,
  useReadDepartmentSummaryByTimeLiveDataQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import {
  DepartmentSummaries,
  filterDepartmentAncestors,
} from "src/frontend/components/Shared/Layout/Components/Departments/filterDepartmentAncestors"

export type DepartmentComponentData =
  | {
      department: EntityDepartment
      dimensionalSummary: DepartmentSummaries[] | undefined
      loading: boolean
    }
  | { department: undefined; dimensionalSummary: undefined; loading: boolean }

/**
 * Queries for layout live data fitting the dimensional reducer shape: by component by time.
 *
 */
export function useDepartmentSummaryByTimeLiveData(
  departmentChart: DepartmentChartConfig | undefined | null,
  type: LayoutComponentType,
  skip?: boolean
): DepartmentComponentData {
  const viewId = useViewVersion()

  const { data, loading } = useReadDepartmentSummaryByTimeLiveDataQuery({
    variables: {
      viewId,
      config: {
        type,
        departmentChart,
      },
    },
    skip: skip || !viewId.viewVersion,
    // Stop batching this request to unblock all the other components
    context: { noBatch: true },
  })

  return React.useMemo(() => {
    const liveData = data?.liveComponentData

    // Resolves the component data union types to the portions we care about
    if (liveData?.__typename === "Chart" && liveData?.entity.__typename === "EntityDepartment") {
      const dimensionalSummary = filterDepartmentAncestors(
        liveData.entity.id,
        liveData.summary.department
      )
      return { department: liveData.entity, dimensionalSummary, loading }
    }

    return { department: undefined, dimensionalSummary: undefined, loading }
  }, [data?.liveComponentData, loading])
}
