import * as React from "react"
import { Link } from "react-router-dom"
import {
  Insight,
  InsightFieldsFragment,
  InsightSubjectType,
  InsightType,
  IntervalOrigin,
  ObjectEntities,
  PartyRole,
} from "@digits-graphql/frontend/graphql-bearer"
import { SessionProps } from "@digits-shared/components/Contexts/SessionContext"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import { RowContentDescription } from "@digits-shared/components/UI/Table/Content"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import numberHelper from "@digits-shared/helpers/numberHelper"
import Session from "@digits-shared/session/Session"
import { withSessionContext } from "@digits-shared/session/withSessionContext"
import { themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import routes from "src/frontend/routes"
import FrontendSession from "src/frontend/session"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"
import { formatInsightChildNode } from "src/shared/components/Insights/sentenceParsing"
import EntitiesParser from "src/shared/components/ObjectEntities/EntitiesParser"
import sentenceParsingHelper, {
  InsightTagInfo,
  SentenceTagKeys,
} from "src/shared/helpers/sentenceParsingHelper"

/*
 STYLES
*/

const InsightItemStyled = styled.li<{ isAdmin?: boolean }>`
  display: flex;
  padding: 8px 0;
  align-items: center;
  justify-content: start;

  &:last-child {
    border-bottom: none;
  }

  border-bottom: 1px solid ${colors.translucentWhite04};
`

const NoResultsListItem = styled(InsightItemStyled)`
  font-size: 13px;
`

const descriptionColor = themedValue({
  light: colors.translucentSecondary80,
  dark: colors.translucentWhite80,
})
const InsightDescription = styled(RowContentDescription)`
  color: ${descriptionColor};
  font-size: 14px;
  font-weight: ${fonts.weight.medium};
  line-height: 19px;
  flex: 1;
  display: flow-root;
  align-items: center;
  white-space: normal;
`

export const Score = styled.div`
  font-size: 13px;
  font-style: ${fonts.style.oblique};
  color: ${colors.gray};
`

const Scores = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
`

const boldColor = themedValue({
  light: colors.secondary,
  dark: colors.theme.dark.text,
})
const Bold = styled.span`
  color: ${boldColor};
  font-weight: ${fonts.weight.heavy};
  display: contents;
`

const Subject = styled.div`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
`

const highlightColor = themedValue({
  light: colors.secondary,
  dark: colors.white,
})
export const SubjectNameHighlight = styled(Link)<{ disabled: boolean }>`
  margin-left: 0;
  color: ${highlightColor};
  font-weight: ${fonts.weight.heavy};

  svg + & {
    margin-left: 24px;
  }

  &:hover {
    text-decoration: underline;
  }

  pointer-events: ${({ disabled }) => (disabled ? "none" : "")};
`

interface DeltaHighlightProps {
  absoluteDirection: AbsoluteDirection
  type: InsightType
}

const deltaHighligthColor = themedValue<DeltaHighlightProps>({
  light: ({ absoluteDirection, type }) =>
    absoluteDirection === AbsoluteDirection.Up
      ? type === InsightType.Income
        ? colors.primary
        : colors.deltaBad
      : type === InsightType.Income
        ? colors.deltaBad
        : colors.primary,
  dark: ({ absoluteDirection, type }) =>
    absoluteDirection === AbsoluteDirection.Up
      ? type === InsightType.Income
        ? colors.neonGreen
        : colors.deltaBad
      : type === InsightType.Income
        ? colors.deltaBad
        : colors.neonGreen,
})

const DeltaHighlight = styled.span<DeltaHighlightProps>`
  color: ${deltaHighligthColor};
  font-weight: ${fonts.weight.heavy};
  display: contents;
`

export const ENTITY_INSIGHT_STYLES = css`
  /* These must match the backend class names. */
  .dollar-value,
  .percentage {
    font-weight: bold;
  }
  .outbound {
    color: ${colors.orange};
  }
  .inbound {
    color: ${colors.primary};
  }
`

export const EntityInsight = styled.div`
  outline: none;
  resize: none;
  text-align: left;
  word-break: break-word;
  font-weight: 350;
  font-size: 14px;
  line-height: 23px;

  ${ENTITY_INSIGHT_STYLES};

  ul,
  ol {
    padding: revert;
  }
`

/*
 INTERFACES
*/

enum AbsoluteDirection {
  Up = "Up",
  Down = "Down",
}

interface Props {
  intervalOrigin: IntervalOrigin
  legalEntityId: string
  entities: ObjectEntities | undefined | null
  insight: Insight
  isAdmin?: boolean
  className?: string
  alwaysShowDate: boolean
}

/*
 COMPONENTS
*/

export class InsightItem extends React.PureComponent<Props & SessionProps> {
  render() {
    const { className, insight, isAdmin } = this.props

    return (
      <InsightItemStyled className={className} isAdmin={isAdmin}>
        {this.renderInsightText()}
        <Scores>
          {isAdmin && <Score>Score: {insight.score}</Score>}
          {isAdmin && <Score>Global Score: {insight.globalScore?.toFixed(4) || "none"}</Score>}
        </Scores>
      </InsightItemStyled>
    )
  }

  renderInsightText() {
    const { insight, alwaysShowDate, intervalOrigin, entities } = this.props
    if (!insight.periodValue) {
      console.error("Insight %o without a periodValue", insight)
      return null
    }

    // Heuristic to detect the new-style insights based on the absence of the old-style tags that used tildes.
    if (insight.sentence && !sentenceParsingHelper.sentenceContainsUnparsedTag(insight.sentence)) {
      return (
        <EntityInsight>
          <EntitiesParser
            text={insight.sentence}
            entities={entities}
            formatChildNode={formatInsightChildNode}
          />
        </EntityInsight>
      )
    }

    const sentenceParts = []
    let sentence = insight.sentence || ""
    const isCurrentPeriod = dateTimeHelper.isCurrentInterval(intervalOrigin)

    while (sentenceParsingHelper.sentenceContainsUnparsedTag(sentence)) {
      const sentenceTagKey = sentenceParsingHelper.getFirstStartTag(sentence)
      const tagInfo = sentenceParsingHelper.parseSentencePart(sentence, sentenceTagKey)

      if (!tagInfo) {
        sentence = "There was a problem processing this insight"
        break
      }

      const { tagStartIndex, tagEndIndex } = tagInfo
      if (tagStartIndex !== 0) {
        sentenceParts.push(sentence.substring(0, tagStartIndex))
      }

      // parse off tag and continue parsing
      // don't display the current time placeholder unless requested to always show date
      if (
        !alwaysShowDate &&
        !isCurrentPeriod &&
        sentenceTagKey === SentenceTagKeys.CURRENT_TIME_PLACEHOLDER
      ) {
        sentence = sentence.substring(tagEndIndex)
        continue
      }

      sentenceParts.push(this.renderSentenceTag(tagInfo, sentenceParts.length, sentenceTagKey))

      sentence = sentence.substring(tagEndIndex)
    }

    if (sentence.length > 0) {
      sentenceParts.push(sentence)
    }

    const fullSentence = sentenceParts.map((part, index) => (
      <React.Fragment key={index}>{part}</React.Fragment>
    ))

    return <InsightDescription>{fullSentence}</InsightDescription>
  }

  renderSentenceTag(
    tagInfo: InsightTagInfo,
    sentencePartCount: number,
    sentenceTagKey?: SentenceTagKeys
  ) {
    const { insight, intervalOrigin } = this.props
    if (!insight.periodValue) {
      console.error("Insight %o without a periodValue", insight)
      return null
    }

    const { parsedValue } = tagInfo
    const { periodValue } = insight

    switch (sentenceTagKey) {
      case SentenceTagKeys.SUBJECT_NAME:
      case SentenceTagKeys.ENTITY_CATEGORY:
      case SentenceTagKeys.ENTITY_CATEGORY_LEGACY: // to support legacy insights
      case SentenceTagKeys.ENTITY_PARTY:
      case SentenceTagKeys.ENTITY_PARTY_LEGACY: // to support legacy insights
      case SentenceTagKeys.ENTITY_PARTY_VENDOR:
      case SentenceTagKeys.ENTITY_PARTY_PREPAID_VENDOR:
      case SentenceTagKeys.ENTITY_PARTY_OWED_VENDOR:
      case SentenceTagKeys.ENTITY_PARTY_CUSTOMER:
      case SentenceTagKeys.ENTITY_PARTY_UNPAID_CUSTOMER:
      case SentenceTagKeys.ENTITY_PARTY_SUPPLIER:
      case SentenceTagKeys.ENTITY_PARTY_SHAREHOLDER:
      case SentenceTagKeys.ENTITY_PARTY_LENDER:
      case SentenceTagKeys.ENTITY_PARTY_FACILITATOR:
        return this.renderCategoryOrPartyName(sentenceTagKey, tagInfo)

      case SentenceTagKeys.PERCENT_DELTA_LESS:
        return this.renderDeltaHighlight(
          AbsoluteDirection.Down,
          numberHelper.formatPercentage(Number(parsedValue))
        )
      case SentenceTagKeys.PERCENT_DELTA_GREATER:
        return this.renderDeltaHighlight(
          AbsoluteDirection.Up,
          numberHelper.formatPercentage(Number(parsedValue))
        )

      case SentenceTagKeys.PREVIOUS_AMOUNT:
      case SentenceTagKeys.ABSOLUTE_AMOUNT:
      case SentenceTagKeys.ABSOLUTE_DELTA:
        return sentenceParsingHelper.getParsedAmountWithCurrency(Number(parsedValue), periodValue)

      case SentenceTagKeys.PREVIOUS_AMOUNT_LESS:
      case SentenceTagKeys.ABSOLUTE_DELTA_LESS:
      case SentenceTagKeys.ABSOLUTE_AMOUNT_LESS:
        return this.renderDeltaHighlight(
          AbsoluteDirection.Down,
          sentenceParsingHelper.getParsedAmountWithCurrency(
            Number(parsedValue),
            periodValue
          ) as string
        )

      case SentenceTagKeys.ABSOLUTE_DELTA_GREATER:
      case SentenceTagKeys.PREVIOUS_AMOUNT_GREATER:
      case SentenceTagKeys.ABSOLUTE_AMOUNT_GREATER:
        return this.renderDeltaHighlight(
          AbsoluteDirection.Up,
          sentenceParsingHelper.getParsedAmountWithCurrency(
            Number(parsedValue),
            periodValue
          ) as string
        )

      case SentenceTagKeys.PERCENT_DELTA_BOLD:
      case SentenceTagKeys.ABSOLUTE_AMOUNT_BOLD:
      case SentenceTagKeys.PREVIOUS_AMOUNT_BOLD:
      case SentenceTagKeys.ABSOLUTE_DELTA_BOLD:
        return (
          <Bold>
            &nbsp;
            {sentenceParsingHelper.getParsedAmountWithCurrency(Number(parsedValue), periodValue)}
          </Bold>
        )

      case SentenceTagKeys.PAST_TIME_PLACEHOLDER:
        return (
          <span>{sentenceParsingHelper.formatPastTime(intervalOrigin, sentencePartCount)}</span>
        )
      case SentenceTagKeys.CURRENT_TIME_PLACEHOLDER: {
        return (
          <span>{sentenceParsingHelper.formatCurrentTime(intervalOrigin, sentencePartCount)}</span>
        )
      }
      case SentenceTagKeys.FULL_DATE_PLACEHOLDER: {
        return <span>{sentenceParsingHelper.formatFullDate(Number(parsedValue))}</span>
      }

      case SentenceTagKeys.LOOKBACK_WINDOW:
      case SentenceTagKeys.PERCENT_DELTA:
      case undefined:
        return parsedValue
    }
  }

  renderDeltaHighlight(absoluteDirection: AbsoluteDirection, absPercentDelta: string) {
    const { insight } = this.props
    const { type } = insight

    return (
      <DeltaHighlight absoluteDirection={absoluteDirection} type={type}>
        {absPercentDelta}
      </DeltaHighlight>
    )
  }

  renderCategoryOrPartyName(sentenceTagKey: SentenceTagKeys, tagInfo: InsightTagInfo) {
    const { insight, intervalOrigin, entities, session } = this.props
    const { parsedValue, entityId } = tagInfo

    const truncatedIntervalOrigin = {
      ...intervalOrigin,
      intervalCount: undefined,
    }
    let name = parsedValue
    let path = routeForInsight(insight, intervalOrigin)
    switch (sentenceTagKey) {
      case SentenceTagKeys.ENTITY_CATEGORY:
        {
          const category = entities?.categories?.find((c) => c.id === entityId)
          if (category) {
            name = category.name || parsedValue
            path = routes.categoryDetails.generateFromCurrentPath({
              categoryId: entityId,
              ...truncatedIntervalOrigin,
            })
          }
        }
        break

      case SentenceTagKeys.ENTITY_PARTY:
      case SentenceTagKeys.ENTITY_PARTY_VENDOR:
      case SentenceTagKeys.ENTITY_PARTY_PREPAID_VENDOR:
      case SentenceTagKeys.ENTITY_PARTY_OWED_VENDOR:
      case SentenceTagKeys.ENTITY_PARTY_CUSTOMER:
      case SentenceTagKeys.ENTITY_PARTY_UNPAID_CUSTOMER:
      case SentenceTagKeys.ENTITY_PARTY_SUPPLIER:
      case SentenceTagKeys.ENTITY_PARTY_SHAREHOLDER:
      case SentenceTagKeys.ENTITY_PARTY_LENDER:
      case SentenceTagKeys.ENTITY_PARTY_FACILITATOR:
        {
          const party = entities?.parties?.find((p) => p.id === entityId)
          if (party) {
            name = party.name || parsedValue
            const frontendPartyRole = FrontendPartyRole.findByRole(
              entities?.parties?.find((p) => p.id === entityId)?.roles?.[0] ||
                PartyRole.EntityVendorRole
            )
            path = routes.partyDetails.generateFromCurrentPath({
              partyId: entityId,
              partyRole: frontendPartyRole.urlKey,
              ...truncatedIntervalOrigin,
            })
          }
        }
        break

      default:
        name = parsedValue
    }

    return (
      <Subject>
        <SubjectNameHighlight
          to={path}
          onClick={this.preventPropagation}
          disabled={hackSharedSession(session) ? session.isSharingContextActive : false}
        >
          {name}
        </SubjectNameHighlight>
      </Subject>
    )
  }

  preventPropagation(e: React.MouseEvent) {
    e.stopPropagation()
  }
}

function hackSharedSession(session: Session): session is FrontendSession {
  return (session as FrontendSession)?.hasLegalEntity
}

export const routeForInsight = (
  insight: Insight | InsightFieldsFragment,
  intervalOrigin: IntervalOrigin
) => {
  const truncatedIntervalOrigin = {
    ...intervalOrigin,
    intervalCount: undefined,
  }

  if (insight.subjectType === InsightSubjectType.Party) {
    const frontendPartyRole = FrontendPartyRole.findByInsightType(insight.type)
    return routes.partyDetails.generateFromCurrentPath({
      partyId: insight.subjectId,
      partyRole: frontendPartyRole.urlKey,
      ...truncatedIntervalOrigin,
    })
  }

  return routes.categoryDetails.generateFromCurrentPath({
    categoryId: insight.subjectId,
    ...truncatedIntervalOrigin,
  })
}

export const EmptyInsight = () => <NoResultsListItem>No Executive Summary</NoResultsListItem>

export const LoadingInsight: React.FC<{ className?: string }> = ({ className }) => (
  <InsightItemStyled className={className}>
    <LoadingBlock height="20px" width="100%" $randomWidthRange={40} />
  </InsightItemStyled>
)

export default withSessionContext<Props>(InsightItem)
