import * as React from "react"
import {
  DepartmentChartConfig,
  LayoutComponentType,
  useReadDepartmentSummaryByTimeComponentDataQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import useConstant from "@digits-shared/hooks/useConstant"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import { filterDepartmentAncestors } from "src/frontend/components/Shared/Layout/Components/Departments/filterDepartmentAncestors"
import {
  DepartmentComponentData,
  useDepartmentSummaryByTimeLiveData,
} from "src/frontend/components/Shared/Layout/Components/Departments/useDepartmentSummaryByTimeLiveData"

/**
 * Queries for layout component data fitting the dimensional reducer shape: by component by time.
 *
 * Must be used within a LayoutContext.
 */
export function useDepartmentSummaryByTimeComponentData(
  departmentChart: DepartmentChartConfig | undefined | null,
  type: LayoutComponentType,
  layoutId: string,
  dataId: string | undefined | null
): DepartmentComponentData {
  const viewId = useViewVersion()
  const { data, loading } = useReadDepartmentSummaryByTimeComponentDataQuery({
    variables: {
      dataId: dataId ?? "",
      layoutId,
      viewId,
    },
    skip: !dataId,
  })

  const initialDataId = useConstant(dataId)
  // Skip loading live data if this component received a dataId at creation time.
  // This means the component was loaded from archive and should only ever show
  // archived data.
  const skipLiveData = !!dataId && dataId === initialDataId

  const {
    department,
    dimensionalSummary,
    loading: liveLoading,
  } = useDepartmentSummaryByTimeLiveData(departmentChart, type, skipLiveData)

  return React.useMemo(() => {
    const componentData = data?.readComponentData.data

    // Data read from the archive takes precedence.
    // Resolves the component data union types to the portions we care about.
    if (
      componentData?.__typename === "Chart" &&
      componentData?.entity.__typename === "EntityDepartment"
    ) {
      const archivedSummary = filterDepartmentAncestors(
        componentData.entity.id,
        componentData.summary.department
      )

      return {
        department: componentData.entity,
        dimensionalSummary: archivedSummary,
        loading,
      }
    }

    // Fall back to live data if we have fetched it.
    // This is necessary to keep data from disappearing mid-drag while we archive the real component data.
    if (department && !liveLoading) {
      return { department, dimensionalSummary, loading: false }
    }

    return { department, dimensionalSummary: undefined, loading }
  }, [data?.readComponentData.data, department, dimensionalSummary, liveLoading, loading])
}
