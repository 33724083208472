import * as React from "react"
import { InsightComponentConfig } from "@digits-graphql/frontend/graphql-bearer"
import {
  InsightComponentItem,
  InsightHeader,
} from "src/frontend/components/Shared/Layout/Components/Insights/InsightComponent"
import { useListInsightsForConfig } from "src/frontend/components/Shared/Layout/Components/Insights/useListInsightsForComponent"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"

/*
  STYLES
*/

/*
  COMPONENTS
*/

export const InsightConfig: React.FC<{ config: InsightComponentConfig }> = ({ config }) => {
  const { loading, entities, sentence } = useListInsightsForConfig(config)
  if (!loading && !sentence) return null

  return (
    <>
      <InsightHeader loading={loading} componentSize={ComponentSize.Small} entities={entities} />
      <InsightComponentItem
        origin={config.origin}
        sentence={sentence}
        entities={entities}
        loading={loading}
      />
    </>
  )
}
