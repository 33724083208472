import * as React from "react"
import {
  CategoryType,
  DirectionFromOrigin,
  EntityLegalEntity,
  EntityLocation,
  Flow,
  Interval,
  useSummarizeTransactionsByTimeQuery,
  ViewType,
} from "@digits-graphql/frontend/graphql-bearer"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgChevronRight } from "@digits-shared/components/SVGIcons/line/ChevronRight.svg"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import {
  SharedDimensionSummaryRow,
  SharedDimensionSummaryRowLink,
  SharedDimensionSummaryRowLoading,
} from "src/frontend/components/OS/Applications/SharedWithMe/Entities/EntityRows/Shared"
import { SparkSummaryChart } from "src/frontend/components/OS/Shared/Charts/SparkSummaryChart"
import {
  DimensionRowDelta,
  DimensionRowSparkChartSizing,
  DimensionRowTotals,
} from "src/frontend/components/OS/Shared/Dimensions/DimensionRow"
import { SvgLocationIcon } from "src/frontend/components/Shared/Layout/Components/Locations/SvgLocationIcon"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import { DEFAULT_INTERVAL_COUNT_MONTH } from "src/shared/hooks/useIntervalOrigin"

/*
  STYLES
*/

const Icon = styled(SvgLocationIcon)`
  width: 24px;
  height: 24px;
  margin-right: 20px;
  margin-left: 20px;
`

const Name = styled.div`
  flex: 1;
  font-size: 13px;
  font-weight: ${fonts.weight.medium};
`

const PointingRightChevron = styled(SvgChevronRight)`
  width: 15px;
  height: 15px;
  ${svgPathStyles(colors.translucentSecondary20, 1.5)};
`

const RowAdditionalSummary = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  margin-right: 15px;
`

/*
  COMPONENTS
*/

export const SharedLocationRow: React.FC<{
  legalEntity: EntityLegalEntity
  location: EntityLocation
  viewType: ViewType | null | undefined
}> = ({ legalEntity, location, viewType }) => {
  const generatePath = useFrontendPathGenerator()
  const { summaries, loading } = useLocationTransactionSummary(
    legalEntity.id,
    location.id,
    viewType
  )

  if (!summaries && loading) {
    return <SharedRowLoading />
  }

  const currentSummary = summaries && summaries[summaries.length - 1]

  return (
    <SharedDimensionSummaryRow>
      <SharedDimensionSummaryRowLink
        to={generatePath(routes.locationDetails, {
          leSlug: legalEntity.slug,
          locationId: location.id,
        })}
      >
        <Icon />
        <Name>{location.name}</Name>

        <RowAdditionalSummary>
          <DimensionRowSparkChartSizing>
            <SparkSummaryChart summaries={summaries || []} animateChart />
          </DimensionRowSparkChartSizing>

          <DimensionRowDelta summary={currentSummary} />

          <DimensionRowTotals summary={currentSummary} />
        </RowAdditionalSummary>

        <PointingRightChevron />
      </SharedDimensionSummaryRowLink>
    </SharedDimensionSummaryRow>
  )
}

const SharedRowLoading: React.FC = () => (
  <SharedDimensionSummaryRow>
    <SharedDimensionSummaryRowLoading>
      <LoadingBlock width="24px" height="24px" margin="0px 20px 0px 0px" />
      <LoadingBlock width="400px" height="24px" margin="0px 20px 0px 0px" />

      <RowAdditionalSummary>
        <LoadingBlock width="145px" height="24px" />
        <DimensionRowDelta isLoading />
        <DimensionRowTotals isLoading />
      </RowAdditionalSummary>

      <PointingRightChevron />
    </SharedDimensionSummaryRowLoading>
  </SharedDimensionSummaryRow>
)

const useLocationTransactionSummary = (
  legalEntityId: string,
  locationId: string,
  viewType: ViewType | null | undefined
) => {
  const origin = dateTimeHelper.todayIntervalOrigin(Interval.Month, DEFAULT_INTERVAL_COUNT_MONTH)
  const { loading, data } = useSummarizeTransactionsByTimeQuery({
    variables: {
      origin,
      filter: {
        viewKey: {
          legalEntityId,
          viewType: viewType,
        },
        locationIds: [locationId],
        categoryTypes: [
          CategoryType.Expenses,
          CategoryType.OtherExpenses,
          CategoryType.CostOfGoodsSold,
          CategoryType.Income,
          CategoryType.OtherIncome,
        ],
      },
      directBookings: true,
      direction: DirectionFromOrigin.Past,
      // Locations are viewed from the lens of profit, so we must specify Income based money flow
      naturalFlow: Flow.Inbound,
    },
    context: { avsBatch: true },
  })

  const summaries = React.useMemo(
    () =>
      data?.dimensionalTransactionSummary.time
        .map((t) => t.summary)
        .sort((s1, s2) => s1.period.startedAt - s2.period.startedAt),
    [data?.dimensionalTransactionSummary.time]
  )

  return React.useMemo(() => ({ loading, summaries }), [loading, summaries])
}
