import * as React from "react"
import { useRouteMatch } from "react-router-dom"
import { useEvent } from "react-use"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import FrontendSession from "src/frontend/session"

export function useSearchKeyLauncher() {
  const { currentLegalEntity } = useSession<FrontendSession>()
  const generatePath = useFrontendPathGenerator()
  const { history } = useRouter()

  const inSearch = !!useRouteMatch(routes.search.parameterizedPath)

  const shouldTrigger = React.useCallback(
    ({ key, metaKey, shiftKey }: React.KeyboardEvent<HTMLElement> | KeyboardEvent) =>
      ((key?.toLowerCase() === "f" && metaKey && shiftKey) ||
        (metaKey && key?.toLowerCase() === "k")) &&
      currentLegalEntity &&
      !inSearch,
    [currentLegalEntity, inSearch]
  )

  const onBodyKeydown = React.useCallback(
    (e: KeyboardEvent) => {
      if (shouldTrigger(e)) {
        const searchPath = generatePath(routes.search, { leSlug: currentLegalEntity?.slug })
        e.preventDefault()
        e.stopPropagation()
        history.push(searchPath)
      }
    },
    [shouldTrigger, generatePath, currentLegalEntity?.slug, history]
  )

  useEvent("keydown", onBodyKeydown, document.body)
}
