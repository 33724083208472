import * as React from "react"
import { SortDirection } from "@digits-graphql/frontend/graphql-bearer"
import { SvgCurrencyDollarCircle } from "@digits-shared/components/SVGIcons/line/CurrencyDollarCircle.svg"
import { SvgLineHeight } from "@digits-shared/components/SVGIcons/line/LineHeight.svg"
import { useDimensionDisplayOptionGroup } from "src/frontend/components/OS/Shared/DisplayOptions/DimensionDisplayOptionGroupContext"
import { DisplayOptionAccessor } from "src/frontend/components/OS/Shared/DisplayOptions/DisplayOption"
import { DisplayOptionsSection } from "src/frontend/components/OS/Shared/DisplayOptions/DisplayOptionsSection"
import { DimensionActivityFilterDropdown } from "src/frontend/components/OS/Shared/DisplayOptions/Filters/DimensionActivityFilterDropdown"
import { FilterColumn } from "src/frontend/components/OS/Shared/DisplayOptions/Filters/FilterColumn"
import { SortColumn } from "src/frontend/components/OS/Shared/DisplayOptions/Sorts/SortColumn"
import { DimensionSortBy } from "src/frontend/types/DimensionFilter"

/*
  COMPONENTS
*/

export const DimensionDisplayOptionGroup: React.FC<{ className?: string }> = ({ className }) => {
  const { clearOnlyFilters, currentFilterURLKeysCount, sortBy, sortDirection } =
    useDimensionDisplayOptionGroup()
  const dimensions = useDimensionSortElements(sortBy, sortDirection)

  return (
    <DisplayOptionsSection dimensions={dimensions} className={className}>
      <SortColumn dimensions={dimensions} sortDirection={sortDirection} />
      <FilterColumn onResetClick={clearOnlyFilters} currentFiltersCount={currentFilterURLKeysCount}>
        <DimensionActivityFilterDropdown />
      </FilterColumn>
    </DisplayOptionsSection>
  )
}

export function useDimensionSortElements(
  sortBy: DisplayOptionAccessor<DimensionSortBy>,
  sortDirection: DisplayOptionAccessor<SortDirection>
) {
  const isSortedIncreasing = React.useMemo(
    () => sortDirection.get() === SortDirection.Ascending,
    [sortDirection]
  )

  const onSelect = React.useCallback(
    (value: DimensionSortBy) => {
      sortBy.set(value)
      sortDirection.clear()
    },
    [sortBy, sortDirection]
  )

  return React.useMemo(
    () =>
      [
        {
          title: "Amount",
          IncreasingTitleIconElement: SvgCurrencyDollarCircle,
          DecreasingTitleIconElement: SvgCurrencyDollarCircle,
          value: DimensionSortBy.Amount,
        },
        {
          title: "Name",
          IncreasingTitleIconElement: SvgLineHeight,
          DecreasingTitleIconElement: SvgLineHeight,
          value: DimensionSortBy.Name,
        },
      ].map(({ value, IncreasingTitleIconElement, DecreasingTitleIconElement, ...rest }) => {
        const selected = sortBy.get() === value
        return {
          ...rest,
          TitleIconElement:
            selected && isSortedIncreasing
              ? IncreasingTitleIconElement
              : DecreasingTitleIconElement,
          selected,
          onSelect: () => onSelect(value),
        }
      }),
    [isSortedIncreasing, onSelect, sortBy]
  )
}
