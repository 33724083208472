import * as React from "react"
import { useInvertValues } from "@digits-shared/components/Contexts/InvertValuesContext"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { useLegendGroupByDataId } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/Legend/useLegendGroupByDataId"
import {
  stackableBarChartYData,
  StackableBarData,
  useStackableBarChartClickGenerator,
  useStackableBarChartShowTooltipGenerator,
} from "src/frontend/components/OS/Shared/Charts/StackableBarChart/Shared"
import { useStackableBarChartContext } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/StackableBarChartContext"

/*
  STYLES
*/

const LegendsColor = styled.div`
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  max-width: 100%;
  margin: -5px auto 0 auto;
  height: 60px;
`

const Legend = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 11px;
  font-weight: ${fonts.weight.light};
  color: ${colors.translucentSecondary80};
  padding: 2px 6px 2px 4px;
  border-radius: 50vh;

  ${({ isSelected }) =>
    isSelected &&
    css`
      border-color: rgba(73, 144, 158, 0.4);
      box-shadow: 0 0 20px rgba(60, 122, 157, 0.5);
    `}
`

const LegendText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

/*
  COMPONENTS
*/

export const StackableBarMiniLegend: React.FC = () => {
  const { tooltipData, loading, otherDimensionName, onBarClick, hideTooltip, chartData } =
    useStackableBarChartContext()
  const invertValues = useInvertValues()
  const legendsByDataId = useLegendGroupByDataId()

  const showTooltipGenerator = useStackableBarChartShowTooltipGenerator()
  const onClickGenerator = useStackableBarChartClickGenerator()

  if (loading) {
    return (
      <LegendsColor>
        <LoadingBlock width="80%" height="20px" margin="12px auto 0" />
      </LegendsColor>
    )
  }

  const items = Object.entries(legendsByDataId)
    .reverse()
    .map(([dataId, stack], i) => {
      const negatives = chartData.filter(
        (d) => d.dataId === dataId && stackableBarChartYData(invertValues, d) < 0
      )
      // Can use first bar data in stack since we only need to check for data id and legend
      // which should be the same for each one
      const barData = stack[0] as StackableBarData
      const fillId = barData.barColorIds.activePeriodFillId
      const onMouseMove = showTooltipGenerator(barData)
      const onClick = onClickGenerator(barData)

      return (
        <Legend
          key={`legend-${i}`}
          css={onBarClick && barData.dataId !== otherDimensionName ? "cursor: pointer;" : ""}
          isSelected={tooltipData?.dataId === dataId}
          onMouseMove={onMouseMove}
          onMouseLeave={hideTooltip}
          onClick={onClick}
        >
          <svg width={8} height={8}>
            <circle r={4} cx={4} cy={4} fill={`url(#${fillId})`} />
            {negatives.length && <path d="M0,4 a1,1 0 0,0 8,0" fill={`url(#neg-${fillId})`} />}
          </svg>
          <LegendText>{barData.legend}</LegendText>
        </Legend>
      )
    })

  return <LegendsColor>{items}</LegendsColor>
}
