import * as React from "react"
import { DayPicker, DayPickerDefaultProps, DayPickerSingleProps } from "react-day-picker"
import { useClickAway } from "react-use"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import { themedStyles } from "@digits-shared/themes"
import borders from "@digits-shared/themes/borders"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import moment from "moment"
import styled, { css } from "styled-components"
import "react-day-picker/dist/style.css"

export const INPUT_WIDTH = "272px"
export const DATE_FORMAT = "MMMM D, YYYY"

type FormatDate = (date: Date, format: string | string[], locale?: string) => string

export const formatDate: FormatDate = (date: Date, format: string | string[], locale = "en") =>
  moment(date)
    .locale(locale)
    .format(Array.isArray(format) ? format[0] : format)

type ParseDate = (str: string, format: string | string[], locale?: string) => Date | undefined

export const parseDate: ParseDate = (str: string, format: string, locale = "en") => {
  const m = moment(str, format, locale, true)
  if (m.isValid()) {
    return m.toDate()
  }
  return undefined
}

const PickerInputContainer = styled.div`
  position: relative;
`

const PickerInput = styled.input`
  padding: 4px 10px;
  border-radius: 3px;
  transition:
    background-color 100ms ease-in-out,
    color 100ms ease-in-out,
    border 250ms ease-out,
    box-shadow 250ms ease-out;
  width: ${INPUT_WIDTH};
  border: 1px solid ${colors.theme.dark.border};
  background: ${colors.transparent};
  color: ${colors.theme.dark.text};

  &:hover:not(:focus) {
    border-color: ${colors.translucentNeonGreen30};
  }
  &:focus {
    border-color: ${colors.neonGreen};
    box-shadow: ${borders.theme.dark.buttonBoxShadow};
  }

  &:disabled {
    background: ${colors.translucentWhite04};
    color: ${colors.altoGray};
    pointer-events: none;
  }
`

const StyledDayPicker = styled(DayPicker)`
  ${themedStyles({
    dark: css`
      --rdp-cell-size: 30px !important;

      padding: 5px;
      background: linear-gradient(
        to bottom,
        ${colors.theme.dark.invertedGradient.top} 0%,
        ${colors.theme.dark.invertedGradient.bottom} 100%
      );
      margin-top: 10px;
      line-height: 1rem;
      font-size: 11px;

      .rdp-nav_button {
        opacity: 0.2;
        transition: opacity 200ms;

        &:hover:not([disabled]) {
          background: none;
          opacity: 1;
        }
      }

      .rdp-day {
        border-radius: 3px;

        &:not([disabled]) {
          cursor: pointer;

          &:not(.rdp-day_selected):not(.rdp-day_outside):hover {
            background-color: ${colors.transparent};
            color: ${colors.neonGreen};
          }
        }
      }

      .rdp-day_selected:not([disabled]):not(.rdp-day_outside) {
        color: ${colors.theme.dark.invertedText};
        background-color: ${colors.neonGreen};
      }

      .rdp-day_today {
        color: unset;
        font-weight: unset;
      }
    `,
    light: css`
      --rdp-accent-color: ${colors.secondary} !important;
      --rdp-day-height: 28px;
      --rdp-day-width: 28px;
      --rdp-weekday-padding: 0;
      --rdp-caption-font-size: 16px !important;
      --rdp-font-family: ${fonts.family.avenir};
      --rdp-selected-font: bold 14px ${fonts.family.avenir};
      --rdp-selected-border: none;

      .rdp-selected .rdp-day_button,
      .rdp-day_button:hover:not([disabled]):not([selected]) {
        background-color: ${colors.primary};
        color: ${colors.white};
      }

      margin: 4px 0 0 0 !important;
      background: white;
      border-radius: 12px;
      padding: 8px 16px;
      box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
      font-size: 14px;

      .rdp-nav_icon {
        width: 12px;
        height: 12px;
      }

      button:focus,
      button:focus-visible {
        outline: none;
      }
    `,
  })};
`

export enum DayPickerLocation {
  Top,
  Bottom,
}

const PickerContainer = styled.div<{ location: DayPickerLocation }>`
  position: absolute;
  ${({ location }) => location === DayPickerLocation.Top && "top: -270px;"};

  z-index: 1;
`

interface DayPickerInputProps {
  defaultDate?: Date
  className?: string
  disabled?: boolean
  placeholder?: string
  format?: string
  formatter?: FormatDate
  parser?: ParseDate
  onDateChange?: (date: Date) => Promise<unknown>
  dayPickerProps?: DayPickerDefaultProps | DayPickerSingleProps
  location?: DayPickerLocation
}

export const DayPickerInput: React.FC<DayPickerInputProps> = ({
  defaultDate,
  className,
  disabled,
  placeholder,
  format = DATE_FORMAT,
  formatter,
  parser,
  onDateChange,
  dayPickerProps,
  location = DayPickerLocation.Bottom,
}) => {
  const pickerRef = React.useRef<HTMLInputElement>(null)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const { value: opened, setTrue: openPicker, setFalse: closePicker } = useStateBoolean()
  const [selected, setSelected] = React.useState<Date>(defaultDate ?? new Date())
  const inputValue = (formatter || formatDate)(selected, format)

  const onMonthChange = React.useCallback((date: Date) => {
    setSelected(date)
  }, [])

  const onSelect = React.useCallback(
    (date: Date) => {
      closePicker()
      const selDate = date || selected
      if (inputRef.current) {
        inputRef.current.value = (formatter || formatDate)(selDate, format)
      }
      onDateChange?.(selDate)
    },
    [closePicker, format, formatter, onDateChange, selected]
  )

  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget
      const date = (parser || parseDate)(value, format)
      if (date) {
        setSelected(date)
      }
    },
    [format, parser]
  )

  // when something other than the date picker is clicked, the picker should close
  useClickAway(pickerRef, (e) => {
    if (inputRef.current) {
      inputRef.current.value = (formatter || formatDate)(selected, format)
    }
    onDateChange?.(selected)
    closePicker()
  })

  return (
    <PickerInputContainer ref={pickerRef}>
      <PickerInput
        ref={inputRef}
        className={className}
        placeholder={placeholder || "Select"}
        defaultValue={inputValue}
        disabled={disabled}
        onClick={openPicker}
        onChange={onChange}
      />
      {opened && (
        <PickerContainer location={location}>
          <StyledDayPicker
            {...dayPickerProps}
            mode="single"
            defaultMonth={selected}
            month={selected}
            onMonthChange={onMonthChange}
            selected={selected}
            onSelect={onSelect}
          />
        </PickerContainer>
      )}
    </PickerInputContainer>
  )
}
