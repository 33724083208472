import * as React from "react"
import { DepartmentChartComponent } from "src/frontend/components/Shared/Layout/Components/Departments/DepartmentChartComponent"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { useConfigLookbackOriginOverride } from "src/frontend/components/Shared/Layout/hooks/useConfigOriginOverride"
import { MatchedComponent, SizingProps } from "src/frontend/components/Shared/Layout/types"
import { usePortalStore } from "src/frontend/components/Shared/Portals/State/portalStore"

/*
  INTERFACES
*/

interface DepartmentChartComponentProps extends SizingProps {
  component: MatchedComponent<"departmentChart">
  componentSize: ComponentSize
  skipAnimations: boolean
}

/*
  COMPONENTS
*/

export const DepartmentPortalChartComponent: React.FC<DepartmentChartComponentProps> = ({
  component,
  componentSize,
  height,
  skipAnimations,
}) => {
  const layoutId = usePortalStore((state) => state.layout.layoutId)
  const config = useConfigLookbackOriginOverride(component.config.departmentChart)

  return (
    <DepartmentChartComponent
      layoutId={layoutId}
      component={component}
      configOverride={config}
      componentSize={componentSize}
      height={height}
      skipAnimations={skipAnimations}
    />
  )
}
