import * as React from "react"
import { ViewIdentifier, ViewType } from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import { useViewType } from "src/frontend/components/Shared/Contexts/ViewTypeContext"
import { ViewVersionContext } from "src/frontend/components/Shared/Contexts/ViewVersionContextConst"
import FrontendSession from "src/frontend/session"

export interface ViewVersions {
  accounting: Map<string, Map<ViewType, ViewIdentifier>>
}

interface UseViewVersionOptions {
  legalEntityId?: string
  viewTypeOverride?: ViewType
}

/**
 * Returns the View Version for ViewType.
 */
export function useViewVersion(options?: UseViewVersionOptions): ViewIdentifier {
  const { viewTypeOverride, legalEntityId: leIdOverride } = options || {}
  const viewType = useViewType(viewTypeOverride)
  const { accounting } = React.useContext(ViewVersionContext)
  const session = useSession<FrontendSession>()
  const targetLegalEntityId = leIdOverride || session.currentLegalEntityId

  const findViewVersion = React.useCallback(() => {
    const legalEntityViews = accounting.get(targetLegalEntityId)
    return (
      legalEntityViews?.get(viewType) ?? {
        viewVersion: "",
        mutationVersion: "",
        viewType,
        legalEntityId: targetLegalEntityId ?? "",
      }
    )
  }, [accounting, targetLegalEntityId, viewType])

  const latestVersionFound = findViewVersion()
  const { viewVersion, mutationVersion, legalEntityId } = latestVersionFound

  // The view version should be updated whenever forced (the background task triggers),
  // or the current legal entity changes.
  // The dependency list must NOT include objects, only strings so memo is not busted on every render
  return React.useMemo<ViewIdentifier>(
    () => ({ viewVersion, mutationVersion, viewType, legalEntityId }),
    [legalEntityId, mutationVersion, viewType, viewVersion]
  )
}
