import * as React from "react"
import { useRouteMatch } from "react-router-dom"
import { IntervalOrigin, ReportPackage } from "@digits-graphql/frontend/graphql-bearer"
import useRouter from "@digits-shared/hooks/useRouter"
import { DEFAULT_COVER_URL } from "src/frontend/components/Shared/Covers/coverImages"
import routes from "src/frontend/routes"

/*
  CONTEXTS
*/

export type ReportPackageMode = "VIEW" | "EDIT"

export enum PublishAnimationState {
  None = "None",
  AnimationStarted = "AnimationStarted",
  AnimationEnded = "AnimationEnded",
}

export const DEFAULT_STATE: ReportPackageState = {
  showAssistant: false,
  canAppendInsightsToSummary: false,
  addedInsights: [],
  publishAnimationState: PublishAnimationState.None,
}

export const ReportPackageContext = React.createContext<ReportPackageContextProps>({
  packageState: DEFAULT_STATE,
  packageDispatch: () => undefined,
})

export default function useReportPackageContext(): ReportPackageContextProps {
  return React.useContext(ReportPackageContext)
}

export function useReportPackageMode(): ReportPackageMode {
  const { location } = useRouter()
  const edit =
    location.name === routes.reportPackageEditVersion.name ||
    location.name === routes.reportPackageVersionOptions.name ||
    location.name === routes.reportStatementOptions.name ||
    location.name === routes.reportLayoutOptions.name ||
    location.name === routes.reportAddLayoutComponent.name
  return edit ? "EDIT" : "VIEW"
}

export function useIsEditMode() {
  const mode = useReportPackageMode()
  return mode === "EDIT"
}

export function useIsOptionsShown() {
  const optionsOpen = useRouteMatch(routes.reportPackageVersionOptions.parameterizedPath)
  return !!optionsOpen
}

export function useIsPreview(): boolean {
  const { location } = useRouter()
  return !!location.queryParams.preview
}

export function useReportPackageHasCoverImage(): boolean {
  const { reportPackage } = useReportPackageContext()

  if (!reportPackage) return false
  const coverUrl = reportPackage.packageOptions?.coverUrl

  return !!coverUrl && coverUrl !== DEFAULT_COVER_URL
}

/*
  INTERFACES
*/

export interface ReportPackageContextProps {
  reportPackage?: ReportPackage
  packageState: ReportPackageState
  packageDispatch: React.Dispatch<ReportPackageAction>
}

type AddedInsight = {
  subjectId: string
  origin: IntervalOrigin
}

export interface ReportPackageState {
  showAssistant: boolean
  canAppendInsightsToSummary: boolean
  addedInsights: AddedInsight[]
  publishAnimationState: PublishAnimationState
  publishAnimationOnEnd?: () => void
}

export type ReportPackageAction =
  | {
      type: "SHOW_ASSISTANT" | "CAN_APPEND_INSIGHTS"
      value: boolean
    }
  | {
      type: "NEW_SUMMARY_INSIGHT"
      subjectId: string
      origin: IntervalOrigin
    }
  | {
      type: "PUBLISH_ANIMATION_STARTED"
      onEnd?: () => void
    }
  | {
      type: "PUBLISH_ANIMATION_ENDED"
    }

/*
  REDUCER
*/

export function processActionReducer(curState: ReportPackageState, action: ReportPackageAction) {
  const newState: ReportPackageState = { ...curState }
  switch (action.type) {
    case "SHOW_ASSISTANT":
      newState.showAssistant = action.value
      return newState
    case "CAN_APPEND_INSIGHTS":
      newState.canAppendInsightsToSummary = action.value
      return newState
    case "NEW_SUMMARY_INSIGHT":
      newState.addedInsights = [
        ...newState.addedInsights,
        { subjectId: action.subjectId, origin: action.origin },
      ]
      return newState
    case "PUBLISH_ANIMATION_STARTED":
      newState.publishAnimationState = PublishAnimationState.AnimationStarted
      newState.publishAnimationOnEnd = action.onEnd
      return newState
    case "PUBLISH_ANIMATION_ENDED":
      newState.publishAnimationState = PublishAnimationState.AnimationEnded
      return newState
  }
}
