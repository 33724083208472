import * as React from "react"
import { EntityLegalEntity, ObjectKind, ProductArea } from "@digits-graphql/frontend/graphql-bearer"
import { SharedCategories } from "src/frontend/components/OS/Applications/SharedWithMe/Entities/Categories"
import { SharedDepartments } from "src/frontend/components/OS/Applications/SharedWithMe/Entities/Departments"
import { LoadReportPackage } from "src/frontend/components/OS/Applications/SharedWithMe/Entities/EntityRows/SharedReportPackageRow"
import { SharedLocations } from "src/frontend/components/OS/Applications/SharedWithMe/Entities/Locations"
import { SharedPartiesByRole } from "src/frontend/components/OS/Applications/SharedWithMe/Entities/Parties"
import { SharedReportPackages } from "src/frontend/components/OS/Applications/SharedWithMe/Entities/ReportPackages"
import { LegalEntitiesContainer } from "src/frontend/components/OS/Applications/SharedWithMe/Entities/Shared"
import { SharedTransactions } from "src/frontend/components/OS/Applications/SharedWithMe/Entities/Transactions"
import { SharedEntities } from "src/frontend/components/OS/Applications/SharedWithMe/Shared"
import { ProductAreaContext } from "src/frontend/components/Shared/Contexts/ProductAreaContext"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"

/*
  COMPONENTS
*/

export const SharedEntityItems: React.FC<{
  selectedObjectKind: ObjectKind
  selectedPartyRole: FrontendPartyRole
  selectedLegalEntity: EntityLegalEntity
  entities: SharedEntities
}> = ({ selectedObjectKind, selectedPartyRole, selectedLegalEntity, entities }) => {
  switch (selectedObjectKind) {
    case ObjectKind.ReportPackage:
      return (
        <SharedReportPackages
          reportEntities={entities.reportPackages || []}
          selectedLegalEntity={selectedLegalEntity}
        />
      )

    case ObjectKind.Department:
      return (
        <SharedDepartments
          departmentEntities={entities.departments || []}
          selectedLegalEntity={selectedLegalEntity}
        />
      )

    case ObjectKind.Location:
      return (
        <SharedLocations
          locationEntities={entities.locations || []}
          selectedLegalEntity={selectedLegalEntity}
        />
      )

    case ObjectKind.Category:
      return (
        <ProductAreaContext.Provider value={ProductArea.All}>
          <SharedCategories
            categoryEntities={entities.categories || []}
            selectedLegalEntity={selectedLegalEntity}
          />
        </ProductAreaContext.Provider>
      )

    case ObjectKind.Transaction:
      return (
        <SharedTransactions
          transactionEntities={entities.transactions || []}
          selectedLegalEntity={selectedLegalEntity}
        />
      )

    case ObjectKind.Party:
      return (
        <SharedPartiesByRole
          partyEntities={entities.parties}
          selectedPartyRole={selectedPartyRole}
          selectedLegalEntity={selectedLegalEntity}
        />
      )

    default:
      return null
  }
}

export const SharedEntityItemsLoading: React.FC = () => (
  <LegalEntitiesContainer>
    {Array.from({ length: 3 }).map((_, i) => (
      <LoadReportPackage key={i} />
    ))}
  </LegalEntitiesContainer>
)
