import * as React from "react"
import { BarChartConfig } from "src/frontend/components/Shared/Layout/Components/Charts/Configs/BarChartConfig"
import { toTimeseries } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import { DepartmentSummaries } from "src/frontend/components/Shared/Layout/Components/Departments/filterDepartmentAncestors"
import { useCombinedDepartmentsSeries } from "src/frontend/components/Shared/Layout/Components/Departments/useCombinedDepartmentsSeries"
import { useDepartmentSummaryByTimeLiveData } from "src/frontend/components/Shared/Layout/Components/Departments/useDepartmentSummaryByTimeLiveData"
import { DepartmentComponentIcon } from "src/frontend/components/Shared/Layout/Components/Headers/ComponentEntityIcons"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { MatchedConfig, SizingProps } from "src/frontend/components/Shared/Layout/types"

/*
  INTERFACES
*/

interface DepartmentChartConfigProps extends SizingProps {
  config: MatchedConfig<"departmentChart">
  skipAnimations: boolean
  layoutId: string
  onSummaries?: (summaries: DepartmentSummaries[]) => void
}

/*
  COMPONENTS
*/
export const DepartmentChartConfig: React.FC<DepartmentChartConfigProps> = ({
  config,
  height,
  skipAnimations,
  onSummaries,
}) => {
  const { department, dimensionalSummary, loading } = useDepartmentSummaryByTimeLiveData(
    config.departmentChart,
    config.type
  )

  const multiTimeseries = React.useMemo(
    () => dimensionalSummary?.map((ds) => toTimeseries(ds.department.name, ds.summaries)) ?? [],
    [dimensionalSummary]
  )
  const combinedSeries = useCombinedDepartmentsSeries(multiTimeseries) ?? []

  // remove this component if the results are empty
  React.useEffect(() => {
    if (!loading) {
      onSummaries?.(dimensionalSummary ?? [])
    }
  }, [dimensionalSummary, loading, onSummaries])

  return (
    <BarChartConfig
      name={department?.name}
      timeseries={combinedSeries}
      skipAnimations={skipAnimations}
      height={height}
      icon={
        <DepartmentComponentIcon
          department={undefined}
          intervalOrigin={config.departmentChart.origin}
          componentSize={ComponentSize.Small}
        />
      }
    />
  )
}
