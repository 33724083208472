import * as React from "react"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgFilterFunnel01 } from "@digits-shared/components/SVGIcons/line/FilterFunnel01.svg"
import { chevronStyles, PointingDirection } from "@digits-shared/components/UI/Elements/Chevron"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@digits-shared/DesignSystem/Popovers/Popover"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import styled, { css } from "styled-components"
import { OS_BUTTON_HOVER_STYLES } from "src/frontend/components/OS/Details/Shared/Styles"
import FrontendSession from "src/frontend/session"
import { DimensionSort } from "src/frontend/types/DimensionFilter"

/*
  STYLES
*/

const DisplayOptionsSectionStyled = styled.div`
  position: relative;
  font-size: 12px;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding: 2px 8px 2px 4px;
  white-space: nowrap;

  color: ${colors.secondary};

  svg {
    max-height: 22px;
    max-width: 22px;
    margin-right: 4px;
    pointer-events: none;
    ${svgPathStyles(colors.secondary)};
  }

  ${OS_BUTTON_HOVER_STYLES};
`

const TitleChevron = styled.div`
  ${chevronStyles(PointingDirection.Down, "3px", colors.translucentSecondary80)};
  margin-left: 9px;
  pointer-events: none;
`

const PopupContainer = styled.div<{ minWidth: number }>`
  display: flex;

  ${(props) => css`
    min-width: ${props.minWidth}px;
  `};
`

const FilterIcon = styled(SvgFilterFunnel01)`
  ${svgPathStyles(colors.secondary)};
  height: 16px;
  width: 16px;
`

/*
  COMPONENTS
*/

interface DisplayOptionsProps {
  dimensions: DimensionSort[]
  className?: string
  minWidth?: number
  children?: React.ReactNode
}

export const DisplayOptionsSection: React.FC<DisplayOptionsProps> = ({
  dimensions,
  className,
  children,
  minWidth,
}) => {
  const { isSharingContextActive } = useSession<FrontendSession>()
  const selectedDimension = React.useMemo(() => dimensions.find((d) => !!d.selected), [dimensions])
  const title = React.useMemo(
    () => (selectedDimension ? `By ${selectedDimension.title}` : "Filter"),
    [selectedDimension]
  )
  const TitleIconElement = React.useMemo(
    () => (selectedDimension ? selectedDimension.TitleIconElement : FilterIcon),
    [selectedDimension]
  )

  return (
    <Popover>
      <PopoverTrigger asChild>
        <DisplayOptionsSectionStyled className={className}>
          <Title>
            <TitleIconElement />
            {title}
            <TitleChevron />
          </Title>
        </DisplayOptionsSectionStyled>
      </PopoverTrigger>

      <PopoverContent align="end" showCloseButton={false}>
        <PopupContainer
          minWidth={minWidth !== undefined ? minWidth : isSharingContextActive ? 112 : 360}
        >
          {children}
        </PopupContainer>
      </PopoverContent>
    </Popover>
  )
}
