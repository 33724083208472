import {
  CategoryType,
  DimensionSortMode,
  Interval,
  IntervalOrigin,
  LayoutComponentType,
  MetricSummaryItem,
  ObjectIdentifier,
  ObjectKind,
  PartyRole,
  ReportKind,
  SummaryLineItem,
  TextComponentConfigTag,
} from "@digits-graphql/frontend/graphql-bearer"
import moment from "moment"
import { v4 as UUID } from "uuid"
import { MatchedConfig, SortableComponentConfig } from "src/frontend/components/Shared/Layout/types"

export function createReportStatementConfig(
  kind: ReportKind,
  origin: IntervalOrigin
): SortableComponentConfig & MatchedConfig<"statement"> {
  return {
    id: UUID(),
    type: LayoutComponentType.Statement,
    statement: {
      kind,
      origin: origin,
    },
  }
}

export function createSummaryLineItemChartConfig(
  origin: IntervalOrigin,
  item: SummaryLineItem
): SortableComponentConfig {
  return {
    id: UUID(),
    type: LayoutComponentType.SummaryLineItemChart,
    summaryLineItem: {
      origin: lookbackIntervalOriginFor(origin),
      item,
    },
  }
}

export function createMetricChartConfig(
  intervalOrigin: IntervalOrigin,
  item: MetricSummaryItem
): SortableComponentConfig {
  return {
    id: UUID(),
    type: LayoutComponentType.MetricSummaryChart,
    metricChart: {
      origin: lookbackIntervalOriginFor(intervalOrigin),
      item,
    },
  }
}

export function createCashSummaryChartConfig(
  intervalOrigin: IntervalOrigin
): SortableComponentConfig {
  return {
    id: UUID(),
    type: LayoutComponentType.CashSummaryChart,
    cashSummaryChart: {
      origin: lookbackIntervalOriginFor(intervalOrigin),
    },
  }
}

export function createRunwayConfig(intervalOrigin: IntervalOrigin): SortableComponentConfig {
  return {
    id: UUID(),
    type: LayoutComponentType.RunwayChart,
    runwayChart: {
      origin: lookbackIntervalOriginFor(intervalOrigin),
    },
  }
}

export function createCategoryTransactionSummaryChartConfig(
  origin: IntervalOrigin,
  categoryId: string
): SortableComponentConfig {
  return {
    id: UUID(),
    type: LayoutComponentType.CategoryTransactionSummaryChart,
    categoryChart: {
      origin: lookbackIntervalOriginFor(origin),
      categoryId,
    },
  }
}

export function createDepartmentChartConfig(
  origin: IntervalOrigin,
  departmentId: string
): SortableComponentConfig {
  return {
    id: UUID(),
    type: LayoutComponentType.DepartmentChart,
    departmentChart: {
      origin: lookbackIntervalOriginFor(origin),
      departmentId,
    },
  }
}

export function createLocationChartConfig(
  origin: IntervalOrigin,
  locationId: string
): SortableComponentConfig {
  return {
    id: UUID(),
    type: LayoutComponentType.LocationChart,
    locationChart: {
      origin: lookbackIntervalOriginFor(origin),
      locationId,
    },
  }
}

export function createTextConfig(
  origin?: IntervalOrigin,
  body: string = "",
  tags: TextComponentConfigTag[] = []
): SortableComponentConfig {
  return {
    id: UUID(),
    type: LayoutComponentType.Text,
    text: {
      body,
      tags,
      current: origin,
      lookback: origin ? lookbackIntervalOriginFor(origin) : undefined,
    },
  }
}

export function createInsightConfig(
  origin: IntervalOrigin,
  objectId: ObjectIdentifier
): SortableComponentConfig & MatchedConfig<"insight"> {
  return {
    id: UUID(),
    type: LayoutComponentType.Insight,
    insight: {
      origin,
      lookback: lookbackIntervalOriginFor(origin),
      objectId,
    },
  }
}

export function createKPIConfig(): SortableComponentConfig {
  return {
    id: UUID(),
    type: LayoutComponentType.StaticMetric,
    staticMetric: {
      name: "",
      value: "",
      updatedAt: moment().unix(),
    },
  }
}

export function createDocumentCollectionConfig(): SortableComponentConfig {
  return {
    id: UUID(),
    type: LayoutComponentType.DocumentCollection,
    documentCollection: {
      collectionId: UUID(),
    },
  }
}

export function createPartyTransactionSummaryChartConfig(
  origin: IntervalOrigin,
  partyId: string,
  partyRole: PartyRole
): SortableComponentConfig {
  return {
    id: UUID(),
    type: LayoutComponentType.PartyTransactionSummaryChart,
    partyChart: {
      origin: lookbackIntervalOriginFor(origin),
      partyId,
      partyRole,
    },
  }
}

export function createCategoryBalanceSummaryChartConfig(
  origin: IntervalOrigin,
  categoryId: string
): SortableComponentConfig {
  return {
    id: UUID(),
    type: LayoutComponentType.CategoryBalanceSummaryChart,
    categoryChart: {
      origin: lookbackIntervalOriginFor(origin),
      categoryId,
      asPermanent: true,
    },
  }
}

export function createPartyRoleBalanceSummaryChartConfig(
  origin: IntervalOrigin,
  partyId: string,
  partyRole: PartyRole
): SortableComponentConfig {
  return {
    id: UUID(),
    type: LayoutComponentType.PartyRoleBalanceSummaryChart,
    partyChart: {
      origin: lookbackIntervalOriginFor(origin),
      partyId,
      partyRole,
      asPermanent: true,
    },
  }
}

export function createTopPartiesInRoleConfig(
  origin: IntervalOrigin,
  partyRole: PartyRole
): SortableComponentConfig {
  return {
    id: UUID(),
    type: LayoutComponentType.TopEntities,
    topEntities: {
      limit: 5,
      kind: ObjectKind.Party,
      origin: origin,
      partyRole,
      sort: DimensionSortMode.Total,
    },
  }
}

export function createTopCategoriesConfig(
  origin: IntervalOrigin,
  categoryType: CategoryType
): SortableComponentConfig {
  return {
    id: UUID(),
    type: LayoutComponentType.TopEntities,
    topEntities: {
      limit: 5,
      kind: ObjectKind.Category,
      categoryType,
      origin: origin,
      sort: DimensionSortMode.Total,
    },
  }
}

export function createTopTransactionsConfig(options: {
  origin: IntervalOrigin
  categoryType?: CategoryType
  partyRole?: PartyRole
}): SortableComponentConfig {
  return {
    id: UUID(),
    type: LayoutComponentType.TopTransactions,
    topTransactions: {
      limit: 5,
      ...options,
      // TODO: other kind of sort info
    },
  }
}

export function createTopChangingCategoriesConfig(
  origin: IntervalOrigin,
  categoryType: CategoryType,
  sortMode: DimensionSortMode
): SortableComponentConfig {
  return {
    id: UUID(),
    type: LayoutComponentType.TopEntities,
    topEntities: {
      limit: 5,
      kind: ObjectKind.Category,
      categoryType,
      origin: origin,
      sort: sortMode,
    },
  }
}

export function lookbackIntervalOriginFor(intervalOrigin: IntervalOrigin): IntervalOrigin {
  if (intervalOrigin.intervalCount && intervalOrigin.intervalCount > 1) return intervalOrigin

  // This is exhaustive, so that any enum changes cause compile errors,
  // even though most of these intervals are not realistic for a report
  switch (intervalOrigin.interval) {
    case Interval.Year:
      return {
        ...intervalOrigin,
        intervalCount: 6,
      }
    case Interval.Month:
      return {
        ...intervalOrigin,
        intervalCount: 12,
      }
    case Interval.Quarter:
      return {
        ...intervalOrigin,
        intervalCount: 4,
      }
    case Interval.Biweek:
      return {
        ...intervalOrigin,
        intervalCount: 8,
      }
    case Interval.Day:
      return {
        ...intervalOrigin,
        intervalCount: 28,
      }
    case Interval.Hour:
      return {
        ...intervalOrigin,
        intervalCount: 12,
      }
    case Interval.Minute:
      return {
        ...intervalOrigin,
        intervalCount: 15,
      }
    case Interval.Week:
      return {
        ...intervalOrigin,
        intervalCount: 12,
      }
    case Interval.IntervalNone:
      return intervalOrigin
  }
}
