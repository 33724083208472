import * as React from "react"
import { IntervalOrigin, ReportOptionComparison } from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import { themedStyles, themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import {
  ColumnOptionKey,
  headerFormattedDate,
  headerTitle,
  isBreakdownColumn,
  isDeltaColumn,
  isDoubleColumn,
  StatementColumns,
} from "src/frontend/components/Shared/Reports/Report/Components/Statements/columnTypes"
import {
  RowStyled,
  StatementSize,
} from "src/frontend/components/Shared/Reports/Report/Components/Statements/shared"
import { useReportDocumentOptions } from "src/frontend/components/Shared/Reports/Report/hooks/useReportDocumentOptions"
import { useReportComponentIntervalOrigin } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/hooks/useReportComponentIntervalOrigin"

/*
  STYLES
*/

const Headers = styled(RowStyled)`
  padding: 0;
  margin-bottom: 2px;
  opacity: 1;
  z-index: 1;
  position: sticky;
  top: 0;
`

const headerStyles = themedStyles({
  print: css`
    color: ${colors.black};
  `,
  light: css`
    background: ${colors.white90};
    color: ${colors.translucentSecondary80};
  `,
  dark: css`
    color: ${colors.translucentWhite50};
  `,
})

export const HeaderStyled = styled.div<{ size?: StatementSize }>`
  ${headerStyles};
  white-space: nowrap;
  font-size: 10px;
  line-height: 11px;
  text-align: right;

  ${({ size }) =>
    size !== StatementSize.Condensed &&
    css`
      padding: 8px 8px;
      & > span {
        margin-right: -2px;
      }
    `};
`

const mainHeaderColor = themedValue({
  print: colors.black,
  light: colors.translucentSecondary80,
  dark: colors.translucentWhite50,
})

const MainHeader = styled(HeaderStyled)`
  color: ${mainHeaderColor};
  font-weight: ${fonts.weight.medium};
`

/*
  INTERFACES
*/

/*
  COMPONENTS
*/

export const StatementHeader: React.FC<{ size?: StatementSize }> = ({ size }) => {
  const origin = useReportComponentIntervalOrigin()
  const { columnKeys, columns } = useReportDocumentOptions()

  const periodName = React.useMemo(() => headerFormattedDate(origin, size), [origin, size])

  return (
    <Headers depth={0}>
      <HeaderStyled />
      <HeaderStyled />
      {isBreakdownColumn("deltaMonthOverMonth", columns) && (
        <DeltaHeadersOverTime
          optionKey="deltaMonthOverMonth"
          comparison={ReportOptionComparison.Total}
          origin={origin}
        />
      )}
      <MainHeader size={size}>{periodName}</MainHeader>
      {columnKeys?.map(
        (optionKey: keyof StatementColumns) =>
          isDeltaColumn(optionKey, columns) && (
            <DeltaHeadersOverTime
              key={`${optionKey}`}
              optionKey={optionKey}
              origin={origin}
              comparison={columns[optionKey] as ReportOptionComparison}
            />
          )
      )}
    </Headers>
  )
}

const DeltaHeadersOverTime: React.FC<{
  optionKey: ColumnOptionKey
  comparison: ReportOptionComparison | undefined
  origin: IntervalOrigin
}> = ({ optionKey, comparison, origin }) => {
  const reportOptions = useReportDocumentOptions()

  const count =
    optionKey === "deltaMonthOverMonth" && comparison === ReportOptionComparison.Total
      ? Math.max(1, reportOptions.deltaMonthOverMonthPeriods)
      : 1

  const { offset, reversed } =
    comparison === ReportOptionComparison.Total
      ? { offset: count - 1, reversed: -1 }
      : { offset: 0, reversed: 1 }

  const dualColumn = isDoubleColumn(optionKey, comparison)

  switch (optionKey) {
    case "deltaYearToDate": {
      const lookback = 1
      return (
        <>
          <Header
            optionKey={optionKey}
            comparison={ReportOptionComparison.Total}
            origin={dateTimeHelper.addIntervalToOrigin(origin, (offset - lookback) * reversed)}
          />
          {dualColumn && (
            <Header
              optionKey={optionKey}
              comparison={comparison}
              origin={dateTimeHelper.addIntervalToOrigin(origin, (offset - lookback) * reversed)}
            />
          )}
        </>
      )
    }

    default: {
      return (
        <>
          {Array.from({ length: count }).map((_, idx) => (
            <React.Fragment key={`${optionKey}${idx}`}>
              {dualColumn && (
                <Header
                  optionKey={optionKey}
                  comparison={ReportOptionComparison.Total}
                  origin={dateTimeHelper.addIntervalToOrigin(origin, (offset - idx) * reversed)}
                />
              )}
              <Header
                optionKey={optionKey}
                comparison={comparison}
                origin={dateTimeHelper.addIntervalToOrigin(origin, (offset - idx) * reversed)}
              />
            </React.Fragment>
          ))}
        </>
      )
    }
  }
}

const Header: React.FC<{
  optionKey: ColumnOptionKey
  comparison: ReportOptionComparison | undefined
  origin: IntervalOrigin
}> = ({ optionKey, comparison, origin }) => (
  <HeaderStyled>
    <span>{headerTitle(optionKey, comparison, origin)}</span>
  </HeaderStyled>
)

// FUNCTIONS
