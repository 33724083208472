import * as React from "react"
import { Transaction, TransactionRecordType } from "@digits-graphql/frontend/graphql-bearer"
import { renderDefaultLoadingCell } from "@digits-shared/components/UI/Table"
import {
  RowContent,
  RowContentDescription,
  RowContentTitle,
} from "@digits-shared/components/UI/Table/Content"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import useRouter from "@digits-shared/hooks/useRouter"
import styled from "styled-components"
import { TransactionCategory } from "src/shared/components/Transactions/TransactionRow/TransactionCategory"
import { TransactionDepartment } from "src/shared/components/Transactions/TransactionRow/TransactionDepartment"
import { TransactionLocation } from "src/shared/components/Transactions/TransactionRow/TransactionLocation"
import {
  getTransactionRowInstitution,
  getTransactionRowParty,
  isTransactionGroup,
  TransactionBodyProps,
  TransactionProps,
} from "src/shared/components/Transactions/TransactionRow/types"
import { TransactionTableRowSubtitle } from "src/shared/components/Transactions/transactionsConstants"
import transactionHelper from "src/shared/helpers/transactionHelper"

/*
  STYLES
*/

const TransactionStyled = styled.div`
  min-height: 37px;
  display: flex;
  flex-direction: column;
  overflow: visible;
`

const TransactionDescriptionStyled = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`

const TransactionDescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`

/*
  COMPONENTS
*/

export const TransactionDescription: React.FC<TransactionBodyProps> = ({
  data,
  rowSubtitle,
  loading,
}) => {
  if (loading || !data) {
    return <LoadingDescription />
  }

  // Use the transaction with the highest amount
  const transaction = isTransactionGroup(data)
    ? data.transactions.toSorted((t1, t2) => {
        const t1Value = moneyFlowHelper.toMonetaryValue(t1.moneyFlow)
        const t2Value = moneyFlowHelper.toMonetaryValue(t2.moneyFlow)
        if (t1Value.amount > t2Value.amount) return 1
        if (t1Value.amount < t2Value.amount) return -1
        return 0
      })[0]
    : data

  return (
    transaction && (
      <SingleTransactionDescription transaction={transaction} rowSubtitle={rowSubtitle} />
    )
  )
}

export const SingleTransactionDescription: React.FC<
  TransactionProps & { rowSubtitle?: TransactionTableRowSubtitle }
> = ({ transaction, rowSubtitle }) => {
  const [showToolTip, setShowToolTip] = React.useState(false)
  const toggleToolTip = React.useCallback(() => {
    setShowToolTip(!showToolTip)
  }, [showToolTip])

  return (
    <TransactionDescriptionContainer onMouseEnter={toggleToolTip} onMouseLeave={toggleToolTip}>
      <RowContent>
        <RowContentTitle>{buildTransactionDescription(transaction)}</RowContentTitle>
        <TransactionDescriptionLink transaction={transaction} rowSubtitle={rowSubtitle} />
      </RowContent>
    </TransactionDescriptionContainer>
  )
}

const TransactionDescriptionLink: React.FC<
  TransactionProps & { rowSubtitle?: TransactionTableRowSubtitle }
> = ({ transaction, rowSubtitle }) => {
  const { department, location } = transaction

  const { params } = useRouter()
  switch (true) {
    // only show department name and link if the transaction's department is not the same in the current view (link to same place)
    case rowSubtitle === TransactionTableRowSubtitle.Department &&
      params.departmentId !== department?.id:
      return <TransactionDepartment transaction={transaction} />

    case rowSubtitle === TransactionTableRowSubtitle.Location && params.locationId !== location?.id:
      return <TransactionLocation transaction={transaction} />

    default:
      return <TransactionCategory transaction={transaction} />
  }
}

const LoadingDescription: React.FC = () => (
  <TransactionDescriptionStyled>
    <TransactionStyled>
      <RowContentTitle>
        {renderDefaultLoadingCell({ width: 150, height: 12 }, "category")}
      </RowContentTitle>
      <RowContentDescription>
        {renderDefaultLoadingCell({ width: 200, height: 12 }, "desc")}
      </RowContentDescription>
    </TransactionStyled>
  </TransactionDescriptionStyled>
)

export const buildTransactionDescription = (transaction: Transaction) => {
  const recordTypeIsTransaction = transactionHelper.fullDataRecordType(transaction.recordType)
  const recordTypeIsJournal = transaction.recordType === TransactionRecordType.Journal

  const party = getTransactionRowParty(transaction)

  let description: string | undefined | null
  if (recordTypeIsTransaction) {
    const transactionDescription = transaction.name
      ? transaction.name
      : transaction.description
        ? transaction.description
        : undefined

    description = party?.name || transactionDescription
  }

  if (recordTypeIsJournal) {
    const journalDescription =
      transaction.name && transaction.description
        ? `${transaction.description} (${transaction.name})`
        : transaction.description
          ? transaction.description
          : transaction.name
            ? transaction.name
            : undefined

    description = party?.name || journalDescription
  }

  const institution = getTransactionRowInstitution(transaction)
  if (!description) {
    description = institution?.name
  }

  // fall back to showing the non-display category's name if no description can be found in the transaction
  if (!description) {
    const nonDisplayCategory = transaction.splitCategory

    description = nonDisplayCategory && nonDisplayCategory.name
  }

  // if description is still empty and this is a journal entry, display "Journal entry"
  if (recordTypeIsJournal && !description) {
    description = "Journal Entry"
  }

  return description
}
