import * as React from "react"
import {
  CategoryChartConfig,
  LayoutComponentType,
  useReadCategorySummaryByTimeLiveDataQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"

/**
 * Queries for layout live data fitting the dimensional reducer shape: by component by time.
 */
export function useCategorySummaryByTimeLiveData(
  config: CategoryChartConfig | undefined,
  type: LayoutComponentType
) {
  const viewId = useViewVersion()

  const { data, loading } = useReadCategorySummaryByTimeLiveDataQuery({
    variables: {
      viewId,
      config: {
        type,
        categoryChart: config,
      },
    },
    skip: !viewId?.viewVersion,
  })

  return React.useMemo(() => {
    const liveData = data?.liveComponentData

    // Resolves the component data union types to the portions we care about
    if (liveData?.__typename === "Chart" && liveData?.entity.__typename === "EntityCategory") {
      return { category: liveData.entity, dimensionalSummary: liveData.summary, loading }
    }

    return { category: undefined, dimensionalSummary: undefined, loading }
  }, [data?.liveComponentData, loading])
}
