import * as React from "react"
import { OnboardCheckmark } from "@digits-shared/components/UI/Elements/Checkbox/OnboardCheckbox"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import routes from "src/frontend/routes"

const AgreeTerms = styled.label`
  margin-top: 12px;
  display: flex;
  align-items: center;
  font-size: 12px;

  a {
    text-decoration: underline;
    margin-right: 4px;
  }

  &:has(input[type="checkbox"]:focus-visible) {
    outline: -webkit-focus-ring-color auto 1px;
  }
`

const StyledCheckmark = styled(OnboardCheckmark)`
  border: 1px solid ${colors.primary};
  margin-right: 8px;
`

interface AcceptTermsProps {
  className?: string
  onTermsAcceptChanged: (accepted: boolean) => void
  disabled?: boolean
  checked?: boolean
}

export const AcceptTerms: React.FC<AcceptTermsProps> = ({
  className,
  onTermsAcceptChanged,
  disabled,
  checked,
}) => (
  <AgreeTerms htmlFor="agreed-terms" className={className}>
    <StyledCheckmark
      id="agreed-terms"
      onCheckboxChange={onTermsAcceptChanged}
      value="agreedToTerms"
      color={colors.primary}
      disabled={disabled}
      checked={checked}
    />
    <span>I agree to the Digits&nbsp;</span>
    <a target="_blank" rel="noreferrer" href={routes.termsOfService.parameterizedPath}>
      Terms of Service
    </a>
  </AgreeTerms>
)
