import * as React from "react"
import { EntityLegalEntity, EntityLocation } from "@digits-graphql/frontend/graphql-bearer"
import { SharedLocationRow } from "src/frontend/components/OS/Applications/SharedWithMe/Entities/EntityRows/SharedLocationRow"
import {
  LegalEntitiesContainer,
  LegalEntityRows,
  useFilterByLegalEntity,
} from "src/frontend/components/OS/Applications/SharedWithMe/Entities/Shared"
import { LegalEntitySharedEntities } from "src/frontend/components/OS/Applications/SharedWithMe/Shared"

/*
  COMPONENTS
*/
export const SharedLocations: React.FC<{
  locationEntities: LegalEntitySharedEntities<EntityLocation>[]
  selectedLegalEntity?: EntityLegalEntity
}> = ({ locationEntities, selectedLegalEntity }) => {
  const filtered = useFilterByLegalEntity(locationEntities, selectedLegalEntity)
  return (
    <LegalEntitiesContainer>
      {filtered.map(({ legalEntity, entities }) => (
        <LegalEntityRows key={legalEntity.id} legalEntity={legalEntity}>
          {entities.map((location, idx) => (
            <SharedLocationRow
              key={idx}
              location={location.entity}
              legalEntity={legalEntity}
              viewType={location.viewType}
            />
          ))}
        </LegalEntityRows>
      ))}
    </LegalEntitiesContainer>
  )
}
