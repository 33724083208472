import * as React from "react"
import { usePrevious } from "react-use"
import envHelper from "@digits-shared/helpers/envHelper"
import objectHelper from "@digits-shared/helpers/objectHelper"
import useRouter from "@digits-shared/hooks/useRouter"
import { EffectReducerExec, useEffectReducer } from "use-effect-reducer"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"

// Handles transforming a display option from a url and back again
export interface DisplayOption<T> {
  urlKey: string
  fromURLValue: (urlValue: T | undefined) => T | undefined
  toURLValue: (value: T) => string | undefined
  defaultValue: T
}

// Get a display option value from the URL
type GetOptionFn = <T>(option: DisplayOption<T>) => T
// Set a display option value to the URL
type SetOptionFn = <T>(setOption: DisplayOption<T>, value: T) => T
// Clear display options for specified keys. If "All" is passed, all keys will be cleared
export type ClearOptionFn = (keys: ClearKeys) => void
type ClearKeys = string[] | "All"

// Wrapper type used as a convenience for wrapping a specific display option accessor, making it
// easier to get, set, and clear a display option.
export type DisplayOptionAccessor<T> = {
  get: () => T
  set: (value: T) => T
  clear: () => void
  option: () => DisplayOption<T>
}

// Builds a wrapper that only returns the default values. Used as default values for contexts.
export function buildDisplayOptionDefaultAccessor<T>(
  displayOption: DisplayOption<T>
): DisplayOptionAccessor<T> {
  return {
    get: () => displayOption.defaultValue,
    set: () => displayOption.defaultValue,
    clear: () => {},
    option: () => displayOption,
  }
}

// Builds a wrapper used as a convenience making it easier to get, set, and clear a display option.
export function buildDisplayOptionAccessor<T>(
  displayOption: DisplayOption<T>,
  getOption: GetOptionFn,
  setOption: SetOptionFn,
  clearOptions: ClearOptionFn
): DisplayOptionAccessor<T> {
  return {
    get: () => getOption(displayOption),
    set: (value) => setOption(displayOption, value),
    clear: () => clearOptions([displayOption.urlKey]),
    option: () => displayOption,
  }
}

type AvailableDisplayOptionAccessor = {
  [key: string]: DisplayOptionAccessor<unknown>
}

export type DisplayOptionGroupContextProps<T> = T &
  Pick<DisplayOptionGroup, "clearOptions" | "currentURLKeys">

interface DisplayOptionGroup {
  getOption: GetOptionFn
  setOption: SetOptionFn
  clearOptions: ClearOptionFn
  currentURLKeys: string[]
}

export function useBuildDisplayOptionGroup(
  availableAccessors: AvailableDisplayOptionAccessor,
  isActive: boolean
) {
  const { history, location } = useRouter()
  const { queryParams } = location

  const supportedURLKeys = React.useMemo(
    () =>
      Object.values(availableAccessors).reduce(
        (agg, option) => agg.add(option.option().urlKey),
        new Set<string>()
      ),
    [availableAccessors]
  )

  const generatePath = useFrontendPathGenerator()

  const pushToRouteWithLatestParams = React.useCallback(
    (state: DisplayOptionURLParamsState) => {
      const includedKeys = new Set(Object.keys(state.inMemoryQueryParams))
      const excludedKeys = Array.from(supportedURLKeys).filter((k) => !includedKeys.has(k))

      history.replace(
        generatePath(
          routes[location.name],
          state.inMemoryQueryParams,
          // Keep non-DisplayOption query params. Existing option params in`includedKeys` will be overridden.
          "includeAllQueryParams",
          // These option params will be deleted from the URL if present.
          excludedKeys
        )
      )
    },
    [generatePath, history, location.name, supportedURLKeys]
  )

  // NOTE: Until this PR is merged (https://github.com/davidkpiano/useEffectReducer/pull/19)
  // this useEffectReducer will dispatch events twice. This is due to the underlying implementation
  // not memoizing the provided reducer function, causing the reducer firing twice. Fortunately,
  // the effects still only fires once. A little more detail on why changing the reducer func causes
  // this: https://stackoverflow.com/questions/54892403/usereducer-action-dispatched-twice
  const [state, dispatch] = useEffectReducer(
    displayOptionURLParamsReducer,
    {
      inMemoryQueryParams: queryParams,
    },
    {
      pushToRouteWithLatestParams,
    }
  )

  const { inMemoryQueryParams } = state

  const getOption = React.useCallback<GetOptionFn>(
    (option) => {
      checkForSupportedURLKey(option, supportedURLKeys)

      const urlValue = inMemoryQueryParams[option.urlKey] as typeof option.defaultValue
      return option.fromURLValue(urlValue) || option.defaultValue
    },
    [inMemoryQueryParams, supportedURLKeys]
  )

  const setOption = React.useCallback<SetOptionFn>(
    (option, value) => {
      checkForSupportedURLKey(option, supportedURLKeys)

      // If the display options is not active, we should not attempt to set an option
      if (!isActive && !envHelper.isProduction()) {
        console.error(`Attempting to set option ${option.urlKey} for an inactive display option`)
        return value
      }

      dispatch({ type: "MergeOption", option, value })
      return value
    },
    [dispatch, supportedURLKeys, isActive]
  )

  const clearOptions = React.useCallback<ClearOptionFn>(
    (keys: ClearKeys) => {
      keys === "All"
        ? dispatch({ type: "ClearAll" })
        : dispatch({ type: "ClearKeys", urlKeys: keys })
    },
    [dispatch]
  )

  const previousQueryParams = usePrevious(queryParams)

  // If the query params change for the location, we can let these always win. Our in memory tracking
  // is only used to track batched changes that haven't been reflected in the query params.
  React.useEffect(() => {
    // An inactive display options will always reset back to all defaults.
    // NOTE: If this is not desired in future implementations, this should be made configurable.
    if (!isActive) {
      if (Object.keys(inMemoryQueryParams).length) dispatch({ type: "InactiveClearAll" })
      return
    }

    // Don't sync params if the URL hasn't changed. This avoids clobbering an option that was
    // just added to inMemoryQueryParams but not yet synced to the URL.
    if (objectHelper.deepEqual(previousQueryParams, queryParams)) return

    const newInMemoryQueryParams = Object.entries(queryParams).reduce(
      (acc, [key, value]) => {
        if (supportedURLKeys.has(key)) acc[key] = value
        return acc
      },
      {} as Record<string, string>
    )

    if (objectHelper.deepEqual(newInMemoryQueryParams, inMemoryQueryParams)) return

    dispatch({ type: "SyncFromQueryParams", queryParams: newInMemoryQueryParams })
  }, [dispatch, inMemoryQueryParams, isActive, previousQueryParams, queryParams, supportedURLKeys])

  return React.useMemo(
    (): DisplayOptionGroup => ({
      getOption,
      setOption,
      clearOptions,
      currentURLKeys: Object.keys(inMemoryQueryParams),
    }),
    [getOption, setOption, clearOptions, inMemoryQueryParams]
  )
}

// State to support tracking/handling of display options
type DisplayOptionURLParamsState = {
  // Contains only DisplayOption-related query params.
  inMemoryQueryParams: Record<string, string>
}

type ClearKeysEvent = { type: "ClearKeys"; urlKeys: string[] }

// Events that can trigger the effect reducer to run
type DisplayOptionURLParamsEvent =
  | { type: "SyncFromQueryParams"; queryParams: Record<string, string> }
  | { type: "MergeOption"; option: DisplayOption<unknown>; value: unknown }
  | ClearKeysEvent
  | { type: "ClearAll" }
  | { type: "InactiveClearAll" }

// Side effects that are available to run after reducer state change
export type DisplayOptionURLParamsEffect = {
  type: "pushToRouteWithLatestParams"
}

type DisplayOptionURLParamsExec = EffectReducerExec<
  DisplayOptionURLParamsState,
  DisplayOptionURLParamsEvent,
  DisplayOptionURLParamsEffect
>

// Track a separate state of in-memory query params to avoid race conditions if multiple setOption
// are called before the URL has been updated. The final effect from a setOption call is to
// push the new query params to the current URL. However, since React batches its re-renders,
// if > 1 setOption is called before a full re-render, the last setOption call will not know
// about the previous updates since they have not yet made it to the URL query params yet.
// This separate in memory query params will allow us to batch up any setOption calls to use
// to push to a new URL. Once the location query params change, we'll clear our in memory
// so it always reflects what's been changed to the URL. This specific implementation leverages
// useEffectReducer which allows us to call an effect after a new state is reduced. This ensures
// everytime we try attempt to `pushToRouteWithLatestParams`, we have the most up-to-date query
// params to push onto the URL.
function displayOptionURLParamsReducer(
  state: DisplayOptionURLParamsState,
  action: DisplayOptionURLParamsEvent,
  exec: DisplayOptionURLParamsExec
): DisplayOptionURLParamsState {
  switch (action.type) {
    // Update the tracked in memory query params exactly from what is in the provided query params.
    // This is generally meant to reset the in memory params to be what is in the URL.
    case "SyncFromQueryParams":
      return {
        ...state,
        inMemoryQueryParams: {
          ...action.queryParams,
        },
      }
    // Merge in the provided new params to what is already be tracked in memory.
    case "MergeOption": {
      const { option, value } = action
      const urlValue = option.toURLValue(value)

      // Value is either the default or not specified. Either case, treat as a clear
      if (value === option.defaultValue || urlValue === undefined) {
        return clearKeys(state, { type: "ClearKeys", urlKeys: [option.urlKey] }, exec)
      }

      exec({ type: "pushToRouteWithLatestParams" })

      return {
        ...state,
        inMemoryQueryParams: {
          ...state.inMemoryQueryParams,
          [option.urlKey]: urlValue,
        },
      }
    }
    // Clear the specified url key. This meant to clear out the in memory value
    // tracked for a specific key.
    case "ClearKeys":
      return clearKeys(state, action, exec)
    // Clear all in memory tracked params. This meant as a hard clear.
    case "ClearAll":
      exec({ type: "pushToRouteWithLatestParams" })
      return {
        ...state,
        inMemoryQueryParams: {},
      }
    // Clear all in memory tracked params but do not push to the current route. Inactive means
    // the display options are disconnected from getting updates from query params on the location.
    case "InactiveClearAll":
      return {
        ...state,
        inMemoryQueryParams: {},
      }
  }
}

function clearKeys(
  state: DisplayOptionURLParamsState,
  action: ClearKeysEvent,
  exec: DisplayOptionURLParamsExec
) {
  const newInMemoryQueryParams = { ...state.inMemoryQueryParams }
  const { urlKeys } = action
  urlKeys.forEach((key) => delete newInMemoryQueryParams[key])

  exec({ type: "pushToRouteWithLatestParams" })
  return {
    ...state,
    inMemoryQueryParams: {
      ...newInMemoryQueryParams,
    },
  }
}

function checkForSupportedURLKey(option: DisplayOption<unknown>, supportedURLKeys: Set<string>) {
  if (supportedURLKeys.has(option.urlKey)) return
  const error = new Error(
    `Attempted to access unsupported display option with url key ${option.urlKey}`
  )
  if (envHelper.isProduction()) {
    return TrackJS?.track(error)
  }

  throw error
}
