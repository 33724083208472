import { SortDirection, TransactionColumnName } from "@digits-graphql/frontend/graphql-bearer"
import { DisplayOption } from "src/frontend/components/OS/Shared/DisplayOptions/DisplayOption"
import {
  DEFAULT_DIMENSION_ACTIVITY,
  DimensionActivity,
  DimensionSortBy,
} from "src/frontend/types/DimensionFilter"
import {
  DEFAULT_PARTY_DESIGNATION_FILTER,
  PartyDesignationFilter,
} from "src/frontend/types/PartyDesignationFilter"

type DisplayOptionWithURLKey<T> = Omit<DisplayOption<T>, "urlKey">

const STRING_DISPLAY_OPTION: DisplayOptionWithURLKey<string> = {
  defaultValue: "",
  fromURLValue(urlValue) {
    return urlValue
  },
  toURLValue(value) {
    return value
  },
}

const BOOLEAN_DISPLAY_OPTION: DisplayOptionWithURLKey<boolean> = {
  defaultValue: false,
  fromURLValue(urlValue) {
    return `${urlValue}` === "true"
  },
  toURLValue(value) {
    return value.toString()
  },
}

/*
  ANY DIMENSION
*/

export const DIMENSION_SORT_DIRECTION_OPTION: DisplayOption<SortDirection> = {
  defaultValue: SortDirection.Descending,
  fromURLValue(urlValue) {
    return urlValue ? SortDirection[urlValue] : undefined
  },
  toURLValue(value) {
    return value.toString()
  },
  urlKey: "direction",
}

export const DIMENSION_SORT_BY_OPTION: DisplayOption<DimensionSortBy> = {
  defaultValue: DimensionSortBy.Amount,
  fromURLValue(urlValue) {
    return urlValue ? DimensionSortBy[urlValue] : undefined
  },
  toURLValue(value) {
    return value.toString()
  },
  urlKey: "sort",
}

export const DIMENSION_ACTIVITY_OPTION: DisplayOption<DimensionActivity> = {
  defaultValue: DEFAULT_DIMENSION_ACTIVITY,
  fromURLValue(urlValue) {
    return urlValue ? DimensionActivity[urlValue] : undefined
  },
  toURLValue(value) {
    return value.toString()
  },
  urlKey: "activity",
}

export const DIMENSION_SEARCH_TERM_OPTION: DisplayOption<string> = {
  ...STRING_DISPLAY_OPTION,
  urlKey: "q",
}

/*
  OWNER
*/

export const OWNER_ID_OPTION: DisplayOption<string> = {
  ...STRING_DISPLAY_OPTION,
  urlKey: "ownerId",
}

/*
  TRANSACTION
*/

export const TRANSACTION_COLUMN_NAME_SORT_BY_OPTION: DisplayOption<TransactionColumnName> = {
  defaultValue: TransactionColumnName.OccurredAt,
  fromURLValue(urlValue) {
    return urlValue ? TransactionColumnName[urlValue] : undefined
  },
  toURLValue(value) {
    return value.toString()
  },
  urlKey: "sort",
}

/*
  CATEGORY
*/

export const CATEGORY_ID_OPTION: DisplayOption<string> = {
  ...STRING_DISPLAY_OPTION,
  urlKey: "catId",
}

export const WITHOUT_CATEGORY_ANCESTORS_OPTION: DisplayOption<boolean> = {
  ...BOOLEAN_DISPLAY_OPTION,
  urlKey: "withoutCategoryAncestors",
}

export const OTHER_SIDE_CATEGORY_ID: DisplayOption<string> = {
  ...STRING_DISPLAY_OPTION,
  urlKey: "otherSideCatId",
}

export const WITHOUT_OTHER_SIDE_CATEGORY_ANCESTORS_OPTION: DisplayOption<boolean> = {
  ...BOOLEAN_DISPLAY_OPTION,
  urlKey: "withoutOtherSideCategoryAncestors",
}

export const CATEGORY_NAME_OPTION: DisplayOption<string> = {
  ...STRING_DISPLAY_OPTION,
  urlKey: "catName",
}

/*
  PARTY
*/

export const PARTY_DESIGNATION_OPTION: DisplayOption<PartyDesignationFilter> = {
  defaultValue: DEFAULT_PARTY_DESIGNATION_FILTER,
  fromURLValue(urlValue) {
    return urlValue
  },
  toURLValue(value) {
    return value.toString()
  },
  urlKey: "designation",
}
