import * as React from "react"
import { StackableBarData } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/Shared"
import { useStackableBarChartContext } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/StackableBarChartContext"

export function useLegendGroupByDataId() {
  const { chartData, tooltipData, currentPeriodBarData } = useStackableBarChartContext()

  return React.useMemo(
    () =>
      chartData.reduce<{ [key: string]: StackableBarData[] }>((agg, barData) => {
        if (
          tooltipData?.period.startedAt === barData.period.startedAt ||
          (!tooltipData && currentPeriodBarData?.[barData.period.startedAt])
        ) {
          const stack = agg[barData.dataId] || []
          stack.push(barData)
          agg[barData.dataId] = stack
          return agg
        }

        return agg
      }, {}),
    [chartData, tooltipData, currentPeriodBarData]
  )
}
