import { MonetaryValue } from "@digits-graphql/frontend/graphql-bearer"
import numberHelper from "@digits-shared/helpers/numberHelper"

export default {
  addValues(...values: MonetaryValue[]) {
    const total = this.buildMonetaryValue({
      ...(values[0] as MonetaryValue),
      amount: 0,
    })

    values.forEach((val) => {
      if (isInvalidValue(val, total)) {
        console.error("Adding 2 monetary values of different currency or multiplier", total, val)
      }
      total.amount += val.amount
    })
    return total
  },

  subtractValues(minuend: MonetaryValue, subtrahend: MonetaryValue) {
    const total = this.buildMonetaryValue(minuend)
    if (isInvalidValue(subtrahend, total)) {
      console.error(
        "Subtracting 2 monetary values of different currency or multiplier",
        total,
        subtrahend
      )
    }
    total.amount -= subtrahend.amount
    return total
  },

  buildMonetaryValue(val?: MonetaryValue) {
    return numberHelper.buildMonetaryAmount(
      val?.amount ?? 0,
      val?.iso4217CurrencyCode,
      val?.currencyMultiplier
    )
  },

  unmultipliedAmount(val: MonetaryValue): number {
    return val.currencyMultiplier === 0 ? 0 : val.amount / val.currencyMultiplier
  },

  absoluteAmount(val: MonetaryValue) {
    return this.buildMonetaryValue({
      ...val,
      amount: Math.abs(val.amount),
    })
  },

  subtractAbsoluteValues(minuend: MonetaryValue, subtrahend: MonetaryValue) {
    return this.subtractValues(this.absoluteAmount(minuend), this.absoluteAmount(subtrahend))
  },

  buildZeroMonetaryValue(): MonetaryValue {
    return {
      amount: 0,
      currencyMultiplier: 1e6,
      iso4217CurrencyCode: "USD",
    }
  },

  /**
   * Given a MonetaryValue calculate the percentage
   */
  percentage(current: MonetaryValue, next: MonetaryValue) {
    const currentTotal = current.amount / current.currencyMultiplier
    const nextTotal = next.amount / next.currencyMultiplier

    const delta = (currentTotal * 100) / nextTotal
    const truncatedDelta = Math.abs(delta) <= 10 ? parseFloat(delta.toFixed(2)) : Math.trunc(delta)

    if (!isFinite(truncatedDelta) || isNaN(truncatedDelta)) return NaN

    return truncatedDelta - 100
  },

  // Implemented to match the behavior in go-services for Invoicing.
  // Don't change without matching the that implementation!
  //
  // Fractional digits should match the number of decimal places used by the currency.
  // e.g. for USD, fractionalDigits should be 2.
  bankersRounding(value: MonetaryValue, fractionalDigits: number = 2): MonetaryValue {
    // The currencyMultiplier is offset to a divisor that produces an _integer portion_ of the value
    // that _includes_ the decimal places used by the currency.
    const divisor = value.currencyMultiplier / Math.pow(10, fractionalDigits)
    // e.g. for USD, an amount of 123455000 with a currencyMultiplier of 1000000 would produce a
    // value of $123.455. But since we want to round to the cent, we take a divisor of 10000 so that
    // the value we round is 12345.5.
    const toRound = value.amount / divisor
    // After rounding, we'd have 12346, which we'd then multiply back out to 12346000, for a monetary
    // value equaling $123.46.
    const rounded = numberHelper.bankersRounding(toRound) * divisor

    return {
      ...value,
      amount: rounded,
    }
  },

  equals(a: MonetaryValue, b: MonetaryValue) {
    return (
      a.amount === b.amount &&
      a.currencyMultiplier === b.currencyMultiplier &&
      a.iso4217CurrencyCode === b.iso4217CurrencyCode
    )
  },
}

function isInvalidValue(val: MonetaryValue, total: MonetaryValue) {
  // Zero monetary values are sometimes returned as "zero value" proto objects, containing no
  // currency multiplier or currency code. However, zero is zero in all currencies and multipliers,
  // so ignore this difference in that case.
  return (
    val.amount !== 0 &&
    (total.currencyMultiplier !== val.currencyMultiplier ||
      total.iso4217CurrencyCode !== val.iso4217CurrencyCode)
  )
}
