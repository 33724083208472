import * as React from "react"
import { useApolloClient } from "@apollo/client"
import {
  DoppelgangerPermit,
  useListDoppelgangerPermitsQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { useLogInSupportMutation } from "@digits-graphql/frontend/graphql-public"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import { Pill } from "@digits-shared/components/UI/Elements/Pill"
import { LoadingUserSummary, UserSummary } from "@digits-shared/components/UI/Elements/User"
import {
  ColumnComponentProps,
  Columns,
  Table,
  TableCell,
  TableRow,
} from "@digits-shared/components/UI/Table"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import { AccessLevel } from "@digits-shared/session/DGAccessLevel"
import colors from "@digits-shared/themes/colors"
import moment from "moment"
import styled, { css } from "styled-components"
import {
  DoppelgangerRedirect,
  useDoppelgangerRedirectURL,
  useDoppelgangerRedirectUserId,
} from "src/frontend/components/OS/Applications/Support/DoppelgangerRedirect"
import routes from "src/frontend/routes"
import FrontendSession from "src/frontend/session"

const DISABLED_CLASSNAME = "disabled"

/*
  STYLES
*/

const TableContainer = styled.div`
  font-size: 13px;

  && ${TableRow} {
    box-shadow: none;

    &.${DISABLED_CLASSNAME} ${TableCell} {
      opacity: 0.5;
    }
  }
`

const Date = styled.div<{ isExpired: boolean }>`
  color: ${colors.primary};

  ${({ isExpired }) =>
    isExpired &&
    css`
      color: ${colors.altoGray};
    `}
`

const ErrorMessage = styled.div`
  color: ${colors.orange};
  margin: 0 20px 10px 20px;
`

const Actions = styled.div`
  color: ${colors.secondary};

  div {
    cursor: pointer;
    margin-left: 10px;
  }
`

const ActionPill = styled(Pill)<{ background: string }>`
  background: ${({ background }) => background};
  color: ${colors.white};
`

/*
  INTERFACES
*/

type RowProps = ColumnComponentProps<DoppelgangerPermit>

interface LogInProps {
  onLogIn: () => void
  onError: (error: Error) => void
}

/*
  COMPONENTS
*/

const PermitTable: React.FC = () => {
  const [error, setError] = React.useState<Error | undefined>()
  const {
    value: inProgress,
    setTrue: setInProgress,
    setFalse: setNotInProgress,
  } = useStateBoolean(false)
  const dgUserId = useDoppelgangerRedirectUserId()

  const { loading, data } = useListDoppelgangerPermitsQuery({
    fetchPolicy: "network-only",
  })

  const filterPermitsBy = React.useCallback(
    (permits: DoppelgangerPermit[]) => {
      if (!dgUserId) return permits
      return permits.filter((p) => p.user.id === dgUserId)
    },
    [dgUserId]
  )

  const handleError = React.useCallback(
    (e: Error) => {
      setNotInProgress()
      setError(e)
    },
    [setNotInProgress]
  )

  const getRowClassName = React.useCallback(
    (permit?: DoppelgangerPermit) => {
      if (!permit) return ""
      const expiredAt = moment.unix(permit.expiresAt)
      if (inProgress || expiredAt.isBefore(moment())) return DISABLED_CLASSNAME
      return ""
    },
    [inProgress]
  )

  const column: Columns<DoppelgangerPermit> = {
    "Target User": {
      component: TargetUser,
      size: { width: "50%", height: 25 },
    },
    Expires: {
      component: ExpiresAt,
      size: { width: 120, height: 25 },
    },
    Created: {
      component: CreatedAt,
      size: { width: 120, height: 25 },
    },
    "Access Level": {
      component: LogInActions,
      size: { width: 300, height: 25 },
      props: {
        onLogIn: setInProgress,
        onError: handleError,
      },
    },
  }

  return (
    <TableContainer>
      <PermissionError error={error} />
      <DoppelgangerRedirect />
      <Table
        isLoading={loading}
        error={error}
        loadingRows={5}
        data={data?.listDoppelgangerPermits}
        addPaddingCells
        columns={column}
        getRowClassName={getRowClassName}
        filterBy={filterPermitsBy}
      />
    </TableContainer>
  )
}

export default PermitTable

const TargetUser: React.FC<RowProps> = ({ data: permit, loading }) => {
  if (loading || !permit) return <LoadingUserSummary />

  return <UserSummary user={permit.user} />
}

const ExpiresAt: React.FC<RowProps> = ({ data: permit, loading }) => {
  if (loading || !permit) return <LoadingBlock width="80%" height="12px" />
  const expiredAt = moment.unix(permit.expiresAt)
  return <Date isExpired={expiredAt.isBefore(moment())}>{expiredAt.fromNow()}</Date>
}

const CreatedAt: React.FC<RowProps> = ({ data: permit, loading }) => {
  if (loading || !permit) return <LoadingBlock width="80%" height="12px" />
  return <>{moment.unix(permit.createdAt).fromNow()}</>
}

const LogInActions: React.FC<RowProps & LogInProps> = ({
  data: permit,
  loading,
  onError,
  onLogIn,
}) => {
  const [selectedPermitId, setSelectedPermitId] = React.useState<string | undefined>()
  const session = useSession<FrontendSession>()
  const { history } = useRouter()
  const apolloClient = useApolloClient()
  const redirectTo = useDoppelgangerRedirectURL() || routes.login.generate()

  const handleError = React.useCallback(
    (error: Error) => {
      setSelectedPermitId(undefined)
      onError(error)
    },
    [onError]
  )

  const [logIn] = useLogInSupportMutation({
    onError: handleError,
  })

  const onClick = React.useCallback(
    (accessLevel: AccessLevel) => {
      if (!permit) return
      onLogIn()
      setSelectedPermitId(permit.id)
      logIn({ variables: { id: permit.id }, context: { publicAPI: true } }).then(
        async ({ data }) => {
          if (!data?.logInSupport) throw new Error("missing session info")

          // Use clearStore to avoid refetching queries
          // https://www.apollographql.com/docs/react/caching/advanced-topics/#resetting-the-cache
          await apolloClient.clearStore()
          session.onBearerChange(data.logInSupport, true).then(() => {
            session.accessLevel = accessLevel
            history.push(redirectTo)
          })
        }
      )
    },
    [permit, onLogIn, logIn, apolloClient, session, history, redirectTo]
  )

  const onCustomerClick = React.useCallback(() => {
    onClick(AccessLevel.Customer)
  }, [onClick])

  const onDashboardClick = React.useCallback(() => {
    onClick(AccessLevel.Dashboard)
  }, [onClick])

  const onFullAccessClick = React.useCallback(() => {
    onClick(AccessLevel.FullAccess)
  }, [onClick])

  if (loading || !permit) return <LoadingBlock width="80%" height="12px" />

  if (selectedPermitId) {
    return <Actions>logging in…</Actions>
  }

  return (
    <Actions>
      <ActionPill onClick={onCustomerClick} background={colors.green}>
        Customer
      </ActionPill>
      <ActionPill onClick={onDashboardClick} background={colors.poloBlue}>
        Dashboard
      </ActionPill>
      <ActionPill onClick={onFullAccessClick} background={colors.orange} color={colors.white}>
        Full Access
      </ActionPill>
    </Actions>
  )
}

const PermissionError: React.FC<{ error?: Error }> = ({ error }) => {
  if (!error) return null
  return <ErrorMessage>{error.message}</ErrorMessage>
}
