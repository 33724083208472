import { ActionItemFilter, ActionItemTargetMode } from "@digits-graphql/frontend/graphql-bearer"
import {
  STATUS_OPEN,
  STATUS_RESOLVED,
} from "src/frontend/components/Shared/NavSidebar/ActionItems/constants"
import { GroupView } from "src/frontend/components/Shared/NavSidebar/ActionItems/useGroupView"
import { useInternalUserSettings } from "src/frontend/hooks/useInternalUserSettings"

export function useActionItemFilter(groupView: GroupView): ActionItemFilter {
  const showAssignments = useInternalUserSettings().actionItemAssignments
  switch (groupView) {
    case "Open":
      return {
        status: STATUS_OPEN,
        excludeMode: showAssignments
          ? [ActionItemTargetMode.Assign, ActionItemTargetMode.Watch]
          : undefined,
      }
    case "Resolved":
      return {
        status: STATUS_RESOLVED,
        includeSuperseded: true,
      }
    case "Assigned":
      return {
        status: STATUS_OPEN,
        includeMode: [ActionItemTargetMode.Assign],
      }
    case "Watched":
      return {
        status: STATUS_OPEN,
        includeMode: [ActionItemTargetMode.Watch],
      }
  }
}
