import {
  EntityCategory,
  EntityDepartment,
  EntityParty,
  IntervalOrigin,
  LayoutComponentType,
  MetricSummaryItem,
  ReportKind,
  SummaryLineItem,
} from "@digits-graphql/frontend/graphql-bearer"
import { PartyRole } from "@digits-graphql/frontend/graphql-public"
import { DigitsRoute } from "@digits-shared/components/Router/DigitsRoute"
import useSession from "@digits-shared/hooks/useSession"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import FrontendSession from "src/frontend/session"
import { FrontendPartyRole } from "src/frontend/types/FrontendPartyRole"

export function useCategoryLinkPath(
  category: EntityCategory | undefined,
  intervalOrigin: IntervalOrigin
) {
  const { isSharingContextActive } = useSession<FrontendSession>()
  const generatePath = useFrontendPathGenerator()
  const { year, index, interval, intervalCount } = intervalOrigin

  return !isSharingContextActive && category
    ? generatePath(routes.categoryDetails, {
        categoryId: category.id,
        year,
        index,
        interval,
        intervalCount,
      })
    : undefined
}

export function usePartyLinkPath(
  party: EntityParty | undefined,
  partyRole: PartyRole | undefined | null,
  intervalOrigin: IntervalOrigin | undefined
) {
  const { isSharingContextActive } = useSession<FrontendSession>()
  const generatePath = useFrontendPathGenerator()
  const { year, index, interval, intervalCount } = intervalOrigin || {}

  return !isSharingContextActive && party
    ? generatePath(routes.partyDetails, {
        partyId: party.id,
        partyRole: FrontendPartyRole.roleURLKey(partyRole),
        year,
        index,
        interval,
        intervalCount,
      })
    : undefined
}

export function useComponentDetailsLinkPath(
  configType: LayoutComponentType | MetricSummaryItem | SummaryLineItem | ReportKind | undefined,
  origin: IntervalOrigin | undefined
) {
  const { isSharingContextActive } = useSession<FrontendSession>()
  const generatePath = useFrontendPathGenerator()
  const { year, index, interval } = origin || {}

  return !isSharingContextActive && configType
    ? generatePath(routes.layoutComponentDetails, {
        configType,
        year,
        index,
        interval,
        intervalCount: undefined,
      })
    : undefined
}

export function useDepartmentLinkPath(
  department: EntityDepartment | undefined,
  intervalOrigin: IntervalOrigin,
  relativeRoute?: DigitsRoute
) {
  const { isSharingContextActive } = useSession<FrontendSession>()
  const generatePath = useFrontendPathGenerator()
  const { year, index, interval, intervalCount } = intervalOrigin

  return !isSharingContextActive && department
    ? generatePath(relativeRoute ?? routes.departmentDetails, {
        departmentId: department.id,
        year,
        index,
        interval,
        intervalCount,
      })
    : undefined
}

export function useLocationLinkPath(
  location: EntityDepartment | undefined,
  intervalOrigin: IntervalOrigin,
  relativeRoute?: DigitsRoute
) {
  const { isSharingContextActive } = useSession<FrontendSession>()
  const generatePath = useFrontendPathGenerator()
  const { year, index, interval, intervalCount } = intervalOrigin

  return location && !isSharingContextActive
    ? generatePath(relativeRoute ?? routes.locationDetails, {
        locationId: location.id,
        year,
        index,
        interval,
        intervalCount,
      })
    : undefined
}
