import * as React from "react"
import {
  DimensionSortMode,
  LayoutComponentType,
  Statement,
  StatementConfig,
  useReadReportStatementLiveDataQuery,
  ViewIdentifierInput,
} from "@digits-graphql/frontend/graphql-bearer"

/**
 * Must be used within a LayoutContext.
 */
export function useStatementLiveData(
  config: StatementConfig,
  type: LayoutComponentType,
  viewId: ViewIdentifierInput,
  skip?: boolean
) {
  const { data, loading, error } = useReadReportStatementLiveDataQuery({
    variables: {
      viewId,
      config: {
        type,
        statement: config,
      },
      limit: 5,
      sort: DimensionSortMode.Total,
    },
    skip,
  })

  return React.useMemo(() => {
    const liveData = data?.liveComponentData

    // Resolves the component data union types to the portions we care about
    if (liveData?.__typename === "Statement") {
      return { statement: liveData as Statement, loading, error }
    }

    return { statement: undefined, loading, error }
  }, [data?.liveComponentData, error, loading])
}
