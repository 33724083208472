import * as React from "react"
import { LocationChartConfig } from "@digits-graphql/frontend/graphql-bearer"
import urlHelper from "@digits-shared/helpers/urlHelper"
import useRouter from "@digits-shared/hooks/useRouter"
import { BreakdownChartComponent } from "src/frontend/components/Shared/Layout/Components/Charts/BreakdownChartComponent"
import { TimeseriesValue } from "src/frontend/components/Shared/Layout/Components/Charts/toTimeseries"
import {
  LocationComponentIcon,
  LocationComponentTitle,
} from "src/frontend/components/Shared/Layout/Components/Headers/ComponentEntityIcons"
import { useLocationChartComponentData } from "src/frontend/components/Shared/Layout/Components/Locations/useLocationChartComponentData"
import { useLocationChartTimeseries } from "src/frontend/components/Shared/Layout/Components/Locations/useLocationChartTimeseries"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { useLocationLinkPath } from "src/frontend/components/Shared/Layout/hooks/useEntityDetailsViewPaths"
import { MatchedComponent, SizingProps } from "src/frontend/components/Shared/Layout/types"

/*
  INTERFACES
*/

interface ChartComponentProps extends SizingProps {
  layoutId: string
  component: MatchedComponent<"locationChart">
  // used by Portals to override the origin (time selectors)
  configOverride?: LocationChartConfig
  componentSize: ComponentSize
  skipAnimations: boolean
}

/*
  COMPONENTS
*/

export const LocationChartComponent: React.FC<ChartComponentProps> = ({
  layoutId,
  component,
  componentSize,
  height,
  skipAnimations,
  configOverride,
}) => {
  const {
    dataId,
    config: { locationChart },
  } = component
  const chartConfig = configOverride ?? locationChart
  const { origin } = chartConfig

  const { history: browserHistory } = useRouter()
  const { data } = useLocationChartComponentData(chartConfig, layoutId, dataId)
  const location = data?.location

  const locationPath = useLocationLinkPath(location, origin)

  const { breakdownTimeseries, totalTimeseries } = useLocationChartTimeseries(data)

  const onBarClick = React.useCallback(
    (value: TimeseriesValue, index: number) => {
      if (!locationPath) return
      const path = urlHelper.addSearchParams(locationPath, { highlight: index })
      if (!path) return

      browserHistory.push(path)
    },
    [browserHistory, locationPath]
  )

  const icon = (
    <LocationComponentIcon
      location={location}
      intervalOrigin={origin}
      componentSize={componentSize}
    />
  )
  const name = (
    <LocationComponentTitle
      location={location}
      intervalOrigin={origin}
      componentSize={componentSize}
    >
      {component.title ?? "Location"}
    </LocationComponentTitle>
  )

  return (
    <BreakdownChartComponent
      title={name}
      icon={icon}
      breakdownTimeseries={breakdownTimeseries}
      totalTimeseries={totalTimeseries}
      componentSize={componentSize}
      height={height}
      skipAnimations={skipAnimations}
      onBarClick={onBarClick}
    />
  )
}
