import { createSelector } from "@reduxjs/toolkit"
import { LEGACY_METRICS_SECTION_ID } from "src/frontend/components/OS/Applications/ClientPortal/consts"
import { RowIdentifier } from "src/frontend/components/Shared/Layout/types"
import { PortalState } from "src/frontend/components/Shared/Portals/State/types"

export const portalStateSelector = (state: PortalState) => state

export const activeComponentSelector = createSelector(portalStateSelector, (state) => {
  if (!state.activeComponentId) return null

  return state.componentMap[state.activeComponentId] ?? null
})

export const rowIdsSelector = createSelector(portalStateSelector, (state) => {
  const ids = new Map<string, RowIdentifier>()

  state.layout.sections?.forEach((s) => {
    s.rows?.forEach((r) => {
      ids.set(r.rowId, { sectionId: s.sectionId, rowId: r.rowId })
    })
  })

  return ids
})

export const layoutSectionsSelector = createSelector(portalStateSelector, (state) =>
  state.layout.sections?.filter((section) => section.sectionId !== LEGACY_METRICS_SECTION_ID)
)

export const sidebarStateSelector = createSelector(portalStateSelector, (state) => state.sidebar)

export const portalModeSelector = createSelector(portalStateSelector, (state) => state.portalMode)
