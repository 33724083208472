import * as React from "react"
import { svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgMessageCheckSquare } from "@digits-shared/components/SVGIcons/line/MessageCheckSquare.svg"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { GroupView } from "src/frontend/components/Shared/NavSidebar/ActionItems/useGroupView"
import { useInternalUserSettings } from "src/frontend/hooks/useInternalUserSettings"

const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-top: 48px;

  color: ${colors.secondary};
  font-size: 18px;
  font-weight: ${fonts.weight.normal};
  white-space: nowrap;

  svg {
    ${svgPathStyles(colors.secondary50)};
    width: 48px;
    height: 48px;
    margin-bottom: 12px;
  }
`

const Secondary = styled.div`
  color: ${colors.secondary60};
  font-size: 12px;
`

export const ActionItemsEmptyState: React.FC<{ groupView: GroupView }> = ({ groupView }) => {
  const showAssignmentUI = useInternalUserSettings().actionItemAssignments
  switch (groupView) {
    case "Assigned":
      return <EmptyState primary="All Caught Up!" secondary="Nothing assigned to you." />
    case "Watched":
      return <EmptyState primary="No Watched Items" secondary="Start watching from the Open tab." />
    case "Open":
      return showAssignmentUI ? (
        <EmptyState primary="No Open Items" secondary="Leave a comment to create one." />
      ) : (
        <EmptyState primary="All Caught Up!" secondary="No New Action Items" />
      )
    case "Resolved":
      return showAssignmentUI ? (
        <EmptyState
          primary="No Closed Items"
          secondary={showAssignmentUI ? "Check the Open tab for action items to do." : undefined}
        />
      ) : (
        <EmptyState primary="No Resolved Action Items" />
      )
    default:
      return null
  }
}

const EmptyState: React.FC<{ primary?: string; secondary?: string }> = ({ primary, secondary }) => (
  <EmptyStateContainer>
    <SvgMessageCheckSquare />
    {primary && <div>{primary}</div>}
    {secondary && <Secondary>{secondary}</Secondary>}
  </EmptyStateContainer>
)
