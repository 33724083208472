import {
  DepartmentDimension,
  EntityDepartment,
  TransactionSummary,
} from "@digits-graphql/frontend/graphql-bearer"

export type DepartmentSummaries = {
  label: string
  department: EntityDepartment
  summaries: TransactionSummary[]
}

export type DepartmentsByTime = Array<
  Pick<DepartmentDimension, "departmentId" | "departmentObject"> & {
    by: {
      time: Array<{
        summary: TransactionSummary
      }>
    }
  }
>

export function filterDepartmentAncestors(
  departmentId: string,
  departments: DepartmentsByTime | undefined
): DepartmentSummaries[] | undefined {
  if (!departments) return departments

  // filter out ancestors by hand because the WithoutDepartmentAncestors will not return everything we need
  const parent = departments.find((d) => d.departmentId === departmentId)
  if (!parent) return []

  const ancestors: string[] = []
  let ancestor = departments.find((d) => d.departmentId === parent.departmentObject.parentId)
  while (ancestor) {
    ancestors.push(ancestor.departmentId)
    ancestor = departments.find((d) => d.departmentId === ancestor?.departmentObject.parentId)
  }

  const departmentWithDescendents = departments
    .filter((d) => !ancestors.includes(d.departmentId))
    // parent department should be first item in array, the children will be name sorted
    .sort((d1, d2) => {
      if (d1.departmentId === departmentId) return -1
      if (d2.departmentId === departmentId) return 1
      return d1.departmentObject.name.localeCompare(d2.departmentObject.name)
    })

  return departmentWithDescendents.map((d) => ({
    label: d.departmentObject.name,
    summaries: d.by.time.map((t) => t.summary).reverse(),
    department: d.departmentObject,
  }))
}
