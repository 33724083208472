import * as React from "react"
import { ApolloClient, ApolloProvider } from "@apollo/client"
import { SessionContext } from "@digits-shared/components/Contexts/SessionContext"
import { StylesContextProvider } from "@digits-shared/components/Contexts/StylesContextProvider"
import { Theme } from "@digits-shared/themes"
import { domMax, LazyMotion } from "framer-motion"
import { GlobalStyles } from "src/shared/initializers/global"
import { ApolloClientScopeProvider } from "src/frontend/ApolloClientScope"
import { FrontendClient } from "src/frontend/components/Client"
import FrontendDigitsRouter from "src/frontend/components/Router"
import routes from "src/frontend/routes"
import FrontendSession from "src/frontend/session"
import "digits-shared/static/css/fonts.css"

export const session = new FrontendSession()

// Memoize to prevent re-renders when graphQLTracer updates. The client prop should never
// change so this effectively will never update.
export const Main: React.FC<{
  apolloClient: ApolloClient<{}>
}> = React.memo(({ apolloClient }) => (
  <ApolloProvider client={apolloClient}>
    <ApolloClientScopeProvider>
      <SessionContext.Provider value={session}>
        <StylesContextProvider mode={Theme.Light}>
          <GlobalStyles />
          <FrontendDigitsRouter routes={routes}>
            <LazyMotion features={domMax} strict>
              <FrontendClient />
            </LazyMotion>
          </FrontendDigitsRouter>
        </StylesContextProvider>
      </SessionContext.Provider>
    </ApolloClientScopeProvider>
  </ApolloProvider>
))
