/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react"
import { DropzoneState } from "react-dropzone"
import { svgIconStyles, svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { SvgAlertCircle } from "@digits-shared/components/SVGIcons/line/AlertCircle.svg"
import { SvgFilePlus03Solid } from "@digits-shared/components/SVGIcons/solid/FilePlus03Solid.svg"
import colors from "@digits-shared/themes/colors"
import fonts, { BodyText } from "@digits-shared/themes/typography"
import styled from "styled-components"
import { useDragAndDropFileUpload } from "src/frontend/components/OS/Applications/Vault/useFilesUpload"

/*
  STYLES
*/

const InputFile = styled.input`
  width: 0;
  height: 0;
  position: absolute;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;

  // pointer-events: none is needed to prevent the drag from leaving the container when the
  // user drags over the message
  pointer-events: none;
`

const DropIcon = styled.div`
  width: 30px;
  height: 30px;
  padding: 6px;
  border-radius: 100px;
  background: ${colors.translucentSecondary05};

  svg {
    ${svgIconStyles(colors.accentPurple)};
  }
`

const Message = styled.div`
  font-size: 12px;
  font-weight: ${fonts.weight.heavy};
`

const Prohibited = styled.div`
  position: absolute;
  font-size: 14px;
  font-weight: ${fonts.weight.heavy};
  color: ${colors.error};
  opacity: 0;

  svg {
    ${svgPathStyles(colors.error)};
    height: 28px;
    width: 28px;
  }
`

const DropContainer = styled.div`
  outline: none;
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  z-index: 5;

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 1px dashed ${colors.accentBlue};

  &.dragging:not(.unsupported) {
    border-color: #71f5bb;

    svg {
      ${svgIconStyles(colors.primary)};
    }

    ${Message}, ${BodyText} {
      color: ${colors.primary};
    }
  }

  &.unsupported {
    border-color: ${colors.error};

    ${Prohibited} {
      opacity: 1;
    }

    ${DropIcon}, ${BodyText}, ${Message} {
      opacity: 0;
    }
  }
`

/*
  INTERFACES
*/

interface DocumentUploaderProps {
  collectionId: string
  className?: string
  disabled?: boolean
}

/*
  COMPONENTS
*/

export const DocumentDragAndDrop: React.FC<React.PropsWithChildren<DocumentUploaderProps>> = ({
  collectionId,
  className,
  disabled = false,
}) => {
  const { dropzone } = useDragAndDropFileUpload(disabled, collectionId)
  return <DropArea className={className} disabled={disabled} dropzone={dropzone} />
}

const DropArea: React.FC<{
  dropzone: DropzoneState
  disabled?: boolean
  className?: string
}> = ({ dropzone, disabled, className }) => {
  const { getRootProps, getInputProps } = dropzone
  const { ...rootProps } = getRootProps()

  const { dropContainerRef, onDragEnter, onDragLeave } = useDocumentDragAndDrop(disabled)

  return (
    <DropContainer
      {...rootProps}
      ref={dropContainerRef}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      className={className}
    >
      <Content>
        <DropIcon>
          <SvgFilePlus03Solid />
        </DropIcon>
        <BodyText color={colors.accentBlue} weight="heavy">
          Drag & Drop
        </BodyText>
        <Prohibited>
          <SvgAlertCircle />
          <div>Unsupported</div>
        </Prohibited>
      </Content>
      <InputFile {...getInputProps()} />
    </DropContainer>
  )
}

function useDocumentDragAndDrop(disabled?: boolean) {
  const dropContainerRef = React.useRef<HTMLDivElement | null>(null)

  const onDragEnter = React.useCallback(
    (e: DragEvent) => {
      if (
        !dropContainerRef.current ||
        disabled ||
        !e.dataTransfer ||
        e.target !== dropContainerRef.current
      ) {
        return
      }

      dropContainerRef.current?.classList.remove("dragging", "unsupported")
      for (const item of e.dataTransfer.items) {
        if (item.kind !== "file") {
          e.preventDefault()
          dropContainerRef.current?.classList.add("unsupported")
          return
        }
      }

      dropContainerRef.current?.classList.add("dragging")
    },
    [dropContainerRef, disabled]
  )

  const onDragEnterReact = React.useCallback(
    (e: React.DragEvent) => {
      onDragEnter(e.nativeEvent as DragEvent)
    },
    [onDragEnter]
  )

  const onDragLeave = React.useCallback(() => {
    if (!dropContainerRef.current || disabled) return

    dropContainerRef.current?.classList.remove("dragging", "unsupported")
  }, [dropContainerRef, disabled])

  const onWindowDragLeave = React.useCallback(
    (event: DragEvent) => {
      if (event.clientX <= 0 && event.clientY <= 0) {
        onDragLeave()
      }
    },
    [onDragLeave]
  )

  React.useEffect(() => {
    window.addEventListener("dragenter", onDragEnter)
    window.addEventListener("dragleave", onWindowDragLeave)
    window.addEventListener("drop", onDragLeave)
    return () => {
      window.removeEventListener("dragenter", onDragEnter)
      window.removeEventListener("dragleave", onWindowDragLeave)
      window.removeEventListener("drop", onDragLeave)
    }
  }, [onDragEnter, onDragLeave, onWindowDragLeave])

  return React.useMemo(
    () => ({ dropContainerRef, onDragEnter: onDragEnterReact, onDragLeave }),
    [onDragEnterReact, onDragLeave]
  )
}
