import * as React from "react"
import { IntervalOrigin } from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import objectHelper from "@digits-shared/helpers/objectHelper"
import useConstant from "@digits-shared/hooks/useConstant"
import { useTooltip } from "@visx/tooltip"
import { WithTooltipProvidedProps } from "@visx/tooltip/lib/enhancers/withTooltip"
import {
  ChartPeriodsMap,
  isTimestampCurrentPeriod,
  stackableBarChartGroupByPeriods,
  StackableBarData,
} from "src/frontend/components/OS/Shared/Charts/StackableBarChart/Shared"

export enum StackableBarChartLabelType {
  None = "None",
  SingleBar = "SingleBar",
  AllBarPeriods = "AllBarPeriods",
}

export enum StackableBarChartLegendType {
  None = "None",
  Count = "Count",
  Color = "Color",
  Mini = "Mini",
}

interface StackableBarChartConfigs {
  uniqueChartId: string
  label: StackableBarChartLabelType
  legend: StackableBarChartLegendType
  alwaysShowAxisTicks?: boolean
  highlightAllPeriodsOnHover?: boolean
  otherDimensionName?: string
  emptyNode?: React.ReactNode
  children?: React.ReactNode

  onBarClick?: (
    barData: StackableBarData | undefined,
    periodIndex: number,
    event: React.MouseEvent
  ) => void
  onMouseOver?: (barData: StackableBarData | undefined, periodIndex: number) => void
  onMouseOut?: (barData?: StackableBarData) => void

  intervalOrigin: IntervalOrigin
  loading?: boolean
  chartData: StackableBarData[]
}

export type StackableBarChartContextProps = StackableBarChartConfigs & {
  glowFilterId: string
  periodData: ChartPeriodsMap
  tooltipData?: StackableBarData
  currentPeriodBarData?: ChartPeriodsMap
  isHoveringOnBar: (barData: StackableBarData) => boolean
  showTooltip: WithTooltipProvidedProps<StackableBarData>["showTooltip"]
  hideTooltip: WithTooltipProvidedProps<StackableBarData>["hideTooltip"]
}

export const StackableBarChartContext = React.createContext<StackableBarChartContextProps>({
  uniqueChartId: "chart-id",
  glowFilterId: "glow-filter",
  label: StackableBarChartLabelType.None,
  legend: StackableBarChartLegendType.None,
  loading: false,
  chartData: [],
  periodData: {},
  isHoveringOnBar: () => false,
  showTooltip: () => {},
  hideTooltip: () => {},
  intervalOrigin: dateTimeHelper.defaultIntervalOrigin(),
})

export function useStackableBarChartContext() {
  return React.useContext(StackableBarChartContext)
}

export const StackableBarChartContextProvider: React.FC<StackableBarChartConfigs> = ({
  children,
  ...props
}) => {
  const context = useBuildStackableBarChartContext(props)

  return (
    <StackableBarChartContext.Provider value={context}>
      {children}
    </StackableBarChartContext.Provider>
  )
}

export function useBuildStackableBarChartContext({
  uniqueChartId,
  label,
  legend,
  alwaysShowAxisTicks,
  highlightAllPeriodsOnHover,
  otherDimensionName,
  emptyNode,
  onBarClick,
  onMouseOut,
  onMouseOver,
  intervalOrigin,
  loading,
  chartData = [],
}: StackableBarChartConfigs) {
  const glowFilterId = useConstant(`glow-${uniqueChartId}`)
  const { tooltipData, showTooltip, hideTooltip } = useTooltip<StackableBarData>()

  const periodData = React.useMemo(() => stackableBarChartGroupByPeriods(chartData), [chartData])

  const currentPeriodBarData = React.useMemo((): ChartPeriodsMap | undefined => {
    const key = objectHelper
      .keysOf(periodData)
      ?.find((startedAt) =>
        isTimestampCurrentPeriod(parseInt(startedAt.toString(), 10), intervalOrigin)
      )

    return key ? { [key]: periodData[key] as StackableBarData[] } : undefined
  }, [periodData, intervalOrigin])

  const isHoveringOnBar = React.useCallback(
    (barData: StackableBarData) =>
      tooltipData?.legend === barData.legend &&
      (highlightAllPeriodsOnHover || tooltipData?.period.startedAt === barData.period.startedAt),
    [tooltipData, highlightAllPeriodsOnHover]
  )

  return React.useMemo(
    (): StackableBarChartContextProps => ({
      uniqueChartId,
      glowFilterId,
      label,
      legend,
      alwaysShowAxisTicks,
      otherDimensionName,
      emptyNode,

      onBarClick,
      onMouseOut,
      onMouseOver,
      isHoveringOnBar,
      currentPeriodBarData,

      intervalOrigin,
      loading,
      chartData,
      periodData,
      tooltipData,
      showTooltip,
      hideTooltip,
    }),
    [
      uniqueChartId,
      glowFilterId,
      label,
      legend,
      alwaysShowAxisTicks,
      otherDimensionName,
      emptyNode,
      onBarClick,
      onMouseOut,
      onMouseOver,
      isHoveringOnBar,
      currentPeriodBarData,
      intervalOrigin,
      loading,
      chartData,
      periodData,
      tooltipData,
      showTooltip,
      hideTooltip,
    ]
  )
}
