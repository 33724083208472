import * as React from "react"
import {
  DirectionFromOrigin,
  EntityHoverFields_CategoryHover_Fragment,
  EntityHoverFields_PartyHover_Fragment,
  EntityHoverFieldsFragment,
  InsightComponentConfig,
  InsightSubjectType,
  ObjectEntities,
  ObjectIdentifier,
  ObjectKind,
  useListInsightsQuery,
  useReadInsightComponentDataQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import envHelper from "@digits-shared/helpers/envHelper"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"

export function useListInsightsForComponent(
  config: InsightComponentConfig,
  layoutId: string,
  dataId?: string | null
) {
  const viewId = useViewVersion()
  const { data, loading } = useReadInsightComponentDataQuery({
    variables: {
      viewId,
      layoutId,
      dataId: dataId ?? "",
      limit: 2,
    },
    skip: !dataId,
  })

  const liveData = useListInsightsForConfig(config, !!dataId)

  return React.useMemo(() => {
    const componentData = data?.readComponentData.data

    // Data read from the archive takes precedence.
    // Resolves the component data union type to the portion we care about
    if (componentData?.__typename === "InsightComponent") {
      const { entities, hovers } = createHoverData(componentData.hovers)
      const { sentence } = componentData
      return { sentence, hovers, entities, loading }
    }

    if (loading) {
      return { sentence: undefined, hovers: undefined, entities: undefined, loading }
    }

    return liveData
  }, [data?.readComponentData.data, liveData, loading])
}

export function useListInsightsForConfig(config: InsightComponentConfig, skip?: boolean) {
  const viewKey = useViewVersion()
  const { origin, objectId } = config

  const { loading, data } = useListInsightsQuery({
    variables: {
      ...origin,
      paginationDirection: DirectionFromOrigin.Past,
      paginationLimit: 1,
      paginationOffset: 0,
      filter: {
        viewKey,
        subjectTypes: [subjectTypeFromObjectKind(objectId)],
        subjectId: objectId.id,
        objectId,
      },
    },
    skip,
  })

  return React.useMemo(() => {
    const entities = data?.listInsights[0]?.entities
    const sentence = data?.listInsights[0]?.insights[0]?.sentence
    return { sentence, hovers: undefined, entities, loading }
  }, [data?.listInsights, loading])
}

export function subjectTypeFromObjectKind(identifier: ObjectIdentifier) {
  switch (identifier.kind) {
    case ObjectKind.Category:
      return InsightSubjectType.Category

    case ObjectKind.Party:
      return InsightSubjectType.Party

    case ObjectKind.Department:
      return InsightSubjectType.Department

    case ObjectKind.Location:
      return InsightSubjectType.Location

    default:
      if (!envHelper.isProduction()) {
        throw new Error("unsupported object kind, fix it")
      }
      // dummy value on prod
      return InsightSubjectType.Category
  }
}

function createHoverData(data: EntityHoverFieldsFragment[] | undefined) {
  const hovers: (
    | EntityHoverFields_PartyHover_Fragment
    | EntityHoverFields_CategoryHover_Fragment
  )[] = []

  data?.forEach((hover) => {
    if (hover.__typename === "CategoryHover" || hover.__typename === "PartyHover") {
      hovers.push(hover)
    }
  })

  const entities: ObjectEntities = {
    parties: [],
    categories: [],
    users: [],
  }

  hovers
    ?.map((hover) => hover.entity)
    .forEach((entity) => {
      switch (entity.__typename) {
        case "EntityCategory":
          entities.categories?.push(entity)
          break
        case "EntityParty":
          entities.parties?.push(entity)
          break
      }
    })

  return { hovers, entities }
}
