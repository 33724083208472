import * as React from "react"
import {
  BillingProductName,
  useCheckBillingOrganizationPlanQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import useSession from "@digits-shared/hooks/useSession"
import FrontendSession from "src/frontend/session"
import { Experience } from "src/frontend/session/personas"

const SECONDS_PER_DAY = 86_400 // 60 seconds/min * 60 min/hr * 24 hr/day

export type TrialPeriod = {
  isActive: boolean
  daysRemaining: number
  trialPeriodDays: number
}

export function useTrialPeriod(): TrialPeriod | { isActive: false } {
  const { currentPrimaryExperience, currentOrganizationId } = useSession<FrontendSession>()
  const isOperator = currentPrimaryExperience === Experience.OperatorPortal

  const { data } = useCheckBillingOrganizationPlanQuery({
    variables: {
      organizationId: currentOrganizationId || "",
    },
    fetchPolicy: "cache-first",
    context: { noBatch: true },
    skip: !currentOrganizationId || !isOperator,
  })

  return React.useMemo(() => {
    if (
      data?.checkBillingOrganizationPlan.billingPlan?.product.name ===
      BillingProductName.FounderFinance
    ) {
      const { billingPlan: plan, trial } = data.checkBillingOrganizationPlan
      const { trialPeriodDays } = plan.product

      if (trialPeriodDays && trial?.end) {
        const daysRemaining = calculateRemainingDays(trial.end)
        const trialPeriod: TrialPeriod = {
          isActive: daysRemaining > 0,
          daysRemaining,
          trialPeriodDays,
        }
        return trialPeriod
      }
    }

    return { isActive: false }
  }, [data])
}

function calculateRemainingDays(trialEnd: number): number {
  const now = dateTimeHelper.unixNowSeconds()
  const remainingSeconds = trialEnd - now
  const remainingDays = Math.ceil(remainingSeconds / SECONDS_PER_DAY)
  return remainingDays > 0 ? remainingDays : 0
}
