import * as React from "react"
import { SessionContext } from "@digits-shared/components/Contexts/SessionContext"
import { JWTSession } from "@digits-shared/session/jwt/jwtSession"
import Session from "@digits-shared/session/Session"
import hoistStatics from "hoist-non-react-statics"

interface SessionProps<T extends Session<JWTSession>> {
  session: T
}

/**
 * Wrap a React Component Type (Class or Function) in higher order component that will
 * automatically pull the Session from the context and pass as an additional
 * prop to provided Component Type.
 *
 * TODO: NOTE: Currently will throw TS compile error if React Class Component uses default props.
 * @param {React.ComponentType<R>} Component to be wrapped
 */
export function withSessionContext<P, S extends Session<JWTSession> = Session>(
  Component: React.ComponentType<P & SessionProps<S>>
) {
  const BoundComponent = React.forwardRef<{}, React.PropsWithChildren<P>>((props, ref) => (
    <SessionContext.Consumer>
      {(value) => <Component {...(props as P)} ref={ref} session={value as S} />}
    </SessionContext.Consumer>
  ))

  BoundComponent.displayName = `withSessionContext(${Component.displayName || Component.name})`

  return hoistStatics(BoundComponent, Component)
}
