import * as React from "react"
import {
  EntityCategory,
  EntityDepartment,
  EntityLegalEntity,
  EntityLocation,
  EntityParty,
  EntityReportPackage,
  EntityTransaction,
  ObjectKind,
  PartyRole,
  ReadUserAclQuery,
  SummarizeInput,
  useListActivePartyRolesBatchLazyQuery,
  useReadUserAclQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import {
  LegalEntitySharedEntities,
  LegalEntityWithObjectGrant,
  ShareableEntity,
  SharedEntities,
  SharedEntity,
} from "src/frontend/components/OS/Applications/SharedWithMe/Shared"
import { SUPPORTED_PARTY_ROLES } from "src/frontend/types/FrontendPartyRole"

type RawEntities = ReadUserAclQuery["readUserACL"]["entities"]

// Stitches together object grants and their hydrated objects, allowing us to more easily render them together.
export function useSharedEntities(): { entities: SharedEntities; loading: boolean } {
  const { data, loading } = useReadUserAclQuery({ fetchPolicy: "network-only" })
  const raw = data?.readUserACL.entities

  // Map for looking up object grants by their object id + kind
  const objectLookup = useObjectIdentifierLookup(data)
  // Need active roles for every party in order to know how to properly render them
  const { activeRoles, loading: rolesLoading } = useListActiveRoles(raw, objectLookup)

  const entities: SharedEntities = React.useMemo(() => {
    // Build skeleton of our SharedEntries to be filled in below
    const sharedEntities: SharedEntities = {
      reportPackages: [],
      categories: [],
      transactions: [],
      departments: [],
      locations: [],
      parties: new Map(),
    }

    // Without a response or any legal entities, we don't have any work to do, so return
    if (loading || rolesLoading || !raw || !raw.legalEntities) return sharedEntities

    // Create map of legal entity to each supported ShareableEntity
    const rpByLE = entityGrantByLE(raw.reportPackages, ObjectKind.ReportPackage, objectLookup)
    const categoryByLE = entityGrantByLE(raw.categories, ObjectKind.Category, objectLookup)
    const departmentByLE = entityGrantByLE(raw.departments, ObjectKind.Department, objectLookup)
    const locationByLE = entityGrantByLE(raw.locations, ObjectKind.Location, objectLookup)
    const tnxByLE = entityGrantByLE(raw.transactions, ObjectKind.Transaction, objectLookup)
    const partyByLE = partyGrantByLE(raw.parties, activeRoles, objectLookup)

    // Add entries for each legal entity and any entities that have grants
    raw.legalEntities.reduce<SharedEntities>((agg, legalEntity) => {
      const reportPackages: LegalEntitySharedEntities<EntityReportPackage> = {
        legalEntity,
        entities: rpByLE.get(legalEntity.id) || [],
      }

      if (reportPackages.entities?.length) {
        agg.reportPackages = (agg.reportPackages || []).concat(reportPackages)
      }

      const categories: LegalEntitySharedEntities<EntityCategory> = {
        legalEntity,
        entities: categoryByLE.get(legalEntity.id) || [],
      }

      if (categories.entities?.length) {
        agg.categories = (agg.categories || []).concat(categories)
      }

      const departments: LegalEntitySharedEntities<EntityDepartment> = {
        legalEntity,
        entities: departmentByLE.get(legalEntity.id) || [],
      }

      if (departments.entities?.length) {
        agg.departments = (agg.departments || []).concat(departments)
      }

      const locations: LegalEntitySharedEntities<EntityLocation> = {
        legalEntity,
        entities: locationByLE.get(legalEntity.id) || [],
      }

      if (locations.entities?.length) {
        agg.locations = (agg.locations || []).concat(locations)
      }

      const transactions: LegalEntitySharedEntities<EntityTransaction> = {
        legalEntity,
        entities: tnxByLE.get(legalEntity.id) || [],
      }

      if (transactions.entities?.length) {
        agg.transactions = (agg.transactions || []).concat(transactions)
      }

      const partiesByRole = partyByLE.get(legalEntity.id)

      if (partiesByRole) {
        Array.from(partiesByRole.entries()).forEach(([role, parties]) => {
          const all = agg.parties.get(role) || []
          agg.parties.set(role, all.concat({ legalEntity, entities: parties }))
        })
      }

      return agg
    }, sharedEntities)

    return sharedEntities
  }, [activeRoles, objectLookup, loading, raw, rolesLoading])

  return React.useMemo(
    () => ({ entities, loading: loading || rolesLoading || !activeRoles }),
    [entities, loading, rolesLoading, activeRoles]
  )
}

function useObjectIdentifierLookup(data: ReadUserAclQuery | undefined) {
  return React.useMemo(() => {
    const leById = (data?.readUserACL.entities?.legalEntities || []).reduce(
      (agg, le) => agg.set(le.id, le),
      new Map<string, EntityLegalEntity>()
    )

    return (data?.readUserACL.grants || []).reduce((agg, grant) => {
      const legalEntity = leById.get(grant.object.legalEntityId)
      if (!legalEntity) return agg

      return agg.set(objectKey(grant.object.id, grant.object.kind), {
        legalEntity,
        grant,
      })
    }, new Map<string, LegalEntityWithObjectGrant>())
  }, [data])
}

function useListActiveRoles(
  raw: RawEntities | undefined,
  objectLookup: Map<string, LegalEntityWithObjectGrant>
) {
  const [summarizeInputs, setSummarizeInputs] = React.useState<SummarizeInput[]>([])
  const [listActiveRoles, { data, loading, error }] = useListActivePartyRolesBatchLazyQuery({
    fetchPolicy: "cache-and-network",
  })

  const rawInputs = React.useMemo(
    () =>
      raw?.parties?.reduce<SummarizeInput[]>((agg, p) => {
        const grant = objectLookup.get(objectKey(p.id || "", ObjectKind.Party))

        if (grant) {
          agg.push({
            filter: {
              viewKey: {
                legalEntityId: grant.legalEntity.id,
                viewType: grant.grant.viewType,
              },
              partyId: p.id,
            },
          })
        }

        return agg
      }, []),
    [objectLookup, raw?.parties]
  )

  React.useEffect(() => {
    if (!loading && summarizeInputs !== rawInputs && raw?.parties?.length && !error) {
      setSummarizeInputs(rawInputs || [])
      listActiveRoles({
        variables: {
          inputs: rawInputs || [],
        },
      })
    }
  }, [
    objectLookup,
    listActiveRoles,
    raw?.parties,
    data,
    loading,
    error,
    summarizeInputs,
    rawInputs,
  ])

  return React.useMemo(() => {
    const allParties = data?.listActivePartyRolesBatch || []
    const activeRoles = allParties.reduce(
      (agg, partyRoles) =>
        agg.set(
          partyRoles.partyId,
          partyRoles.roles.filter((r) => SUPPORTED_PARTY_ROLES.includes(r))
        ),
      new Map<string, PartyRole[]>()
    )

    return {
      activeRoles,
      loading: loading || !raw || !!(!activeRoles.size && raw.parties?.length),
    }
  }, [data?.listActivePartyRolesBatch, loading, raw])
}

// Generic handler for organizing entities by their legal entity
function entityGrantByLE<E extends ShareableEntity>(
  entities: E[] | undefined | null,
  kind: ObjectKind,
  grantByLE: Map<string, LegalEntityWithObjectGrant>
) {
  return (entities || []).reduce((agg, entity) => {
    // Determine id field. Transaction is the only unique case,
    // we'll want to `factId` for those, otherwise just `id`.
    const objectId = isEntityTransaction(entity) ? entity.factId || "" : entity.id

    // Find the object's grant
    const objGrant = grantByLE.get(objectKey(objectId, kind))

    if (!objGrant?.legalEntity) return agg

    const all = agg.get(objGrant.legalEntity.id) || []
    const shared: SharedEntity<E> = {
      entity,
      grantCreatedAt: objGrant.grant.createdAt,
      viewType: objGrant.grant.viewType,
    }

    agg.set(objGrant.legalEntity.id, all.concat(shared))

    return agg
  }, new Map<string, SharedEntity<E>[]>())
}

function partyGrantByLE(
  entities: EntityParty[] | undefined | null,
  rolesByPartyId: Map<string, PartyRole[]>,
  grantByLE: Map<string, LegalEntityWithObjectGrant>
) {
  return (entities || []).reduce((agg, entity) => {
    // Find the party grant
    const objGrant = grantByLE.get(objectKey(entity.id, ObjectKind.Party))

    if (!objGrant?.legalEntity) return agg

    // Find or make our by-role map
    const byRole =
      agg.get(objGrant.legalEntity.id) || new Map<PartyRole, SharedEntity<EntityParty>[]>()

    const availableRoles = rolesByPartyId.get(entity.id) || []

    // Organize the party into each role array so that we can later
    // render it correctly with other parties of the same role
    availableRoles.forEach((r) => {
      const all = byRole.get(r) || []
      const share = {
        entity,
        grantCreatedAt: objGrant.grant.createdAt,
        viewType: objGrant.grant.viewType,
      }
      byRole.set(r, all.concat(share))
    })

    // Put it back on the aggregator organized by their legal entity
    agg.set(objGrant.legalEntity.id, byRole)

    return agg
  }, new Map<string, Map<PartyRole, SharedEntity<EntityParty>[]>>())
}

function isEntityTransaction(entity: ShareableEntity): entity is EntityTransaction {
  return "factId" in entity
}

function objectKey(id: string, kind: ObjectKind) {
  return `${id}:${kind}`
}
