import * as React from "react"
import {
  GRAPHQL_TRACE_KEY,
  useGraphQLTracer,
} from "@digits-shared/components/Contexts/GraphQLTracerContext"
import { PageHeader, PageHeaderTitle } from "@digits-shared/components/UI/Elements/Container"
import { Switch } from "@digits-shared/components/UI/Elements/Switch"
import envHelper from "@digits-shared/helpers/envHelper"
import useSession from "@digits-shared/hooks/useSession"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import { DIGITS_EMPLOYEE_OVERRIDE } from "@digits-shared/session/Session"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled from "styled-components"
import { useInternalUserSettings } from "src/frontend/hooks/useInternalUserSettings"
import FrontendSession from "src/frontend/session"
import { userPreferenceKeys } from "src/shared/config/userPreferenceKeys"

/*
  STYLES
*/

const Preferences = styled.div`
  width: var(--application-pane-full-width);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  justify-content: space-between;
  margin-bottom: 48px;
`

const PreferenceContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 60px;
`

const PreferenceName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  font-weight: ${fonts.weight.heavy};
  margin-bottom: 5px;
`

const PreferenceDescription = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  font-weight: ${fonts.weight.book};
`

const PreferenceStatus = styled.div<{ isEnabled: boolean }>`
  color: ${({ isEnabled }) => (isEnabled ? colors.secondary : colors.translucentSecondary50)};
  font-weight: ${fonts.weight.normal};
  margin-left: 10px;
`

/*
  COMPONENTS
*/

export const DigitsEmployeePreferences: React.FC = () => (
  <>
    <PageHeader>
      <PageHeaderTitle>Digits Employee-Only</PageHeaderTitle>
    </PageHeader>
    <Preferences>
      <AnimationConfig />
      <GraphQLTrace />
      <DigitsEmployeeOverride />
      <GridOverlay />
      <ApolloBatching />
      <ActionItemAssignments />
    </Preferences>
  </>
)

const AnimationConfig: React.FC = () => {
  const { animationConfigDevToolsEnabled, toggleUserSetting } = useInternalUserSettings()
  const [_, setUpdated] = React.useState<Date>(new Date())

  const toggle = React.useCallback(
    (enabled: boolean) => {
      toggleUserSetting(userPreferenceKeys.animationConfigDevTools, enabled)
      // Have to change something locally to force a rerender of the switch UI
      setUpdated(new Date())
    },
    [toggleUserSetting]
  )

  return (
    <Preference
      name="Animation Controls Dev Tools"
      description="Enable Animation Controls dev tool. Displays animation controls window."
      isEnabled={animationConfigDevToolsEnabled}
      isLoading={false}
      toggle={toggle}
    />
  )
}

const GraphQLTrace: React.FC = () => {
  const { graphqlTracing, toggleUserSetting } = useInternalUserSettings()
  const graphQLTracer = useGraphQLTracer()

  const toggle = React.useCallback(
    (enable: boolean) => {
      toggleUserSetting(GRAPHQL_TRACE_KEY, enable)
      graphQLTracer.toggleDebugger()
    },
    [toggleUserSetting, graphQLTracer]
  )

  return (
    <Preference
      name="GraphQL Trace Dev Tools"
      description="Enable GraphQL trace dev tools to display debug info about network requests."
      isEnabled={graphqlTracing}
      isLoading={false}
      toggle={toggle}
    />
  )
}

const DigitsEmployeeOverride: React.FC = () => {
  const session = useSession<FrontendSession>()
  const { toggleUserSetting } = useInternalUserSettings()

  const toggle = React.useCallback(
    (enable?: boolean) => {
      toggleUserSetting(DIGITS_EMPLOYEE_OVERRIDE, enable)
      window.location.reload()
    },
    [toggleUserSetting]
  )

  // DO NOT ALLOW THIS IN PRODUCTION. Use Doppelgangers instead
  if (envHelper.isProduction()) return null

  return (
    <Preference
      name="Digits Employee"
      description="Current user will be treated as Digits employee. Client-side only, this wont affect your JWT or API requests."
      isEnabled={session.isDigitsEmployee}
      isLoading={false}
      toggle={toggle}
    >
      <PreferenceDescription
        css="margin-top: 6px; font-weight: bold; cursor: pointer"
        onClick={toggle.bind(undefined, undefined)}
      >
        Clear override from storage
      </PreferenceDescription>
    </Preference>
  )
}

const GridOverlay: React.FC = () => {
  const { gridOverlayEnabled, toggleUserSetting } = useInternalUserSettings()
  const { value: isLoading, toggle: toggleLoading } = useStateBoolean(false)

  const toggle = React.useCallback(
    (enable: boolean) => {
      toggleLoading()
      toggleUserSetting(userPreferenceKeys.gridDevTools, enable)
      window.location.reload()
    },
    [toggleUserSetting, toggleLoading]
  )

  return (
    <Preference
      name="Grid Overlay"
      description="Displays an overlay of the design system grid."
      isEnabled={gridOverlayEnabled}
      isLoading={isLoading}
      toggle={toggle}
    />
  )
}

const ApolloBatching: React.FC = () => {
  const { graphqlNoBatching, toggleUserSetting } = useInternalUserSettings()
  const { value: isLoading, toggle: toggleLoading } = useStateBoolean(false)

  const toggle = React.useCallback(
    (enable: boolean) => {
      toggleLoading()
      toggleUserSetting(userPreferenceKeys.graphqlNoBatching, enable)
      window.location.reload()
    },
    [toggleUserSetting, toggleLoading]
  )

  return (
    <Preference
      name="Disable Graphql Batching"
      description="Each operation will be single request"
      isEnabled={graphqlNoBatching}
      isLoading={isLoading}
      toggle={toggle}
    />
  )
}

const ActionItemAssignments: React.FC = () => {
  const { actionItemAssignments, toggleUserSetting } = useInternalUserSettings()
  const { value: isLoading, toggle: toggleLoading } = useStateBoolean(false)

  const toggle = React.useCallback(
    (enable: boolean) => {
      toggleLoading()
      toggleUserSetting(userPreferenceKeys.actionItemAssignments, enable)
      window.location.reload()
    },
    [toggleUserSetting, toggleLoading]
  )

  return (
    <Preference
      name="Action Item Assignment UI"
      description="Show the new tabs in the drawer and options on comments"
      isEnabled={actionItemAssignments}
      isLoading={isLoading}
      toggle={toggle}
    />
  )
}

const Preference: React.FC<{
  name: string
  description: string
  isEnabled: boolean
  isLoading: boolean
  toggle: (on: boolean) => void
  children?: React.ReactNode
}> = ({ name, description, isEnabled, isLoading, toggle, children }) => (
  <PreferenceContainer>
    <PreferenceName>
      {name}
      <PreferenceStatus isEnabled={isEnabled && !isLoading}>
        {isLoading ? "Updating…" : isEnabled ? "Enabled" : "Disabled"}
      </PreferenceStatus>
    </PreferenceName>
    <PreferenceDescription>
      {description}
      <div css="margin-left: 10px;">
        <Switch on={isEnabled} onChange={toggle} />
      </div>
    </PreferenceDescription>
    {children}
  </PreferenceContainer>
)
