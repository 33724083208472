import {
  LayoutComponent,
  LayoutRow,
  LayoutSection,
  ViewType,
} from "@digits-graphql/frontend/graphql-bearer"
import { produce } from "immer"
import { v4 as UUID } from "uuid"
import QueryBuilder from "src/frontend/components/OS/Applications/Search/QueryBuilder"
import {
  ComponentMap,
  DeleteComponentStep,
  SortableLayoutComponent,
  TabNames,
} from "src/frontend/components/Shared/Layout/types"
import { LayoutBuilderAction } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/actions"
import { LayoutBuilderState } from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/types"

export const MAX_COMPONENTS_PER_ROW = 3

export function initialBuilderState(): LayoutBuilderState {
  return {
    confirmDeleteComponent: undefined,
    deleteComponentAnimation: undefined,
    dropAllowed: true,
    componentMap: {},
    textInFocus: false,
    layout: {
      viewKey: {
        viewVersion: "",
        mutationVersion: "",
        viewType: ViewType.Ledger,
        legalEntityId: "",
      },
      layoutId: UUID(),
      sections: [createSection()],
    },
    layoutLoading: null,
    documentId: null,
    sidebar: {
      lists: {
        configs: [],
      },
      metrics: {
        configs: [],
      },
      statements: {
        configs: [],
      },
      custom: {
        configs: [],
      },
    },
    activeConfig: null,
    activeComponentId: null,
    dragOverlayContent: null,
    dirty: false,
    saveErrorCount: 0,
    query: new QueryBuilder().build(),
    emptySearch: false,
  }
}

// This reducer uses a mutation-like style because we're using Immer (produce()) to manage the mutation-free changes.
//
// The alternative of manually updating of this nested layout structure in a mutation-free way is unpleasant.
/* eslint-disable max-nested-callbacks */
export const reducer = produce((curState: LayoutBuilderState, action: LayoutBuilderAction) => {
  switch (action.type) {
    case "reset": {
      return initialBuilderState()
    }
    case "layoutLoading": {
      curState.layoutLoading = action.loading
      break
    }
    case "layoutSaved": {
      curState.dirty = false
      curState.saveErrorCount = 0
      break
    }
    case "layoutSaveError": {
      curState.saveErrorCount += 1
      break
    }
    case "setDropAllowed": {
      curState.dropAllowed = action.allowed
      break
    }
    case "confirmDeleteComponent": {
      curState.confirmDeleteComponent = {
        componentId: action.componentId,
      }
      break
    }
    case "cancelDeleteComponent": {
      curState.confirmDeleteComponent = undefined
      break
    }
    case "animateDeleteComponent": {
      curState.confirmDeleteComponent = undefined
      curState.deleteComponentAnimation = {
        componentId: action.componentId,
        step: DeleteComponentStep.Shrink,
      }
      break
    }
    case "componentSlideEnded": {
      if (curState.deleteComponentAnimation?.step === DeleteComponentStep.Slide) {
        curState.deleteComponentAnimation.step = DeleteComponentStep.Shrink
      }
      break
    }
    case "deleteComponentShrinkEnded": {
      const animation = curState.deleteComponentAnimation
      if (!animation) return
      animation.step = DeleteComponentStep.Poof
      break
    }
    case "deleteComponentAnimationEnded": {
      curState.deleteComponentAnimation = undefined
      break
    }
    case "deleteComponent": {
      delete curState.componentMap[action.componentId]

      curState.layout.sections?.forEach((s) => {
        s.rows?.forEach((r) => {
          if (r.components?.some((c) => c.componentId === action.componentId)) {
            if (r.components?.length === 1) {
              s.rows = s.rows?.filter((row) => row.rowId !== r.rowId)
            } else {
              r.components = r.components.filter((c) => c.componentId !== action.componentId)
            }
          }
        })
      })
      curState.dirty = true
      curState.textInFocus = false
      break
    }
    case "dragStart": {
      curState.activeConfig = action.activeConfig

      setActiveComponent(curState, action.activeComponent)
      break
    }
    case "dragCancel": {
      curState.activeConfig = null
      curState.activeComponentId = null
      curState.dragOverlayContent = null
      break
    }
    case "dragEnd": {
      curState.activeConfig = null
      curState.activeComponentId = null
      curState.dragOverlayContent = null
      break
    }
    case "dropInRowGutter": {
      curState.dirty = true

      const { layout, componentMap, activeComponentId } = curState
      if (!activeComponentId) return

      const component = componentMap[activeComponentId]
      if (!component) return

      const section = layout.sections?.find((s) => s.sectionId === action.sectionId)
      if (!section) return

      // Remove the component from any row it was previously in
      layout.sections?.forEach((s) => {
        s.rows?.forEach((r) => {
          const rowComponents = r.components ?? []

          if (rowComponents.some((c) => c.componentId === activeComponentId)) {
            if (r.components?.length === 1) {
              // Delete the row if it was the last component in it
              s.rows = s.rows?.filter((row) => row.rowId !== r.rowId)
            } else {
              r.components = rowComponents.filter((c) => c.componentId !== activeComponentId)
            }
          }
        })
      })

      const newRow = createRow([component])
      const rows = section.rows ?? []

      section.rows = [...rows.slice(0, action.index), newRow, ...rows.slice(action.index)]
      break
    }
    case "dropInComponentGutter": {
      curState.dirty = true

      const { layout, componentMap, activeComponentId } = curState
      if (!activeComponentId) return

      const component = componentMap[activeComponentId]
      if (!component) return

      const section = layout.sections?.find((s) => s.sectionId === action.sectionId)
      if (!section) return

      const row = section.rows?.find((r) => r.rowId === action.rowId)
      if (!row) return

      const index = (row.components ?? []).findIndex((c) => c.componentId === activeComponentId)
      if (index === action.index) return

      // Remove the component from any row it was previously in
      layout.sections?.forEach((s) => {
        s.rows?.forEach((r) => {
          const rowComponents = r.components ?? []

          if (rowComponents.some((c) => c.componentId === activeComponentId)) {
            if (r.components?.length === 1 && r.rowId !== action.rowId) {
              // Delete the row if it was the last component in it
              s.rows = s.rows?.filter((rs) => rs.rowId !== r.rowId)
            } else {
              r.components = rowComponents.filter((c) => c.componentId !== activeComponentId)
            }
          }
        })
      })

      // Compensate for the fact that the indexes will have shifted if we removed the component
      // from a spot before where we're trying to insert it
      const insertIndex = index >= 0 && index < action.index ? action.index - 1 : action.index

      const components = row.components ?? []

      row.components = [
        ...components.slice(0, insertIndex),
        component,
        ...components.slice(insertIndex),
      ]
      break
    }
    case "addSection": {
      const curSections = curState.layout.sections ?? []

      // Store the initial component so that it can be updated once its data is archived.
      if (action.initialComponent) {
        curState.componentMap[action.initialComponent.id] = action.initialComponent
      }

      const index = action.index ?? curSections.length
      const newSections = [...curSections]
      newSections.splice(
        index,
        0,
        createSection(action.sectionId, action.title, action.initialComponent)
      )
      curState.layout.sections = newSections
      curState.dirty = true
      break
    }
    case "deleteSection": {
      const curSections = curState.layout.sections ?? []

      const sectionIdx = curSections.findIndex((s) => s.sectionId === action.sectionId)
      if (sectionIdx === -1) break

      // remove components from Map
      curSections[sectionIdx]?.rows?.forEach((r) =>
        r.components?.forEach((c) => delete curState.componentMap[c.componentId])
      )

      curSections.splice(sectionIdx, 1)
      curState.layout.sections = curSections
      curState.dirty = true
      break
    }
    case "setLayout": {
      curState.layout = action.layout
      curState.documentId = action.documentId

      // Set the component map to make all components resolvable by ID
      const newMap: ComponentMap = {}

      action.layout.sections?.forEach((s) => {
        s.rows?.forEach((r) => {
          r.components?.forEach((c) => {
            newMap[c.componentId] = c
          })
        })
      })

      curState.componentMap = newMap
      break
    }
    case "setComponentDataId": {
      // Find the component in the map. It should be present because either:
      // 1) It is the currently active dragged component
      // 2) The component has been dropped and now belongs to the layout
      const component = curState.componentMap[action.componentId]
      if (!component) return

      component.dataId = action.dataId

      // Update the component data in whatever row it may be in
      curState.layout.sections?.forEach((s) => {
        s.rows?.forEach((r) => {
          const componentInRow = r.components?.find((c) => c.componentId === action.componentId)
          if (componentInRow) {
            componentInRow.dataId = action.dataId
          }
        })
      })
      curState.dirty = true
      break
    }
    case "setConfigs": {
      curState.sidebar[action.tab].configs = action.configs
      break
    }
    case "removeSidebarConfig": {
      // remove config ID from all tabs because we don't know which tab the component is configured for
      TabNames.forEach((tabName) => {
        curState.sidebar[tabName].configs = curState.sidebar[tabName].configs.filter(
          (it) => it.id !== action.id
        )
      })
      break
    }
    case "setQuery": {
      curState.query = action.query
      break
    }
    case "setEmptySearch": {
      curState.emptySearch = action.emptySearch
      break
    }
    case "setConfig": {
      // Update the component config where it resides in the map
      const mapComponent = curState.componentMap[action.componentId]
      if (!mapComponent) {
        return
      }

      mapComponent.config = action.config

      // Update the component config where it resides in the layout
      curState.layout.sections?.forEach((s) => {
        s.rows?.forEach((r) => {
          r.components?.forEach((c) => {
            if (c.componentId === action.componentId) {
              c.config = action.config
            }
          })
        })
      })

      if (action.save) {
        curState.dirty = true
      }
      break
    }
    case "setActiveComponent": {
      setActiveComponent(curState, action.component, action.removeCurrentActiveFromMap)
      break
    }
    case "setDragOverlayContent": {
      curState.dragOverlayContent = action.content
      break
    }
    case "textInFocus": {
      curState.textInFocus = action.value
      break
    }
    case "updateSection": {
      const newSection = action.section
      const sectionIndex = curState.layout.sections?.findIndex(
        (s) => s.sectionId === newSection.sectionId
      )

      if (!curState.layout.sections || sectionIndex === undefined) {
        break
      }

      const newSections = [...curState.layout.sections]
      newSections[sectionIndex] = newSection

      curState.layout = {
        ...curState.layout,
        sections: newSections,
      }

      action.section.rows?.forEach((r) => {
        r.components?.forEach((c) => {
          curState.componentMap[c.componentId] = c
        })
      })

      curState.dirty = true
      break
    }
    case "updateComponent": {
      const newComponent = action.component
      const sectionIndex = curState.layout.sections?.findIndex((s) =>
        s.rows?.find((r) => r.components?.find((c) => c.componentId === newComponent.componentId))
      )
      const section = curState.layout.sections?.[sectionIndex ?? -1]
      const rowIndex = section?.rows?.findIndex((r) =>
        r.components?.find((c) => c.componentId === newComponent.componentId)
      )
      const row = section?.rows?.[rowIndex ?? -1]
      const componentIndex = row?.components?.findIndex(
        (c) => c.componentId === newComponent.componentId
      )

      if (
        !curState.layout.sections ||
        !row?.components ||
        !section?.rows ||
        componentIndex === undefined ||
        rowIndex === undefined ||
        sectionIndex === undefined
      ) {
        break
      }

      const newComponents = [...row.components]
      newComponents[componentIndex] = newComponent

      const newRow = { ...row, components: newComponents }
      const newRows = [...section.rows]
      newRows[rowIndex] = newRow

      const newSection = { ...section, rows: newRows }
      const newSections = [...curState.layout.sections]
      newSections[sectionIndex] = newSection

      curState.layout = {
        ...curState.layout,
        sections: newSections,
      }
      curState.componentMap[newComponent.componentId] = newComponent
      curState.dirty = true
      break
    }
  }
  return curState
})

function createSection(
  sectionId = UUID(),
  title: string | undefined = undefined,
  component: LayoutComponent | undefined = undefined
): LayoutSection {
  const rows = component ? [createRow([component])] : []
  return {
    sectionId,
    title,
    rows,
  }
}

export function createRow(components: LayoutComponent[] = []): LayoutRow {
  return {
    rowId: UUID(),
    components,
  }
}

function setActiveComponent(
  curState: LayoutBuilderState,
  activeComponent: SortableLayoutComponent | null,
  removeCurrentActiveFromMap?: boolean
) {
  // Update the component map
  const newMap = {
    ...curState.componentMap,
  }
  if (removeCurrentActiveFromMap && curState.activeComponentId) {
    delete newMap[curState.activeComponentId]
  }
  if (activeComponent) {
    newMap[activeComponent.componentId] = activeComponent
  }
  curState.componentMap = newMap

  curState.activeComponentId = activeComponent?.componentId ?? null
}
