import * as React from "react"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import envHelper from "@digits-shared/helpers/envHelper"
import { storage } from "@digits-shared/helpers/storage/storage"
import stringHelper from "@digits-shared/helpers/stringHelper"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { useInternalUserSettings } from "src/frontend/hooks/useInternalUserSettings"
import FrontendSession from "src/frontend/session"
import {
  CONFIG_DEFAULT_VALUES,
  ControlsRecord,
  useSpringControlsContext,
  useSpringControlsNames,
} from "src/shared/components/Contexts/SpringControlsContext"
import zIndexes from "src/shared/config/zIndexes"

const ANIMATION_CONFIG_DEV_TOOLS_SESSION_STORAGE_KEY = "animation-config-dev-tools-open"
const TITLE_HEIGHT = "38px"

/*
  STYLES
*/

interface MenuProps {
  isOpen: boolean
}

function useMenuTranslateY({ isOpen }: MenuProps) {
  return isOpen ? 0 : `calc(100% - ${TITLE_HEIGHT})`
}

const Menu = styled.div<MenuProps>`
  position: fixed;
  bottom: 0;
  right: 20px;
  background-color: ${colors.translucentWhite80};
  color: ${colors.secondary};
  font-size: 12px;
  z-index: ${zIndexes.modalOverlay + 1};
  transition: transform 200ms ease-out;
  transform: translateY(${useMenuTranslateY});
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  backdrop-filter: blur(5px);
`

const CloseButton = styled.div`
  padding-left: 10px;
`

const Title = styled.div`
  pointer-events: all;
  display: flex;
  padding: 10px 20px;
  color: ${colors.translucentSecondary50};
  font-weight: ${fonts.weight.heavy};
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 250ms ease-out;
  height: ${TITLE_HEIGHT};

  &:hover {
    background-color: ${colors.translucentWhite04};
  }
`

const Sections = styled.div`
  display: flex;
`

const Section = styled.div`
  padding-bottom: 10px;
`

const SectionTitle = styled.div`
  pointer-events: all;
  display: flex;
  padding: 10px 20px;
  color: ${colors.translucentSecondary50};
  font-weight: ${fonts.weight.medium};
  justify-content: space-between;
  border-top: 1px solid ${colors.translucentSecondary10};
  border-bottom: 1px solid ${colors.translucentSecondary10};
`

const Row = styled.div<{ current: boolean }>`
  padding: 5px 20px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.translucentWhite04};
  }

  ${(props) =>
    props.current &&
    css`
      background-color: ${colors.translucentWhite20};

      &:hover {
        background-color: ${colors.translucentWhite20};
        cursor: auto;
      }
    `}
`

const ConfigEditorContainer = styled.div`
  padding: 10px 20px 0;
`

const ConfigEditorGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;

  input {
    color: ${colors.secondary};
    border: 1px solid ${colors.translucentSecondary10};
  }
`

/*
  COMPONENTS
*/

export const AnimationConfigDevTool: React.FC = () => {
  const openForSession =
    storage.session.getItem(ANIMATION_CONFIG_DEV_TOOLS_SESSION_STORAGE_KEY) === "true"
  const [isOpen, setOpen] = React.useState(openForSession)

  const toggleOpen = React.useCallback(() => {
    setOpen(!isOpen)
    storage.session.setItem(ANIMATION_CONFIG_DEV_TOOLS_SESSION_STORAGE_KEY, (!isOpen).toString())
  }, [isOpen])

  const names = useSpringControlsNames()
  const [selectedName, setSelectedName] = React.useState<string | undefined>(names[0])

  // Select the first animation if nothing is selected
  React.useEffect(() => {
    if (!selectedName && names[0]) {
      setSelectedName(names[0])
    }
  }, [names, selectedName])

  const { registry } = useSpringControlsContext()

  const selectedRecord = React.useMemo(() => {
    if (!selectedName) return undefined
    return registry[selectedName]
  }, [registry, selectedName])

  const nameClicked = React.useCallback((name: string) => {
    setSelectedName(name)
  }, [])

  const session = useSession<FrontendSession>()
  const { animationConfigDevToolsEnabled } = useInternalUserSettings()

  if (
    (!envHelper.isDevelopment() && !session.isDigitsEmployee) ||
    !animationConfigDevToolsEnabled
  ) {
    return null
  }

  return (
    <Menu isOpen={isOpen}>
      <Title onClick={toggleOpen}>
        Animation Controls
        <CloseButton>{isOpen ? "▼" : "▲"}</CloseButton>
      </Title>
      <Sections>
        <Section>
          <SectionTitle>Animations</SectionTitle>
          {names.map((name) => (
            <Row key={name} current={name === selectedName} onClick={nameClicked.bind(null, name)}>
              {stringHelper.camelCaseToSpaces(name)}
            </Row>
          ))}
        </Section>
        <Section>
          <SectionTitle>Controls</SectionTitle>
          {selectedName && selectedRecord && (
            <ConfigEditor record={selectedRecord} name={selectedName} />
          )}
        </Section>
      </Sections>
    </Menu>
  )
}

export const ConfigEditor: React.FC<{ name: string; record: ControlsRecord }> = ({
  name,
  record,
}) => {
  const { config, loop } = record
  const { updateConfig, updateLoop, forceUpdate } = useSpringControlsContext()

  const onForceUpdate = React.useCallback(() => {
    forceUpdate(name)
  }, [forceUpdate, name])

  const onChangeLoop = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateLoop(name, e.currentTarget.checked)
    },
    [name, updateLoop]
  )

  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { currentTarget } = e
      const { value } = currentTarget

      if (!value) return

      if (currentTarget.name.includes("-enabled")) {
        const realName = currentTarget.name.split("-")[0] as keyof typeof CONFIG_DEFAULT_VALUES

        updateConfig(name, {
          ...config,
          [realName]: currentTarget.checked ? CONFIG_DEFAULT_VALUES[realName] : undefined,
        })
        return
      }

      updateConfig(name, {
        ...config,
        // TODO: needs modification once we start supporting non-numeric values
        [currentTarget.name]: currentTarget.valueAsNumber,
      })
    },
    [config, name, updateConfig]
  )

  return (
    <ConfigEditorContainer>
      <ConfigEditorGrid>
        <label htmlFor="bounce">Bounce:</label>
        <div>
          <input
            id="bounce"
            name="bounce"
            type="number"
            value={config.bounce ?? CONFIG_DEFAULT_VALUES.bounce}
            onChange={onChange}
            step={0.1}
            min={0.1}
            disabled={!config.bounce}
          />
          <input
            name="bounce-enabled"
            type="checkbox"
            value="checked"
            onChange={onChange}
            checked={!!config.bounce}
          />
        </div>

        <label htmlFor="mass">Mass:</label>
        <input
          id="mass"
          name="mass"
          type="number"
          value={config.mass ?? CONFIG_DEFAULT_VALUES.mass}
          onChange={onChange}
          step={0.1}
          min={0.1}
        />

        <label htmlFor="friction">Friction:</label>
        <input
          id="friction"
          name="friction"
          type="number"
          value={config.friction ?? CONFIG_DEFAULT_VALUES.friction}
          onChange={onChange}
          min={1}
        />

        <label htmlFor="tension">Tension:</label>
        <input
          id="tension"
          name="tension"
          type="number"
          value={config.tension ?? CONFIG_DEFAULT_VALUES.tension}
          onChange={onChange}
          step={0.1}
          min={0}
        />

        <DigitsButton
          size="small"
          id="force-update"
          name="force-update"
          type="button"
          onClick={onForceUpdate}
          style={{ gridColumn: "1 / span 2", marginTop: "10px" }}
        >
          Force Update
        </DigitsButton>

        <label htmlFor="loop">Loop:</label>
        <input
          name="loop-enabled"
          type="checkbox"
          value="checked"
          onChange={onChangeLoop}
          checked={loop}
        />
      </ConfigEditorGrid>
    </ConfigEditorContainer>
  )
}
