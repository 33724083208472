import { Contact, Employee, Layout, Portal } from "@digits-graphql/frontend/graphql-bearer"
import { QueryRequest } from "src/frontend/components/OS/Applications/Search/QueryBuilder"
import {
  ComponentMap,
  DeleteComponentAnimation,
  PendingDeleteComponent,
  Sidebar,
  SortableComponentConfig,
} from "src/frontend/components/Shared/Layout/types"
import { Experience } from "src/frontend/session/personas"

export enum PortalMode {
  // We're fetching the portal
  Pending = "Pending",

  // We're provisioning the initial portal and layout
  Bootstrap = "Bootstrap",

  // The portal is ready to use
  Ready = "Ready",
}

export enum InviteClientsModalState {
  Initial = "Initial",
  MyClients = "MyClients",
  InviteClients = "InviteClients",
  SendingInvites = "SendingInvites",
  Done = "Done",
}

export interface PortalState {
  portalMode: PortalMode
  portal: Omit<Portal, "kind"> | undefined
  confirmDeleteComponent: PendingDeleteComponent | undefined
  deleteComponentAnimation: DeleteComponentAnimation | undefined
  dropAllowed: boolean
  componentMap: ComponentMap
  layout: Layout
  layoutLoading: boolean
  sidebar: Sidebar
  activeConfig: SortableComponentConfig | null
  activeComponentId: string | null
  dragOverlayContent: JSX.Element | null
  textInFocus: boolean
  dirty: boolean
  saveErrorCount: number
  employeesWithAccess: (Employee | Contact)[]
  invitedEmployees: Employee[]
  employeesLoading: boolean
  showInviteClientsModal: boolean
  initialInviteClientsModalState: InviteClientsModalState
  currentExperience: Experience | undefined
  isPreview: boolean
  query: QueryRequest
  emptySearch: boolean
}

export const PORTAL_CONFIG = {
  maxComponentsPerRow: 4,
  rowWidth: 1080,
}
