import * as React from "react"
import {
  ActionItemFieldsFragment,
  ActionItemThreadFieldsFragment,
  EntityWorkItem,
  Interval,
  WorkItemReviewState,
  WorkItemState,
} from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import { PathGenerator } from "@digits-shared/hooks/usePathGenerator"
import useSession from "@digits-shared/hooks/useSession"
import { getClientTransactionReviewTabArgs } from "src/frontend/components/OS/Applications/ClientTransactionReview/Shared/List/useClientTransactionReviewTab"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import routes from "src/frontend/routes"
import FrontendSession from "src/frontend/session/FrontendSession"

export function useGenerateActionItemPath(item: ActionItemFieldsFragment) {
  const generatePath = useFrontendPathGenerator()
  const { currentLegalEntity, isAffiliatedSession } = useSession<FrontendSession>()
  return React.useMemo(() => {
    const { value } = item
    switch (value.__typename) {
      case "ActionItemBillApproval":
        return generatePath(routes.billDetailsComments, {
          leSlug: currentLegalEntity.slug,
          billId: value.bill.id,
        })
      case "ActionItemPublishedReport":
        return generatePath(routes.reportPackage, {
          leSlug: currentLegalEntity.slug,
          packageId: value.package.id,
        })
      case "ActionItemThread": {
        return generateThreadLink({
          targetEntity: value.targetEntity,
          isAffiliatedSession,
          generatePath,
          leSlug: currentLegalEntity.slug,
          threadId: value.threadId,
        })
      }
      default:
        return ""
    }
  }, [item, generatePath, currentLegalEntity.slug, isAffiliatedSession])
}

function generateThreadLink({
  targetEntity,
  isAffiliatedSession,
  generatePath,
  leSlug,
  threadId,
}: {
  targetEntity: ActionItemThreadFieldsFragment["targetEntity"]
  isAffiliatedSession: boolean
  generatePath: PathGenerator
  leSlug: string
  threadId: string
}) {
  if (targetEntity.__typename !== "EntityWorkItem" && !(targetEntity as { id: string }).id) {
    // if we don't have an id the route generator will throw an error
    return ""
  }
  switch (targetEntity.__typename) {
    case "EntityCategory":
      return generatePath(routes.categoryDetailsComments, {
        leSlug: leSlug,
        categoryId: targetEntity.id,
      })
    case "EntityParty": {
      return generatePath(routes.partyDetailsRedirectForThread, {
        leSlug: leSlug,
        partyId: targetEntity.id,
        threadId,
      })
    }
    case "EntityBill":
      return generatePath(routes.billDetailsComments, {
        leSlug: leSlug,
        billId: targetEntity.id,
      })
    case "EntityReportPackage":
      return generatePath(routes.reportPackage, {
        leSlug: leSlug,
        packageId: targetEntity.id,
        threadId,
      })
    case "EntityLedgerTransaction":
      return generatePath(routes.accountingDetailLedgerTransactionIdComment, {
        leSlug: leSlug,
        ledgerTransactionId: targetEntity.id,
      })
    case "EntityWorkItem": {
      if (isAffiliatedSession) {
        return generateAffiliatedWorkItemRoute({
          targetEntity,
          generatePath,
          leSlug,
        })
      }
      return generateOperatorWorkItemRoute({
        targetEntity,
        generatePath,
        leSlug,
      })
    }
    default:
      return ""
  }
}

function generateAffiliatedWorkItemRoute({
  targetEntity,
  generatePath,
  leSlug,
}: {
  targetEntity: EntityWorkItem
  generatePath: PathGenerator
  leSlug: string
}) {
  const period = dateTimeHelper.periodFromTimestamp(targetEntity.time, Interval.Month)
  const state = [WorkItemState.Completed, WorkItemState.Invalidated].includes(
    targetEntity.workItemState
  )
    ? "Closed"
    : undefined
  const workItemRoute =
    targetEntity.reviewState === WorkItemReviewState.ClientResponded
      ? routes.transactionReviewReplies
      : routes.transactionReviewPending
  return generatePath(workItemRoute, {
    leSlug: leSlug,
    ...dateTimeHelper.intervalOriginFromPeriod(period),
    ...(state && { state }),
    taskId: targetEntity.id,
  })
}

function generateOperatorWorkItemRoute({
  targetEntity,
  generatePath,
  leSlug,
}: {
  targetEntity: EntityWorkItem
  generatePath: PathGenerator
  leSlug: string
}) {
  if (targetEntity.workItemState === WorkItemState.Invalidated) {
    // Invalidated work items do not show up in Action Items, so we have nowhere to send them
    return ""
  }

  return generatePath(routes.clientTransactionReviewItem, {
    leSlug: leSlug,
    workItemId: targetEntity.id,
    ...getClientTransactionReviewTabArgs(targetEntity.workItemState),
  })
}
