import * as React from "react"
import { DigitsLocation } from "@digits-shared/components/Router/DigitsLocation"
import useRouter from "@digits-shared/hooks/useRouter"
import { History } from "history"

export default function useRedirectToApp(
  path: string,
  newState: History.LocationState | undefined = undefined
) {
  const { location, history } = useRouter()
  return React.useCallback(
    (options?: { clearMagicLinkToken?: boolean }) => {
      let redirectPath: string
      const fromLocation: DigitsLocation | undefined = location.state?.from
      if (fromLocation && options?.clearMagicLinkToken) {
        const searchParams = new URLSearchParams(fromLocation.search)
        searchParams.delete("ml")
        const search = searchParams.toString()
        redirectPath = search ? `${fromLocation.pathname}?${search}` : fromLocation.pathname
      } else if (fromLocation) {
        redirectPath = fromLocation.fullPathname
        TrackJS?.console.info("Redirecting to previous location")
      } else {
        redirectPath = path
        TrackJS?.console.info(`Redirecting to ${path}`)
      }

      history.replace(redirectPath, newState)
    },
    [location, history, path, newState]
  )
}
