import * as React from "react"
import { ViewIdentifier, ViewType } from "@digits-graphql/frontend/graphql-bearer"
import useForceUpdate from "@digits-shared/hooks/useForceUpdate"
import useSession from "@digits-shared/hooks/useSession"
import { ViewVersionContext } from "src/frontend/components/Shared/Contexts/ViewVersionContextConst"
import FrontendSession from "src/frontend/session"
import SessionBusinessOrganization from "src/frontend/session/BusinessOrganization"
import SessionLegalEntity from "src/frontend/session/LegalEntity"
import BackgroundTask from "src/shared/components/Background/BackgroundTask"

type ViewMap = Map<string, Map<ViewType, ViewIdentifier>>

export const ViewVersionContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { organizations } = useSession<FrontendSession>()
  const [updateKey, forceUpdate] = useForceUpdate()

  const value = React.useMemo(
    () => ({
      accounting: organizations.reduce(allLegalEntitiesReducer, new Map()),
      updateKey,
      forceUpdate,
    }),
    [organizations, forceUpdate, updateKey]
  )

  return (
    <>
      {/* When idle for 10 mins, update with the latest view versions */}
      <BackgroundTask frequency={1000 * 60 * 10} task={forceUpdate} />
      <ViewVersionContext.Provider value={value}>{children}</ViewVersionContext.Provider>
    </>
  )
}

function allLegalEntitiesReducer(agg: ViewMap, o: SessionBusinessOrganization) {
  o.affiliatedLegalEntities.forEach((le) => legalEntityViewTypeReducer(agg, le))
  o.legalEntities.forEach((le) => legalEntityViewTypeReducer(agg, le))

  return agg
}

function legalEntityViewTypeReducer(agg: ViewMap, le: SessionLegalEntity) {
  le.accountingViewVersions.forEach((viewVersion, viewType) => {
    const viewMap = agg.get(le.id) || new Map()

    viewMap.set(viewType, {
      legalEntityId: le.id,
      viewType,
      viewVersion,
      mutationVersion: le.mutationViewVersions.get(viewType) ?? "",
    })

    agg.set(le.id, viewMap)
  })
}
