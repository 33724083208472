import * as React from "react"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import { SvgAccountantFirm } from "@digits-shared/components/SVGIcons/custom/AccountantFirm.svg"
import { chevronStyles, PointingDirection } from "@digits-shared/components/UI/Elements/Chevron"
import { isDigitsMacApp } from "@digits-shared/helpers/devicesHelper"
import useSession from "@digits-shared/hooks/useSession"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { useNavSidebarContext } from "src/frontend/components/Shared/Contexts/useNavSidebarContext"
import { SidebarDivider } from "src/frontend/components/Shared/NavSidebar/sidebarConstants"
import { SidebarRow } from "src/frontend/components/Shared/NavSidebar/SidebarExpando"
import { SidebarMenu } from "src/frontend/components/Shared/NavSidebar/SidebarMenu"
import FrontendSession from "src/frontend/session"
import { EntityIcon } from "src/shared/components/ObjectEntities/EntityIcon"
import { useCurrentAffiliation } from "src/shared/hooks/useCurrentAffiliation"

/*
  STYLES
*/

const Header = styled.div`
  background: ${colors.translucentWhite10};
  width: 100%;
  padding: 1px 2px 4px;
  margin-bottom: 10px;

  ${isDigitsMacApp &&
  css`
    padding-top: 40px;
  `};
`

const Chevron = styled.div<{
  expanded: boolean
}>`
  ${({ expanded }) =>
    chevronStyles(
      expanded ? PointingDirection.Up : PointingDirection.Down,
      "5px",
      colors.secondary
    )};
`

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  div:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const HeaderRow = styled(SidebarRow)`
  padding: 4px 3px;

  &&::before {
    content: "";
    width: 0;
  }
`

const BusinessIcon = styled(EntityIcon)`
  margin: 0;
  padding: 0;
`

const LabelContainer = styled.div`
  text-align: left;
  position: relative;
`

const PoweredBy = styled.div`
  font-weight: ${fonts.weight.medium};
  font-size: 10px;
  line-height: 14px;
  color: ${colors.secondary70};
  padding-right: 10px;
`

const OrgName = styled.div`
  font-weight: ${fonts.weight.heavy};
  font-size: 16px;
  line-height: 18px;
  color: ${colors.secondary};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  white-space: normal;
  // maintain width to prevent adding lines when sidebar collapses
  min-width: 156px;
`

const BookmarkContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.white};
  width: 48px;
  height: 48px;
  border-radius: 11px;
  overflow: hidden;
  margin-left: -12px;
`

const Logo = styled.img`
  max-width: 48px;
  max-height: 48px;
`

const LogoPlaceholder = styled(SvgAccountantFirm)`
  width: 38px;
  height: 38px;
`

/*
  COMPONENT
*/

export const SidebarBrandedHeader: React.FC = () => {
  const {
    currentLegalEntity,
    currentAffiliatedOrganization,
    currentAffiliation,
    currentOrganization,
  } = useSession<FrontendSession>()
  const legalEntity = currentAffiliation?.entity || currentLegalEntity
  const iconUrl = (currentAffiliatedOrganization || currentOrganization)?.iconUrl
  const { isHeaderMenuOpen, hideHeaderMenu, toggleHeaderMenu, onMouseLeaveHeaderMenu } =
    useNavSidebarContext()

  return (
    <>
      <Header onMouseLeave={onMouseLeaveHeaderMenu}>
        <AffiliationBadge />
      </Header>

      <div onMouseLeave={onMouseLeaveHeaderMenu} css="width: 100%;">
        <SidebarRow
          element={
            <BusinessIcon imageUrl={iconUrl} size={30}>
              {legalEntity.name}
            </BusinessIcon>
          }
          onClick={toggleHeaderMenu}
        >
          <HeaderTitle>
            <div css="flex: 1">{legalEntity.name}</div>
            <Chevron expanded={isHeaderMenuOpen} />
          </HeaderTitle>
        </SidebarRow>
        <SidebarMenu css="top: 75px" isOpen={isHeaderMenuOpen} hideMenu={hideHeaderMenu} />
      </div>
      <SidebarDivider />
    </>
  )
}

const AffiliationBadge: React.FC = () => {
  const currentAccountingFirm = useCurrentAffiliation()

  if (!currentAccountingFirm) {
    return <AffiliationLoading />
  }

  return (
    <HeaderRow element={<Bookmark iconUrl={currentAccountingFirm?.organization.iconUrl} />}>
      <LabelContainer>
        <PoweredBy>Powered by</PoweredBy>
        <OrgName>{currentAccountingFirm?.organization.name}</OrgName>
      </LabelContainer>
    </HeaderRow>
  )
}

const Bookmark: React.FC<{
  iconUrl?: string | null
}> = ({ iconUrl }) => (
  <BookmarkContainer>
    {iconUrl && <Logo src={iconUrl} />}
    {!iconUrl && <LogoPlaceholder />}
  </BookmarkContainer>
)

const AffiliationLoading: React.FC = () => (
  <HeaderRow
    element={
      <BookmarkContainer>
        <LoadingBlock borderRadius="11px" height="48px" width="48px" margin-left="12px" />
      </BookmarkContainer>
    }
  >
    <LabelContainer>
      <PoweredBy>Powered by</PoweredBy>
      <OrgName>
        <LoadingBlock height="15px" width="134px" />
      </OrgName>
    </LabelContainer>
  </HeaderRow>
)
