import { QueryResult } from "@apollo/client"
import { useActionItemsBadgeCountsQuery } from "src/frontend/components/OS/Applications/hooks/useActionItemsBadgeCountsQuery"
import { useBoostBadgeCountsQuery } from "src/frontend/components/OS/Applications/hooks/useBoostBadgeCountsQuery"
import { useConnectionsBadgeCountsQuery } from "src/frontend/components/OS/Applications/hooks/useConnectionsBadgeCountsQuery"
import { useDataSourcesBadgeCountsQuery } from "src/frontend/components/OS/Applications/hooks/useDataSourcesBadgeCountsQuery"
import { useReportsBadgeCountsQuery } from "src/frontend/components/OS/Applications/hooks/useReportsBadgeCountsQuery"
import { useTransactionReviewBadgeCountsQuery } from "src/frontend/components/OS/Applications/hooks/useTransactionReviewBadgeCountsQuery"

export type BadgeCounts = {
  count: number
  legalEntityId: string
}

type BadgeQueryResult = {
  counts?: BadgeCounts[]
  loading: boolean
  refetch?: QueryResult["refetch"]
}

interface NotificationsProps {
  name: string
  description?: string
  iconURL?: string
  notificationIconURL?: string
  generateBadgeCopy?: () => string
  badgeQuery?: (legalEntities: string[], skip?: boolean) => BadgeQueryResult
}

export class Application implements NotificationsProps {
  static DigitsSearch = new Application({
    name: "Search",
    description: "Your business. At your fingertips.",
    iconURL: require("static/images/springboard/search.png"),
  })

  static Fuel = new Application({
    name: "Fuel",
    description: "Your cash. Monitored.",
    iconURL: require("static/images/springboard/fuel.png"),
  })

  static Revenue = new Application({
    name: "Revenue",
    description: "Your revenue. Visualized.",
    iconURL: require("static/images/springboard/cash.png"),
  })

  static SubscriptionRadar = new Application({
    name: "Subscription Radar",
    description: "Your repeat vendors. Tracked.",
    iconURL: require("static/images/springboard/subscriptions.png"),
  })

  static BurnControl = new Application({
    name: "Expenses",
    description: "Your spend. Controlled.",
    iconURL: require("static/images/springboard/burn.png"),
  })

  static DigitsReports = new Application({
    name: "Reports",
    description: "Financial reporting. Reimagined.",
    iconURL: require("static/images/springboard/reports.png"),
    notificationIconURL: require("static/images/springboard/reports_notification.png"),
    generateBadgeCopy: () => "UNPUBLISHED",
    badgeQuery: useReportsBadgeCountsQuery,
  })

  static QualityCheck = new Application({
    name: "Quality Check",
    description: "Save time. Automate your work.",
    iconURL: require("static/images/springboard/boost.png"),
    generateBadgeCopy: () => "NEW BOOSTS",
    badgeQuery: useBoostBadgeCountsQuery,
  })

  static TransactionReview = new Application({
    name: "Transaction Review",
    description: "Fix uncategorized transactions. Faster.",
    iconURL: require("static/images/springboard/transaction_review.png"),
    notificationIconURL: undefined, // TODO: Add this once we have a real icon.
    generateBadgeCopy: () => "ITEMS",
    badgeQuery: useTransactionReviewBadgeCountsQuery,
  })

  // Technically not an application, but needed to properly display the badge count
  static Connections = new Application({
    name: "Connections",
    badgeQuery: useConnectionsBadgeCountsQuery,
  })

  static ActionItems = new Application({
    name: "ActionItems",
    badgeQuery: useActionItemsBadgeCountsQuery,
  })

  // Technically not an application, but needed to properly display the badge count on Settings
  static DataSources = new Application({
    name: "DataSources",
    badgeQuery: useDataSourcesBadgeCountsQuery,
  })

  static all: Application[] = Object.values(Application).filter(
    (application) => application instanceof Application
  )

  name: string
  description?: string
  iconURL?: string
  notificationIconURL?: string
  generateBadgeCopy?: () => string
  badgeQuery?: (legalEntities: string[], skip?: boolean) => BadgeQueryResult

  private constructor({
    name,
    description,
    iconURL,
    notificationIconURL,
    generateBadgeCopy,
    badgeQuery,
  }: NotificationsProps) {
    this.name = name
    this.description = description
    this.iconURL = iconURL
    this.notificationIconURL = notificationIconURL
    this.generateBadgeCopy = generateBadgeCopy
    this.badgeQuery = badgeQuery
  }
}
