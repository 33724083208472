import * as React from "react"
import {
  DocumentFilter,
  DocumentSort,
  DocumentSortBy,
  FeatureKind,
  ListVaultFilesQueryVariables,
  Pagination,
  SortDirection,
} from "@digits-graphql/frontend/graphql-bearer"
import useRouter from "@digits-shared/hooks/useRouter"
import useSession from "@digits-shared/hooks/useSession"
import { makeValidDocumentTypes } from "src/frontend/components/OS/Applications/Vault/VaultBrowser/documentTypes"
import FrontendSession from "src/frontend/session"

export enum VaultQueryParam {
  sort = "sort",
  direction = "direction",
  mime = "mime",
  creatorId = "creatorId",
  documentType = "documentType",
}

export const VAULT_PAGE_LIMIT = 100

export function useVaultQueryParams(documentCollectionId?: string): ListVaultFilesQueryVariables {
  const { currentLegalEntityId: legalEntityId } = useSession<FrontendSession>()
  const { search } = useRouter()

  const filter = React.useMemo((): DocumentFilter => {
    const mimeTypes = search.has(VaultQueryParam.mime)
      ? search.getAll(VaultQueryParam.mime)
      : undefined
    const creatorId = search.get(VaultQueryParam.creatorId) ?? undefined
    const documentTypes = makeValidDocumentTypes(search.get(VaultQueryParam.documentType))

    return {
      featureKinds: [FeatureKind.Title, FeatureKind.DocumentType],
      documentCollectionId,
      creatorId,
      mimeTypes,
      documentTypes,
    }
  }, [documentCollectionId, search])

  const order = React.useMemo((): DocumentSort | undefined => {
    const fieldParam = search.get(VaultQueryParam.sort) as DocumentSortBy | null
    if (!fieldParam) return undefined

    const field = DocumentSortBy[fieldParam]

    const directionParam = search.get(VaultQueryParam.direction) as SortDirection | null
    const direction = directionParam ? SortDirection[directionParam] : undefined

    return {
      field,
      direction,
    }
  }, [search])

  const pagination = React.useMemo((): Pagination => ({ limit: VAULT_PAGE_LIMIT, offset: 0 }), [])

  return {
    legalEntityId,
    filter,
    order,
    pagination,
  }
}
