import * as React from "react"
import { useInvertValues } from "@digits-shared/components/Contexts/InvertValuesContext"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import { unique } from "@digits-shared/helpers/filters"
import objectHelper from "@digits-shared/helpers/objectHelper"
import { ParentSize } from "@visx/responsive"
import { scaleBand, scaleLinear } from "@visx/scale"
import { Bar } from "@visx/shape"
import { ScaleBand, ScaleLinear } from "d3-scale"
import styled from "styled-components"
import { GlowFilter } from "src/frontend/components/OS/Shared/Charts/GlowFilter"
import { StackableBarChartLabelForSingleBar } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/Label/StackableBarChartLabelForSingleBar"
import { StackableBarChartLegend } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/Legend/StackableBarChartLegend"
import { StackableBarChartLegendCount } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/Legend/StackableBarChartLegendCount"
import {
  ChartPeriodsMap,
  STACKABLE_BAR_CHART_ALL_BAR_LABEL_HEIGHT,
  STACKABLE_BAR_CHART_LEGEND_SPACING,
  STACKABLE_BAR_CHART_SINGLE_LABEL_OFFSET,
  STACKABLE_BAR_CHART_X_AXIS_OFFSET,
  STACKABLE_BAR_CHART_X_AXIS_PADDING,
  STACKABLE_BAR_CHART_X_INNER_PADDING,
  stackableBarChartGroupByPeriods,
  stackableBarChartLargestValue,
  stackableBarChartXData,
  stackableBarChartYData,
  stackableBarChartYMaxData,
  StackableBarData,
} from "src/frontend/components/OS/Shared/Charts/StackableBarChart/Shared"
import { StackableBar } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/StackableBar"
import { StackableBarChartAxis } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/StackableBarChartAxis"
import {
  StackableBarChartLabelType,
  StackableBarChartLegendType,
  useStackableBarChartContext,
} from "src/frontend/components/OS/Shared/Charts/StackableBarChart/StackableBarChartContext"

const CHART_X_PADDING = 15
const CHART_Y_PADDING = 10

/*
 STYLES
*/

const StackedBarChart = styled.div`
  position: relative;
  text-align: center;
  height: 100%;

  .month-axis {
    text-transform: uppercase;
    cursor: pointer;
  }
`

const LoadingBars = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 15px;
`

const EmptyViewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

/*
 COMPONENTS
*/

interface ChildProps {
  graphWidth: number
  graphHeight: number
  children?: React.ReactNode
}

export const StackableBarChart: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { label, legend } = useStackableBarChartContext()
  const labelOffset =
    label !== StackableBarChartLabelType.SingleBar ? 0 : STACKABLE_BAR_CHART_SINGLE_LABEL_OFFSET
  const legendOffset =
    legend === StackableBarChartLegendType.None ? 0 : STACKABLE_BAR_CHART_LEGEND_SPACING

  return (
    <ParentSize>
      {({ width, height }) => (
        <StackedBarChart>
          {label === StackableBarChartLabelType.SingleBar && (
            <StackableBarChartLabelForSingleBar>{children}</StackableBarChartLabelForSingleBar>
          )}
          <StackableBarChartContent
            graphWidth={width}
            graphHeight={height - labelOffset - legendOffset}
          >
            {children}
          </StackableBarChartContent>
          <StackableBarChartLegend />
        </StackedBarChart>
      )}
    </ParentSize>
  )
}

const StackableBarChartContent: React.FC<ChildProps> = ({ graphWidth, graphHeight, children }) => {
  const { glowFilterId, chartData, loading, emptyNode } = useStackableBarChartContext()

  if (loading) return <StackableBarChartLoading graphWidth={graphWidth} graphHeight={graphHeight} />

  if (!chartData.length) {
    return emptyNode ? <EmptyViewContainer>{emptyNode}</EmptyViewContainer> : null
  }

  return (
    <svg width={graphWidth} height={Math.max(graphHeight, 0)}>
      <GlowFilter id={glowFilterId} standardDeviation={3} />
      {children}
      <StackableBarChartElements graphWidth={graphWidth} graphHeight={graphHeight} />
    </svg>
  )
}

const StackableBarChartElements: React.FC<ChildProps> = ({ graphWidth, graphHeight }) => {
  const invertValues = useInvertValues()
  const { chartData, periodData, label } = useStackableBarChartContext()

  const negatives = React.useMemo(
    () => chartData.filter((d) => stackableBarChartYData(invertValues, d) < 0),
    [chartData, invertValues]
  )

  const negativePeriods = React.useMemo(
    () => stackableBarChartGroupByPeriods(negatives),
    [negatives]
  )

  const hasNegatives = negatives.length > 0
  const labelHeight =
    label === StackableBarChartLabelType.AllBarPeriods
      ? hasNegatives
        ? STACKABLE_BAR_CHART_ALL_BAR_LABEL_HEIGHT * 2
        : STACKABLE_BAR_CHART_ALL_BAR_LABEL_HEIGHT
      : 0

  const yMax = graphHeight - STACKABLE_BAR_CHART_X_AXIS_OFFSET - labelHeight

  const yMaxDomain = React.useMemo(
    () => stackableBarChartYMaxData(invertValues, periodData),
    [invertValues, periodData]
  )

  // scale is dependent upon all abs values
  const yScale: ScaleLinear<number, number> = scaleLinear({
    range: [yMax, CHART_Y_PADDING],
    round: true,
    domain: [0, yMaxDomain],
  })

  const xScaleDomain = React.useMemo(
    () => chartData.map(stackableBarChartXData).filter(unique),
    [chartData]
  )

  const xScale = scaleBand({
    range: [CHART_X_PADDING, graphWidth - CHART_X_PADDING],
    round: true,
    domain: xScaleDomain,
    paddingInner: STACKABLE_BAR_CHART_X_INNER_PADDING,
  })

  // largest of accumulated negative values across periods
  const maxH = React.useMemo(
    () => stackableBarChartLargestValue(invertValues, negativePeriods, yScale, yMax),
    [invertValues, negativePeriods, yScale, yMax]
  )

  const negativeLabelSpacing =
    label === StackableBarChartLabelType.AllBarPeriods
      ? STACKABLE_BAR_CHART_ALL_BAR_LABEL_HEIGHT
      : 0
  const negativeHeightMax = hasNegatives ? maxH + negativeLabelSpacing : 0
  const negativesTop = graphHeight - negativeHeightMax
  const axisTop =
    negativesTop - STACKABLE_BAR_CHART_X_AXIS_OFFSET - STACKABLE_BAR_CHART_X_AXIS_PADDING * 2

  return (
    <>
      {negatives.map((barData, index) => (
        <StackableBar
          graphWidth={graphWidth}
          graphHeight={graphHeight}
          dataSet={negatives}
          key={index}
          xScale={xScale}
          yScale={yScale}
          periods={negativePeriods}
          yMax={yMax}
          yOffset={negativesTop}
          barData={barData}
          index={index}
        />
      ))}
      <PositiveStackableBars
        graphWidth={graphWidth}
        graphHeight={graphHeight}
        dataSet={chartData}
        xScale={xScale}
        yScale={yScale}
        yMax={yMax}
        yOffset={axisTop}
      />
      <StackableBarChartAxis
        xScale={xScale}
        top={axisTop}
        width={graphWidth}
        height={graphHeight}
      />
    </>
  )
}

const PositiveStackableBars: React.FC<
  ChildProps & {
    dataSet: StackableBarData[]
    xScale: ScaleBand<number>
    yScale: ScaleLinear<number, number>
    yMax: number
    yOffset: number
  }
> = ({ graphWidth, graphHeight, dataSet, xScale, yScale, yMax, yOffset }) => {
  const invertValues = useInvertValues()

  const positives = React.useMemo(
    () => dataSet.filter((d) => stackableBarChartYData(invertValues, d) >= 0),
    [dataSet, invertValues]
  )
  const positivePeriods = React.useMemo(
    () => stackableBarChartGroupByPeriods(positives),
    [positives]
  )

  const emptyPeriods = React.useMemo(
    () =>
      objectHelper
        .keysOf(positivePeriods)
        .filter((timestamp) =>
          positivePeriods[timestamp]?.every((b) => stackableBarChartYData(invertValues, b) === 0)
        ),
    [invertValues, positivePeriods]
  )

  return (
    <>
      {positives.map((barData, index) => (
        <StackableBar
          key={index}
          graphWidth={graphWidth}
          graphHeight={graphHeight}
          dataSet={positives}
          xScale={xScale}
          yScale={yScale}
          periods={positivePeriods}
          yMax={yMax}
          yOffset={yOffset}
          barData={barData}
          index={index}
        />
      ))}
      {emptyPeriods.map((timestamp, index) => (
        <StackableEmptyPeriodBar
          key={`$empty_${index}`}
          graphWidth={graphWidth}
          graphHeight={graphHeight}
          xScale={xScale}
          yScale={yScale}
          yMax={yMax}
          yOffset={yOffset}
          periods={positivePeriods}
          timestamp={timestamp}
          index={index}
        />
      ))}
    </>
  )
}

const StackableEmptyPeriodBar: React.FC<{
  graphWidth: number
  graphHeight: number
  xScale: ScaleBand<number>
  yScale: ScaleLinear<number, number>
  periods: ChartPeriodsMap
  timestamp: number
  yMax: number
  yOffset: number
  index: number
}> = ({ xScale, periods, timestamp }) => {
  const { onBarClick, onMouseOut, onMouseOver } = useStackableBarChartContext()

  const x = xScale(+timestamp) || 0
  const width = xScale.bandwidth()
  const periodBarData = periods[timestamp] || []
  const periodBarIndex = Object.values(periods).indexOf(periodBarData)

  const onClick = React.useCallback(
    (event: React.MouseEvent) => onBarClick?.(undefined, periodBarIndex, event),
    [onBarClick, periodBarIndex]
  )

  const onBarOver = React.useCallback(
    (event: React.MouseEvent) => {
      onMouseOver?.(undefined, periodBarIndex)
    },
    [onMouseOver, periodBarIndex]
  )

  const onBarOut = React.useCallback(
    (event: React.MouseEvent) => {
      onMouseOut?.(undefined)
    },
    [onMouseOut]
  )

  return (
    <Bar
      css="cursor: pointer;"
      x={x - STACKABLE_BAR_CHART_X_INNER_PADDING}
      y={0}
      fill="transparent"
      width={width}
      height="100%"
      onClick={onClick}
      onMouseMove={onBarOver}
      onMouseLeave={onBarOut}
    />
  )
}

const StackableBarChartLoading: React.FC<ChildProps> = ({ graphWidth, graphHeight }) => {
  const { label, legend, intervalOrigin } = useStackableBarChartContext()

  const yMax = Math.max(graphHeight - STACKABLE_BAR_CHART_X_AXIS_OFFSET, 10)

  const numberOfBars = intervalOrigin.intervalCount || 12
  const fakeBars = React.useMemo(
    () => Array.from({ length: numberOfBars }).map(() => Math.random() * Math.floor(yMax)),
    [numberOfBars, yMax]
  )

  const xScale = scaleBand({
    range: [0, Math.max(graphWidth, 10)],
    round: true,
    domain: fakeBars.map((_: number, index: number) => index),
    paddingInner: STACKABLE_BAR_CHART_X_INNER_PADDING,
  })

  const loadingBars = fakeBars.map((height, index) => (
    <LoadingBlock
      key={`loading-${index}`}
      width={`${xScale.bandwidth()}px`}
      height={`${height}px`}
      margin="12px auto 0"
    />
  ))

  return (
    <>
      {!label && <StackableBarChartLabelForSingleBar />}
      <LoadingBars>{loadingBars}</LoadingBars>
      {!legend && <StackableBarChartLegendCount />}
    </>
  )
}
