import React from "react"
import { Link, matchPath } from "react-router-dom"
import { DigitsRoute } from "@digits-shared/components/Router/DigitsRoute"
import { svgIconStyles, svgPathStyles } from "@digits-shared/components/SVG/svgIconStyles"
import { isDigitsMacApp } from "@digits-shared/helpers/devicesHelper"
import { defined } from "@digits-shared/helpers/filters"
import objectHelper from "@digits-shared/helpers/objectHelper"
import useRouter from "@digits-shared/hooks/useRouter"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { useNavSidebarContext } from "src/frontend/components/Shared/Contexts/useNavSidebarContext"
import { MENU_ANIMATION_DURATION_MS } from "src/frontend/components/Shared/NavSidebar/constants"
import {
  ACTIVE_CLASSNAME,
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_SIDE_MARGIN,
  SIDEBAR_WIDTH,
} from "src/frontend/components/Shared/NavSidebar/sidebarConstants"
import { useFrontendPathGenerator } from "src/frontend/hooks/useFrontendPathGenerator"
import zIndexes from "src/shared/config/zIndexes"

/*
  STYLES
*/

export const SidebarContainer = styled.div<{
  isHeaderMenuOpen: boolean
  isNavPinned: boolean
}>`
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  z-index: ${zIndexes.stickyNavMax};
  margin: 0 ${SIDEBAR_SIDE_MARGIN}px 0 0;
  padding: 8px 0 32px;
  display: flex;
  flex-direction: column;
  background: ${colors.secondary05};
  height: 100%;
  overflow-y: ${({ isHeaderMenuOpen }) => (isHeaderMenuOpen ? "hidden" : "auto")};

  transition:
    width 350ms
      ${({ isHeaderMenuOpen, isNavPinned }) =>
        /* Adds an initial delay to the width animation if the menu is open, so it can close first */
        isHeaderMenuOpen || isNavPinned ? "" : `${MENU_ANIMATION_DURATION_MS}ms`}
      ease-in-out,
    filter 350ms ease-in-out;
  width: ${({ isHeaderMenuOpen, isNavPinned }) =>
    isHeaderMenuOpen || isNavPinned ? SIDEBAR_WIDTH : SIDEBAR_COLLAPSED_WIDTH}px;

  @media only screen and (min-width: 1550px) {
    width: ${SIDEBAR_WIDTH}px;
  }

  &:hover {
    width: ${SIDEBAR_WIDTH}px;

    @media only screen and (max-width: 1550px) {
      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
    }
  }

  ${isDigitsMacApp &&
  css`
    margin-left: 0;
  `}
`

const RowContainerStyles = ({
  disabled,
  $isSolidIcon,
}: {
  disabled: boolean
  $isSolidIcon?: boolean
}) => css`
  display: flex;
  align-items: center;
  gap: 10px;
  width: calc(100% - 24px);
  position: relative;
  white-space: nowrap;
  margin: 0 12px;
  padding: 10px 12px;
  cursor: pointer;
  color: ${colors.secondary80};
  border-radius: 8px;

  ${disabled &&
  css`
    pointer-events: none;
  `};

  &.${ACTIVE_CLASSNAME} {
    color: ${colors.primary};
    background: ${colors.white};

    &:hover {
      background: ${colors.white};
    }

    svg {
      ${$isSolidIcon ? svgIconStyles(colors.primary) : svgPathStyles(colors.primary, 1.5)};
    }
  }

  &:hover {
    background: ${colors.translucentSecondary05};
  }
`

const LinkRowContainer = styled(Link)<{ disabled: boolean; $isSolidIcon?: boolean }>`
  ${RowContainerStyles}
`

const ButtonRowContainer = styled.button<{ disabled: boolean; $isSolidIcon?: boolean }>`
  ${RowContainerStyles};
  background: transparent;
  border: none;
  ${fonts.scale.detail};
  font-weight: ${fonts.weight.heavy};
  text-align: left;
`

const LeftColumn = styled.div``

export const RightColumn = styled.div<{ isNavPinned?: boolean }>`
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  opacity: 0;
  transition: opacity 300ms;

  ${SidebarContainer} &,
  ${SidebarContainer}:hover & {
    opacity: 1;
  }

  @media only screen and (max-width: 1550px) {
    .digits-wordmark {
      opacity: 0;
    }

    ${SidebarContainer}:hover & {
      .digits-wordmark {
        opacity: 1;
      }
    }

    ${({ isNavPinned }) =>
      isNavPinned &&
      css`
        opacity: 1;

        .digits-wordmark {
          opacity: 1;
        }
      `}
  }
`

export const Badge = styled.div<{ $isError: boolean }>`
  display: inline-block;
  background: ${({ $isError }) => ($isError ? colors.error : "#00ffbe")};
  font-weight: ${fonts.weight.heavy};
  line-height: 12px;
  color: ${({ $isError }) => ($isError ? colors.white : colors.secondary)};
  padding: 2px 8px;
  border-radius: 8px;

  position: absolute;
  left: 28px;
  bottom: 5px;
  font-size: 8px;
  margin: 0;
`

const DotBadge = styled(Badge)`
  bottom: 7px;
  padding: 0;
  width: 12px;
  height: 12px;
`

/*
  INTERFACES
*/

type IconAdornment = { Icon: React.ComponentType }
type ElementAdornment = { element: JSX.Element }
type IconOrElement = IconAdornment | ElementAdornment
type SidebarRowSharedProps = IconOrElement & {
  className?: string
  badge?: number | boolean
  badgeType?: "info" | "error"
  isSolidIcon?: boolean
}
type SidebarLinkRowProps = SidebarRowSharedProps & {
  route?: DigitsRoute
  activeRoutes?: DigitsRoute[]
  params?: Record<string, string>
  isExact?: boolean
  onClick?: () => void
}
type SidebarButtonRowProps = SidebarRowSharedProps & {
  onClick: () => void
  active?: boolean
}
type RowType = { rowType?: "link" | "button" }
type SidebarRowProps = RowType & (SidebarLinkRowProps | SidebarButtonRowProps)

/*
  COMPONENT
*/

/**
 * A row in the sidebar that can be a {@link Link} or a button, distinguished by the {@link RowType} (`rowType` prop).
 *
 * If `rowType` is `"link"` (or falsy), it will navigate to the generated path when clicked.
 *
 * If `rowType` is `"button"`, it will call the provided `onClick` prop when clicked.
 *
 * {@link SidebarButtonRow} will be styled "active" if the `active` prop is truthy.
 *
 * {@link SidebarLinkRow} will be styled "active" if the current route matches the provided `route` or `activeRoutes` props.
 */
export const SidebarRow: React.FC<React.PropsWithChildren<SidebarRowProps>> = (props) => {
  const { className, children, badge, isSolidIcon, badgeType = "info" } = props
  if (isButtonRow(props)) {
    const { onClick, active } = props
    return (
      <SidebarButtonRow
        // shared props
        className={className}
        isSolidIcon={isSolidIcon}
        badge={badge}
        badgeType={badgeType}
        onClick={onClick}
        children={children}
        Icon={(props as IconAdornment).Icon}
        element={(props as ElementAdornment).element}
        // button-specific props
        active={active}
      />
    )
  }

  const { route, activeRoutes, params, isExact, onClick } = props
  return (
    <SidebarLinkRow
      // shared props
      className={className}
      isSolidIcon={isSolidIcon}
      badge={badge}
      badgeType={badgeType}
      onClick={onClick}
      children={children}
      Icon={(props as IconAdornment).Icon}
      element={(props as ElementAdornment).element}
      // link-specific props
      route={route}
      activeRoutes={activeRoutes}
      params={params}
      isExact={isExact}
    />
  )
}

export const SidebarLinkRow: React.FC<React.PropsWithChildren<SidebarLinkRowProps>> = (props) => {
  const { className, children, badge, onClick, isSolidIcon, badgeType = "info" } = props
  const { active, to } = useLink(props)
  const { isActionItemsSidebarOpen, hideActionItemsSidebar } = useNavSidebarContext()

  const handleNavClick = React.useCallback(() => {
    hideActionItemsSidebar()
    if (onClick) {
      onClick()
    }
  }, [onClick, hideActionItemsSidebar])

  const showActive = active && !isActionItemsSidebarOpen
  const classNames = [className, showActive ? ACTIVE_CLASSNAME : undefined]
    .filter(defined)
    .join(" ")

  const disabled = !to && !onClick
  return (
    <LinkRowContainer
      to={to || "#"}
      className={classNames}
      disabled={disabled}
      onClick={handleNavClick}
      $isSolidIcon={isSolidIcon}
    >
      <SidebarRowContent
        badge={badge}
        badgeType={badgeType}
        children={children}
        element={(props as ElementAdornment).element}
        Icon={(props as IconAdornment).Icon}
      />
    </LinkRowContainer>
  )
}

export const SidebarButtonRow: React.FC<React.PropsWithChildren<SidebarButtonRowProps>> = (
  props
) => {
  const { className, children, active, badge, onClick, isSolidIcon, badgeType = "info" } = props

  const classNames = [className, active ? ACTIVE_CLASSNAME : undefined].filter(defined).join(" ")

  return (
    <ButtonRowContainer
      className={classNames}
      disabled={!onClick}
      onClick={onClick}
      $isSolidIcon={isSolidIcon}
    >
      <SidebarRowContent
        badge={badge}
        badgeType={badgeType}
        children={children}
        element={(props as ElementAdornment).element}
        Icon={(props as IconAdornment).Icon}
      />
    </ButtonRowContainer>
  )
}

const SidebarRowContent: React.FC<
  React.PropsWithChildren<
    Pick<SidebarRowSharedProps, "badge" | "badgeType"> & Partial<IconOrElement>
  >
> = (props) => {
  const { badge, badgeType, children } = props
  const { isNavPinned } = useNavSidebarContext()

  return (
    <>
      <LeftColumn>
        {icon(props as IconOrElement)}
        {!!badge &&
          (badge === true ? (
            <DotBadge $isError={badgeType === "error"} />
          ) : (
            <Badge $isError={badgeType === "error"}>{badge}</Badge>
          ))}
      </LeftColumn>
      <RightColumn isNavPinned={isNavPinned}>{children}</RightColumn>
    </>
  )
}

function useLink({ route, activeRoutes, params, isExact }: SidebarLinkRowProps) {
  const generatePath = useFrontendPathGenerator()
  const { location } = useRouter()
  const fallbackActiveRoutes = route ? [route] : []

  const active = (activeRoutes ?? fallbackActiveRoutes)?.some((r) => {
    const match = matchPath(location.pathname, r.parameterizedPath)
    return match && isExact
      ? match.isExact && (!params || objectHelper.shallowEqual(params, match.params))
      : !!match
  })

  const to = route ? generatePath(route, params) : ""
  return { active, to }
}

function icon(props: IconOrElement) {
  if (isIcon(props)) {
    return <props.Icon />
  }
  return props.element
}

function isIcon(props: IconOrElement): props is IconAdornment {
  return (props as IconAdornment).Icon !== undefined
}

function isButtonRow(props: SidebarRowProps): props is SidebarButtonRowProps & RowType {
  return props.rowType === "button"
}
