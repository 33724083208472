import * as React from "react"
import {
  LocationChartConfig,
  useReadLocationChartComponentDataQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import useConstant from "@digits-shared/hooks/useConstant"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import { useLocationChartLiveData } from "src/frontend/components/Shared/Layout/Components/Locations/useLocationChartLiveData"
import { LocationChartResponse } from "src/frontend/types"

/**
 * Must be used within a LayoutContext.
 */
export function useLocationChartComponentData(
  config: LocationChartConfig,
  layoutId: string,
  dataId: string | undefined | null
): LocationChartResponse {
  const viewId = useViewVersion()

  const { data, loading } = useReadLocationChartComponentDataQuery({
    variables: {
      dataId: dataId ?? "",
      layoutId,
      viewId,
    },
    skip: !dataId,
  })

  const initialDataId = useConstant(dataId)
  // Skip loading live data if this component received a dataId at creation time.
  // This means the component was loaded from archive and should only ever show
  // archived data.
  const skipLiveData = !!dataId && dataId === initialDataId

  const { data: liveData, loading: liveLoading } = useLocationChartLiveData(config, skipLiveData)

  return React.useMemo(() => {
    const componentData = data?.readComponentData.data

    // Data read from the archive takes precedence.
    // Resolves the component data union types to the portions we care about
    if (
      componentData?.__typename === "LocationSummary" &&
      componentData.total?.time &&
      componentData.location
    ) {
      const {
        total,
        expensesChange,
        otherExpensesChange,
        incomeChange,
        otherIncomeChange,
        cogsChange,
        location,
      } = componentData
      return {
        data: {
          total: total.time.map(({ summary }) => summary),
          expensesChange: expensesChange.time.map(({ summary }) => summary),
          otherExpensesChange: otherExpensesChange.time.map(({ summary }) => summary),
          incomeChange: incomeChange.time.map(({ summary }) => summary),
          otherIncomeChange: otherIncomeChange.time.map(({ summary }) => summary),
          cogsChange: cogsChange.time.map(({ summary }) => summary),
          location,
        },
        loading,
      }
    }

    // Fall back to live data if we have fetched it.
    // This is necessary to keep data from disappearing mid-drag while we archive the real component data.
    if (liveData && !liveLoading) {
      return { data: liveData, loading: liveLoading }
    }

    return { data: undefined, loading }
  }, [data?.readComponentData.data, liveData, liveLoading, loading])
}
