import * as React from "react"
import {
  DocumentsSummary,
  DocumentStatus,
  ListVaultFilesQuery,
  useListVaultFilesQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import fileHelper from "@digits-shared/helpers/fileHelper"
import stringHelper from "@digits-shared/helpers/stringHelper"
import userHelper from "@digits-shared/helpers/userHelper"
import useRouter from "@digits-shared/hooks/useRouter"
import { VaultFile } from "src/frontend/components/OS/Applications/Vault/types"
import { useCanListVault } from "src/frontend/components/OS/Applications/Vault/useCanListVault"
import { useVaultQueryParams } from "src/frontend/components/OS/Applications/Vault/useVaultQueryParams"

const REFRESH_TIME = 3.5 * 1000 // 3.5s
const POLL_TIME = REFRESH_TIME * 60 // 3.5min

// Hook used to filter results based on search query input
export function useVaultFilteredList(documentCollectionId?: string) {
  const skip = !useCanListVault()
  const variables = useVaultQueryParams(documentCollectionId)
  const { data, loading, startPolling, stopPolling, fetchMore } = useListVaultFilesQuery({
    variables,
    pollInterval: POLL_TIME,
    fetchPolicy: "cache-and-network",
    skip,
  })

  const { filteredFiles, filteredSummary } = useFilteredResults(data)

  const summary = data?.summarizeDocuments
  const files = data?.listDocuments

  // If there are files being processed (no thumbnail yet), poll every 3.5s, otherwise every 3.5min to refresh tokens
  React.useEffect(() => {
    const pollTime = files?.some((f) =>
      [DocumentStatus.New, DocumentStatus.Processing].includes(f.status)
    )
      ? REFRESH_TIME
      : POLL_TIME

    startPolling(pollTime)
    return stopPolling
  }, [files, startPolling, stopPolling])

  return React.useMemo(() => {
    const hasFilteredResults =
      filteredFiles?.length ||
      !!variables.filter.mimeTypes?.length ||
      !!variables.filter.documentTypes?.length ||
      !!variables.filter.creatorId
    if (filteredFiles) {
      return {
        files: filteredFiles,
        summary: filteredSummary,
        loading,
        hasFilteredResults,
        fetchMore,
      }
    }

    return { files, loading, summary, hasFilteredResults, fetchMore }
  }, [
    fetchMore,
    files,
    filteredFiles,
    filteredSummary,
    loading,
    summary,
    variables.filter.creatorId,
    variables.filter.mimeTypes?.length,
    variables.filter.documentTypes?.length,
  ])
}

function useFilteredResults(data: ListVaultFilesQuery | undefined) {
  const { search } = useRouter()
  const query = search.get("query")

  const filteredFiles = React.useMemo((): VaultFile[] | undefined => {
    const files = data?.listDocuments
    if (!files || !query) return undefined

    const regexObj = new RegExp(stringHelper.escapeRegExp(query), "gi")
    return files.filter(
      (f) =>
        regexObj.test(f.title || "") ||
        regexObj.test(f.fileName || "") ||
        regexObj.test(f.mimeType || "") ||
        regexObj.test(fileHelper.fileTypeDisplayName(f.mimeType || "")) ||
        regexObj.test(f.creator ? userHelper.displayName(f.creator) : "")
    )
  }, [data?.listDocuments, query])

  const filteredSummary = React.useMemo((): DocumentsSummary | undefined => {
    const summary = data?.summarizeDocuments
    if (!summary || !filteredFiles) return undefined

    return { count: filteredFiles.length }
  }, [data?.summarizeDocuments, filteredFiles])

  return { filteredFiles, filteredSummary }
}
