import * as React from "react"
import {
  LayoutComponentType,
  TopTransactionsConfig,
  useReadTopTransactionsLiveDataQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"

/**
 * Queries for live data fitting the TopTransactions type.
 *
 * Must be used within a LayoutContext.
 */
export function useTopTransactionsLiveData(
  config: TopTransactionsConfig,
  componentType: LayoutComponentType
) {
  const viewId = useViewVersion()

  const { data, loading } = useReadTopTransactionsLiveDataQuery({
    variables: {
      config: {
        type: componentType,
        topTransactions: config,
      },
      viewId,
    },
    skip: !viewId?.viewVersion,
  })

  return React.useMemo(() => {
    const componentData = data?.liveComponentData

    // Resolves the component data union type to the portion we care about
    if (componentData?.__typename === "TopTransactions") {
      return { data: componentData, loading }
    }

    return { data: undefined, loading }
  }, [data?.liveComponentData, loading])
}
