import * as React from "react"
import { useListActionItemsAndBadgeCountQuery } from "@digits-graphql/frontend/graphql-bearer"
import styled from "styled-components"
import { useActionItemsBadgeCountsQueryVariables } from "src/frontend/components/OS/Applications/hooks/useActionItemsBadgeCountsQuery"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import { ActionItemsEmptyState } from "src/frontend/components/Shared/NavSidebar/ActionItems/EmptyState"
import { LoadingRows } from "src/frontend/components/Shared/NavSidebar/ActionItems/LoadingRows"
import { Row } from "src/frontend/components/Shared/NavSidebar/ActionItems/Row/Row"
import { useActionItemFilter } from "src/frontend/components/Shared/NavSidebar/ActionItems/useActionItemFilter"
import { GroupView } from "src/frontend/components/Shared/NavSidebar/ActionItems/useGroupView"

/*
  STYLES
*/

const Rows = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
  overflow-y: auto;
  padding: 2px 0;
`

/*
  COMPONENTS
*/

export const GroupedActionItemsList: React.FC<{
  groupView: GroupView
  hideSidebar: () => void
}> = ({ groupView, hideSidebar }) => {
  const { data, loading: actionItemsLoading } = useActionItemsQuery(groupView)
  if (actionItemsLoading && !data) {
    return (
      <Rows>
        <LoadingRows />
      </Rows>
    )
  }
  if (!data?.listActionItems.length) {
    return <ActionItemsEmptyState groupView={groupView} />
  }
  return (
    <Rows>
      {data.listActionItems.map((actionItem) => (
        <Row key={actionItem.id} item={actionItem} hideSidebar={hideSidebar} />
      ))}
    </Rows>
  )
}

function useActionItemsQuery(groupView: GroupView) {
  const viewKey = useViewVersion()
  const { filter: badgeCountFilter, ...badgeCountVariables } =
    useActionItemsBadgeCountsQueryVariables(viewKey.legalEntityId)
  const listFilter = useActionItemFilter(groupView)
  return useListActionItemsAndBadgeCountQuery({
    variables: {
      ...badgeCountVariables,
      badgeCountFilter,
      listFilter,
      pagination: {
        limit: groupView === "Open" ? 40 : 100,
        offset: 0,
      },
      viewKey,
    },
    fetchPolicy: "cache-and-network",
  })
}
