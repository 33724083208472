import * as React from "react"
import { Link } from "react-router-dom"
import {
  AccountNumbersDisplay,
  MoneyFlow,
  StatementRow,
  StatementRowSummary,
  StatementRowTitle,
} from "@digits-graphql/frontend/graphql-bearer"
import moneyFlowHelper from "@digits-shared/helpers/moneyFlowHelper"
import { themedValue } from "@digits-shared/themes"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import styled, { css } from "styled-components"
import { useReportOptionsContext } from "src/frontend/components/Shared/Layout/Components/Statements/ReportOptionsContext"
import useReportPackageContext from "src/frontend/components/Shared/Reports/Packages/Viewer/ReportPackageContext"
import { DetailsPopOver } from "src/frontend/components/Shared/Reports/Report/Components/Shared/DetailsPopOver/DetailsPopOver"
import {
  CollapsedRow,
  CollapsibleRow,
} from "src/frontend/components/Shared/Reports/Report/Components/Statements/CollapsibleRow"
import { RowSelectionContext } from "src/frontend/components/Shared/Reports/Report/Components/Statements/RowSelectionContext"
import { useRowSelectionHandlers } from "src/frontend/components/Shared/Reports/Report/Components/Statements/rowSelectionHandlers"
import {
  RowStyled,
  SectionSummaryTitle,
  StatementSize,
  Title,
  TotalCell,
} from "src/frontend/components/Shared/Reports/Report/Components/Statements/shared"
import {
  PinnedCell,
  SelectedValue,
} from "src/frontend/components/Shared/Reports/Report/Components/Statements/StatementContext"
import {
  RowDeltas,
  TotalsOverTime,
} from "src/frontend/components/Shared/Reports/Report/Components/Statements/StatementRowDeltas"
import { StatementTrendLine } from "src/frontend/components/Shared/Reports/Report/Components/Statements/StatementTrendline"
import {
  extractAccountDisplayNumber,
  RowDetails,
} from "src/frontend/components/Shared/Reports/Report/Components/Statements/toDetailsData"
import { useRowDetailsLinks } from "src/frontend/components/Shared/Reports/Report/Components/Statements/useRowDetailsLinks"
import { useStatementCellEvents } from "src/frontend/components/Shared/Reports/Report/Components/Statements/useStatementCellEvents"
import { useReportCurrencyOptions } from "src/frontend/components/Shared/Reports/Report/hooks/useReportDocumentOptions"
import { useIsSectionCollapsed } from "src/frontend/components/Shared/Reports/Report/Options/CollapsingSections"
import WithComments from "src/frontend/components/Shared/Reports/ReportComments/WithComments"

/*
  STYLES
*/

const borderColor = themedValue({
  print: colors.theme.light.border,
  light: colors.translucentSecondary10,
  dark: colors.theme.dark.border,
})

const BORDER_STYLE = css`
  border-top: 1px solid ${borderColor};
  padding-top: 3px;

  .popover-active & {
    border-top-color: transparent;
  }
`

const sectionTitleColor = themedValue({
  print: colors.black,
  light: colors.secondary,
  dark: colors.white,
})

const SectionTitle = styled.div`
  grid-column: span calc(var(--statement-total-columns) + 1); // Safari
  color: ${sectionTitleColor};
  font-weight: ${fonts.weight.heavy};
  text-transform: uppercase;
  margin: 10px 0 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const SectionSummaryText = styled.div`
  text-transform: capitalize;
`

const CondensedSectionSummaryText = styled.div`
  margin-bottom: 5px;
  text-align: right;
`

const CondensedSectionSummaryContainer = styled.div`
  margin-bottom: 5px;
  color: ${sectionTitleColor};
  font-weight: ${fonts.weight.medium};
  display: flex;
  grid-column: span 2;
`

const CondensedSectionSummaryTitle = styled(SectionSummaryTitle)`
  text-transform: unset;
`

const ParentTitle = styled.div<{ $collapsed: boolean; disabled?: boolean }>`
  ${({ $collapsed }) =>
    !$collapsed &&
    css`
      margin-bottom: 5px;
    `};

  &[disabled] {
    pointer-events: none;
  }
  &:not([disabled]):hover {
    color: ${colors.primary};
  }

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;

  & > ${Title} {
    flex: 1;
  }
`

const StyledDisplayNumber = styled.span`
  color: ${colors.translucentSecondary60};
`

const ParentCategorySpacing = styled.div`
  /* Fill the whole parent grid and define an equivalent one for the child. */
  display: grid;
  grid-column: span calc(var(--statement-total-columns) + 1);
`

/*
  INTERFACES
*/

interface SectionTitleProps {
  id: string
  depth: number
  title: StatementRowTitle
  details?: RowDetails
}

interface TitleProps {
  id: string
  depth: number
  title: StatementRowTitle
  details?: RowDetails
}

interface SummaryProps {
  id: string
  depth: number
  summary: StatementRowSummary
  details?: RowDetails
}

interface SectionSummaryProps {
  id: string
  depth: number
  summary: StatementRowSummary
  details?: RowDetails
}

/*
  COMPONENTS
*/

export const StatementRowComponent: React.FC<{
  row: StatementRow
  collapsed: boolean
  size?: StatementSize
  details: RowDetails | undefined
}> = ({ row, collapsed, size, details }) => {
  const [selectedValue, setSelectedValue] = React.useState<SelectedValue>()
  const [pinnedCell, setPinnedCell] = React.useState<PinnedCell | undefined>()

  const selectionContext = React.useMemo(
    () => ({
      pinnedCell,
      setPinnedCell,
      selectedValue,
      setSelectedValue,
    }),
    [pinnedCell, selectedValue]
  )

  const renderedRow = React.useMemo(() => {
    if (collapsed) return <CollapsedRow row={row} />

    switch (size) {
      case StatementSize.Condensed:
        return <CondensedStatementRow row={row} details={details} />

      case StatementSize.Full:
      case undefined:
        return <FullStatementRow row={row} details={details} />
    }
  }, [collapsed, details, row, size])

  return (
    <RowSelectionContext.Provider value={selectionContext}>
      {renderedRow}
    </RowSelectionContext.Provider>
  )
}

const FullStatementRow: React.FC<{ row: StatementRow; details: RowDetails | undefined }> = ({
  row,
  details,
}) => {
  const {
    rowId: id,
    depth,
    leafCategorySummary,
    parentCategorySummary,
    parentCategoryTitle,
    sectionSummary,
    sectionTitle,
  } = row

  /**
   * SECTION TITLE
   * parentCategorySummary
   *   leafCategorySummary
   * ---------------------
   * parentCategorySummary
   * ---------------------
   * sectionSummary
   */

  // Revenue, Accounts Receivable (A/R), etc
  if (leafCategorySummary)
    return (
      <LeafCategorySummary id={id} depth={depth} summary={leafCategorySummary} details={details} />
    )

  // Total Business Development, Total Payroll Expenses, etc
  if (parentCategorySummary)
    return (
      <ParentCategorySummary
        id={id}
        depth={depth}
        summary={parentCategorySummary}
        details={details}
      />
    )

  // Current Assets, Business Development, Payroll Expenses, etc
  if (parentCategoryTitle)
    return (
      <ParentCategoryTitle id={id} depth={depth} title={parentCategoryTitle} details={details} />
    )

  // Total Liabilities and Equity, Total Equity, etc
  if (sectionSummary)
    return <SectionSummary id={id} depth={depth} summary={sectionSummary} details={details} />

  // INCOME, OPERATING EXPENSES, etc
  if (sectionTitle)
    return <RowSectionTitle id={id} depth={depth} title={sectionTitle} details={details} />

  return null
}

const CondensedStatementRow: React.FC<{
  row: StatementRow
  details: RowDetails | undefined
}> = ({ row, details }) => {
  const { rowId: id, depth, sectionSummary } = row

  if (sectionSummary)
    return (
      <CondensedSectionSummary id={id} depth={depth} summary={sectionSummary} details={details} />
    )

  // Omit all other rows.

  return null
}

const Row: React.FC<{
  rowId: string
  className?: string
  depth: number
  details?: RowDetails
  onClick?: () => void
  children?: React.ReactNode
}> = ({ rowId, className, depth, details, onClick, children }) => {
  const { onMouseMove, onMouseLeave } = useRowSelectionHandlers()

  return (
    <RowStyled
      className={className}
      depth={depth}
      onClick={onClick}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
    >
      {children}
      {details && <RowDeltas rowId={rowId} details={details} />}
    </RowStyled>
  )
}

const RowSectionTitle: React.FC<SectionTitleProps> = ({ id, depth, title: { title }, details }) => {
  const { toggleCollapsedSection } = useReportOptionsContext()

  const onClick = React.useCallback(() => {
    toggleCollapsedSection(id)
  }, [id, toggleCollapsedSection])

  return (
    <CollapsibleRow rowId={id} expandedButtonOffset={10} depth={depth}>
      <DetailsPopOver rowId={id} depth={depth} details={details}>
        <Row rowId={id} depth={depth}>
          <SectionTitle data-context-id={id} onClick={onClick}>
            {title}
          </SectionTitle>
        </Row>
      </DetailsPopOver>
    </CollapsibleRow>
  )
}

const RowWithDetails: React.FC<{
  className?: string
  details?: RowDetails
  id: string
  depth: number
  children?: React.ReactNode
}> = ({ className, id, depth, details, children }) => (
  <DetailsPopOver rowId={id} depth={depth} details={details}>
    <WithComments contextId={id} disabled>
      <Row className={className} rowId={id} depth={depth} details={details}>
        {children}
      </Row>
    </WithComments>
  </DetailsPopOver>
)

const SectionRow = styled(RowWithDetails)<{ $collapsed?: boolean }>`
  margin-bottom: 5px;

  ${({ $collapsed }) =>
    $collapsed
      ? css`
          font-weight: ${fonts.weight.medium};
        `
      : css`
          font-weight: ${fonts.weight.heavy};
        `};
`

const ParentRow = styled(RowWithDetails)<{ $collapsed?: boolean }>`
  ${({ $collapsed }) =>
    !$collapsed &&
    css`
      font-weight: ${fonts.weight.heavy};
    `};
`

const SectionSummary: React.FC<SectionSummaryProps> = ({
  id,
  depth,
  summary: { label, moneyFlow },
  details,
}) => {
  const { path } = useRowDetailsLinks(details)
  const isSectionCollapsed = useIsSectionCollapsed(id)

  const title = React.useMemo(
    () => (isSectionCollapsed ? label.replace("Total ", "") : label),
    [isSectionCollapsed, label]
  )

  const hasBorder = !isSectionCollapsed
  const borderStyle = hasBorder
    ? BORDER_STYLE
    : css`
        margin-top: 10px;
      `

  const contents = (
    <SectionRow
      id={id}
      depth={depth}
      details={details}
      css={borderStyle}
      $collapsed={isSectionCollapsed}
    >
      <SectionSummaryTitle>
        <Link to={path}>{title}</Link>
      </SectionSummaryTitle>
      <StatementTrendLine details={details} />
      <TotalsOverTime rowId={id} details={details} />
      <SectionSummaryText data-context-id={id}>
        <Amount id={id} moneyFlow={moneyFlow} />
      </SectionSummaryText>
    </SectionRow>
  )

  return isSectionCollapsed ? (
    <CollapsibleRow rowId={id} depth={depth}>
      {contents}
    </CollapsibleRow>
  ) : (
    contents
  )
}

const CondensedSectionSummary: React.FC<SectionSummaryProps> = ({
  id,
  depth,
  summary: { label, moneyFlow },
  details,
}) => {
  const options = useReportCurrencyOptions()
  return (
    <RowWithDetails id={id} depth={depth} details={details}>
      <CondensedSectionSummaryContainer>
        <CondensedSectionSummaryTitle>{label}</CondensedSectionSummaryTitle>
      </CondensedSectionSummaryContainer>
      <CondensedSectionSummaryText data-context-id={id}>
        {moneyFlowHelper.currency(moneyFlow, options)}
      </CondensedSectionSummaryText>
    </RowWithDetails>
  )
}

const ParentCategoryTitle: React.FC<TitleProps> = ({ id, depth, title: { title }, details }) => {
  const isSectionCollapsed = useIsSectionCollapsed(id)
  const { disabled, path } = useRowDetailsLinks(details)

  let content = (
    <Title depth={depth} disabled={disabled} data-context-id={id}>
      <DisplayNumber details={details} />
      <Link to={path}>{title}</Link>
    </Title>
  )

  if (!isSectionCollapsed) {
    content = <ParentCategorySpacing>{content}</ParentCategorySpacing>
  }

  return (
    <CollapsibleRow rowId={id} depth={depth}>
      <RowWithDetails id={id} depth={depth}>
        {content}
      </RowWithDetails>
    </CollapsibleRow>
  )
}

const ParentCategorySummary: React.FC<SummaryProps> = ({
  id,
  depth,
  summary: { label, moneyFlow },
  details,
}) => {
  const isSectionCollapsed = useIsSectionCollapsed(id)
  const { disabled, path } = useRowDetailsLinks(details)

  const title = React.useMemo(
    () => (isSectionCollapsed ? label.replace("Total ", "") : label),
    [isSectionCollapsed, label]
  )

  const hasBorder = !isSectionCollapsed
  const borderStyle = hasBorder ? BORDER_STYLE : undefined

  const contents = (
    <ParentRow
      id={id}
      depth={depth}
      details={details}
      css={borderStyle}
      $collapsed={isSectionCollapsed}
    >
      <ParentTitle $collapsed={isSectionCollapsed} disabled={disabled}>
        <Title depth={depth}>
          <Link to={path}>{title}</Link>
        </Title>
      </ParentTitle>
      <StatementTrendLine details={details} />
      <TotalsOverTime rowId={id} details={details} />
      <Amount id={id} moneyFlow={moneyFlow} />
    </ParentRow>
  )

  return isSectionCollapsed ? (
    <CollapsibleRow rowId={id} depth={depth}>
      {contents}
    </CollapsibleRow>
  ) : (
    contents
  )
}

const LeafCategorySummary: React.FC<SummaryProps> = ({
  id,
  depth,
  summary: { label, moneyFlow },
  details,
}) => {
  const { disabled, path } = useRowDetailsLinks(details)

  return (
    <RowWithDetails id={id} depth={depth} details={details}>
      <Title depth={depth} disabled={disabled}>
        <DisplayNumber details={details} />
        <Link to={path}>{label}</Link>
      </Title>
      <StatementTrendLine details={details} />
      <TotalsOverTime rowId={id} details={details} />
      <Amount id={id} moneyFlow={moneyFlow} />
    </RowWithDetails>
  )
}

const Amount: React.FC<{ id: string; moneyFlow: MoneyFlow }> = ({ id, moneyFlow }) => {
  const options = useReportCurrencyOptions()
  const { onMouseEnter, onClick } = useStatementCellEvents(id)
  return (
    <TotalCell data-context-id={id} onMouseEnter={onMouseEnter} onClick={onClick}>
      {moneyFlowHelper.currency(moneyFlow, options)}
    </TotalCell>
  )
}

const DisplayNumber: React.FC<{ details?: RowDetails }> = ({ details }) => {
  const { reportPackage } = useReportPackageContext()
  const periodDetails = details?.periodDetails
  const hideAccountNumbers =
    reportPackage?.packageOptions?.accountNumbersDisplay === AccountNumbersDisplay.Hidden
  const displayNumber = extractAccountDisplayNumber(periodDetails)
  if (hideAccountNumbers || !displayNumber) return null

  return <StyledDisplayNumber>{displayNumber}&nbsp;&nbsp;</StyledDisplayNumber>
}
