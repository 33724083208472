import * as React from "react"
import { IntervalOrigin } from "@digits-graphql/frontend/graphql-bearer"
import styled from "styled-components"
import { GradientStackableBarChart } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/GradientStackableBarChart"
import { StackableBarData } from "src/frontend/components/OS/Shared/Charts/StackableBarChart/Shared"
import {
  StackableBarChartLabelType,
  StackableBarChartLegendType,
} from "src/frontend/components/OS/Shared/Charts/StackableBarChart/StackableBarChartContext"
import {
  BasicDimension,
  DimensionSummary,
  DimensionSummaryHistory,
  PeriodDimensionSummary,
  useDimensionSummaryStackableData,
} from "src/frontend/components/OS/Shared/Charts/StackableBarChart/useDimensionSummaryStackableData"
import { ChartContainer } from "src/frontend/components/Shared/Layout/Components/Charts/shared"
import { ComponentSize } from "src/frontend/components/Shared/Layout/ComponentSize"
import { SizingProps } from "src/frontend/components/Shared/Layout/types"

const ChartSegment = styled(ChartContainer)`
  padding-bottom: 10px;
  svg {
    overflow: visible;
  }
`

/*
  COMPONENTS
*/

export interface StackableBarChartComponentData<D extends BasicDimension> {
  topDimensions: DimensionSummary<D>[]
  summaries: PeriodDimensionSummary<D>[]
  histories: DimensionSummaryHistory[]
}

interface Props<D extends BasicDimension> extends SizingProps {
  componentSize: ComponentSize
  data: StackableBarChartComponentData<D>
  intervalOrigin: IntervalOrigin
  loading: boolean
  onBarClick?: (
    barData: StackableBarData | undefined,
    periodIndex: number,
    event: React.MouseEvent
  ) => void
  onMouseOver?: (barData: StackableBarData | undefined, periodIndex: number) => void
  onMouseOut?: (barData?: StackableBarData) => void

  legend?: StackableBarChartLegendType
  alwaysShowAxisTicks?: boolean
  emptyNode?: React.ReactNode
}

export function StackedBarChartComponent<D extends BasicDimension>({
  data,
  intervalOrigin,
  loading,
  onBarClick,
  onMouseOver,
  onMouseOut,
  emptyNode,
  legend = StackableBarChartLegendType.Mini,
  alwaysShowAxisTicks = true,
  height,
}: Props<D>) {
  const { topDimensions, summaries, histories } = data
  const chartData = useDimensionSummaryStackableData(topDimensions, summaries, histories)

  return (
    <ChartSegment height={height} width="auto">
      <GradientStackableBarChart
        data={chartData}
        intervalOrigin={intervalOrigin}
        label={StackableBarChartLabelType.AllBarPeriods}
        legend={legend}
        isLoading={loading}
        onBarClick={onBarClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        alwaysShowAxisTicks={alwaysShowAxisTicks}
        highlightAllPeriodsOnHover
        emptyNode={emptyNode}
      />
    </ChartSegment>
  )
}
