import * as React from "react"
import { DocumentStatus, FormatClass } from "@digits-graphql/frontend/graphql-bearer"
import { SvgDownload01 } from "@digits-shared/components/SVGIcons/line/Download01.svg"
import { SvgTrash01 } from "@digits-shared/components/SVGIcons/line/Trash01.svg"
import {
  EllipsisMenuTrigger,
  Menu,
  MenuContent,
  MenuItem,
  MenuItemSeparator,
} from "@digits-shared/DesignSystem/Menu"
import fileHelper from "@digits-shared/helpers/fileHelper"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import styled, { css } from "styled-components"
import { VaultFile } from "src/frontend/components/OS/Applications/Vault/types"
import { useDownloadVaultFile } from "src/frontend/components/OS/Applications/Vault/useDownloadVaultFile"
import { DeleteFileConfirm } from "src/frontend/components/OS/Applications/Vault/VaultBrowser/DeleteFileButton"
import { FileSelection } from "src/frontend/components/OS/Applications/Vault/VaultBrowser/useVaultFileSelection"
import { fileTypeFor } from "src/frontend/components/OS/Applications/Vault/vaultMime"
import { documentDownloadUrl } from "src/frontend/components/OS/Shared/DocumentViewer/helpers"

/*
  STYLES
*/

const StyledMenuTrigger = styled(EllipsisMenuTrigger)<{ $hidden: boolean }>`
  position: absolute;
  top: 10px;
  right: 10px;

  ${({ $hidden }) =>
    $hidden &&
    css`
      opacity: 0;
      pointer-events: none;
    `};
`

/*
  INTERFACES
*/

/*
  COMPONENTS
*/

export const FileActionsMenu: React.FC<{
  file: VaultFile
  selection: FileSelection | undefined
  className?: string
}> = ({ file, selection, className }) => {
  const hidden = file.status === DocumentStatus.New || !!selection?.selectedIds.size
  const { value: show, setTrue: showConfirm, setFalse: hideConfirm } = useStateBoolean()

  return (
    <>
      <Menu>
        <StyledMenuTrigger $hidden={hidden} size="small" inert={hidden} />

        <MenuContent small>
          <DownloadFile file={file} />
          <DownloadCSV file={file} />
          <MenuItemSeparator />
          <MenuItem label="Delete file…" Icon={SvgTrash01} onSelect={showConfirm} />
        </MenuContent>
      </Menu>

      <DeleteFileConfirm
        file={file}
        show={show}
        showConfirm={showConfirm}
        hideConfirm={hideConfirm}
      />
    </>
  )
}

const DownloadFile: React.FC<{
  file: VaultFile
}> = ({ file }) => {
  const downloadFile = useDownloadVaultFile()

  const onDownloadFile = React.useCallback(() => downloadFile(file), [downloadFile, file])

  const fileType = React.useMemo(() => fileHelper.fileTypeDisplayName(fileTypeFor(file)), [file])

  // Cant download if there is no source format id (pre-Vault docs)
  if (!file.sourceFormat) return null

  return (
    <MenuItem
      Icon={SvgDownload01}
      label={`Download ${fileType || "file"}`}
      onSelect={onDownloadFile}
    />
  )
}

const DownloadCSV: React.FC<{
  file: VaultFile
}> = ({ file }) => {
  const csvFile = React.useMemo(
    () => file.formats.find((format) => format.formatClass === FormatClass.CSV)?.segments?.[0],
    [file.formats]
  )

  const onDownloadFile = React.useCallback(() => {
    if (!csvFile) return
    return (window.location.href = documentDownloadUrl(
      csvFile.collectionId,
      csvFile.fileId,
      csvFile.token,
      `${file.title ?? file.fileName ?? "export"}.csv`
    ))
  }, [csvFile, file.fileName, file.title])

  if (!csvFile) return null

  return <MenuItem Icon={SvgDownload01} label="Export as CSV" onSelect={onDownloadFile} />
}
