import * as React from "react"
import {
  ActionItemKind,
  ActionItemThreadFieldsFragment,
  EntityUser,
} from "@digits-graphql/frontend/graphql-bearer"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import stringHelper from "@digits-shared/helpers/stringHelper"
import userHelper from "@digits-shared/helpers/userHelper"
import { ActionItemRow } from "src/frontend/components/Shared/NavSidebar/ActionItems/Row/ActionItemRow"
import { NameDateDetail } from "src/frontend/components/Shared/NavSidebar/ActionItems/Row/NameAndDate"

/*
  COMPONENTS
*/

export const ThreadRow: React.FC<{
  date: string
  author?: EntityUser | null
  targetEntity: ActionItemThreadFieldsFragment["targetEntity"]
}> = ({ date, author, targetEntity }) => {
  const { title, iconUrl, categoryName } = processThreadTarget(targetEntity)
  return (
    <ActionItemRow
      title={title}
      actionItemKind={ActionItemKind.Comment}
      categoryName={categoryName}
      party={{
        name: author?.givenName,
        iconUrl: iconUrl || author?.avatarUrl,
      }}
    >
      {author && (
        <NameDateDetail
          date={date}
          secondary={`${userHelper.displayName(author, "abbreviateLast")}`}
        />
      )}
    </ActionItemRow>
  )
}

function processThreadTarget(targetEntity: ActionItemThreadFieldsFragment["targetEntity"]): {
  title: string
  categoryName?: string | null
  iconUrl?: string | null
} {
  switch (targetEntity.__typename) {
    case "EntityCategory":
      return {
        title: targetEntity.name ? `Commented on ${targetEntity.name}` : "Commented on a category",
        categoryName: targetEntity.name,
      }
    case "EntityParty":
      return {
        title: targetEntity.name ? `Commented on ${targetEntity.name}` : "Commented on a party",
        iconUrl: targetEntity.iconUrl,
      }
    case "EntityReportPackage":
      return {
        title: targetEntity.title ? `Commented on ${targetEntity.title}` : "Commented on a report",
      }
    case "EntityLedgerTransaction": {
      const transactionMoment = dateTimeHelper.momentFromTimestamp(targetEntity.timestamp)
      return {
        title: transactionMoment
          ? `Commented on a transaction from ${transactionMoment.format("MMMM D")}`
          : "Commented on a transaction",
      }
    }
    case "EntityWorkItem":
      return {
        title: targetEntity.objectTransaction.party
          ? `Asked a question on ${stringHelper.getIndefiniteArticle(targetEntity.objectTransaction.party?.name)} ${targetEntity.objectTransaction.party?.name} transaction`
          : "Asked a question on a transaction",
        iconUrl: targetEntity.objectTransaction.party?.iconUrl,
      }
  }
  return { title: "New Comment" }
}
