import React from "react"
import {
  BillingProductName,
  Interval,
  useReadOrganizationBillingPlanQuery,
} from "@digits-graphql/frontend/graphql-bearer"
import useSession from "@digits-shared/hooks/useSession"
import { JWTPermissionFlag } from "@digits-shared/session/jwt/jwtPermissions"
import { useAffiliationEntities } from "src/frontend/components/OS/Applications/hooks/useAffiliationEntities"
import { useHasPermission } from "src/frontend/components/Shared/Permissions/Requires"
import FrontendSession from "src/frontend/session/FrontendSession"
import {
  FrontendPermissionModule,
  FrontendPermissionSource,
} from "src/frontend/session/permissionModule"

const PLAN_INTERVAL_TEXT_MAPPING: { [key: string]: "Monthly" | "Annual" } = {
  [Interval.Month]: "Monthly",
  [Interval.Year]: "Annual",
}

export function useBillingOrganizationPlan(organizationId?: string) {
  const session = useSession<FrontendSession>()
  const hasReadBillingPermission = useHasPermission({
    source: FrontendPermissionSource.Organization,
    module: FrontendPermissionModule.Billing,
    flag: JWTPermissionFlag.Read,
  })

  const organization = React.useMemo(
    () => session.findOrganizationById(organizationId ?? ""),
    [session, organizationId]
  )

  const { data, loading } = useReadOrganizationBillingPlanQuery({
    variables: {
      organizationId: organizationId ?? "",
    },
    skip: !organizationId || !hasReadBillingPermission || !organization?.hasAffiliations,
    context: { noBatch: true },
  })

  const clientCount = useClientCount()
  return React.useMemo(() => {
    const plan = data?.readOrganizationBillingPlan.plan

    const planName = plan?.product.name ?? BillingProductName.Free
    const planMaxLimit = plan?.legalEntityLimit?.maxLegalEntityLimit ?? clientCount
    const planDetails = generatePlanDetails(planName, planMaxLimit)
    const planInterval = plan?.price.interval
    const planIntervalText = PLAN_INTERVAL_TEXT_MAPPING[plan?.price.interval || ""] || ""

    const planHasLimit = planName !== BillingProductName.FreeForever
    const planExceeded = planHasLimit && planMaxLimit ? planMaxLimit < clientCount : false
    const planMaxed = planHasLimit && planMaxLimit <= clientCount

    return {
      loading,
      plan,
      planName,
      planMaxLimit,
      planDetails,
      planInterval,
      planIntervalText,
      planExceeded,
      planMaxed,
      planHasLimit,
      clientCount,
    }
  }, [clientCount, data?.readOrganizationBillingPlan.plan, loading])
}

const useClientCount = () => {
  const { currentOrganization } = useSession<FrontendSession>()
  const { affiliationEntities } = useAffiliationEntities(currentOrganization?.id)

  return affiliationEntities.length
}

const generatePlanDetails = (planName: BillingProductName, maxLELimit?: number) => {
  if (planName === BillingProductName.UnknownName) {
    return ""
  }

  if (planName === BillingProductName.Free || planName === BillingProductName.FreeForever) {
    return `${maxLELimit} Clients for Free`
  }

  return maxLELimit ? `${maxLELimit} Clients Included` : "Unlimited Clients Included"
}
