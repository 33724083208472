import * as React from "react"
import { StatementRow } from "@digits-graphql/frontend/graphql-bearer"
import { max } from "d3-array"
import {
  SectionSummaryTitle,
  STATEMENT_COLUMN_WIDTH,
  StatementSize,
  StatementTrendLineCell,
  Title,
  TotalCell,
} from "src/frontend/components/Shared/Reports/Report/Components/Statements/shared"
import { HeaderStyled } from "src/frontend/components/Shared/Reports/Report/Components/Statements/StatementHeader"
import { useReportDocumentOptions } from "src/frontend/components/Shared/Reports/Report/hooks/useReportDocumentOptions"

export function useRowCellSizes(
  rowsRef: React.RefObject<HTMLDivElement>,
  rows: StatementRow[],
  size?: StatementSize
) {
  const templateRef = React.useRef<HTMLDivElement>(null)
  const options = useReportDocumentOptions()
  const [maxTitleCellWidth, setMaxTitleWidth] = React.useState<string | number>("1fr")
  const [trendlineWidth, setTrendlineWidth] = React.useState<string | number>("auto")
  const [maxTotalCellWidth, setMaxTotalWidth] = React.useState(STATEMENT_COLUMN_WIDTH)

  React.useEffect(() => {
    if (!rowsRef.current || !templateRef.current) return

    setMaxTotalWidth(() => {
      if (!rowsRef.current || !templateRef.current) return STATEMENT_COLUMN_WIDTH
      const totalsCells = Array.from(
        rowsRef.current.querySelectorAll(`.${TotalCell.styledComponentId}`)
      ).map((c) => c.cloneNode(true) as HTMLElement)
      const headerCells = Array.from(
        rowsRef.current.querySelectorAll(`.${HeaderStyled.styledComponentId}`)
      ).map((c) => c.cloneNode(true) as HTMLElement)

      const cellsToMeasure = [...totalsCells, ...headerCells]
      templateRef.current.innerHTML = ""
      templateRef.current.append(...cellsToMeasure)

      const maxWidth = Math.max(
        max(cellsToMeasure, (c) => c.offsetWidth) ?? 0,
        STATEMENT_COLUMN_WIDTH
      )
      templateRef.current.innerHTML = ""
      return maxWidth
    })

    setMaxTitleWidth(() => {
      if (size === StatementSize.Condensed || !rowsRef.current || !templateRef.current) {
        return "1fr"
      }

      const titleCells = Array.from(
        rowsRef.current.querySelectorAll(`.${Title.styledComponentId}`)
      ).map((c) => c.cloneNode(true) as HTMLElement)
      const sectionTitleCells = Array.from(
        rowsRef.current.querySelectorAll(`.${SectionSummaryTitle.styledComponentId}`)
      ).map((c) => c.cloneNode(true) as HTMLElement)

      const cellsToMeasure = [...titleCells, ...sectionTitleCells]
      templateRef.current.innerHTML = ""
      templateRef.current.append(...cellsToMeasure)

      const maxWidth = Math.max(
        max(cellsToMeasure, (c) => {
          const padding =
            parseFloat(window.getComputedStyle(c).paddingLeft) +
            parseFloat(window.getComputedStyle(c).paddingRight)
          return c.offsetWidth + padding + 5
        }) ?? 0,
        STATEMENT_COLUMN_WIDTH
      )
      templateRef.current.innerHTML = ""
      return maxWidth
    })

    setTrendlineWidth(() => {
      if (size === StatementSize.Condensed || !rowsRef.current || !templateRef.current) {
        return "auto"
      }

      const cells = Array.from(
        rowsRef.current.querySelectorAll(`.${StatementTrendLineCell.styledComponentId}`)
      ).map((c) => c.cloneNode(true) as HTMLElement)
      templateRef.current.innerHTML = ""
      templateRef.current.append(...cells)

      const maxWidth = max(cells, (c) => c.offsetWidth) || "auto"
      templateRef.current.innerHTML = ""
      return maxWidth
    })

    templateRef.current.innerHTML = ""
  }, [options, size, rows, rowsRef])

  return React.useMemo(
    () => ({
      templateRef,
      trendlineWidth,
      maxTitleCellWidth,
      maxTotalCellWidth,
    }),
    [maxTitleCellWidth, maxTotalCellWidth, trendlineWidth]
  )
}
