import * as React from "react"
import { MonetaryValue } from "@digits-graphql/frontend/graphql-bearer"
import { useInvertValues } from "@digits-shared/components/Contexts/InvertValuesContext"
import monetaryValueHelper from "@digits-shared/helpers/monetaryValueHelper"
import numberHelper, { CurrencyStyle } from "@digits-shared/helpers/numberHelper"
import colors from "@digits-shared/themes/colors"
import fonts from "@digits-shared/themes/typography"
import { Text } from "@visx/text"
import styled from "styled-components"
import {
  STACKABLE_BAR_CHART_ALL_BAR_LABEL_HEIGHT,
  STACKABLE_BAR_CHART_X_AXIS_OFFSET,
  StackableBarData,
  StackableBarDimensions,
} from "src/frontend/components/OS/Shared/Charts/StackableBarChart/Shared"
import {
  StackableBarChartLabelType,
  useStackableBarChartContext,
} from "src/frontend/components/OS/Shared/Charts/StackableBarChart/StackableBarChartContext"

/*
 STYLES
*/

const AmountLabel = styled(Text)`
  font-size: 13px;
  font-weight: ${fonts.weight.medium};
  fill: ${colors.secondary};
`

/*
 COMPONENTS
*/

export const StackableBarChartLabelForAllBarPeriods: React.FC<{
  isLastStack?: boolean
  graphHeight: number
  periodBarData: StackableBarData[]
  barDimensions: StackableBarDimensions
}> = ({ isLastStack, graphHeight, periodBarData, barDimensions }) => {
  const { amountLabel, labelY } = usePeriodDataAmount(
    isLastStack,
    graphHeight,
    periodBarData,
    barDimensions,
    false
  )

  return (
    <>
      {amountLabel !== undefined && (
        <AmountLabel x={barDimensions.x + barDimensions.width / 2} y={labelY} textAnchor="middle">
          {amountLabel}
        </AmountLabel>
      )}
    </>
  )
}

function usePeriodDataAmount(
  isLastStack: boolean | undefined,
  graphHeight: number,
  periodData: StackableBarData[],
  dimensions: StackableBarDimensions,
  aggregateTotal?: boolean
) {
  const invertValues = useInvertValues()
  const { tooltipData, label } = useStackableBarChartContext()
  const height = graphHeight ?? 0

  if (!tooltipData || !isLastStack || label !== StackableBarChartLabelType.AllBarPeriods) return {}

  let totalValue: MonetaryValue

  if (aggregateTotal) {
    const initialValue = numberHelper.buildMonetaryAmount()

    totalValue = periodData.reduce(
      (total, barData) => monetaryValueHelper.addValues(total, barData.total.value),
      initialValue
    )
  } else {
    const hoveredPeriodData = periodData.find((barData) => barData.dataId === tooltipData.dataId)

    if (!hoveredPeriodData) return {}

    totalValue = hoveredPeriodData.total.value
  }

  // if there are too many bars, ignore the 0.00
  if (totalValue.amount === 0) return {}

  const renderOptions = { style: CurrencyStyle.Summary, invertValues }
  const amountLabel = numberHelper.currency(totalValue, renderOptions) as string

  const totalAmount = invertValues ? -totalValue.amount : totalValue.amount
  // if the amount is negative, the label will be drawn below the bar; otherwise it's drawn
  // above it.
  const labelY =
    totalAmount < 0
      ? height - STACKABLE_BAR_CHART_ALL_BAR_LABEL_HEIGHT + STACKABLE_BAR_CHART_X_AXIS_OFFSET
      : dimensions.y - 7

  return { amountLabel, labelY }
}
