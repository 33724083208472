import * as React from "react"
import { ApolloError, MutationResult } from "@apollo/client"
import {
  TokenRefreshMutation,
  useTokenRefreshMutation,
} from "@digits-graphql/frontend/graphql-public"
import useSession from "@digits-shared/hooks/useSession"
import { GraphQLError } from "graphql"
import FrontendSession from "src/frontend/session"

type FetchToken = [(id: string) => Promise<string>, MutationResult<TokenRefreshMutation>]

export default function useFetchLegalEntityToken(): FetchToken {
  const [fetchToken, results] = useTokenRefreshMutation()
  const session = useSession<FrontendSession>()

  return [
    React.useCallback(
      (legalEntityId: string) => {
        // Don't attempt to fetch if the user is logged out
        if (!session.hasUserData) {
          return Promise.reject("logged out")
        }

        return fetchToken({
          variables: {
            activeLegalEntityId: legalEntityId,
          },
          context: {
            publicAPI: true,
            skipNotifyTokenUpdate: true,
          },
        }).then((response) => {
          if (response.errors) throw new ApolloError({ graphQLErrors: response.errors })
          if (!response.data) {
            throw new ApolloError({
              graphQLErrors: [new GraphQLError("No data returned from token refresh mutation")],
            })
          }
          return response.data.tokenRefresh?.bearer
        })
      },
      [fetchToken, session.hasUserData]
    ),
    results,
  ]
}
