import React from "react"
import { FetchResult, MutationResult } from "@apollo/client"
import {
  ArchiveComponentDataMutation,
  LayoutComponent,
  useArchiveComponentDataMutation,
} from "@digits-graphql/frontend/graphql-bearer"
import { useViewVersion } from "src/frontend/components/Shared/Contexts/useViewVersion"
import {
  useLayoutDispatch,
  useLayoutStore,
} from "src/frontend/components/Shared/Reports/Report/Viewer/Layout/layoutStore"

export function useArchiveComponentData(): [
  (c: LayoutComponent) => Promise<FetchResult<ArchiveComponentDataMutation>>,
  MutationResult<ArchiveComponentDataMutation>,
] {
  const builderDispatch = useLayoutDispatch()
  const layoutId = useLayoutStore((state) => state.layout.layoutId)
  const viewId = useViewVersion()

  const [archiveComponentData, result] = useArchiveComponentDataMutation()

  const mutation = React.useCallback(
    (component: LayoutComponent) => {
      const { config, componentId } = component

      return archiveComponentData({
        variables: {
          layoutId,
          viewId,
          config,
        },
      }).then((res) => {
        const dataId = res.data?.archiveComponentData
        if (!dataId) {
          return res
        }

        // Update the component with the ID of its archived data
        builderDispatch({
          type: "setComponentDataId",
          componentId,
          dataId,
        })

        return res
      })
    },
    [archiveComponentData, builderDispatch, layoutId, viewId]
  )

  return React.useMemo(() => [mutation, result], [mutation, result])
}
