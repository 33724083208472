import * as React from "react"
import { EntityLegalEntity } from "@digits-graphql/frontend/graphql-bearer"
import { LoadingBlock } from "@digits-shared/components/Loaders"
import {
  PickerBubble,
  PickerBubbleIconProps,
  PickerBubbleLabelProps,
} from "@digits-shared/components/UI/Picker/Bubble"
import { PickerPosition } from "@digits-shared/components/UI/Picker/constants"
import stringHelper from "@digits-shared/helpers/stringHelper"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { LegalEntityPickerPopUp } from "src/frontend/components/OS/Applications/SharedWithMe/LegalEntityPicker"
import { WIDE_WIDGET_PADDING } from "src/frontend/components/OS/Shared/Widgets/WidgetContainer"

/*
  STYLES
*/

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 45px;
  padding: ${WIDE_WIDGET_PADDING}px ${WIDE_WIDGET_PADDING}px 10px ${WIDE_WIDGET_PADDING}px;
`

const StyledPickerBubble = styled(PickerBubble)`
  align-items: center;
  font-size: 13px;
  width: 200px;
  height: 32px;
  border: 1px solid ${colors.translucentWhite20};

  &::after {
    top: 14px;
    right: 9px;
  }
`

/*
  COMPONENTS
*/

const Icon: React.FC<PickerBubbleIconProps<Element>> = () => null

const Label: React.FC<PickerBubbleLabelProps<string>> = ({ element }) => (
  <>{stringHelper.camelCaseToSpaces(element)}</>
)

export const SharedWithMeContentHeader: React.FC<{
  legalEntities: EntityLegalEntity[]
  pickedLegalEntity: EntityLegalEntity
  togglePicker: () => void
  isPickerActive: boolean
  onPickerClose: () => void
  onPick: (legalEntity: EntityLegalEntity) => void
}> = ({
  legalEntities,
  pickedLegalEntity,
  togglePicker,
  isPickerActive,
  onPickerClose,
  onPick,
}) => (
  <Container>
    <StyledPickerBubble
      height={24}
      IconComponent={Icon}
      LabelComponent={Label}
      element={pickedLegalEntity.name}
      onClick={togglePicker}
    />
    {isPickerActive && (
      <LegalEntityPickerPopUp
        width={275}
        pickerPosition={PickerPosition.BelowTarget}
        coordinates={{ top: 50 }}
        legalEntities={legalEntities}
        isLoading={false}
        onLegalEntityPick={onPick}
        onClose={onPickerClose}
      />
    )}
  </Container>
)

export const SharedWithMeContentHeaderLoading: React.FC = () => (
  <Container>
    <LoadingBlock width="200px" height="32px" />
  </Container>
)
