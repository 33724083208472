import * as React from "react"
import { usePrevious } from "react-use"
import objectHelper from "@digits-shared/helpers/objectHelper"
import { PartialTransactionListRequestVariables } from "src/frontend/components/OS/Applications/Shared/Transactions/transactionListRequestVariables"
import routes from "src/frontend/routes"

export interface TransactionsTableContextProps {
  legalEntityId: string
  withHeader?: boolean
  noRecurring?: boolean
  noGrouping?: boolean
  expandedTransactionId?: string | null
  expandedCommentId?: string | null
  listRequestVariables?: PartialTransactionListRequestVariables
}

const TransactionsTableContext = React.createContext<TransactionsTableContextProps>({
  legalEntityId: "",
})

export const TransactionsTableContextProvider: React.FC<{
  value: TransactionsTableContextProps
  children?: React.ReactNode
}> = ({
  value: {
    legalEntityId,
    withHeader,
    noRecurring,
    noGrouping,
    expandedTransactionId,
    expandedCommentId,
    listRequestVariables,
  },
  children,
}) => {
  const [filters, setFilters] = React.useState(listRequestVariables)
  const previousAdditionalFilters = usePrevious(listRequestVariables)

  React.useEffect(() => {
    if (objectHelper.deepEqual(listRequestVariables, previousAdditionalFilters))
      setFilters(listRequestVariables)
  }, [listRequestVariables, previousAdditionalFilters])

  const context = React.useMemo<TransactionsTableContextProps>(
    () => ({
      legalEntityId,
      routes,
      withHeader,
      noRecurring,
      noGrouping,
      expandedTransactionId,
      expandedCommentId,
      listRequestVariables: filters,
    }),
    [
      legalEntityId,
      withHeader,
      noRecurring,
      noGrouping,
      expandedTransactionId,
      expandedCommentId,
      filters,
    ]
  )

  return (
    <TransactionsTableContext.Provider value={context}>
      {children}
    </TransactionsTableContext.Provider>
  )
}

export function useTransactionsTableContext(): TransactionsTableContextProps {
  return React.useContext(TransactionsTableContext)
}
