import * as React from "react"
import { PopUp, PopUpHTMLProps } from "@digits-shared/components/UI/Elements/PopUp/PopUp"
import { PickerPosition } from "@digits-shared/components/UI/Picker/constants"
import styled, { css, keyframes } from "styled-components"

const CLOSE_UP_KEYFRAMES = keyframes`
  from { clip-path: inset(0 0 0 0); }
  
  to { clip-path: inset(0 0 100% 0); }
`

const CLOSE_DOWN_KEYFRAMES = keyframes`
  from { clip-path: inset(0 0 0 0); }
  
  to { clip-path: inset(100% 0 0 0); }
`

const StyledAnimatablePopUp = styled(PopUp)<{
  pickerPosition: PickerPosition
  // Required to animate the closing of the pop up
  startCloseAnimation?: boolean
}>`
  ${({ startCloseAnimation, pickerPosition }) =>
    startCloseAnimation &&
    css`
      animation: ${pickerPosition === PickerPosition.AboveTarget
          ? CLOSE_DOWN_KEYFRAMES
          : CLOSE_UP_KEYFRAMES}
        300ms linear forwards;
    `}
`

export const AnimatablePopUp = React.forwardRef<
  HTMLDivElement,
  PopUpHTMLProps & {
    pickerPosition: PickerPosition
    startCloseAnimation?: boolean
    onCloseAnimationEnd?: () => void
  }
>(
  (
    { pickerPosition, startCloseAnimation, onCloseAnimationEnd, onAnimationEnd, as, ...popUpProps },
    ref
  ) => {
    const watchAnimationEnd = React.useCallback(
      (event: React.AnimationEvent<HTMLElement>) => {
        if (event.animationName === CLOSE_UP_KEYFRAMES.getName()) onCloseAnimationEnd?.()
        if (event.animationName === CLOSE_DOWN_KEYFRAMES.getName()) onCloseAnimationEnd?.()
        onAnimationEnd?.(event)
      },
      [onCloseAnimationEnd, onAnimationEnd]
    )

    return (
      <StyledAnimatablePopUp
        ref={ref}
        {...popUpProps}
        pickerPosition={pickerPosition}
        startCloseAnimation={startCloseAnimation}
        onAnimationEnd={watchAnimationEnd}
      />
    )
  }
)
