import * as React from "react"
import { SvgTrash01 } from "@digits-shared/components/SVGIcons/line/Trash01.svg"
import { DigitsButton } from "@digits-shared/DesignSystem/Button"
import { AlertModal } from "@digits-shared/DesignSystem/Modals/AlertModal"
import useStateBoolean from "@digits-shared/hooks/useStateBoolean"
import { VaultFile } from "src/frontend/components/OS/Applications/Vault/types"
import { useDeleteVaultFiles } from "src/frontend/components/OS/Applications/Vault/useDeleteVaultFiles"
import { useStopClickPropagation } from "src/shared/hooks/useStopClickPropagation"

export const DeleteFileButton: React.FC<{ file: VaultFile; className?: string }> = ({
  file,
  className,
}) => {
  const { value: show, setTrue: showConfirm, setFalse: hideConfirm } = useStateBoolean()
  const stopPropagation = useStopClickPropagation({ preventDefault: true })
  const onClick = React.useCallback(
    (e: React.MouseEvent) => {
      stopPropagation(e)
      showConfirm()
    },
    [showConfirm, stopPropagation]
  )

  return (
    <div css="padding: 0 4px;">
      <DeleteFileConfirm
        file={file}
        show={show}
        showConfirm={showConfirm}
        hideConfirm={hideConfirm}
      >
        <DigitsButton
          className={[className, "delete-button"].join(" ")}
          $variant="ghost-dark"
          size="small"
          $circle
          onClick={onClick}
        >
          <SvgTrash01 />
        </DigitsButton>
      </DeleteFileConfirm>
    </div>
  )
}

interface DeleteConfirmProps {
  file: VaultFile
  show: boolean
  showConfirm: () => void
  hideConfirm: () => void
}

export const DeleteFileConfirm: React.FC<React.PropsWithChildren<DeleteConfirmProps>> = ({
  file,
  show,
  showConfirm,
  hideConfirm,
  children,
}) => {
  const {
    deleteFiles,
    result: { loading },
  } = useDeleteVaultFiles()
  const stopPropagation = useStopClickPropagation({ preventDefault: true })

  const onConfirm = React.useCallback(
    (e: React.MouseEvent) => {
      stopPropagation(e)
      deleteFiles(file).finally(hideConfirm)
    },
    [deleteFiles, file, hideConfirm, stopPropagation]
  )

  const onCancel = React.useCallback(
    (e: React.MouseEvent) => {
      stopPropagation(e)
      hideConfirm()
    },
    [hideConfirm, stopPropagation]
  )

  return (
    <>
      {children}

      {show && (
        <AlertModal
          headerLabel="Delete file?"
          primaryButtonLabel={loading ? "Deleting…" : "Delete"}
          primaryButtonClick={onConfirm}
          secondaryButtonLabel="Cancel"
          secondaryButtonClick={onCancel}
          disableAll={loading}
          primaryButtonVariant="error"
        >
          Are you sure you want to delete <b>{file.fileName}</b>?
        </AlertModal>
      )}
    </>
  )
}
