import * as React from "react"
import colorHelper from "@digits-shared/helpers/colorHelper"
import colors from "@digits-shared/themes/colors"
import styled from "styled-components"
import { STATEMENT_HOVERED_COLUMN_CLASSNAME } from "src/frontend/components/Shared/Reports/Report/Components/Statements/shared"

const Highlights = styled.div`
  display: grid;
  grid-template-columns: var(--statement-grid-template);
`

export const HighlightColumn = styled.div`
  &:after {
    border-radius: 6px;
    position: absolute;
    top: 0;
    height: var(--statement-rows-height);
    width: calc(var(--statement-cell-width) + 8px);
    transform: translateX(-4px);
    transition: background-color 10ms 100ms;
    z-index: 1;
    pointer-events: none;
    background-color: ${colorHelper.hexToRgba(colors.secondary, 0.05)};
  }
  &${"." + STATEMENT_HOVERED_COLUMN_CLASSNAME}::after {
    content: "";
  }
`

interface HighlightsProps {
  totalColumns: number
}

export const StatementColumnHighlights: React.FC<HighlightsProps> = ({ totalColumns }) => (
  <Highlights>
    {/* TODO: remove 2 */}
    {Array.from({ length: totalColumns + 2 }).map((_, index) => (
      <HighlightColumn key={index} />
    ))}
  </Highlights>
)
