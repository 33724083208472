import React from "react"
import {
  closestCenter,
  CollisionDetection,
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  PointerSensor,
  pointerWithin,
  rectIntersection,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import {
  DRAWER_DROP_TARGET_ID,
  TRASH_CAN_DROP_TARGET_ID,
} from "src/frontend/components/OS/Applications/ClientPortal/consts"
import { TargetKind } from "src/frontend/components/Shared/DragAndDrop/dragAndDropShared"
import {
  COMPONENT_GUTTER_ID_PREFIX,
  findComponentsIn,
  GUTTER_ID_PREFIX,
} from "src/frontend/components/Shared/Layout/Shared"
import { configToComponent } from "src/frontend/components/Shared/Layout/types"
import { useGetDropInfo } from "src/frontend/components/Shared/Portals/DragAndDrop/useDropInfo"
import { useSetComponentDragOverlay } from "src/frontend/components/Shared/Portals/DragAndDrop/useUpdateComponentDragOverlay"
import { useSetConfigDragOverlay } from "src/frontend/components/Shared/Portals/DragAndDrop/useUpdateConfigDragOverlay"
import {
  usePortalDispatch,
  usePortalStore,
} from "src/frontend/components/Shared/Portals/State/portalStore"
import { rowIdsSelector } from "src/frontend/components/Shared/Portals/State/selectors"
import { PORTAL_CONFIG } from "src/frontend/components/Shared/Portals/State/types"

/*
  COMPONENTS
*/

export const DragAndDropContext: React.FC<React.PropsWithChildren> = ({ children }) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      // Establishes a minimum amount of drag distance (in pixels) before things start being handled as drag-and-drop.
      // This allows clicks on buttons and menus internal to the component to be registered.
      activationConstraint: {
        distance: 1,
      },
    })
  )

  const { collisionDetection, onDragMove, onDragEnd, onDragCancel } = useDndHandlers()

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetection}
      // Using onDragMove to avoid a quick click, move and release
      // to trigger DnD. When this happens very quickly DnDContext will not fire
      // onDragEnd leaving the dragged component in limbo
      onDragMove={onDragMove}
      onDragEnd={onDragEnd}
      onDragCancel={onDragCancel}
    >
      {children}
    </DndContext>
  )
}

/*
  HOOKS
*/

/** Private helper hook to establish drag & drop handler callbacks */
function useDndHandlers() {
  const builderDispatch = usePortalDispatch()
  const layout = usePortalStore((state) => state.layout)
  const dragOverlayContent = usePortalStore((state) => state.dragOverlayContent)

  const { maxComponentsPerRow } = PORTAL_CONFIG
  const rowIds = usePortalStore(rowIdsSelector)
  const setConfigDragOverlay = useSetConfigDragOverlay()
  const setComponentDragOverlay = useSetComponentDragOverlay()
  const getDropInfo = useGetDropInfo()

  /**
   * Adapted from code in: https://github.com/clauderic/dnd-kit/blob/master/stories/2%20-%20Presets/Sortable/MultipleContainers.tsx
   *
   * Custom collision detection strategy optimized for multiple containers
   */
  const collisionDetection: CollisionDetection = React.useCallback(
    (args) => {
      // Start by finding anything that the pointer is within
      const pointerIntersections = pointerWithin(args)

      // Filter these down to things which are priority drop targets
      const priorityPointerIntersections = pointerIntersections.filter(
        (i) =>
          i.id.toString().startsWith(GUTTER_ID_PREFIX) ||
          i.id === DRAWER_DROP_TARGET_ID ||
          i.id === TRASH_CAN_DROP_TARGET_ID
      )

      // Return any priority drop targets we find
      if (priorityPointerIntersections.length) {
        return priorityPointerIntersections
      }

      // Find anything our dragged object's rectangle intersects with
      const rectIntersections = rectIntersection(args)

      // If we're not overlapping anything, then return no collisions. If dropped in this state, the drag is cancelled.
      if (!rectIntersections.length) return []

      // Filter the pointer intersections back down to find the ID of the row that the pointer is within
      const rowIntersectionId: string | undefined = pointerIntersections
        .find((i) => rowIds.has(i.id.toString()))
        ?.id.toString()

      // If the pointer is within a row, limit component gutters we consider to be just those within the row that the
      // pointer is within
      const componentGutters = args.droppableContainers.filter((dc) => {
        if (rowIntersectionId) {
          return (
            dc.id.toString().startsWith(COMPONENT_GUTTER_ID_PREFIX) &&
            dc.id.toString().includes(`r${rowIntersectionId}`)
          )
        }
        return dc.id.toString().startsWith(COMPONENT_GUTTER_ID_PREFIX)
      })

      const intersectionIds = new Set<string>(rectIntersections.map((i) => i.id.toString()))

      const intersectedGutters = componentGutters.filter((dc) =>
        intersectionIds.has(dc.id.toString())
      )

      const guttersToConsider = intersectedGutters.length ? intersectedGutters : componentGutters
      const gutterIds = new Set<string>(guttersToConsider.map((dc) => dc.id.toString()))
      // Only consider rects matching a gutter to consider
      const rectsToConsider = new Map(
        Array.from(args.droppableRects.entries()).filter(([id, _]) => gutterIds.has(id.toString()))
      )

      // Find the ID of the component gutter whose center is closest to the center of our dragged object
      const closest = closestCenter({
        ...args,
        droppableRects: rectsToConsider,
        droppableContainers: guttersToConsider,
      })[0]?.id

      return [{ id: closest || "" }]
    },
    [rowIds]
  )

  const onDragMove = React.useCallback(
    ({ active }: DragMoveEvent) => {
      // overlay content already set, skip move event
      if (dragOverlayContent) return

      const activeData = active.data.current
      const activeConfig = activeData?.config ?? null

      // The component is known directly from the drag data if a component is being dragged.
      // If a config is being dragged, synthesize a new component from that config.
      const activeComponent = activeData?.component ?? configToComponent(activeConfig)

      if (!activeComponent) return

      builderDispatch({ type: "dragStart", activeConfig, activeComponent })

      if (activeConfig) {
        setConfigDragOverlay(activeConfig)
      } else if (activeData) {
        const { sectionId, rowId } = activeData
        const { componentSize, componentWidth } = getDropInfo(sectionId, rowId)

        setComponentDragOverlay(activeComponent, componentSize, componentWidth)
      }
    },
    [
      builderDispatch,
      dragOverlayContent,
      getDropInfo,
      setComponentDragOverlay,
      setConfigDragOverlay,
    ]
  )

  const onDragEnd = React.useCallback(
    ({ active, over }: DragEndEvent) => {
      const overData = over?.data.current
      if (!overData) {
        builderDispatch({ type: "dragCancel" })
        return
      }

      switch (overData.targetKind) {
        case TargetKind.RowGutter: {
          builderDispatch({
            type: "dropInRowGutter",
            sectionId: overData.sectionId,
            index: overData.index,
          })
          break
        }
        case TargetKind.ComponentGutter: {
          const { sectionId, rowId, index } = overData

          // We exclude the active component from the list of those in the row
          // so that it is possible to move a component from a full row to another
          // spot in that row.
          const componentsInRow = findComponentsIn(layout, sectionId, rowId).filter(
            (c) => c.componentId !== active?.id
          )

          if (componentsInRow.length < maxComponentsPerRow) {
            builderDispatch({
              type: "dropInComponentGutter",
              sectionId,
              rowId,
              index,
            })
          }
          break
        }
      }

      builderDispatch({ type: "dragEnd" })
    },
    [builderDispatch, layout, maxComponentsPerRow]
  )

  const onDragCancel = React.useCallback(() => {
    builderDispatch({ type: "dragCancel" })
  }, [builderDispatch])

  return React.useMemo(
    () => ({
      collisionDetection,
      onDragMove,
      onDragEnd,
      onDragCancel,
    }),
    [collisionDetection, onDragMove, onDragEnd, onDragCancel]
  )
}

/*
  HELPERS
*/
